/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceViewModel { 
    name?: string | null;
    deviceType?: string | null;
    deviceTypeId?: number;
    id?: string;
    associatedUser?: string | null;
    associatedUserId?: number | null;
    isDisabled?: boolean;
    isPVTransfer?: boolean;
    isAlarmTransferEnabled?: boolean;
    bC400ConfigurationId?: number | null;
    serialNumber?: string | null;
    replaced?: boolean;
    protectionPlanUrl?: string | null;
    iconName?: string | null;
    lastConnected?: string | null;
    alarmEventNetworksEnabled?: Array<number> | null;
    requestNetworksEnabled?: Array<number> | null;
    liveEnabled?: boolean;
}

