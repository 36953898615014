import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    SecuraMaxApiService,
    TagViewModel,
} from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-tag-type-selection-modal',
    templateUrl: './tag-type-selection-modal.component.html',
    styleUrl: './tag-type-selection-modal.component.css',
})
export class TagTypeSelectionModalComponent {
    tagTypes = [
        { name: 'Date', type: 'date' },
        { name: 'Selection List', type: 'selection' },
        { name: 'Text Entry', type: 'text' },
        // add more tag types as needed
    ];

    allTags: TagViewModel[] = [];

    constructor(
        private securaMaxApiService: SecuraMaxApiService,
        public dialogRef: MatDialogRef<TagTypeSelectionModalComponent>,
        private toastr: SnackbarService
    ) {}

    ngOnInit() {
        this.securaMaxApiService.tags_GetAll().subscribe(
            (tags) => {
                this.allTags = tags.filter((x) => x.isActive === true);
            },
            (err) => {
                this.toastr.error('Error occurred while getting tags.');
            }
        );
    }

    selectTagType(tagType: any) {
      this.dialogRef.close(tagType);
    }
}
