/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BC300PhotoSettings } from './bC300PhotoSettings';
import { BC300RecordSettings } from './bC300RecordSettings';
import { BC300AdminSettings } from './bC300AdminSettings';
import { BC300SystemSettings } from './bC300SystemSettings';
import { BC300InfoSettings } from './bC300InfoSettings';


export interface BC300DeviceSettings { 
    record?: BC300RecordSettings;
    photo?: BC300PhotoSettings;
    system?: BC300SystemSettings;
    info?: BC300InfoSettings;
    admin?: BC300AdminSettings;
}

