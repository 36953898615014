<div class="card mt-3">

    <div class="card-body card-container">
        <h2  i18n>Categories</h2>
        <small  i18n>Allows you to create and edit Categories, as well as assign the required number of Retention days. Videos can be assigned to one or multiple categories allowing you to organize the videos for easy retrieval.</small>

        <div class="outer-container">
            <div *ngIf="showLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div *ngIf="!showLoading" >
                <div *ngIf="categories.length===0">
                    <p class="text-center" i18n>None Defined</p>
                </div>
                <mat-action-list>
                    <button mat-list-item (click)="select(category)" *ngFor="let category of categories">
                        {{category.wrappedObject.name}}
                    </button>
                </mat-action-list>
                <button mat-button class="smx-primary-button mt-2" *ngIf="!showLoading" (click)="add()"
                    [disabled]="isSelected" i18n>Add
                    One</button>
            </div>

            <div *ngIf="isSelected" style="padding-top: 8px;" >
                <h2><span *ngIf="selected.wrappedObject.id > 0" i18n>Edit Category</span><span
                        *ngIf="!!selected.wrappedObject.id === false" i18n>Add
                        Category</span>
                </h2>

                <mat-divider></mat-divider>

                <form name="categoryForm" >
                    <mat-form-field class="form-item">
                        <mat-label i18n>Name</mat-label>
                        <input matInput type="text" required id="catName" name="name" [(ngModel)]="selected.wrappedObject.name">
                    </mat-form-field>

                    <mat-form-field class="form-item">
                        <mat-label i18n>Retain For</mat-label>
                        <input matInput type="number" min="0" max="2000000" required name="retainForDays" id="retainFor"
                            [(ngModel)]="selected.wrappedObject.retainForDays" />
                    </mat-form-field>
                </form>

                <h3 i18n>Rules</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th i18n>Rule Type</th>
                            <th i18n>Rule Parameters</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rule of selected.rules">
                            <td>
                                <div>
                                    <span>{{rule.name}}</span>
                                </div>
                            </td>
                            <td>
                                <div *ngFor="let item of rule.dvrEvents">
                                    <span>{{item.name}}</span>
                                </div>
                            </td>
                            <td>
                                <button mat-mini-fab (click)="removeRule(rule)" color="warn">
                                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button mat-button class="smx-primary-button" (click)="startAddRule()" [disabled]="isAddingNewRule" i18n>
                                    Add New Rule
                                </button>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr *ngIf="isAddingNewRule">
                            <td>
                                <mat-form-field class="form-item" appearance="fill">
                                    <mat-label i18n>Select a Rule Type</mat-label>
                                    <mat-select [(ngModel)]="newRule.wrappedObject.ruleType">
                                        <mat-option *ngFor="let item of lookups.ruleTypes" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="form-item" appearance="fill"
                                    *ngIf="newRule && newRule.wrappedObject.ruleType===3 || newRule.wrappedObject.ruleType===4">
                                    <mat-select [(ngModel)]="newRule.selectedDvrEventTypes" multiple>
                                        <mat-option *ngFor="let item of lookups.dvrEventTypes" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <ui-select multiple ng-model="vm.newRule.EventsToLookFor" theme="bootstrap" ng-show="vm.newRule.RuleType==3 || vm.newRule.RuleType==4">
                                    <ui-select-match placeholder="{{'Choose at least one event' | translate}}">
                                        {{$item.Name}}</ui-select-match>
                                    <ui-select-choices repeat="item in vm.lookups.DVREventTypes | propsFilter: {Name: $select.search}">
                                        <div ng-bind-html="item.Name | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select> -->
                            </td>
                            <td>
                                <button mat-mini-fab (click)="endAddRule()"
                                    [disabled]="(newRule.wrappedObject.ruleType==3 || newRule.wrappedObject.ruleType==4) && newRule.selectedDvrEventTypes.length === 0">
                                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <mat-divider></mat-divider>
                <div class="panel-footer">
                    <div class="d-flex justify-content-start">
                        <button mat-button class="smx-primary-button ml-2 mt-2" (click)="save()"
                            ng-disabled="!categoryForm.$dirty && categoryForm.$invalid" i18n>Save</button>
                        <button mat-button class="smx-secondary-button ml-2 mt-2" (click)="cancel()" i18n>Cancel</button>
                        <button mat-button class="smx-critical-button ml-5 mt-2" (click)="delete()"
                            *ngIf="selected.wrappedObject.id > 0" i18n>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
