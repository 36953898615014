/**
 * Parses the content-range header to get from-to/range of partial page.
 * @param hdr - String of data that contains value of 'content-range' hader.
 */
export function parseRange(hdr) {
    var m = hdr && hdr.match(/^(?:items )?(\d+)-(\d+)\/(\d+|\*)$/);
    if (m) {
        return {
            from: +m[1],
            to: +m[2],
            total: m[3] === '*' ? Infinity : +m[3],
        };
    } else if (hdr === '*/0') {
        return { total: 0 };
    }
    return null;
}

export function delay(time: number): Promise<number> {
    return new Promise((resolve) => setTimeout(resolve, time));
}
