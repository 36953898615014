import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels, NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { interval } from 'rxjs';
import { DeviceCodeModel, IDeviceCode, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from '../../../services/title.service';

export class PVTransferQRCode {
    pvUrl: string;
}

export class DeviceCodeItem implements IDeviceCode {
    code: string;
    approved: boolean;
    created: Date;
    IP: string;
    associatedUserId: number;
    enabledAlarmTransfers: boolean;
    isCompletedRegistration: boolean;
    serial: string;
    deviceName: string;
    deviceId: string;
    configurationType: number = 0;
    configurationItem: any;
}

@Component({
    selector: 'app-devices-bc4-setup',
    templateUrl: './devices-bc4-setup.component.html',
    styleUrls: ['./devices-bc4-setup.component.scss']
})
export class DevicesBc4SetupComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = ['serialNumber', 'status', 'userMode', 'userAssignment', 'finishButton']; // Header for the table columns
    devicePendingApprovalModelList: DeviceCodeItem[] = [];
    devicesPendingApprovalInterval: any = null;   // Holds the $interval object so the interval can be canceled.
    pvTransferQrCode: PVTransferQRCode = new PVTransferQRCode();
    pvTransferUrl: string = "";
    elementType: string = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
    userMode: number = 0;

    constructor(private apiService: SecuraMaxApiService, private toastr: SnackbarService, private titleService: TitleService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.titleService.setTitle('Add BODYCAM 4');

        this.pvTransferQrCode.pvUrl = window.location.protocol + '//' + window.location.host + '/api/PVTransfer';
        this.pvTransferUrl = JSON.stringify(this.pvTransferQrCode);

        this.route.queryParams
            .subscribe(params => {
                if (params.ip && params.ip.length > 0) {
                    this.pvTransferQrCode.pvUrl = window.location.protocol + '//' + params.ip + '/api/PVTransfer';
                    this.pvTransferUrl = JSON.stringify(this.pvTransferQrCode);
                }
            }
            );

        // Kick off polling to check Devices Waiting Approval.
        this.devicesPendingApprovalInterval = interval(5000).subscribe(x => {
            this.CheckDevicesPendingApprovalInterval();
        });
    }

    /**
     * Stops the interval that checks for devices awaiting approval.
     */
    ngOnDestroy(): void {
        this.devicesPendingApprovalInterval.unsubscribe();
    }

    /**
     * Background interval that polls for the Server for Devices Pending Approval.
     * */
    CheckDevicesPendingApprovalInterval() {
        var vm = this;

        this.apiService.device_GetDevicesPendingApproval().subscribe(data => {
            // Creates list that only contains EXISTING items in the UI (devicePendingApprovalModel);
            var exisitingItemsList = data.filter(function (o1) {
                return vm.devicePendingApprovalModelList.some(function (o2) {
                    return o1.serial === o2.serial;
                })
            });

            // Update the properties of 'Approved' and 'IsCompletedRegistrtion' to reflect status in the UI.
            if (exisitingItemsList.length > 0) {
                exisitingItemsList.forEach(function (obj) {
                    var index = vm.devicePendingApprovalModelList.findIndex(dev => dev.serial == obj.serial);
                    if (index > -1) {
                        vm.devicePendingApprovalModelList[index].code = obj.code;
                        vm.devicePendingApprovalModelList[index].approved = obj.approved;
                        vm.devicePendingApprovalModelList[index].isCompletedRegistration = obj.isCompletedRegistration;
                    }
                });
            }

            // Reduced list only leaves NEW items to be added to the UI.
            // Filters items from retuned data that exist in devicePndingApprovalModel so there are no duplicates in the UI.
            var reducedList = <any>data.filter(function (o1) {
                // filter out (!) items in result2
                return !vm.devicePendingApprovalModelList.some(function (o2) {
                    return o1.serial === o2.serial;          // assumes unique id
                })
            });

            // If there is a new item in the reducedList we set ConfigurationType to '0' for 'Multiple Users'.
            // and add it to the list in the UI.
            if (reducedList.length > 0) {
                reducedList.map((obj) => {
                    obj.configurationType = 0;
                });

                vm.devicePendingApprovalModelList = vm.devicePendingApprovalModelList.concat(reducedList);
            }

            // No data at all, reset array.
            if (data.length == 0) {
                vm.devicePendingApprovalModelList = [];
            }
        }, (err) => {
            console.log(err);
        });

    }

    OnApproveDevice(device: DeviceCodeItem) {
        var deviceCodeModel = new DeviceCodeModel();
        deviceCodeModel.code = device.code;
        deviceCodeModel.enableAlarmTransfers = true;

        if (device.configurationType == 0)
            deviceCodeModel.associatedUserId = device.associatedUserId;

        if (device.configurationType == 1)
            deviceCodeModel.associatedUserId = null;

        this.apiService.device_Approve(deviceCodeModel).subscribe(data => {
            this.toastr.success("Successfully approved. Waiting for device to finish...");
            device.approved = true;
        }, (err) => {
            console.log(err);
            this.toastr.error("A server error occurred while submitting the device approval form. " + err.response);
        });
    }

}
