import { ICasesDetailsViewModel, CaseDocumentListViewModel } from 'src/app/services/api/securamaxapi.service';
import { CaseDocumentListViewModelExtension } from 'src/app/models/cases-details-documents-viewmodel.model';

export class CasesDetailsViewModelExtension implements ICasesDetailsViewModel {
    id?: number | undefined;
    title?: string | undefined;
    createDate?: Date | undefined;
    startRange?: Date | undefined;
    endRange?: Date | undefined;
    description?: string | undefined;
    status?: any | undefined;//TODO: verify this type
    modified?: Date | undefined;
    caseStatusChangeDescription?: string | undefined;
    documents?: CaseDocumentListViewModelExtension[] | undefined;
    documentsToRemoveFromCase?: CaseDocumentListViewModelExtension[] | undefined;
    createdByStr?: string | undefined;
    canEditCase?: boolean | undefined;
    ownerId?: number | undefined;
    ownerName?: string | undefined;
    retention?: number | undefined;
    sharedUsersCanEdit?: boolean | undefined;

    constructor(data?: ICasesDetailsViewModel) {
        
        this.documentsToRemoveFromCase = new Array<CaseDocumentListViewModelExtension>();
        this.documents = new Array<CaseDocumentListViewModelExtension>();

        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}