<mat-card class="form-group p-3" *ngIf="tag.tagTypeId===3">
    <mat-card-header class="d-flex justify-content-between">
        <label>
            <fa-icon [icon]="'list'"></fa-icon> {{tag.name}}<span *ngIf="tag.isRequired">*</span>
        </label>
        <button class="delete-button" mat-mini-fab style="background-color:rgb(238, 20, 20); color:white;" (click)="deleteTag(tag)">
            <fa-icon [icon]="['fas','trash']"></fa-icon>
        </button>
    </mat-card-header>


    <mat-card-content>
        <mat-form-field appearance="outline" class="w-75">
            <mat-select [(ngModel)]="tag.value">
                <mat-option *ngFor="let tagOption of tagListOptions" [value]="tagOption.value" > {{tagOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
</mat-card>

<mat-card class="form-group p-3" *ngIf="tag.tagTypeId===2">
    <mat-card-header class="d-flex justify-content-between">
        <label>
            <fa-icon [icon]="'map-marker'"></fa-icon> {{tag.name}}<span *ngIf="tag.isRequired">*</span>
        </label>
        <button class="delete-button" mat-mini-fab style="background-color:rgb(238, 20, 20); color:white;" (click)="deleteTag(tag)">
            <fa-icon [icon]="['fas','trash']"></fa-icon>
        </button>
    </mat-card-header>

    <mat-card-content>
        <mat-form-field appearance="outline" class="w-75">
            <input matInput  placeholder="00.0000 Latitude" min="-90" max="90" type="number" [(ngModel)]="latlongs.latitude" (ngModelChange)="latLongChange()" i18n-placeholder/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-75">
            <input matInput placeholder="00.0000 Longitude" min="-180" max="180" type="number" [(ngModel)]="latlongs.longitude" (ngModelChange)="latLongChange()" i18n-placeholder/>
        </mat-form-field>
    </mat-card-content>
</mat-card>

<mat-card class="form-group p-3" *ngIf="tag.tagTypeId===1">
    <mat-card-header class="d-flex justify-content-between">
        <label>
            <fa-icon [icon]="'info'"></fa-icon> {{tag.name}}<span *ngIf="tag.isRequired">*</span>
        </label>
        <button class="delete-button" mat-mini-fab style="background-color:rgb(238, 20, 20); color:white;" (click)="deleteTag(tag)">
            <fa-icon [icon]="['fas','trash']"></fa-icon>
        </button>
    </mat-card-header>

    <mat-card-content>

        <mat-form-field appearance="outline" class="w-75">
            <input matInput [(ngModel)]="tag.value" type="text"/>
        </mat-form-field>
    </mat-card-content>
</mat-card>
