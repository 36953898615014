<h2 mat-dialog-title>Update Document Data</h2>
<mat-dialog-content *ngIf="!isInitialMetadataLoaded">
    <mat-progress-spinner class="spinner" mode="indeterminate" diameter="40">
    </mat-progress-spinner>
</mat-dialog-content>
<mat-dialog-content *ngIf="isInitialMetadataLoaded">
    <div class="form-section">
        <mat-label i18n>Title</mat-label>
        <mat-form-field class="mt-2 w-100" appearance="outline">
            <input
                matInput
                [placeholder]="
                    data.document.requireDocumentTitle &&
                    data.document.documentTitleRegex
                        ? ''
                        : 'Ex. New Video'
                "
                #title="ngModel"
                [(ngModel)]="titleToEdit"
                [required]="data.document.requireDocumentTitle"
                [pattern]="
                    data.document.requireDocumentTitle
                        ? data.document.documentTitleRegex
                        : ''
                "
                (blur)="titleToEditError = validateMetadataTitle()"
                i18n-placeholder
            />
        </mat-form-field>
        <div *ngIf="titleToEditError">
            {{ titleToEditError }}
        </div>
    </div>

    <div class="form-section">
        <mat-label>Description</mat-label>
        <mat-form-field class="w-100" appearance="outline">
            <textarea
                matInput
                placeholder="Ex. New Video"
                [(ngModel)]="descriptionToEdit"
            ></textarea>
        </mat-form-field>
    </div>

    <div class="form-section">
        <mat-label>Categories</mat-label>
        <mat-chip-set
            class="w-100 my-2"
            #chipList
            aria-label="category selection"
        >
            <mat-chip
                *ngFor="let category of categoryEditList"
                [removable]="true"
                (removed)="removeCategory(category)"
            >
                {{ category.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-set>

        <mat-form-field appearance="outline" class="d-flex">
            <mat-select
                [(ngModel)]="selectedCategory"
                (selectionChange)="addCategory(selectedCategory)"
                ><mat-option
                    *ngFor="
                        let category of categoryList
                            | filterDuplicate : categoryEditList : 'name'
                    "
                    [value]="category"
                    i18n
                >
                    {{ category.name }}</mat-option
                ></mat-select
            >
        </mat-form-field>
    </div>

    <div class="form-section">
        <label>Owner</label>

        <app-user-select
            [(userId)]="ownerSelect.associatedUserId"
            [(associatedUserName)]="ownerSelect.associatedUser"
        ></app-user-select>
    </div>

    <div class="form-section">
        <label>Mark this document as important</label>
        <mat-checkbox [(ngModel)]="importantToEdit"></mat-checkbox>
    </div>

    <div class="form-section">
        <mat-label>Cases</mat-label>

        <mat-chip-set class="w-100 my-2" #chipList aria-label="case selection">
            <mat-chip
                *ngFor="let case of caseEditList"
                [removable]="true"
                (removed)="removeCase(case)"
                i18n
            >
                {{ case.title }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-set>

        <app-case-select
            class="w-100"
            [(caseListItem)]="caseListItem"
            [duplicateFilterList]="caseEditList"
            [duplicateFilterProperty]="'title'"
            (change)="addCase()"
        ></app-case-select>
        <!-- todo readd create case button when support on case create page -->
        <!-- <button class="smx-primary-button create-case-button" i18n>
            Create New Case
        </button> -->
    </div>

    <div class="form-section">
        <mat-label>Tags</mat-label>

        <div
            class="alert alert-info my-2"
            *ngIf="!tagsEditList || tagsEditList.length <= 0"
        >
            No tags currently on document
        </div>
        <app-tag-types
            *ngFor="let tag of tagsEditList; let i = index"
            [tag]="tag"
            (deleteClick)="removeTag(i)"
        ></app-tag-types>

        <div class="mt-3">
            <mat-label>Add new tag by selecting a tag type</mat-label>
            <mat-form-field matLine class="w-100 ca80" appearance="outline">
                <mat-select
                    [(ngModel)]="selectedTag"
                    (selectionChange)="addTag(selectedTag)"
                >
                    <mat-option
                        *ngFor="let tag of tagsList"
                        [value]="tag"
                        [disabled]="!tag.isActive"
                        i18n
                    >
                        {{ tag.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        class="smx-secondary-button"
        mat-dialog-close
        cdkFocusInitial
    >
        Cancel
    </button>
    <button
        mat-button
        class="smx-primary-button ml-2"
        [disabled]="isSavingMetadata"
        (click)="onSaveMetadata()"
    >
        Save
    </button>
</mat-dialog-actions>
