<div class="base-container">
    <div class="dropscroll-area-0" *ngIf="isLoading">
        <div class="dropscroll-area-1">
            <div class="spinner-0">
                <mat-spinner color="accent"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="dropscroll-area-0" *ngIf="!isLoading && datasource">
        <div class="dropscroll-area-1">
            <div class="content-container" *ngIf="datasource.length === 0">
                <mat-card>
                    <mat-card-content class="document-item">
                        <div>No results found for current search and filters.</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="content-container" *ngIf="datasource.length > 0">
                <ng-container *ngFor="let document of datasource">

                    <mat-card>
                        <mat-card-content class="document-item">
                            <app-doc-thumbnail [id]="document.id" [big]="true" [openInNewTab]="true"></app-doc-thumbnail>
                            <div class="document-description-0">
                                <strong i18n>Title: </strong>{{document.title}}<br/>
                                <strong i18n>Owner: </strong>{{document.ownerName}}<br/>
                                <strong i18n>File: </strong>{{document.name}}<br/>
                                <strong i18n>Created: </strong>{{document.created}}<br/>
                                <div *ngIf="document.categories.length != 0">
                                    <strong>Categories: </strong>
                                    <label *ngFor="let cat of document.categories" class="badge badge-default"
                                           i18n>{{cat}}</label>
                                </div>
                                <div *ngIf="document.statusId === documentStatus.archived">
                                    <label class="badge badge-info" i18n>Archived</label>
                                </div>
                            </div>
                            <div *ngIf="(document.children !== undefined && document.children.length > 0)"
                                 class="document-item-child-container">
                                <div *ngFor="let redact of document.children">
                                    <div class="d-flex justify-content-between" style="margin-top: 1rem;">
                                        <div class="document-item-child">
                                            <app-doc-thumbnail [id]="redact.id" [big]="true"></app-doc-thumbnail>
                                            <div class="document-description-0">
                                                <strong i18n>Title: </strong>{{redact.title}}<br/>
                                                <strong i18n>Owner: </strong>{{redact.ownerName}}<br/>
                                                <strong i18n>File: </strong>{{redact.name}}<br/>
                                                <strong i18n>Created: </strong>{{redact.created}}<br/>
                                                <div *ngIf="redact.categories.length != 0">
                                                    <strong i18n>Categories: </strong>
                                                    <label *ngFor="let cat of redact.categories"
                                                           class="badge badge-default" i18n>{{cat}}</label>
                                                </div>
                                                <div *ngIf="document.statusId === documentStatus.archived">
                                                    <label class="badge badge-info" i18n>Archived</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="controls-0">
        <div>
            <mat-radio-button checked>
                <label>List</label>
            </mat-radio-button>
            <mat-radio-button (click)="timeline_click()">
                <label>Timeline</label>
            </mat-radio-button>
        </div>

        <mat-paginator style="grid-column: -2/-1;" #paginator [length]="pagingModel.totalRecordCount" [pageIndex]="pagingModel_currentPageIndex"
                       [pageSize]="pagingModel_perPage" [pageSizeOptions]="pageSizeOptions" (page)="onPageFired($event)"
        >
        </mat-paginator>
    </div>



</div>
