import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { API_BASE_URL, CategoryViewModel } from './api/securamaxapi.service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, @Inject(API_BASE_URL) private baseUrl?: string) { }

  thumbnail(id: string) {
    let url_ = this.baseUrl + "/api/api/Documents/{id}/thumbnail";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");

    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    return this.http.get(url_, {
      'observe': 'response',
      'responseType': 'text',
      'headers': new HttpHeaders({
        "Accept": "text/plain"
      })
    }).pipe(map((resp) => {
      return resp.body;
    }));
  }

  uploaderUrl() {
    let url_ = this.baseUrl + "/api/api/StaticFiles/desktopUploader";

    return this.http.get(url_, {
      'observe': 'response',
      'responseType': 'text',
      'headers': new HttpHeaders({
        "Accept": "text/plain"
      })
    }).pipe(map((resp) => {
      return resp.body;
    }));
  }

  bulkCreateUsers(formData: FormData) {
    let url_ = this.baseUrl + "api/api/UserBulkRegister";
    if (formData === undefined || formData === null)
      throw new Error("There must be file data included.");

    return this.http.post(url_, formData);
  }

  auditReport(id: string, startDate: Date) {
    let url_ = this.baseUrl + "/api/api/AuditReport/UserAuditReport/{id}/{startDate}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    if (startDate === undefined || startDate === null)
      throw new Error("The parameter 'startDate' must be defined.");
    url_ = url_.replace("{startDate}", encodeURIComponent("" + startDate));
    url_ = url_.replace(/[?&]$/, "");

    return this.http.get(url_, {
      'observe': 'response',
      'responseType': 'blob',
      'headers': new HttpHeaders({
        "Content-Type": 'application/pdf',
        "Accept": "application/pdf"
      })
    }).pipe(map((resp) => {
      let cd: String = resp.headers.get('Content-Disposition');
      let filename = cd.replace('attachment; filename=', '');
      const url = window.URL.createObjectURL(resp.body);
      var anchor = document.createElement("a");
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    }));
  }

  ///api/AuditReport/{id}
  downloadDocumentAuditReport(id: string) {

    let url_ = this.baseUrl + "/api/api/AuditReport/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    return this.http.get(url_, {
      'observe': 'response',
      'responseType': 'blob',
      'headers': new HttpHeaders({
        "Content-Type": 'application/pdf',
        "Accept": "application/pdf"
      })
    }).pipe(map((resp) => {
      let cd: String = resp.headers.get('Content-Disposition');
      let filename = cd.replace('attachment; filename=', '');
      const url = window.URL.createObjectURL(resp.body);
      var anchor = document.createElement("a");
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    }))
  }

  downloadCaseAuditReport(id: string) {
    let url_ = this.baseUrl + "/api/api/AuditReport/GetCaseAuditReport/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    return this.http.get(url_, {
      'observe': 'response',
      'responseType': 'blob',
      'headers': new HttpHeaders({
        "Content-Type": 'application/pdf',
        "Accept": "application/pdf"
      })
    }).pipe(map((resp) => {
      let cd: String = resp.headers.get('Content-Disposition');
      let filename = cd.replace('attachment; filename=', '');
      const url = window.URL.createObjectURL(resp.body);
      var anchor = document.createElement("a");
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    }))

  }

  //TODO: this is not a pdf and just shoulnd't work this way. 
  downloadDocument(id: string) {
    let url_ = this.baseUrl + "/api/api/Documents/{id}/download";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");

    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    return this.http.get(url_, {
      'observe': 'response',
      'responseType': 'text',
      'headers': new HttpHeaders({
        "Accept": "text/plain"
      })
    }).pipe(map((resp) => {
      return resp.body;
    }));

  }
}
