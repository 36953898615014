import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SecuraMaxApiService, UserViewModel } from '../../../services/api/securamaxapi.service';
import { UserSelectClearService } from 'src/app/services/user/user-select-clear.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit, OnChanges {
  @ViewChild("userInput", {static: false}) userSelectField: ElementRef;
  
  @Input() userId = 0;
  @Output() userIdChange = new EventEmitter<any>();
  
  @Input() associatedUserName = '';
  @Output() associatedUserNameChange = new EventEmitter<any>();

  @Input() userEmail = 0;
  @Output() userEmailChange = new EventEmitter<any>();
  
  @Input() disabled = false;
  @Output() change = new EventEmitter<any>();

  @Input() includeCurrentUser = true;

  @Input() includeShareUsers = true;

  @Input() userSelectName = '';

  userList: UserViewModel[] = [];
  selectedUser: UserViewModel = new UserViewModel();
  subscription: Subscription;
  searchInput: string;

  constructor(private apiService: SecuraMaxApiService, private toastr: SnackbarService, private userSelectClearService: UserSelectClearService) {
    this.subscription = userSelectClearService.clearUserSelectAnnounced$.subscribe((data) => {
      this.clearSelectBox(data);
    });
  }

  /**
   * Initially fill the userList so it can be used in the for loop.
   */
  ngOnInit(): void {
    let emptyUser = <UserViewModel> {    
      id: null,
      displayName: "",
      email: "",
    }

    // If 'userId' wasn't passed in, push empty user so dropdown still works for 'search' bar.
    if (this.userId == null)
    {
      this.userList.push(emptyUser);
    }
    else {
      this.apiService.users_SearchInitial(this.userId)
        .subscribe(data => {
          this.userList.push(data);
          this.selectedUser = data;
        },
        err=>{
          this.toastr.error("Error occurred getting associated user.");          
          this.userList.push(emptyUser);
        });
    }
  }  

  /**
   * Emits event when one of the properties has changed.
   * @param changes The object that has changed.
   */
  ngOnChanges(changes) {
    this.change.emit();
  }

  /**
   * Receive user input and send to search method.
   * @param value String search term.
   */
  onKey(event) { 
    //event.stopPropagation();
    var value = event.target.value;
    this.apiService.users_Search(value, this.includeCurrentUser, this.includeShareUsers)
    .subscribe(data =>{
      this.userList = data;
    })
  }

  /**
   * Used when user selects a new user from the drop down list.
   * Will emit that the variable passed in will have updated data.
   */
  selectedUserChanged() {
    this.userIdChange.emit(this.selectedUser.id);    
    this.associatedUserNameChange.emit(this.selectedUser.displayName);
    this.userEmailChange.emit(this.selectedUser.email);
    this.change.emit();
  }

  /**
   * Used to determine when the select drop down closed. If it closed with
   * the data list being empty it will re-populate with the selected user
   * so it is displayed to the user.
   * 
   * Also, used to set focus to the search input on drop down open.
   * 
   * @param event Boolean - True drop down opened, False drop down closed.
   */
  dropDownChanged(event) {
    this.userSelectField.nativeElement.focus();

    if(this.userList.length == 0 && !event){      
      this.userList.push(this.selectedUser);
    }
  }

  clearSelectBox(data) {
    if (data === this.userSelectName)
    {
      
      this.selectedUser = new UserViewModel();
      this.searchInput = '';
      this.userSelectClearService.confirmUserClear(this.userSelectName);

      let emptyUser = <UserViewModel> {    
        id: null,
        displayName: "",
        email: "",
      }
      this.userList = [];
      this.userList.push(emptyUser);
      
    }
  }
}
