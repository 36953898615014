<h1 mat-dialog-title>Close Case</h1>
<mat-dialog-content>
    <p i18n>
        Warning:
        Closing a case will now force the documents in the case to adhear to either the 'Default Retention' Site Settings or the document category 'Retention Settings'
        (whichever retention setting is longer). If a document is currently locked from deletion it will remain locked and not be deleted. By closing a case you understand
        that a document could be deleted within 24 hours. To prevent a document from being deleted when the case is closed, lock the document from deletion.
        Click 'Save' button to save changes.
    </p>
    <mat-form-field class="case-close-reason w-100">
        <mat-label i18n>Close Case Reason</mat-label>
        <textarea [(ngModel)]="data.caseCloseReason" matInput i18n-placeholder placeholder="Ex. No longer needed"></textarea>
      </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="smx-primary-button mr-2" mat-dialog-close (click)="CloseSelected()" i18n>Keep Case Open</button>
    <button mat-button class="smx-critical-button" [mat-dialog-close]="data" i18n>Close Case</button>
</mat-dialog-actions>
