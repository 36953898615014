/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TwoFactorAuthMode } from './twoFactorAuthMode';


export interface UserQueryResponse { 
    id?: string;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    isAccountClosed?: boolean;
    key?: number;
    isAccountVerified?: boolean;
    currentTwoFactorAuthStatus?: TwoFactorAuthMode;
    isShareUser?: boolean;
    country?: string | null;
    state?: string | null;
    email?: string | null;
    isAD?: boolean;
    redactionSeat?: string | null;
}
export namespace UserQueryResponse {
}


