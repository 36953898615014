<div class="main-container">
    <div style="display: flex; justify-content: space-between;">

        <app-searchbar (search)="onSearch($event)" (clear)="searchCleared()" placeholder="Device Usage Search"
                       isPrimary="true"></app-searchbar>

        <div *ngIf="planInformation" style="font-size: 18px; margin-right: 20px;">
            <div>
                <div><b>Allocated Storage Available:&nbsp;&nbsp;</b>{{getTotalDisplay()}}<span
                    *ngFor="let plan of planInformation.plans">({{plan.numDevices}}
                    devices x {{this.getPlanSizeDisplay(plan.gbPerDevice)}})</span></div>
            </div>
            <div><b>Storage Used:</b>&nbsp;&nbsp;{{planInformation.totalStorageUsedGB | number:'1.0-2'}}GB (as
                of {{planInformation.asOfDate | date: 'MM/dd/yyyy'}})
            </div>
            <div *ngIf="!hasUnlimitedPlan && overageGB > 0" [style.color]="getOverageColor()"><b>Storage Overage:</b>&nbsp;&nbsp;{{overageGB | number:'1.0-2'}}
                GB (as of {{planInformation.asOfDate | date: 'MM/dd/yyyy'}})
            </div>
        </div>

    </div>

    <div class="details-card">
        <table class="table">
            <thead>
            <tr>
                <th i18n>Device</th>
                <th i18n>Device Serial</th>
                <th i18n>Assigned User</th>
                <th i18n>Status</th>
                <th i18n>Last Connected</th>
                <th i18n>Uploads in Last 30 Days</th>
                <th i18n>Storage Used</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let device of deviceUsages">
                <td>{{device.name}}</td>
                <td>{{device.deviceSerial}}</td>
                <td>{{device.userName}}</td>
                <td>{{device.isDisabled ? "Disabled" : "Active"}}</td>
                <td>{{device.lastConnected | date: 'MM/dd/yyyy'}}</td>
                <td>{{device.bytesUploaded | bytes:1}} ({{device.uploadedFileCount}}
                    File{{device.uploadedFileCount != 1 ? "s" : ""}})
                </td>
                <td>{{device.bytesUsed | bytes:1}} ({{device.fileCount}} File{{device.fileCount != 1 ? "s" : ""}})</td>
            </tr>
            </tbody>
        </table>
    </div>


    <span *ngIf="deviceUsages">{{totalItems}}
        <ng-container i18n>Results</ng-container></span>
    <span *ngIf="!deviceUsages">
        <mat-spinner color="accent"></mat-spinner>
      </span>


    <app-s-paginate [modelFromJS]="modelFromJS" (collection)="onDataSourceUpdate($event)"
                    [url]="'/api/api/Usage/Device'" [urlParams]="filter"
                    (numItems)="numItemsUpdated($event)"></app-s-paginate>
</div>
