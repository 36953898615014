import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-document-rights-dialog',
  templateUrl: './help-document-rights-dialog.component.html',
  styleUrls: ['./help-document-rights-dialog.component.css']
})
export class HelpDocumentRightsDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
