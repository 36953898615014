<div class="outer-container">
    <p *ngIf="!transfersLoaded">
        <mat-spinner color="accent"></mat-spinner>
    </p>

    <div class="inner-container" *ngIf="pendingRequestList.length !== 0">
        <div *ngFor="let transfer of pendingRequestList">
            <div class="card mb-3">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <a class="card-title" routerLink="/admin/devices/{{transfer.deviceId}}/details">
                            <img class="mr-3" src="assets/images/icons/{{transfer.iconName}}" alt="{{transfer.deviceTypeName}}" i18n>
                            {{transfer.deviceName}}
                        </a>
                        <span *ngIf="transfer.isDisabled" class="text-danger"> - <b i18n>DISABLED</b></span>
                    </div>
                    <div>
                        <button *ngIf="environment.featureFlag_newCreateRequestDialog === false" mat-button routerLink="/transfer/{{transfer.deviceId}}/request" ui-sref="transfers.requestTransfer({id: transfer.deviceId})" class="smx-primary-button ml-1" [disabled]="transfer.isDisabled" i18n><fa-icon [icon]="['fas','plus']" class="mr-1"></fa-icon> New Transfer Request
                        </button>
                        <button *ngIf="environment.featureFlag_newCreateRequestDialog" mat-button (click)="handleVideoRequestClick(transfer.deviceId)"  class="smx-primary-button ml-1" [disabled]="transfer.isDisabled" i18n>
                            <fa-icon [icon]="['fas','plus']" class="mr-1"></fa-icon> New Transfer Request
                        </button>
                        <a mat-button class="smx-primary-button ml-1" routerLink="/admin/devices/{{transfer.deviceId}}/details" ui-sref="transfers.deviceDetails({id: transfer.deviceId})" i18n>
                            <fa-icon [icon]="['fas','file']" class="mr-1"></fa-icon> Device Details
                        </a>
                    </div>

                </div>
                <div class="card-body">
                    <table class="table">
                        <thead>
                        <tr>
                            <th i18n>Created</th>
                            <th i18n>Cameras</th>
                            <th i18n>Requested Start</th>
                            <th i18n>Requested End</th>
                            <th style="width: 140px;"></th>
                        </tr>
                        </thead>
                        <tbody *ngFor="let transferRequest of transfer.transferRequestList">
                        <tr>
                            <td i18n>{{transferRequest.created | date: 'MMM dd YYYY, h:mm:ss a'}}</td>
                            <td i18n>{{transferRequest.cameras}}</td>
                            <td i18n>{{transferRequest.desiredStart  | date: 'MMM dd YYYY, h:mm:ss a'}}</td>
                            <td i18n>{{transferRequest.desiredStop  | date: 'MMM dd YYYY, h:mm:ss a'}}</td>
                            <td><button class="smx-critical-button" (click)="handleCancelClick(transferRequest, transfer)" >Cancel</button></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="pendingRequestList.length === 0">
        <h3 i18n>No Pending Transfers.</h3>
    </div>
</div>
