import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { DeviceCode, DeviceCodeModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { TitleService } from '../../../services/title.service';
import { MatDialog } from '@angular/material/dialog';
import { DVRQuickConfigDeviceType, DvrQuickConfigDialogComponent, DVRQuickConfigData } from '../dvr-quick-config-dialog/dvr-quick-config-dialog.component';
import { timezoneList } from 'src/app/shared/helpers/timezone-list-const';

@Component({
  selector: 'app-devices-file-transfer',
  templateUrl: './devices-file-transfer.component.html',
  styleUrls: ['./devices-file-transfer.component.scss']
})

export class DevicesFileTransferComponent implements OnInit {
  @ViewChild('step4') private myScrollContainer: ElementRef;

  deviceCodeModel: DeviceCodeModel = new DeviceCodeModel();
  pvTransferUrl: string;
  associatedUserId: number;
  associatedUser: string;
  deviceCode: string = "";

  formSubmissionResponse: string;
  formSubmissionSuccess: boolean = false;
  dvrConfirmationInterval: any = null;            // Holds the $interval object so the interval can be canceled.
  dvrConfirmationIntervalRetries: number = 40;    // Interval is every 3 seconds, so retry for 120 seconds before stopping.
  dvrConfirmationIntervalCount: number = 0;       // Keeps track of the retry count.
  deviceConfirmationModel: DeviceCode;            // Returned by the confirmation status call.
  dvrConfirmationError: boolean = false;          // Flags an error when confirmation status fails after 120 seconds
  timezones: typeof timezoneList = timezoneList;

  constructor(private apiService: SecuraMaxApiService, private titleService: TitleService, public dvrConfigDialog: MatDialog) { }

  ngOnInit(): void {
    this.titleService.setTitle('Setup PV Transfer Device');
    this.pvTransferUrl = window.location.protocol + '//' + window.location.host + '/api/PVTransfer';
    this.deviceConfirmationModel = new DeviceCode();
  }

  /**
   * Button click function for entering the DVR code.
   * 
   * Will submit the code to the server. On success it begins the background interval of polling
   * the status of the DVR confirming the code.
   * */
  confirmEnterCode() {
    this.dvrConfirmationIntervalCount = 0;
    this.dvrConfirmationError = false;
    this.deviceConfirmationModel = new DeviceCode();
    var vm = this;

    this.apiService.device_Approve(this.deviceCodeModel).subscribe(data => {
      this.formSubmissionResponse = null;
      this.formSubmissionSuccess = true;

      // Kick off polling to check DVR confirmation.
      this.dvrConfirmationInterval = interval(3000).subscribe(x => {
        this.CheckDvrConfirmationInterval();
      });

      // Auto scroll down to the final step. Need timeout so step4 becomes visable.
      setTimeout(function () {
        vm.scrollToBottom();
      }, 100)
    },
      err => {
        this.formSubmissionResponse = "A server error occurred while submitting the device approval form. " + err.response;
      })
  }

  /**
     * Background interval that polls for the DVR confirm status of the code.
     * 
     * On success the interval cancels itself, allows for the user to navigate
     * to the new devices page for viewing
     * 
     * The interval will self cancel after 120 seconds of failing to confirm code.
     * */
  CheckDvrConfirmationInterval() {
    this.dvrConfirmationIntervalCount++;

    this.apiService.device_GetDeviceCodeStatus(this.deviceCodeModel.code).subscribe(data => {
      this.deviceConfirmationModel = data;

      if (this.deviceConfirmationModel.isCompletedRegistration) {
        this.dvrConfirmationInterval.unsubscribe();
      }
    },
      err => {
        console.log("ERROR: Get Device Code Status ");
        console.log(err);
      });

    if (this.dvrConfirmationIntervalCount >= this.dvrConfirmationIntervalRetries) {
      this.dvrConfirmationInterval.unsubscribe();
      this.dvrConfirmationError = true;
    }
  }

  /**
   * Button click function for reseting form to approve a different device.
   * */
  approveAnotherDevice() {
    this.formSubmissionSuccess = null;
    this.deviceCodeModel = new DeviceCodeModel();
    this.deviceConfirmationModel = new DeviceCode();
  }

  /**
   * Auto scrolls to 'step 4' when it becomes visible.
   */
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollIntoView(false);
    } catch (err) {
      console.log("SCROLL ERROR", err);
    }
  }

  openQuickDVRConfigDialog() {
    const dialogRef = this.dvrConfigDialog.open(DvrQuickConfigDialogComponent, {
      width: "40%",
      data: {}
    });

    dialogRef.afterClosed().subscribe(data => {

      if (data === "")
      {
        return;
      }

      var fileContents = this.buildFileContents(data);

      var makeCfgFile = this.createConfigFile;

      var link = document.createElement('a');
      var filename = "";
      if (data.targetDeviceType == DVRQuickConfigDeviceType.PD1902DR200v2)
      {
        filename = "transfersetup.txt";
      }
      else if (data.targetDeviceType == DVRQuickConfigDeviceType.PD1900)
      {
        filename = "PD1900_config.cfg";
      }
      link.setAttribute('download', filename);
      link.href = makeCfgFile(fileContents);
      document.body.appendChild(link);

      window.requestAnimationFrame(function () {
        var event = new MouseEvent('click');
        link.dispatchEvent(event);
        document.body.removeChild(link);
      });
    });
  }

  buildFileContents(data: DVRQuickConfigData): string {
    var fileContents = "/transfer/url=" + window.location.protocol + '//' + window.location.host + "\n";
    fileContents += "/transfer/enabled=1\n";

    fileContents += "/time/format=0\n";
    if (data.timezone !== undefined)
    {
      fileContents += "/time/TZ=" + data.timezone + "\n";
      fileContents += "/time/iana=" + this.timezones.find(x => x.value == data.timezone).name + "\n";
    }
    
    if (data.LANEnabled)
    {
      if (data.DHCPEnabled)
      {
        fileContents += "/net/ipmode=1\n";
      }
      else
      {
        fileContents += "/net/ipmode=0\n";
        if (data.staticIP !== undefined)
        {
          fileContents += "/net/ipaddress=" + data.staticIP + "\n";
        }
        if (data.subnetMask !== undefined)
        {
          fileContents += "/net/netmask=" + data.subnetMask + "\n";
        }
        if (data.gateway !== undefined)
        {
          fileContents += "/net/gateway=" + data.gateway + "\n";
        }
      }
    }

    if (data.wifiEnable)
    {
      fileContents += "/net/wifienable=1\n";

      fileContents += "/net/wifimode=2\n";
      fileContents += "/net/clientipmode=1\n";
      
      for (var i = 0; i < data.wifiSSIDPasswordList.length; i++)
      {
        if (data.wifiSSIDPasswordList[i].SSID !== undefined)
        {
          fileContents += "/wifi[" + i + "]/ssid=" + data.wifiSSIDPasswordList[i].SSID + "\n";
        }

        if (data.wifiSSIDPasswordList[i].Password === undefined)
        {
          data.wifiSSIDPasswordList[i].Password = "";
        }
        fileContents += "/wifi[" + i + "]/ssidpassword=" + data.wifiSSIDPasswordList[i].Password + "\n";

        if (data.wifiSSIDPasswordList[i].Username !== undefined)
        {
          fileContents += "/wifi[" + i + "]/identity=" + data.wifiSSIDPasswordList[i].Username + "\n";
        }
      }
      fileContents += "/net/wifiautoupdate=1\n";
      
    }
    
    if (data.FourGEnabled) {
      fileContents += "/net/dialEnable=1\n";
      if (data.dialCmd !== undefined)
      {
        fileContents += "/net/dialcmd=" + data.dialCmd + "\n";
      }
      if (data.apn !== undefined)
      {
        fileContents += "/net/apn=" + data.apn + "\n";
      }
      if (data.username !== undefined)
      {
        fileContents += "/net/username=" + data.username + "\n";
      }
      if (data.password !== undefined)
      {
        fileContents += "/net/password=" + data.password + "\n";
      }
    }
    else {
      fileContents += "/net/dialEnable=0\n";
    }
    return fileContents;
  }

  createConfigFile(text: string): string {
    var textFile = null;
    var data = new Blob([text], {type: 'text/plain'});

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (textFile !== null) {
      window.URL.revokeObjectURL(textFile);
    }

    textFile = window.URL.createObjectURL(data);

    // returns a URL you can use as a href
    return textFile;
  }
}
