import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../../../services/snackbar.service';
import { BC4ListItemViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { TitleService } from '../../../services/title.service';

@Component({
    selector: 'app-bc4configs',
    templateUrl: './bc4configs.component.html',
    styleUrls: ['./bc4configs.component.css']
})
export class Bc4configsComponent implements OnInit {

    displayedColumns: string[] = ['name', 'assignedToGroups', 'devices', 'actions']; // Header for the table columns

    datasource: BC4ListItemViewModel[];     // Contains the devices list data.

    constructor(private api: SecuraMaxApiService, private toastr: SnackbarService, private titleService: TitleService) { }

    ngOnInit(): void {
        this.titleService.setTitle('Manage BODYCAM 4 Configurations');
        this.api.bC400_Get().toPromise().then((res) => {
            this.datasource = res;
        }).catch((err) => {
            this.toastr.error('Error! Please try again later.');
            //log?
        });
    }

}
