import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './services/user/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'SecuraMax';
    loginDisplay: boolean = false;

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        this.userService
            .getCurrentUser()
            .then(() => {
                this.loginDisplay = true;
                const l = window.location;
                const siteRoot = `${l.protocol}//${l.hostname}:${environment.port}`;
                const xhr = new XMLHttpRequest();
                xhr.withCredentials = true;
                xhr.open('GET', siteRoot + '/Auth/Antiforgery', false);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status !== 204) {
                            console.error(xhr.statusText);
                        }
                    }
                };
                xhr.send();
            })
            .catch(() => {
                const l = window.location;
                const siteRoot = `${l.protocol}//${l.hostname}:${environment.port}`;
                let loginUrl = siteRoot + '/Auth/Login';
                if (l.pathname) {
                    const safePathname = encodeURIComponent(l.pathname);
                    loginUrl += '?ReturnUrl=' + safePathname;
                }
                console.log(
                    'Failed to get user information. Attempting to reset by navigating to: ' +
                        loginUrl
                );
                window.location.href = loginUrl;
            });
    }
}
