import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { EditUserViewModel, GroupPermissionViewModel, GroupViewModel, SecuraMaxApiService, UserRightViewModel, RFIDAssignmentListItem } from '../../../services/api/securamaxapi.service';
import { TitleService } from '../../../services/title.service';
import { HelpAdminRightsDialogComponent } from './help-admin-rights-dialog/help-admin-rights-dialog.component';
import { HelpDocumentRightsDialogComponent } from './help-document-rights-dialog/help-document-rights-dialog.component';
import { HelpGroupPermissionsDialogComponent } from './help-group-permissions-dialog/help-group-permissions-dialog.component';
import { allCountries  } from 'country-region-data';
import {environment} from "../../../../environments/environment";
var regionData = require('country-region-data/data.json');
import {UserService} from 'src/app/services/user/user.service';

@Component({
    selector: 'app-users-edit',
    templateUrl: './users-edit.component.html',
    styleUrls: ['./users-edit.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class UsersEditComponent implements OnInit {
    currentLoggedInUser: EditUserViewModel;
    user: EditUserViewModel;
    id: number = null;
    groups: GroupViewModel[];
    isEditForm: boolean;
    countries: Array<any>;
    states: Array<any>;
    togglingAccount: boolean = false;
    originalEmail: string = '';
    sendingVerificationEmail: boolean = false;
    isResettingPassword: boolean = false;
    savingRedactionSeat: boolean = false;
    changePassword: any;
    changeUserName: string;
    administrativeRightsAllCheckBox: boolean;
    ownDocumentsRightsAllCheckBox: boolean;
    serverError: string;
    formError: string;
    isSaving: boolean = false; // Used on the 'Create' users page to disable save button so they don't spam the form.
    loaded: boolean = false;
    userLoaded: boolean = false;
    countryNameList: string[] = [];
    stateNameList: string[] = [];
    countryList: any;
    CurrentRFIDAssignmentsList: RFIDAssignmentListItem[] = [];
    rfidOverwrite: boolean = false;


    static AdministrativeRightsList = ['manage_devices', 'manage_upload_stations', 'manage_categories', 'manage_tags', 'manage_users', 'manage_groups', 'view_report_pending_deletion',
        'view_report_device_usage', 'manage_site_settings', 'approve_deletion_requests', 'live_view_inactive_devices'];

    isAdminRight(right: UserRightViewModel) {
        return UsersEditComponent.AdministrativeRightsList.indexOf(right.name) > -1;
    }

    constructor(private api: SecuraMaxApiService, private route: ActivatedRoute, private toastr: SnackbarService, public dialog: MatDialog, private titleService: TitleService, public userService: UserService) { }

    ngOnInit(): void {
        this.userService.getCurrentUser().then(x => this.currentLoggedInUser = x).then(() => {
            const id: string = this.route.snapshot.params.id;
            this.api.users_Get(id).toPromise().then((data) => {
                this.user = data;
                if (this.userService.hasAccessToLive() === false) {
                    const index = this.user.rights.findIndex(x => x.name === 'live_view_inactive_devices');
                    if (index > -1) {
                      this.user.rights.splice(index, 1);
                    }
                }
                this.originalEmail = data.email;
                this.administrativeRightOptionToggled();
                this.ownDocumentsRightOptionToggled();
                this.userLoaded = true;
                this.countryChanged();
                this.GetRfidAssignments();
            }, (err) => {
                this.toastr.error('Error occurred while getting the users');
            });
        });

        this.countryNameList = allCountries.map(function(x) { return x[0] });

        this.titleService.setTitle('Edit User');

        this.api.group_GetAll().toPromise().then((groups) => {
            this.groups = groups;
            this.loaded = true;
        }, (err) => {
            this.toastr.error('Error occurred while getting the groups');
        });

    }

    countryChanged()
    {
        this.stateNameList = regionData.find(({countryName}) => countryName === this.user.country).regions.map(function(x) { return x.name });
    }

    //invite new member?
    //generate invite url and email.
    //invite url allows them to create account
    openAccount() {
        var that = this;
        that.togglingAccount = true;
        that.api.users_PutOpenAccount(that.user.iD).toPromise().then(() => {
            this.toastr.success('Success!');
            that.user.isAccountClosed = false;
            that.togglingAccount = false;
        }).catch((err) => {
            that.togglingAccount = false;
            this.toastr.error(err.message || err.message);
        });
    }

    closeAccount() {
        var that = this;

        that.togglingAccount = true;
        that.api.users_PutCloseAccount(that.user.iD).toPromise().then(() => {
            this.toastr.success('Account closed!');

            if (that.user.redactionSeat) {
                this.savingRedactionSeat = true;
                this.user.redactionSeat = false;
                this.api.users_PutUpdateUserRedactionSeat(this.user).toPromise().then(() => {
                    this.toastr.success('Redaction seat removed!');
                    this.user.redactionSeatAvailable += 1;
                    this.savingRedactionSeat = false;
                    that.user.isAccountClosed = true;
                    that.togglingAccount = false;
                }).catch((err) => {
                    console.error(err);
                    this.user.redactionSeat = true;
                    this.toastr.error('Unable to remove redaction seat.');
                    this.savingRedactionSeat = false;
                    that.user.isAccountClosed = true;
                    that.togglingAccount = false;
                });
            }

        }, (err) => {
            that.togglingAccount = false;
            this.toastr.error(err.Message || err.message);
        });
    }

    resendVerificationEmail() {
        var that = this;
        that.sendingVerificationEmail = true;
        that.api.users_PutResendVerification(
            that.user.iD
        ).toPromise().then(() => {
            this.toastr.success('Sent!');
            that.sendingVerificationEmail = false;
        }, (err) => {
            that.sendingVerificationEmail = false;
            this.toastr.error(err?.Message ?? err?.message ?? err);
        });
    }

    /**
   * When a user is on the User's Edit page this function is used to save both the Users Rights AND
   * the users profile information. This eliminates having 2 'Save' buttons on the page.
   * */
    saveUserEdit() {
        var that = this;

        this.isSaving = true;
        if (that.user.rFID != null) {
            // if (!this.ValidateRFID())
            // return;
        }

        const possibleCanBeAssignedToDeviceRight = this.user.rights.find(x => x.name === 'can_be_assigned_to_device');
        if (possibleCanBeAssignedToDeviceRight && possibleCanBeAssignedToDeviceRight.value === true) {
            if (!this.user.syncUserNameAlias || this.user.syncUserNameAlias === '') {
                this.formError = "You must provide a video identifier if the user has the \"Can be assigned to device\" permission.";
                this.isSaving = false;
                return;
            }
            
        }

        this.api.users_PutUpdate(this.user).toPromise().then(() => {
            if (that.originalEmail !== that.user.email) {
                this.toastr.success('Saved! The user will be getting an email to validate the address.');
            } else {
                this.toastr.success('Successfully saved!');
            }
            Promise.all([
                this.api.users_PutUpdateUserPermissions(this.user.iD, this.user.rights).toPromise(),
                this.api.users_PutUpdateGroupPermissions(this.user.iD, this.user.groupPermissions).toPromise()
            ]).then((res) => {
                this.toastr.success('Successfully saved!');
                this.isSaving = false;
                //TODO: previous version forced reload
            });
        }).catch((err) => {
            this.toastr.error('Error! ' + err);
            this.isSaving = false;
        });
    }

    assignRedactionSeat() {
        this.savingRedactionSeat = true;
        this.user.redactionSeat = true;

        this.api.users_PutUpdateUserRedactionSeat(this.user).toPromise().then(() => {
            this.toastr.success('Successfully saved!');

            this.user.redactionSeatAvailable -= 1;
            this.savingRedactionSeat = false;
        }).catch((err) => {
            console.error(err);
            this.user.redactionSeat = false;
            this.toastr.error('Unable to assign redaction seat.');
            this.savingRedactionSeat = false;
        });
    }

    removeRedactionSeat() {
        this.savingRedactionSeat = true;
        this.user.redactionSeat = false;
        this.api.users_PutUpdateUserRedactionSeat(this.user).toPromise().then(() => {
            this.toastr.success('Successfully saved!');
            this.user.redactionSeatAvailable += 1;
            this.savingRedactionSeat = false;
        }).catch((err) => {
            console.error(err);
            this.user.redactionSeat = true;
            this.toastr.error('Unable to remove redaction seat.');
            this.savingRedactionSeat = false;
        });
    }

    permCheckedChanged(perm: GroupPermissionViewModel) {
        if (perm.view === false) {
            perm.download = false;
            perm.share = false;
            perm.edit = false;
            perm.liveMap = false;
            perm.liveView = false;
            perm.remoteRetrieval = false;
        }
    }

    static userRights = {
        "approve_deletion_requests": "Approve Deletion Requests",
        "can_be_assigned_to_device": "Can be Assigned to Device",
        "documents_download_own": "Download Own Documents",
        "documents_edit_metadata_on_own": "Edit Metadata on Own Documents",
        "documents_share_own": "Share Own Documents",
        "documents_view_own": "View Own Documents",
        "manage_categories": "Manage Categories",
        "manage_devices": "Manage Devices",
        "manage_groups": "Manage Groups",
        "manage_site_settings": "Manage Site Settings",
        "manage_tags": "Manage Tags",
        "manage_upload_stations": "Manage Upload Stations",
        "manage_users": "Manage Users",
        "upload_directly": "Upload Directly",
        "upload_via_device": "Upload via Device",
        "view_report_device_usage": "View Device Usage Report",
        "view_report_pending_deletion": "View Pending Deletion Report",
        "live_view_inactive_devices": "Show Non-Logged in Devices on Live Map"
    };

    translate(userRightString: string) {
        return UsersEditComponent.userRights[userRightString];
    }

    checkAll(perm: GroupPermissionViewModel) {
        if (
            perm.edit &&
            perm.view &&
            perm.share &&
            perm.download &&
            perm.liveMap &&
            perm.liveView &&
            perm.remoteRetrieval
            ) {
            perm.edit = false;
            perm.view = false;
            perm.download = false;
            perm.share = false;
            perm.liveMap = false;
            perm.liveView = false;
            perm.remoteRetrieval = false;
        } else {
            perm.edit = true;
            perm.view = true;
            perm.download = true;
            perm.share = true;
            perm.liveMap = true;
            perm.liveView = true;
            perm.remoteRetrieval = true;
        }
    }

    /*
    * Handles the 'Select All' check box to update and select all of the admin rights checkbox items.
    */
    checkAllAdministrativeRights() {
        var that = this;
        that.user.rights.filter(x => UsersEditComponent.AdministrativeRightsList.includes(x.name)).forEach((val, i) => {
            val.value = this.administrativeRightsAllCheckBox;
        });
    }

    /*
    * Handles the check for when all of the admin rights are checked, then the 'select all' gets checked.
    */
    administrativeRightOptionToggled() {
        var temp = this.user.rights.filter(x => UsersEditComponent.AdministrativeRightsList.includes(x.name));
        this.administrativeRightsAllCheckBox = temp.every(function (item) { return item.value; });
    }

    /*
    * Handles the 'Select All' check box to update and select all of the owner documents rights checkbox items.
    */
    checkAllOwnDocumentsRights() {
        var that = this;
        var temp = that.user.rights.filter(x => !UsersEditComponent.AdministrativeRightsList.includes(x.name));
        temp.forEach(function (item) { item.value = that.ownDocumentsRightsAllCheckBox });
    }

    /*
    * Handles the check for when all of the owner documents rights are checked, then the 'select all' gets checked.
    */
    ownDocumentsRightOptionToggled() {
        var temp = this.user.rights.filter(x => !UsersEditComponent.AdministrativeRightsList.includes(x.name));
        this.ownDocumentsRightsAllCheckBox = temp.every(function (item) { return item.value; });
    }

    openAdministrativeRightsHelpDialog() {
        const dialogRef = this.dialog.open(HelpAdminRightsDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    openDocumentPermissionsHelpDialog() {
        const dialogRef = this.dialog.open(HelpDocumentRightsDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    openHelpGroupPermissionsDialog() {
        const dialogRef = this.dialog.open(HelpGroupPermissionsDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    GetRfidAssignments() {
      this.api.users_GetRFIDAssignmnets().subscribe(data => {
          this.CurrentRFIDAssignmentsList = data;

      }, (err) => {
          this.toastr.error('Error occurred while getting the RFIDs');
      });
  }
  CheckForRFIDOverwrite(): void
    {
      var retVal = false;

      var listOfAssignments = [];

      this.CurrentRFIDAssignmentsList.forEach(x => listOfAssignments.push(parseInt(x.rFID.substring(1, x.rFID.length))));

      var startingTag = parseInt(this.user.rFID.substring(1, this.user.rFID.length));

      if (listOfAssignments.includes(startingTag))
        {
          retVal = true;
        }

      this.rfidOverwrite = retVal;

      return;
    }

    protected readonly environment = environment;
}
