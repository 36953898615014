/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UploadLocationViewModel { 
    name: string;
    lastConnectedIPAddress?: string | null;
    lastconnectedDate?: string;
    location_Key?: number;
    tenantId?: number;
    id?: number;
    enabled?: boolean;
    apiKey?: string | null;
    secret?: string | null;
}

