import { Component, OnInit } from '@angular/core';
import { SecuraMaxApiService, SiteSettings } from '../../services/api/securamaxapi.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TitleService } from 'src/app/services/title.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  settingsForm: UntypedFormGroup;
  settings: SiteSettings;
  isDefaultRetentionInvalid: boolean = false;
  defaultRetentionError: '';
  constructor(private titleService: TitleService, private api: SecuraMaxApiService, private _matSnackBar: MatSnackBar) {
  }

  settingsLoaded: boolean = false;
  save() {

    if (this.settingsForm.valid) {
      this.settings.defaultRetentionDays = this.f.defaultRetentionDays.value;
      this.settings.autoLockDocument = this.f.autoLockDocument.value;
      this.settings.documentTitleRegex = this.f.documentTitleRegex.value;
      this.settings.requireDocumentTitle = this.f.requireDocumentTitle.value;
      this.settings.maxDefaultRetentionDays = this.settings.maxDefaultRetentionDays;
      this.settings.titleRegexDescription = this.f.titleRegexDescription.value;
      let config: MatSnackBarConfig<any> = {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000,
        politeness: 'assertive'
      };

      this.api.settings_Put(this.settings).toPromise().then((resp) => {
        this._matSnackBar.open('Successfully saved settings!');
      }).catch((err) => {
        config.panelClass = ['notify-error'];
        if (err.status === 403) {
          this._matSnackBar.open('User does not have permission for this operation.', '', config);
        } else {
          this._matSnackBar.open(err, '', config);
        }
      });
    }

  };

  //convenience getter for formfields
  get f() { return this.settingsForm.controls; }

  ngOnInit(): void {
      this.titleService.setTitle('Site Settings');

    this.api.settings_Get().toPromise().then((data) => {
      this.settings = data;

      this.settingsForm = new UntypedFormGroup({
        defaultRetentionDays: new UntypedFormControl(this.settings.defaultRetentionDays, [
          Validators.required,
          Validators.min(1),
          Validators.max(this.settings.maxDefaultRetentionDays > 3650 ? 3650 : this.settings.maxDefaultRetentionDays)
        ]),
        autoLockDocument: new UntypedFormControl(this.settings.autoLockDocument),
        requireDocumentTitle: new UntypedFormControl(this.settings.requireDocumentTitle),
        documentTitleRegex: new UntypedFormControl(this.settings.documentTitleRegex),
        titleRegexDescription: new UntypedFormControl(this.settings.titleRegexDescription),
      });

      console.log(this.f.defaultRetentionDays.value);
      this.settingsLoaded = true;
    });
  }



}
