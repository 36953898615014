<div class="main-container">
    <p i18n>Select a user to audit all actions taken by that user.</p>
    <div class="d-flex">
        <app-searchbar (search)="onSearch($event)" (clear)="onSearchCleared()" placeholder="Audit User Search"
                       isPrimary="true"></app-searchbar>
    </div>


    <div class="details-card">
        <table class="table">
            <thead>
            <tr>
                <th i18n>Last</th>
                <th i18n>First</th>
                <th i18n>Email</th>
                <th i18n>Type</th>
                <th i18n>Status</th>

                <th i18n></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of datasource" [class]="{active: user.isAccountClosed===true}">
                <td>{{user.lastName}}</td>
                <td>{{user.firstName}}</td>
                <td>{{user.email}}</td>
                <td class="d-flex align-items-center">

                    <span *ngIf="user.isAD">AD</span>
                    <span *ngIf="!user.isAD" class="mr-1" i18n>Local</span>
                    <span *ngIf="user.isShareUser===true" class="badge badge-info mr-1" i18n>Share User</span>
                    <span *ngIf="user.redactionSeat === true" class="badge" title="Redaction License Assigned"
                          i18n-title i18n>R</span>
                </td>
                <td>
                    <mat-chip-set>
                        <mat-chip color="accent" *ngIf="user.isAccountVerified && !user.isAccountClosed" i18n>Active
                        </mat-chip>
                        <mat-chip color="warn" *ngIf="!user.isAccountVerified && !user.isAccountClosed" i18n>Not
                            Verified
                        </mat-chip>
                        <mat-chip *ngIf="user.isAccountClosed" i18n>Closed</mat-chip>
                    </mat-chip-set>
                </td>
                <td>
                    <button mat-button class="smx-primary-button" (click)="openDialog(user)" i18n>Audit</button>
                </td>
            </tr>
            <tr *ngIf="!datasource">
                <td colspan="4">
                    <mat-spinner color="accent"></mat-spinner>
                </td>
            </tr>
            </tbody>
        </table>
    </div>


    <app-s-paginate [modelFromJS]="modelFromJS" (collection)="onDataSourceUpdate($event)"
                    (numItems)="numItemsUpdated($event)" [url]="'/api/api/Users/All'" [urlParams]="filter">
    </app-s-paginate>

</div>
