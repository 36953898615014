import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent {
    @Input() placeholder: string = 'Search';
    @Input() isPrimary: boolean = false;
    @Input() isUppercase: boolean = false;
    @Input() startingText: string = null;
    searchText = new FormControl<string>('');
    @Output() search: EventEmitter<any> = new EventEmitter();
    @Output() clear: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        if (this.startingText) {
            this.searchText.setValue(this.startingText);
        }
    }

    clear_click() {
        this.searchText.setValue('');
        this.clear.emit();
    }

    onEnter(event: KeyboardEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.search.emit(this.searchText.value)
    }
}
