<mat-card>
    <mat-card-header>
        <mat-card-title i18n>Add Metadata To Files</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form class="example-form" [formGroup]="metadataForm">

            <h4 class="labels" i18n>Title</h4>
            <mat-form-field class="full-width">
                <input matInput formControlName="title">
            </mat-form-field>


            <h4 class="labels" i18n>Description</h4>
            <mat-form-field class="full-width">
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>

            <!--Dynamic Tags Start-->
            <div formArrayName="tags">
                <div *ngFor="let tag of tags.value; let i=index" class="tag-item">
                    <!-- The repeated alias template -->
                    <mat-form-field class="full-width">
                        <mat-label>{{tag.name}}</mat-label>
                        <input matInput [value]="tag.tagValue" (input)="tag.tagValue = $event.target.value">
                    </mat-form-field>

                    <button mat-raised-button (click)="deleteTag(i)" class="tag-delete-button" style="height:50%">
                        <mat-icon class="tag-delete-button-icon">delete</mat-icon>
                    </button>
                </div>
            </div>
            <!--Dynamic Tags End-->

            <h4 class="labels" i18n>Tags</h4>
            <mat-form-field appearance="fill" class="full-width">
                <mat-label i18n>Select a Tag Type</mat-label>
                <mat-select (selectionChange)="tagSelectionChange($event.value)">
                    <mat-option *ngFor="let tags of tagsList" [value]="tags">{{tags.name}}</mat-option>
                </mat-select>
            </mat-form-field>



            <h4 i18n>Categories</h4>
            <mat-form-field class="full-width">
                <mat-select multiple formControlName="categoriesForm">
                    <mat-option *ngFor="let categories of categoriesList" [value]="categories.id">{{categories.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <h4 class="labels" i18n>Cases</h4>
            <mat-form-field style="width:87%">
                <mat-select multiple formControlName="casesForm">
                    <mat-option *ngFor="let cases of casesList" [value]="cases.id">{{cases.title}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button (click)="createNewCase()" i18n>Create New</button>
        </form>

    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button (click)="saveMetadata()" [disabled]="!isSaveButtonEnabled" i18n>Save</button>
        <a mat-raised-button routerLink="/" i18n>Cancel</a>
    </mat-card-actions>
</mat-card>
