<app-navbar></app-navbar>

<mat-toolbar style="background-color:white;justify-content:center;border-top: 1px solid #e3e3e3;">
    <mat-list dense>
        <mat-list-item>
            8625 Byron Commerce Dr SW <br /> Byron Center, MI 49315
        </mat-list-item>
    </mat-list>
    <img src="assets/images/pv_footer_logo.svg" style="height:40px" />

    <mat-list dense>
        <mat-list-item> +1 (800) 576-1126 </mat-list-item>
    </mat-list>
</mat-toolbar>

<div style="background-color:white; height:84vh">
    <!--Awaiting Review-->
    <mat-grid-list cols="4" gutterSize="10px" rowHeight="84vh">
        <mat-grid-tile colspan="1" style="background-color:whitesmoke; border-right:1px solid #acacac; overflow:auto">
            <!--mat-selection-list-- #shoes>
                    <h3 matHeader>Awaiting Review <span matBadge="3" matBadgeOverlap="false" matBadgeColor="warn"></span></h3>
                    <mat-divider></mat-divider>
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title style="font-size:14px">0Cam02 </mat-card-title>
                            <mat-card-subtitle>6J00254_0Cam02_05232017132559_0001.jpg</mat-card-subtitle>
                            <mat-list-option></mat-list-option>
                        </mat-card-header>
                    </mat-card>

                    <mat-card>
                        <mat-card-header>
                            <mat-card-title style="font-size:14px">0Cam02 </mat-card-title>
                            <mat-card-subtitle>6J00254_0Cam02_05232017132559_0002.jpg</mat-card-subtitle>
                            <mat-list-option></mat-list-option>
                        </mat-card-header>
                    </mat-card>

                    <mat-card>
                        <mat-card-header>
                            <mat-card-title style="font-size:14px">0Cam02 </mat-card-title>
                            <mat-card-subtitle>6J00254_0Cam02_05232017132559_0003.jpg</mat-card-subtitle>
                            <mat-list-option></mat-list-option>
                        </mat-card-header>
                    </mat-card>

                    <mat-card>
                        <mat-card-content class="reviewSubmit">
                            <div>
                                <a mat-raised-button color="warn" routerLink="../metadata">Review Now</a>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </!--mat-selection-list-->

            <mat-selection-list #shoes>
                <h3 matHeader style="display:inherit" i18n>
                    <mat-checkbox type="checkbox" [checked]="reviewAllCheckbox" (change)="toggleAllReview()">
                    </mat-checkbox>
                    Awaiting Review
                    <span matBadge="{{metadataDayVM?.length}}" matBadgeOverlap="false" matBadgeColor="warn">
                    </span>
                    <button mat-raised-button color="warn" routerLink="../metadata" style="float:right" [disabled]="reviewNowButtonDisabled">Review Now</button>
                </h3>
                <mat-divider></mat-divider>
                <div *ngFor="let day of metadataDayVM">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="toggleReviewDay(day)" [checked]="day.selected">
                                </mat-checkbox>
                                {{day.date}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-selection-list *ngFor="let file of day.files">
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="toggleReviewFile(file, day)" [checked]="file.selected">
                                {{file.name}}
                            </mat-checkbox>
                        </mat-selection-list>


                    </mat-expansion-panel>
                </div>
            </mat-selection-list>

        </mat-grid-tile>

        <!--Center Component-->
        <mat-grid-tile colspan="2" style="overflow:auto">
            <router-outlet></router-outlet>
        </mat-grid-tile>

        <!--File Transfer-->
        <mat-grid-tile colspan="1" style="background-color:whitesmoke; border-left:1px solid #acacac; margin-left:-1px">
            <h3 matHeader style="display:flex; justify-content:right; padding-right:10px" i18n>
                <span matBadge="2" matBadgeOverlap="false" matBadgeColor="warn" matBadgePosition="below"></span> Wireless File Transfer Devices
            </h3>

            <mat-card class="card">
                <mat-card-header>
                    <mat-card-subtitle routerLink="../cases" class="pendingTransfersLink" i18n>View Pending Transfers: 2
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item style="border-bottom:1px solid #f8f8f8">
                            <div style="display:table">
                                <img src="assets/images/dvr800_thumb.png" />
                                <a routerLink="/cases" style="display:table-cell; vertical-align:middle; text-decoration:none; padding-left:5px" class="pendingTransfersLink">
                                    Eng175
                                </a>
                            </div>
                            <button mat-stroked-button i18n>
                                New Transfer Request
                            </button>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item style="margin-top:10px">
                            <div style="display:table">
                                <img src="assets/images/dvr800_thumb.png" />
                                <a routerLink="/cases" style="display:table-cell; vertical-align:middle; text-decoration:none; padding-left:5px" class="pendingTransfersLink">
                                    Eng180
                                </a>
                            </div>
                            <button mat-stroked-button i18n>
                                New Transfer Request
                            </button>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>