<div class="card mt-3">
    <div class="card-body card-container">
        <div class="header-container" style="grid-area: header;">

            <h1 i18n>File Transfer</h1>


        </div>


        <mat-nav-list class="raised-container custom-nav-list" style="grid-area: nav;">
            <a mat-list-item routerLinkActive="active" routerLink="/transfer/list" i18n>Device List</a>
            <a mat-list-item routerLinkActive="active" routerLink="/transfer/pending" i18n>Pending Transfers</a>
        </mat-nav-list>

        <div style="grid-area: sub; min-height: 0;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

