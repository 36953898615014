<div mat-dialog-title class="title">
    <div>Inactivity Check</div>
</div>

<mat-dialog-content class="content">
    <div class="form-container">
        Are you still here?
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="primary" class="smx-primary-button" (click)="stillHereClick()" i18n>I'm still here</button>
</mat-dialog-actions>