import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    DocumentCaseItemViewModel,
    DocumentDetailsViewModel,
    SecuraMaxApiService,
    ShareViewModel,
} from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { documentStatus } from 'src/app/shared/helpers/document-status-const';

interface DocumentDetailsDialogData {
    document: DocumentDetailsViewModel;
    documentStatus: typeof documentStatus;
    isUnlimitedPlanUser: boolean;
    docInOpenCaseWithRetentionList: DocumentCaseItemViewModel[];
}

@Component({
    selector: 'app-share-modify-dialog2',
    templateUrl: './document-details-dialog.component.html',
    styleUrls: ['./document-details-dialog.component.css'],
})
export class DocumentDetailsDialogComponent {
    sharesIsLoading: boolean = true;
    shares: ShareViewModel[] = null;

    constructor(
        private apiService: SecuraMaxApiService,
        private toastr: SnackbarService,
        private dialogRef: MatDialogRef<DocumentDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DocumentDetailsDialogData
    ) {
        data.documentStatus = documentStatus;
    }

    ngOnInit() {
        this.apiService
            .share_GetAll(this.data.document.id)
            .toPromise()
            .then(
                (data) => {
                    this.shares = data;
                    this.sharesIsLoading = false;
                },
                () => {
                    this.toastr.error(
                        'An error occurred while getting document shares.'
                    );
                    this.shares = [];
                    this.sharesIsLoading = false;
                }
            );
    }

    openCancelShareDialog(_t115: any) {
        throw new Error('Method not implemented.');
    }
}
