/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UsageHistoryViewModel { 
    year?: number;
    month?: number;
    avgDocumentsStoredBytes?: number;
    avgDocumentsStored?: number;
    documentsUploadedBytes?: number;
    documentsUploaded?: number;
    documentsArchivedBytes?: number;
    documentsArchived?: number;
    documentsArchiveRetrievedBytes?: number;
    documentsArchiveRetrieved?: number;
    dataDownloaded?: number;
    allowedBytes?: number | null;
}

