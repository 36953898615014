import { Directive, Injectable, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, debounceTime } from 'rxjs/operators';
import { SecuraMaxApiService } from '../../services/api/securamaxapi.service';

@Directive({
  selector: '[emailExistsValidator][ngModel],[emailExistsValidator][formControl]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: EmailExistsValidator, multi: true }
  ]
})
export class EmailExistsValidator implements AsyncValidator {
  @Input() userId: string;

  constructor(private api: SecuraMaxApiService) { }

  checkIfEmailExists(email: string) {
    if (this.userId?.length > 0) {
      return this.api.users_MemberEmailAvailable(email, this.userId);
    } else {
      return this.api.users_MemberEmailAvailable(email, '');
    }
  }

  validate(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.checkIfEmailExists(ctrl.value).pipe(debounceTime(500),
      map(res => {
        // if res is true, member name is valid and available
        //if false it means not available
        return res ? null : { emailExists: true };
        // NB: Return null if there is no error
      }), catchError(err => {
        return of({ emailValid: false });
      })
    );
  };

}

function distinctUntilChange(): import("rxjs").OperatorFunction<{ emailExists: boolean; } | { emailValid: boolean; }, unknown> {
  throw new Error('Function not implemented.');
}
