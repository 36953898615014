/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TagTypeViewModel } from './tagTypeViewModel';


export interface DocumentTagViewModel { 
    id?: number | null;
    tagId?: number;
    value?: string | null;
    typeName?: string | null;
    typeId?: string | null;
    tagType?: TagTypeViewModel;
}

