import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';


@Component({
  selector: 'app-share-verify',
  templateUrl: './share-verify.component.html',
  styleUrls: ['./share-verify.component.css']
})
export class ShareVerifyComponent implements OnInit {
  id: string;
  verificationKey: string;
  showExpired: boolean = false;

  constructor(private securaMaxApiService: SecuraMaxApiService, private route: ActivatedRoute, private router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.verificationKey = this.route.snapshot.paramMap.get('verificationKey');
  }

  ngOnInit(): void {
    
    this.securaMaxApiService.share_GetSingle(this.id).subscribe((data) => {
      if (data.expires > new Date())
      {
        this.router.navigate(['documents', data.documentId]);
      }
      else
      {
        this.showExpired = true;
      }
    }, (err) => {
      this.router.navigate(['/']);
    });

  }
}
