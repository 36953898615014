<h2 mat-dialog-title>Select a Tag Type</h2>
<mat-dialog-content>
    <mat-selection-list>
        <mat-list-item
            *ngFor="let tags of allTags"
            (click)="selectTagType(tags)"
        >
            {{ tags.name }}
        </mat-list-item>
    </mat-selection-list>
</mat-dialog-content>
