/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransferRequestViewModel } from './transferRequestViewModel';


export interface PendingTransferList { 
    deviceId?: string;
    deviceName?: string | null;
    iconName?: string | null;
    deviceTypeName?: string | null;
    isDisabled?: boolean;
    transferRequestList?: Array<TransferRequestViewModel> | null;
}

