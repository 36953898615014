<div class="card mt-3">
    <div class="card-body card-container">
        <div class="header-container" style="grid-area: header;">

            <h1 i18n>User Management</h1>


        </div>


        <mat-nav-list class="raised-container custom-nav-list" style="grid-area: nav;">
            <a mat-list-item routerLinkActive="active" routerLink="/admin/users/list" i18n>Users</a>
            <a mat-list-item routerLinkActive="active" routerLink="/admin/users/bulkcreate" i18n>Users - Bulk Create</a>
            <a mat-list-item routerLinkActive="active" routerLink="/admin/users/groups" i18n>Groups</a>
            <a mat-list-item routerLinkActive="active" routerLink="/admin/users/rfid" i18n>RFID Assignment</a>
        </mat-nav-list>

        <div style="grid-area: sub; min-height: 0;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

