import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedactionItem, DocumentDetailsViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { TitleService } from 'src/app/services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatSliderModule } from '@angular/material/slider'


@Component({
    selector: 'app-redaction-create',
    templateUrl: './redaction-create.component.html',
    styleUrls: ['./redaction-create.component.css']
})
export class RedactionCreateComponent implements OnInit {
    redactionId: string;
    saving: boolean = false;
    loading: boolean = true;
    vgAPI: VgApiService;
    redaction: RedactionItem = new RedactionItem();
    document: DocumentDetailsViewModel;
    documentId: string;
    videoSrcUrl: string = null;
    videoTrimStart: number = 0;
    videoTrimEnd: number = 10;
    videoTrimMaxEnd: number = 10;
    audioTrimStart: number = 0;
    audioTrimEnd: number = 10;
    audioTrimMaxEnd: number = 10;
    blurAmount: number = 0;
    currentTime: string;
    timeLeft: string;
    duration: number;


    constructor(private securamaxApiService: SecuraMaxApiService,private router: Router, private route: ActivatedRoute, private titleService: TitleService, private toastr: SnackbarService) {

    }

    ngOnInit(): void {
        this.titleService.setTitle('Create Redaction');
        this.documentId = this.route.snapshot.paramMap.get('id');
        this.redactionId = this.route.snapshot.paramMap.get('redactionId');
        this.securamaxApiService.documents_Get(this.documentId).subscribe(doc => {
            this.document = doc;

            if (this.document.versions) {
                this.videoSrcUrl = this.document.versions.find(x =>
                    x.videoFormat === "mp4"
                )?.url;
            }
            this.loading = false;

        }, (err) => {
            this.toastr.error('Error occurred while getting the document.');
        });
    }

    toTimespanString(seconds: number) {
        var date = new Date(null);
        date.setMilliseconds(seconds * 1000);
        var isoDate = date.toISOString().substr(11, 12);
        return isoDate.replace('-', ':');
    }

    fromTimespanString(timespanString: string) {
        var date = new Date('1970-01-01T' + timespanString + 'Z');
        //convert to seconds
        return date.getMinutes() * 60 + date.getSeconds() + date.getMilliseconds() * .001;
    }

    submit(): void {
        this.saving = true;
        this.redaction.documentId = this.documentId;
        this.redaction.videoStart = this.toTimespanString(this.videoTrimStart);
        this.redaction.videoEnd = this.toTimespanString(this.videoTrimEnd);
        this.redaction.audioStart = this.toTimespanString(this.audioTrimStart);
        this.redaction.audioEnd = this.toTimespanString(this.audioTrimEnd);
        this.redaction.blurAmount = this.blurAmount;

        this.securamaxApiService.redaction_Post(this.redaction).toPromise().then((id) => {
            this.redactionId = id + '';
            this.saving = false;
            this.toastr.success('Created successfully!');
            this.router.navigate(['documents', this.document.id, 'redactions', id]);
        }, (failure) => {
            this.saving = false;
            this.toastr.error('Create failed: ' + failure);
        });
    }

    onPlayerReady(api: VgApiService) {
        this.vgAPI = api;

        this.vgAPI.getDefaultMedia().subscriptions.timeUpdate.subscribe(
            () => {
                var currenttime = Math.round((this.vgAPI.getDefaultMedia().currentTime + Number.EPSILON) * 100) / 100;
                var timeleft = Math.round((this.duration - currenttime + Number.EPSILON) * 100) / 100;

                var currenttimeminutes = Math.floor(currenttime / 60);
                var currenttimeseconds = Math.round((currenttime % 60 + Number.EPSILON) * 100) / 100;

                var timeleftminutes = Math.floor(timeleft / 60);
                var timeleftseconds = Math.round((timeleft % 60 + Number.EPSILON) * 100) / 100;

                var currenttimesecondscorrected = currenttimeseconds.toString();
                if (currenttimeseconds < 10) {
                    currenttimesecondscorrected = "0" + currenttimeseconds;
                }

                var timeleftsecondscorrected = timeleftseconds.toString();
                if (timeleftseconds < 10) {
                    timeleftsecondscorrected = "0" + timeleftseconds;
                }

                this.currentTime = currenttimeminutes + ":" + currenttimesecondscorrected;
                this.timeLeft = timeleftminutes + ":" + timeleftsecondscorrected;

                console.log("currenttime " + currenttime);
                console.log("timeleft " + timeleft);
                console.log("currenttimeminutes " + currenttimeminutes);
                console.log("currenttimeseconds " + currenttimeseconds);
                console.log("timeleftminutes " + timeleftminutes);
                console.log("timeleftseconds " + timeleftseconds);
            }
        );

        this.vgAPI.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
            () => {
                this.duration = this.vgAPI.getDefaultMedia().duration;
                this.audioTrimMaxEnd = this.duration;
                this.videoTrimMaxEnd = this.duration;
                this.audioTrimEnd = this.duration;
                this.videoTrimEnd = this.duration;
            }
        );
    }

    videoSliderValueChanged(event) {

        if (event != undefined) {
            this.videoTrimStart = event.value;
            this.videoTrimEnd = event.highValue;
        }

        if (this.audioTrimStart < this.videoTrimStart) {
            this.audioTrimStart = this.videoTrimStart;
        }

        if (this.audioTrimEnd > this.videoTrimEnd) {
            this.audioTrimEnd = this.videoTrimEnd;
        }

        if (this.audioTrimStart > this.videoTrimEnd) {
            this.audioTrimStart = this.videoTrimStart;
            this.audioTrimEnd = this.videoTrimEnd;
        }

        if (this.audioTrimEnd < this.videoTrimStart) {
            this.audioTrimStart = this.videoTrimStart;
            this.audioTrimEnd = this.videoTrimEnd;
        }
    }

    audioSliderValueChanged(event) {

        if (event != undefined) {
            this.audioTrimStart = event.value;
            this.audioTrimEnd = event.highValue;
        }

        if (this.audioTrimStart < this.videoTrimStart) {
            this.audioTrimStart = this.videoTrimStart;
        }

        if (this.audioTrimEnd > this.videoTrimEnd) {
            this.audioTrimEnd = this.videoTrimEnd;
        }

        if (this.audioTrimStart > this.videoTrimEnd) {
            this.audioTrimStart = this.videoTrimStart;
            this.audioTrimEnd = this.videoTrimEnd;
        }

        if (this.audioTrimEnd < this.videoTrimStart) {
            this.audioTrimStart = this.videoTrimStart;
            this.audioTrimEnd = this.videoTrimEnd;
        }
    }

    formatLabel(value: number): string {
        console.log(value);
        return `${value}`;
    }
}
