<h2 mat-dialog-title i18n>Generate Quick Setup File</h2>
<hr/>

<mat-dialog-content>

    <div class="card">
        <h3 class="card-header" i18n>
            Instructions
        </h3>
        <div class="card-body">
            <div class="card-text" i18n>
                A configuration file can be used to quickly set up DVR units for connection to SecuraMax. <br><br>
                <ol>
                    <li>
                        Set all the desired connection settings below and then click "Generate File". <br>
                    </li>
                    <li>
                        Place the config file onto an SD card in a folder called "auto_upgrade" (Example: F:/auto_upgrade/config.cfg). <br>
                    </li>
                    <li>
                        Place the SD card into a DVR unit, it will apply the settings and then beep 4 times when complete. <br>
                    </li>
                    <li>
                        Remove the config SD card and reinstall the original card. DVR will now use the new connection settings. <br>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-6">
            <mat-form-field>
                <mat-label i18n>Device Type</mat-label>
                <mat-select [(value)]="data.targetDeviceType">
                    <mat-option [value]="1" i18n>PD-1902/DR-200v2</mat-option>
                    <mat-option [value]="2" i18n>PD-1900</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n>Timezone </mat-label>
                <mat-select [(value)]="data.timezone">
                    <mat-option *ngFor="let timezone of timezones" [(value)]="timezone.value" i18n>{{timezone.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>


    <div class="card">
        <div class="card-header">
            <mat-checkbox [(ngModel)]="data.FourGEnabled" class="mr-2" i18n>
              <label class="mt-3">4G Connection</label>
            </mat-checkbox>
        </div>
        <div class="card-body" *ngIf="data.FourGEnabled">
            <div class="card-text">
                <div class="row">
                    <fieldset>
                        <div class="col-12" i18n>
                            The default Verizon Wireless connection settings will be used unless modified below.
                            <hr>
                        </div>
                        <div class="col-6">
                            <mat-form-field>
                                <mat-label i18n>Dial Cmd </mat-label>
                                <input matInput [(ngModel)]="data.dialCmd">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label i18n>APN </mat-label>
                                <input matInput [(ngModel)]="data.apn">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label i18n>Username </mat-label>
                                <input matInput [(ngModel)]="data.username">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label i18n>Password </mat-label>
                                <input matInput [(ngModel)]="data.password">
                            </mat-form-field>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
    <br>

    <div class="card">
        <div class="card-header">
            <mat-checkbox [(ngModel)]="data.LANEnabled" class="mr-2" i18n>
              <label class="mt-3">LAN Connection</label>
            </mat-checkbox>
        </div>
        <div class="card-body" *ngIf="data.LANEnabled">
            <div class="card-text">
                <div class="row">
                    <div class="col-6">
                        <p>
                            <mat-checkbox [(ngModel)]="data.DHCPEnabled" class="mr-2" i18n>
                              <label class="mt-3">Use DHCP for LAN IP</label>
                            </mat-checkbox>
                        </p>
                        <fieldset *ngIf="data.LANEnabled && !data.DHCPEnabled">
                            <mat-form-field>
                                <mat-label i18n>LAN Static IP </mat-label>
                                <input matInput #staticIP="ngModel" [(ngModel)]="data.staticIP" placeholder="192.168.1.2"
                                       pattern="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
                                <mat-error *ngIf="staticIP.errors?.pattern" i18n>Please enter a valid IP address ex. xxx.xxx.xxx.xxx</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>LAN Subnet Mask </mat-label>
                                <input matInput #subnetMask="ngModel" [(ngModel)]="data.subnetMask" placeholder="255.255.255.0"
                                       pattern="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
                                <mat-error *ngIf="subnetMask.errors?.pattern" i18n>Please enter a valid IP address ex. xxx.xxx.xxx.xxx</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>LAN Gateway </mat-label>
                                <input matInput #gateway="ngModel" [(ngModel)]="data.gateway" placeholder="192.168.1.1"
                                       pattern="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
                                <mat-error *ngIf="gateway.errors?.pattern" i18n>Please enter a valid IP address ex. xxx.xxx.xxx.xxx</mat-error>
                            </mat-form-field>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>


    <div class="card">
        <div class="card-header">
            <mat-checkbox [(ngModel)]="data.wifiEnable" class="mr-2" i18n>
              <label class="mt-3">Wi-Fi Connection</label>
            </mat-checkbox>
        </div>
        <div class="card-body" *ngIf="data.wifiEnable">
            <div class="card-text">
                <div class="row">
                    <div class="col-12" i18n>
                        Create a list of desired Wi-Fi networks for the DVR to connect to when in range, username is required for enterprise Wi-Fi, otherwise leave empty:
                    </div>
                </div>

                <div class="flex-wrap" *ngFor="let wifiNetwork of data.wifiSSIDPasswordList; let i = index">
                  <div class="d-flex justify-content-between">
                    <mat-form-field class="mr-2">
                        <mat-label i18n>SSID </mat-label>
                        <input matInput [(ngModel)]="wifiNetwork.SSID" required>
                    </mat-form-field>

                    <mat-form-field class="mr-2">
                        <mat-label i18n>Password </mat-label>
                        <input matInput [(ngModel)]="wifiNetwork.Password">
                    </mat-form-field>

                    <mat-form-field class="mr-2">
                        <mat-label i18n>Username </mat-label>
                        <input matInput [(ngModel)]="wifiNetwork.Username">
                    </mat-form-field>
                    <div class="mb-2">
                        <button mat-button class="smx-critical-button" (click)="removeWiFiNetwork(wifiNetwork)" i18n>Remove</button>
                    </div>
                  </div>
                </div>
                <hr/>
              <button mat-button class="smx-primary-button" [disabled]="data.wifiSSIDPasswordList.length >= 50" (click)="addWiFiNetwork()" i18n>Add a WiFi Network</button>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="smx-secondary-button" mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-button class="ml-2 smx-primary-button" [disabled]="(data.wifiEnable && data.wifiSSIDPasswordList.length == 0)"
        [mat-dialog-close]="data">Generate File</button>
    <p>Note: Your browser may prompt you to 'Allow' or 'Accept' the download of the config file.</p>
</mat-dialog-actions>
