import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';

export interface DialogData {
    deviceId: string;
    associatedUserId?: number;
    associatedUser?: string;
    dialogResult?: string;
}

@Component({
    selector: 'app-approve-device-dialog',
    templateUrl: './approve-device-dialog.component.html',
    styleUrls: ['./approve-device-dialog.component.css'],
})
export class ApproveDialogComponent {
    public noUserSelected: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private dialogRef: MatDialogRef<ApproveDialogComponent>,
        public userService: UserService
    ) {}

    somethingChanged() {
        this.noUserSelected = false;
    }

    tryClose(cancelled: boolean) {
        if (cancelled) {
            this.data.dialogResult = 'cancel';
            this.dialogRef.close(this.data);
            return;
        }

        let hasProblems = false;

        if (!this.data.associatedUser) {
            this.noUserSelected = true;
            hasProblems = true;
        }

        if (hasProblems === false) {
            this.data.dialogResult = 'ok';
            this.dialogRef.close(this.data);
        }
    }
}
