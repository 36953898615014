import { Component, OnInit } from '@angular/core';
import { PendingDeletionViewModel } from '../../../services/api/securamaxapi.service';
import { PagingApiService } from 'src/app/services/paging-api.service';
import { TitleService } from 'src/app/services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
  selector: 'app-pendingdeletion',
  templateUrl: './pendingdeletion.component.html',
  styleUrls: ['./pendingdeletion.component.css']
})
export class PendingdeletionComponent implements OnInit {
  datasource: PendingDeletionViewModel[];
  filter: any = { searchTerm: '' };
  totalItems: number;
  modelFromJS: any = PendingDeletionViewModel.fromJS;

  constructor(private pagingAPIService: PagingApiService, private titleService: TitleService, private toastr: SnackbarService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Pending Deletion Report');
  }

  onSearch(searchText: string) {
    this.filter.searchTerm = searchText;
    this.filterChanged();
    //this.pagingAPIService.fetchData<PendingDeletionViewModel>('/api/api/DocumentTask/GetPendingDeletionList', this.filter, this.modelFromJS, undefined).subscribe(function (data) {
    //    console.log(data);
    //    this.datasource = data.data;
    //}, (err) => {
    //  this.toastr.error('Error occurred while getting the reports.');
    //});
  }

  onSearchCleared()
  {
    this.filter.searchTerm = '';
    this.filterChanged();
  }

  onDataSourceUpdate(data: PendingDeletionViewModel[]) {
    this.datasource = data;
  }

  numItemsUpdated(data: number) {
    this.totalItems = data;
  }
  filterChanged() {
    this.filter = Object.assign({}, this.filter);
  }

  search_clear_click() {
    this.filter.searchTerm = '';
}


}
