import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BC300ConfigGroupAssignmentViewModel, BC300ListItemViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-bc300configs-assign',
  templateUrl: './bc300configs-assign.component.html',
  styleUrls: ['./bc300configs-assign.component.css']
})
export class Bc300configsAssignComponent implements OnInit {
  configs: BC300ListItemViewModel[];
  groups: BC300ConfigGroupAssignmentViewModel[];
  loading: boolean = false;

  constructor(private api: SecuraMaxApiService, private toastr: SnackbarService, private location: Location, private titleService: TitleService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Assign Groups to BC-300 Configs');
    this.api.deviceConfigurations_GetAssignments().toPromise().then((data) => {
      this.groups = data;
    }, function (err) {
      this.toastr.error('Error loading configurations');
    });

    this.api.deviceConfigurations_GetAll().toPromise().then((data) => {
      this.configs = data;
    }, function (err) {
      this.toastr.error('Error loading configurations');
    });
  }

  save() {
    var that = this;
    that.loading = true;
    this.api.deviceConfigurations_PutAssignments(that.groups).toPromise().then((data) => {
      this.toastr.success('Saved!');
      that.loading = false;
    }, function (err) {
      that.loading = false;
      this.toastr.error('Error saving!');
    });
  };

  cancel() {
    this.location.back();
  };
}
