/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoryViewModel } from './categoryViewModel';
import { DocumentTagViewModel } from './documentTagViewModel';
import { CasesListViewModel } from './casesListViewModel';


export interface DocumentMetadataViewModel { 
    documentId?: string;
    title?: string | null;
    description?: string | null;
    ownerId?: number | null;
    tags?: Array<DocumentTagViewModel> | null;
    categories?: Array<CategoryViewModel> | null;
    cases?: Array<CasesListViewModel> | null;
    autoCategorized?: boolean;
    important?: boolean;
}

