import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import { MetadataDayViewModel } from './api2';


export interface IAnnotationState {
    step: number;
    metadataPendingDay?: MetadataDayViewModel;
}


@Injectable({
    providedIn: 'root'
})
export class AnnotateService {

    public currentAnnotation = new BehaviorSubject<IAnnotationState>(AnnotateService.currentAnnotation_getStartingState());


    constructor() {
    }

    public reset() {
        this.currentAnnotation.next(AnnotateService.currentAnnotation_getStartingState());
    }


    public static currentAnnotation_getStartingState(): IAnnotationState {
        return {
            step: 1,
            metadataPendingDay: null
        };
    }
}
