/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceUsageViewModel { 
    id?: string;
    deviceSerial?: string | null;
    name?: string | null;
    userId?: number | null;
    userName?: string | null;
    bytesUsed?: number;
    fileCount?: number;
    bytesUploaded?: number;
    uploadedFileCount?: number;
    isDisabled?: boolean;
    lastConnected?: string | null;
}

