<div *ngIf="groupLoading">
    <mat-spinner color="accent"></mat-spinner>
</div>

<div *ngIf="!groupLoading">
    <div class="card">
        <div class="card-body">
            <h2 class="card-title"><span *ngIf="!group.iD || group.iD==null" i18n>Create</span><span *ngIf="group.iD" i18n>Edit</span></h2>
            <form #groupForm="ngForm" (ngSubmit)="save()" novalidate class="d-flex flex-column">
                <mat-form-field appearance="outline">
                    <mat-label i18n>Name</mat-label>
                    <input type="text" matInput name="gName" appearance="outline" required [(ngModel)]="group.name" [disabled]="group.isSystemGroup===true" />
                </mat-form-field>

                <mat-form-field *ngIf="group.iD!=null" appearance="outline">
                    <mat-label i18n>Description</mat-label>
                    <input matInput type="text" appearance="outline" name="gDescription" [(ngModel)]="group.description" [disabled]="group.isSystemGroup===true" />
                </mat-form-field>
                <div class="action-area">
                    <button mat-button class="smx-primary-button" type="submit" [disabled]="!groupForm.valid || saving===true" *ngIf="!group.isSystemGroup" i18n>Save</button>
                    <a mat-button class="smx-critical-button ml-2" routerLink="../" mat-stroked-button i18n>Cancel</a>
                </div>
            </form>
        </div>
    </div>

</div>
