<h2 mat-dialog-title i18n class="dialogContent">Approve Device</h2>
<mat-dialog-content>
    <div>Associated user for this device</div>
    <app-user-select [(userId)]="data.associatedUserId" 
    (userIdChange)="somethingChanged()"
    [(associatedUserName)]="data.associatedUser"
    [includeCurrentUser]="true"></app-user-select>
    <div class="mt-2 alert alert-danger" *ngIf="noUserSelected">You must select a user to assign to in order to complete deivce approval.</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-button
        class="smx-secondary-button"
        (click)="tryClose(true)"
        cdkFocusInitial
        i18n
    >
        Cancel
    </button>
    <button
        mat-button
        class="smx-primary-button ml-2"
        (click)="tryClose(false)"
        i18n
    >
        Approve
    </button>
</mat-dialog-actions>
