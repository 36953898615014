import {OnChanges, SimpleChanges} from '@angular/core';
import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ApiService} from 'src/app/services/api.service';
import {SecuraMaxApiService} from 'src/app/services/api/securamaxapi.service';

@Component({
    selector: 'app-doc-thumbnail',
    templateUrl: './doc-thumbnail.component.html',
    styleUrls: ['./doc-thumbnail.component.css']
})
export class DocThumbnailComponent implements OnInit, OnChanges {

    @Input() id = '';
    @Input() showGoToIcon = true;
    @Input() openInNewTab = false;
    @Input() big = false;

    hasLoaded = false;
    url: SafeUrl = null;

    constructor(private api: ApiService, private sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.id !== '' && this.id.length > 0) {
            this.api.thumbnail(this.id).subscribe((ret) => {
                if (ret !== null) {
                    this.url = this.sanitizer.bypassSecurityTrustUrl(ret);
                }
                this.hasLoaded = true;
            });
        }

    }

    ngOnInit(): void {
    }

    openDocInNewTab($event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        window.open('/documents/'+this.id, '_blank');
    }
}
