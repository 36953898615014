<div class="outer-container">
    <div class="d-flex justify-content-end">
        <div>
            <a mat-button class="smx-secondary-button" routerLink="/admin/devices/bc4configs/assign" i18n>Assign to Groups</a>
            <a mat-button routerLink="/admin/devices/bc4configs/create" class="smx-primary-button ml-1"
                ui-sref="devices.bc4configs.create" i18n>Create</a>
        </div>
    </div>
    <div class="inner-container">
        <table mat-table [dataSource]="datasource">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef i18n>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="assignedToGroups">
                <th mat-header-cell *matHeaderCellDef i18n>Assigned to Groups</th>
                <td mat-cell *matCellDef="let element">{{element.assignedToGroups}} </td>
            </ng-container>

            <ng-container matColumnDef="devices">
                <th mat-header-cell *matHeaderCellDef i18n>Single User Mode Devices*</th>
                <td mat-cell *matCellDef="let element">{{element.devices}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <a mat-button [routerLink]="['/admin/devices/bc4configs/',element.id,'edit']" class="smx-secondary-button"
                       i18n>Edit</a>
                    <a mat-button [routerLink]="['/admin/devices/bc4configs/',element.id,'delete']" class="smx-critical-button ml-1"
                       *ngIf="element.name!=='default'" i18n>Delete</a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"> </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>


    <p class="mt-2">* The devices column will only apply to Single User Mode BC4's.</p>

</div>
