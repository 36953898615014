<div class="card mt-3">
    <div class="card-body card-container">
        <div class="header-container">


            <h1 i18n>Cases</h1>
            <div style="grid-area: buttons;">
                <button mat-button class="smx-primary-button mr-2" routerLink="/cases/create" i18n>New Case</button>
                <button mat-button class="smx-secondary-button help" (click)="openHelpDialog()" i18n>
                    <i class="fas fa-question-sign"></i>
                    <fa-icon [icon]="['fas','question-circle']"></fa-icon>
                    Help
                </button>
            </div>

        </div>
        <div class="d-flex my-4 search-controls filter-settings-container">
            <app-searchbar (search)="search($event)"></app-searchbar>
            <div class="label-and-control-container ml-3">
                <label>Date and Time Range</label>
                <app-daterangepicker #dateRangePicker (change)="daterangepicker_change($event)" [(start)]="daterangepicker_start" [(end)]="daterangepicker_end"></app-daterangepicker>
            </div>

            <mat-radio-group name="caseSelection" [(ngModel)]="filter.caseType"
                             (ngModelChange)="filterChanged()">
                <mat-radio-button [value]=1 i18n>
                    <label>My Cases</label>
                </mat-radio-button>
                <mat-radio-button [value]=2 i18n>
                    <label>Shared Cases</label>
                </mat-radio-button>
                <mat-radio-button [value]=0 i18n>
                    <label>All Cases</label>
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="table-container">
            <table class="table">
                <thead>
                <tr>
                    <th i18n>Identifier</th>
                    <th i18n>Owner</th>
                    <th i18n>Document Start Time</th>
                    <th i18n>Document End Time</th>
                    <th i18n>Documents</th>
                    <th i18n>Share Status</th>
                    <th i18n>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let case of datasource" [class]="{active: (case.status == 0)}">
                    <td i18n>{{case.title}}</td>
                    <td i18n>{{case.owner}}</td>
                    <td *ngIf="case.documentsCount" i18n>{{case.startRange | date:'short'}}
                        <!--{{case.StartRange | utcToLocal:'yyyy-MM-dd h:mma'}}-->
                    </td>
                    <td *ngIf="case.documentsCount" i18n>{{case.endRange | date:'short'}}
                        <!--{{case.EndRange | utcToLocal:'yyyy-MM-dd h:mma'}}-->
                    </td>
                    <td *ngIf="!case.documentsCount"></td>
                    <td *ngIf="!case.documentsCount"></td>
                    <td i18n>{{case.documentsCount}}</td>
                    <td>
                        <label *ngIf="case.isShared" class="badge badge-success" i18n>Shared With
                            ({{case.isShared}} Users)</label>
                        <label *ngIf="!case.isShared" class="badge badge-warning" i18n>Not
                            Shared</label>
                    </td>
                    <td>
                        <label *ngIf="case.status == 0" class="badge badge-default" title="Case Closed" i18n-title
                               i18n>Closed</label>
                        <label *ngIf="case.status == 1" class="badge badge-success" title="Case Open" i18n-title
                               i18n>Open</label>
                    </td>

                    <td class="buttons">
                        <a mat-button class="smx-primary-button mr-2"
                           routerLink="/cases/{{case.id}}/details2" i18n>Details</a>
                        <button mat-button class="smx-secondary-button" color="primary"
                                routerLink="/cases/{{case.id}}/edit"
                                [disabled]="!case.canEditCase" i18n>Edit
                        </button>
                    </td>
                </tr>
                <tr *ngIf="isLoading">
                    <td colspan="8">
                        <mat-spinner color="accent" diameter="20"></mat-spinner>
                    </td>
                </tr>
                <tr *ngIf="totalItems=== 0">
                    <td colspan="8">
                        <span i18n>No Cases Found</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div>
            <app-s-paginate [modelFromJS]="modelFromJS"
                            (collection)="onDataSourceUpdate($event)"
                            (numItems)="numItemsUpdated($event)"
                            [url]="'/api/api/Cases/GetAll'"
                            [urlParams]="filter"
                            (isLoading)="onIsLoadingChange($event)">
            </app-s-paginate>
        </div>
    </div>
</div>
