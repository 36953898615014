import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-icon',
  templateUrl: './device-icon.component.html',
  styleUrls: ['./device-icon.component.css']
})
export class DeviceIconComponent implements OnInit {

  @Input() deviceType = "";
  
  @Input() iconClass="";

  
  
  iconAltText:string=""

  iconPath:string="";
  
  constructor() { }

  ngOnInit(): void {
    this.iconAltText=this.deviceType+" icon";
    this.iconPath='assets/images/icons/'+this.getIconName(this.deviceType);
  }

  getIconName(deviceType: string){
    let iconName: string = "";
    switch (deviceType)
    {
        case "BC100":
          iconName = "bc100_thumb.png";
            break;
        case "BC300":
          iconName = "bc300_thumb.png";
            break;
        case "BC4":
          iconName = "bc400_thumb.png";
            break;
        case "700 Series DVR":
          iconName = "dvr700_thumb.png";
            break;
        case "800 Series DVR":
          iconName = "dvr800_thumb.png";
            break;
        case "PD-1900":
          iconName = "pd1900_thumb.png";
            break;
        case "DR-200":
          iconName = "dr200_thumb.png";
            break;
        case "BODY CAMERA":
          iconName = "bc_generic_thumb.png";
            break;
        case "WEB":
          iconName = "web_generic_thumb.png";
            break;
        case "In Vehicle DVR":
        default:
          iconName = "dvr_generic_thumb.png";
            break;
    }

    return iconName;
  }

}
