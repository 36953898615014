<div *ngIf="document !== undefined">
    <nav  aria-label="breadcrumb">

        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/" i18n>Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/documents/{{documentId}}" i18n>Document {{document.name}}</a></li>
            <li class="breadcrumb-item active"  aria-current="page" i18n>Create Advanced Redaction</li>
        </ol>
    </nav>

    <div class="alert alert-danger" *ngIf="isServerError===true" i18n>
        <strong>Error</strong> Could not load redaction tool. Please verify your license is not expired and try again. If this problem persists, contact Pro-Vision support.
    </div>
    <!--<div class="well" *ngIf="isServerError===false && showGeneratingProgress===false">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon> Loading...
    </div>-->

    <div class="embed-responsive embed-responsive-4by3 mh-780" *ngIf="!(isServerError)"  [ngClass]="{'embedhide': showGeneratingProgress===true}">
        <iframe scrolling="no" [src]="iframeUrl" i18n-title title="video-redaction" class="embed-responsive-item mh-780" id="mainframe" [ngClass]="{'iframehide': showGeneratingProgress===true}"></iframe>
    </div>
    <div *ngIf="showGeneratingProgress" class="well">
        <h3 *ngIf="showGeneratingProgressValue < 100" i18n><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon> Generating Redacted Video...</h3>
        <h5 *ngIf="showGeneratingProgressValue < 100" class="mb-1" i18n>This may take a few minutes.</h5>
        <mat-progress-bar *ngIf="showGeneratingProgressValue<100" class="mt-4 mb-3" color="accent" mode="determinate" value="{{showGeneratingProgressValue}}"></mat-progress-bar>
        <div *ngIf="showGeneratingProgressValue === 100" class="alert alert-success" i18n>
            <strong>Complete</strong> Redaction generated successfully
        </div>

        <div><a routerLink="/documents/{{redaction.redactionDocumentId}}" *ngIf="showGeneratingProgressValue === 100" i18n>View Redacted Document</a></div>
    </div>
    <div *ngIf="!initialLoadComplete" i18n>
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon> Loading Redaction Tool....
    </div>
    <div class="btn-container" *ngIf="isServerError === false && iframeUrl &&!showGeneratingProgress" style="padding: 4px;">
        <a mat-button routerLink="/documents/{{documentId}}" class="smx-secondary-button mr-1" *ngIf="!(showGeneratingProgress || isServerError)" i18n><i class="fa fa-arrow-left"></i> Back to Document</a>
        <button mat-button (click)="sendPostMessage('save_metadata')" *ngIf="!(showGeneratingProgress || isServerError)" class="smx-primary-button mr-1" i18n>
            <i class="fa fa-save"></i> Save Work
        </button>

        <button mat-button class="smx-primary-button" *ngIf="!(showGeneratingProgress || isServerError)" (click)="generateRedactedVideo()" i18n>
            <i class="fa fa-file-movie-o"></i> Generate Redacted Video
        </button>
    </div>
</div>
