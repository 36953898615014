<h2 mat-dialog-title i18n>Modify Upload Station</h2>
<hr/>

<div>
    <div class="form-group pl-3 pr-3">
        <label class="control-label" i18n>Station Name</label>
        <mat-form-field appearance="fill" hintLabel="" style="display:block;">
            <input matInput [(ngModel)]="data.stationName">
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="smx-critical-button" mat-dialog-close cdkFocusInitial i18n>Cancel</button>
    <button mat-button class="ml-2 smx-primary-button" [mat-dialog-close]="data" [disabled]="data.stationName === ''" i18n>Save</button>
  </mat-dialog-actions>
