import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';
import { DocumentSearchService } from 'src/app/services/document-search.service';
import { SearchFilter } from 'src/app/models/search-filter.model';


@Component({
  selector: 'app-documents-search',
  templateUrl: './documents-search.component.html',
  styleUrls: ['./documents-search.component.css']
})
export class DocumentsSearchComponent implements OnInit {
  filter: SearchFilter = {};

  constructor(private titleService: TitleService,
    private documentSearchService: DocumentSearchService)
  {
    // this.documentSearchService.isSearchButtonClick.subscribe(value => {
    //   this.filter = Object.assign({}, value);
    // }, (err) => {
    // });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Search');
  }
}
