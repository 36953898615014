import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css']
})
export class GroupDetailComponent implements OnInit {

  group: GroupViewModel;
  id: string = null;
  groupLoading: boolean = true;
  saving: boolean = false;

  constructor(private api: SecuraMaxApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: SnackbarService,
    private titleService: TitleService) { }

    ngOnInit(): void {
        this.titleService.setTitle('Group Details');
    this.route.params.subscribe(val => {
      // put the code from `ngOnInit` here
      this.id = val.id;

      if (this.id) {
          this.api.group_Get(this.id).toPromise().then((data) => {
              this.titleService.setTitle('Group Details: ' + data.name);
          this.group = data;
          this.groupLoading = false;
        }, (err) => {
          this.toastr.error('Error occurred while getting the group');
        });
      } else {
        this.group = new GroupViewModel();
        this.groupLoading = false;
      }
    });
  }

  save() {
    this.saving = true;
    if (this.group.iD) {
      this.api.group_Put(this.group).toPromise().then((data) => {
        this.saving = false;
        this.toastr.success('Saved successfully!');
        this.router.navigate(['/admin/users/groups'], { state: { reload: true } });
        //this.$state.go('users.groups', null, { reload: true });
      }).catch((err) => {
        this.saving = false;
        this.toastr.error('Save failed! Please try again later.');
      });
    } else {
      this.api.group_Post(this.group.name).toPromise().then((data) => {
        this.saving = false;
        this.group = data;
        this.toastr.success('Saved successfully!');
        this.router.navigate(['/admin/users/groups'], { state: { reload: true } });
      }).catch((err) => {
        this.saving = false;
        if (err?.status === 409) {
          this.toastr.error('Error! Group with that name already exists.');
        } else {
          this.toastr.error('Save failed! Please try again later.');
        }
      });
    }
  }

  remove() {
    if (this.group.isSystemGroup === false) {
      this.api.group_Delete(this.group.iD).toPromise().then(() => {
        this.router.navigate(['/admin/users/groups']);

      });
    }
  }

  saveRight(right) {
    this.api.group_UpdateRight(this.group.iD, right).toPromise().then(() => {
      this.toastr.success('Successfully saved!');
      this.router.navigate(['/admin/users/groups/group', this.group.iD]);
    });
  }
}
