import { Directive, Injectable, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, debounceTime } from 'rxjs/operators';
import { SecuraMaxApiService } from '../../services/api/securamaxapi.service';

@Directive({
  selector: '[userAliasValidator]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: UserAliasValidator, multi: true }
  ]
})
export class UserAliasValidator implements AsyncValidator {

  @Input('userAliasValidator') userId = '';

  constructor(private api: SecuraMaxApiService) { }

  checkIfUserAliasValid(alias: string) {
    if (this.userId?.length > 0) {
      return this.api.users_IsUserAliasValid(this.userId, alias);
    } else {
      return this.api.users_IsUserAliasValid('', alias);
    }
  }

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {

    return this.checkIfUserAliasValid(ctrl.value).pipe(debounceTime(500),
      map(res => {
        // if res is true, alias is valid
        //if false it means not valid
        return res ? null : { aliasInvalid: true };
        // NB: Return null if there is no error
      }), catchError(err => {
        return of({ aliasInvalid: true });
      })
    );
  };
}