/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiPvTransferRequestCompleteEmailModel { 
    serverUrl?: string | null;
    clientId?: string | null;
    deviceUnitId?: string | null;
    transfersCount?: number;
    start?: string | null;
    stop?: string | null;
    to?: Array<string> | null;
}

