<div class="main-container">
    <p i18n>These documents have a deletion request pending. You should either approve or disapprove.</p>
    <div class="d-flex">
        <app-searchbar (search)="onSearch($event)" (clear)="onSearchCleared()"
                       placeholder="Awaiting Delete Approval Search" isPrimary="true"></app-searchbar>
    </div>


    <div class="details-card">
        <table class="table">
            <thead>
            <tr>
                <th i18n>Document</th>
                <th i18n>Requestor</th>
                <th i18n>Reason</th>
                <th colspan="2" i18n>Approve Deletion Request</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let doc of datasource">
                <td>
                    <a routerLink="/documents/{{doc.id}}">{{doc.name}}</a>
                    <p *ngIf="doc.title">{{doc.title}}</p>
                </td>
                <td>{{doc.deleteRequestorName}}</td>
                <td>{{doc.reason}}</td>
                <td *ngIf="!doc.hasBeenReviewed">
                    <button mat-button class="smx-critical-button" (click)="approveDelete(doc)" i18n>Yes, Delete
                    </button>
                </td>
                <td *ngIf="!doc.hasBeenReviewed">
                    <button mat-button class="smx-secondary-button" (click)="disapproveDelete(doc)" i18n>No,
                        Deny
                    </button>
                </td>
                <td *ngIf="doc.hasBeenReviewed == true">
                    <label class="badge badge-success" i18n>Document Reviewed</label>
                </td>
            </tr>
            </tbody>
        </table>
    </div>


    <span *ngIf="datasource">{{totalItems}}
        <ng-container i18n>Results</ng-container></span>
    <span *ngIf="!datasource">
            <mat-spinner color="accent"></mat-spinner>
        </span>


    <app-s-paginate [modelFromJS]="modelFromJS" (collection)="onDataSourceUpdate($event)"
                    (numItems)="numItemsUpdated($event)" [url]="'/api/api/DocumentTask/GetDeleteApprovalList'"
                    [urlParams]="filter">
    </app-s-paginate>
</div>
