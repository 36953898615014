<h2 mat-dialog-title i18n>Audit User</h2>
<hr />
<p class="pl-3 pr-3" i18n>Auditing contains information in 90 day time frames (or increments).
    Select a start date to begin the Users Audit.</p>
<div class="pl-3">
    <mat-form-field appearance="fill">
        <mat-label i18n>Select Audit Start Date</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="startDate" max="maxDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="smx-secondary-button" mat-dialog-close cdkFocusInitial i18n>Cancel</button>
    <button mat-button class="smx-primary-button ml-2" (click)="close()" color="accent" [disabled]="startDate.invalid" i18n>Audit</button>
</mat-dialog-actions>
