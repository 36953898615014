
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";



@Injectable()
export class AntiforgeryInterceptor implements HttpInterceptor {
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === 'https://files.provisionusa.com/api/Feedback') {
      return next.handle(req);
    }

    // JSONP requests don't support headers, so we cannot add a header when calling this api
    if (req.url.startsWith('https://maps.googleapis.com/')) {
      return next.handle(req);
    }

    req = req.clone({ withCredentials: true });

    let possibleCookie = document.cookie.split("; ").find(x => x.startsWith("pv_antiforgery_token"))
    if (possibleCookie) {
      const antiforgeryCode = possibleCookie.split("=")[1];
      const newReq = req.clone({
          setHeaders: {
            'X-XSRF-TOKEN': antiforgeryCode
          }
      });
      return next.handle(newReq);
    }

    return next.handle(req);
  }
}
