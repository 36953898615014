/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RedactionType } from './redactionType';
import { RedactionStatus } from './redactionStatus';


export interface RedactionListItemModel { 
    id?: number;
    redactionStatus?: RedactionStatus;
    created?: string;
    createdByUsername?: string | null;
    redactionType?: RedactionType;
    redactionDocumentId?: string | null;
}
export namespace RedactionListItemModel {
}


