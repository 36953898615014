<div class="card-container">
    <mat-progress-spinner *ngIf="metadataPendingDays_loaded === false" class="spinner"
                          mode="indeterminate"
                          diameter="40">
    </mat-progress-spinner>
    <table *ngIf="metadataPendingDays_loaded && metadataPendingDays.length > 0" class="table">
        <thead>
        <tr>
            <th i18n>Upload Date</th>
            <th i18n># Remaining</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let day of metadataPendingDays; let dayIndex = index">
            <td i18n>
                {{day.date | date:'fullDate':'GMT'}}
            </td>
            <td i18n>
                {{day.files.length}}
            </td>
            <td>
                <button mat-button class="smx-primary-button" (click)="selectDay(dayIndex)" i18n>Review
                </button>
            </td>
        </tr>

        </tbody>

    </table>
    <div *ngIf="metadataPendingDays_loaded && metadataPendingDays.length === 0">
        None. All caught up!
    </div>
</div>
