import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserQueryResponse } from '../../../services/api/securamaxapi.service';
import { UserAuditReportSelectDialogComponent } from './user-audit-report-select-dialog/user-audit-report-select-dialog.component';
import { PagingApiService } from 'src/app/services/paging-api.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-audit-user',
  templateUrl: './audit-user.component.html',
  styleUrls: ['./audit-user.component.css']
})
export class AuditUserComponent implements OnInit {
  datasource: UserQueryResponse[];
  filter: any = { searchTerm: '' };
  totalItems: number;
  modelFromJS: any = UserQueryResponse.fromJS;

  constructor(private api: ApiService, private titleService: TitleService, private toastr: SnackbarService, public dialog: MatDialog, private pagingAPIService: PagingApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Audit User Report');
  }

  onSearch(searchText: string) {
    this.filter.searchTerm = searchText;
    this.filterChanged();
    //this.pagingAPIService.fetchData<UserQueryResponse>('/api/api/Users/All', this.filter, this.modelFromJS, undefined).subscribe(function (data) {
    //  this.datasource = data.data;
    //}, (err) => {
    //  this.toastr.error('Error occurred while getting the reports.');
    //});
  }

  onSearchCleared() {
    this.filter.searchTerm = '';
    this.filterChanged();
  }

  onDataSourceUpdate(data: UserQueryResponse[]) {
    this.datasource = data;
  }

  numItemsUpdated(data: number) {
    this.totalItems = data;
  }

  filterChanged() {
    this.filter = Object.assign({}, this.filter);
  }

  downloadFile(data: Response) {

  }

  openDialog(element: UserQueryResponse) {
    const dialogRef = this.dialog.open(UserAuditReportSelectDialogComponent, {
      height: "300px",
      width: "500px",
      data: element
    });

    dialogRef.afterClosed().subscribe((evt) => {
      this.api.auditReport(evt.iD, evt.startDate).subscribe();
    });
  }
}
