import { Component, Inject, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    DeviceCamerasViewModel,
    EditUserViewModel,
    SecuraMaxApiService,
    TransferRequestModel,
} from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

type CameraModel = {
    cameraName: string;
    cameraNumber: number;
    image: string;
    hasPreviewImage: boolean;
    selected: boolean;
};

type LengthModel = {
    value: number;
    description: string;
};

interface ICreateRequestDialogComponentForm {
    cameras: FormControl<CameraModel[] | null>;
    length: FormControl<number | null>;
    notes: FormControl<string | null>;
}

@Component({
    selector: 'app-create-request-dialog',
    standalone: false,
    templateUrl: './create-request-dialog.component.html',
    styleUrl: './create-request-dialog.component.css',
})
export class CreateRequestDialogComponent implements OnInit {
    cameras: CameraModel[] = [];
    cameras_loaded: boolean = false;

    deviceType: string | null = null;

    allLengths: LengthModel[] = [];

    calculatedEndTime: string | null = null;

    form: FormGroup<ICreateRequestDialogComponentForm>;
    daterangepicker_start: any;
    daterangepicker_end: any;
    
    user: EditUserViewModel;

    constructor(
        private securaMaxApiService: SecuraMaxApiService,
        public dialogRef: MatDialogRef<CreateRequestDialogComponent>,
        private toastr: SnackbarService,
        public userService: UserService,
        fb: FormBuilder,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.allLengths = [
            { value: 1, description: '1 minute' },
            { value: 5, description: '5 minutes' },
            { value: 10, description: '10 minutes' },
            { value: 15, description: '15 minutes' },
            { value: 30, description: '30 minutes' },
            { value: 45, description: '45 minutes' },
            { value: 60, description: '60 minutes' },
        ];
        this.form = fb.group({
            cameras: fb.control(null, [Validators.required]),
            length: fb.control(this.allLengths[0].value, [Validators.required]),
            notes: fb.control(null),
        });
    }

    ngOnInit(): void {
        this.userService.getCurrentUser().then((userResult) => {
            this.user = userResult;
        });

        this.form.controls.length.valueChanges.subscribe(() => {
            this.updateCaluatedEndTime();
        });

        this.securaMaxApiService
            .devices_GetDevice(this.data.id)
            .subscribe((x) => {
                this.deviceType = x.deviceType;
            });

        this.securaMaxApiService
            .devices_GetCameraSelectionList(this.data.id)
            .subscribe((x) => {
                const newCameras = x.cameraList.map((xx) => {
                    const cameraModel = xx as unknown as CameraModel;
                    return cameraModel;
                });
                newCameras.forEach((xx) => {
                    if (xx.image) {
                        xx.image = `data:image/png;base64, ${xx.image}`;
                        xx.hasPreviewImage = true;
                    }
                });
                this.cameras = newCameras;
                this.cameras_loaded = true;
            }, () => {
                // todo logging
                this.cameras_loaded = true;
            });
    }

    async createRequest() {
        try {
            if (this.deviceType === 'BC4') {
                this.form.controls.cameras.setValue([this.cameras[0]]);
            }

            if (!this.form.valid) {
                this.toastr.error('There are issues that need addressing.');
                return;
            }

            const length = this.form.controls.length.value;
            const start = moment(this.daterangepicker_start);
            const end = start.clone().add(length, 'minutes');
            const model = new TransferRequestModel({
                startTime: start.toDate(),
                endTime: end.toDate(),
                cameras: this.form.controls.cameras.value.map(
                    (x) => new DeviceCamerasViewModel(x)
                ),
            });
            await this.securaMaxApiService
                .device_PostRequestTransfer(this.data.id, model)
                .toPromise();
            this.toastr.success('Request Successfully Submitted.');
            // Apparently this behavior is no longer desired by default.
            // todo make optional?
            // await this.router.navigateByUrl(
            //     `/admin/devices/${this.data.id}/details`
            // );
            this.dialogRef.close();
        } catch (err) {
            this.toastr.error(
                'An error occurred while requesting file transfer.'
            );
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    daterangepicker_change() {
        this.updateCaluatedEndTime();
    }

    updateCaluatedEndTime() {
        const start = moment(this.daterangepicker_start);
        const length = this.form.controls.length.value;
        this.calculatedEndTime = start
            .add(length, 'minutes')
            .format('MM/DD/YYYY hh:mm A');
    }
}
