
<div class="search-1">
    <div class="search-input-1">
        <input type="text" [formControl]="searchText" [placeholder]="placeholder" class="search-input" [ngClass]="{'search-input-uppercase': isUppercase}" (keydown.enter)="onEnter($event)">
        <mat-icon *ngIf="searchText.value !== ''" class="search-x-button" (click)="clear_click()" >clear</mat-icon>
    </div>
    <div class="search-button-1" [ngClass]="{'search-button-1-primary': isPrimary}">
        <mat-icon class="search-button-icon" (click)="search.emit(searchText.value)">search</mat-icon>
    </div>
</div>
