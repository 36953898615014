<h2 mat-dialog-title i18n>Administrative Rights Help</h2>
<mat-dialog-content class="mat-typography">

    <div class="list-group">
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Manage Devices</h4>
            <p class="mb-1" i18n>Provides access to Admin > Devices. On this page a user can edit the name of a device as well as the associated user for that device. This also allows a user to configure a new device to the site through the Upload Station. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Manage Upload Stations</h4>
            <p class="mb-1" i18n>Provides access to Admin > Upload Stations. This page holds the download links for the BC-300 drivers and Upload Station Windows software. The Upload Station is the transfer tool used for BC-300(s). A user can also create a new station identity
                on this page and manage existing Upload Stations.
            </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Manage Categories</h4>
            <p class="mb-1" i18n>Provides access to the Categories section in Admin > Lookups. A user can create new categories or manage existing categories on this page. Categories are conceptually similar to folders and can hold specific retention policies if required.
                </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Manage Tags</h4>
            <p class="mb-1" i18n>Provides access to the Tags section in Admin > Lookups. A user can create new tags or manage existing tags on this page. Tags help create uniform metadata users can attach to their documents to make searching for documents much easier.</p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Manage Users</h4>
            <p class="mb-1" i18n>Provides access to the Users sections in Admin > Users/Permissions. A user can create other new users or manage existing users on this page. Manage existing users includes functionality like resetting passwords, editing user account information,
                and managing permissions on the site. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Manage Groups</h4>
            <p class="mb-1" i18n>Provides access to the Groups sections in Admin > Users/Permissions. A user can create new groups or manage existing groups on this page. Assigning users to groups is a function held under the Manage Users permission. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>View Pending Deletion Report</h4>
            <p class="mb-1" i18n>Provides access to the Pending Deletion Report in Admin > Reports. A user can view documents that are close to retention policy or deletion on this page. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>View Device Usage Report</h4>
            <p class="mb-1" i18n>Provides access to the Device Usage Report in Admin > Reports. A user can view how much data a device is holding on the site from this page. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Manage Site Settings</h4>
            <p class="mb-1" i18n>Provides access to Admin > Site Settings. A user can edit the default retention policy of documents that have not yet been assigned a category on this page. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Approve Deletion Request</h4>
            <p class="mb-1" i18n>Allows user to approve deletion requests on the site. The user must first have View permissions for the documents they need to approve or disapprove deletion requests on. </p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial i18n>Close</button>
</mat-dialog-actions>