/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestDocumentModel } from './requestDocumentModel';


export interface RequestResponseModel { 
    cameras?: string | null;
    completed?: string | null;
    created?: string;
    desiredStart?: string;
    desiredStop?: string;
    documentsList?: Array<RequestDocumentModel> | null;
    id?: number;
    status?: number;
}

