/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CaseStatus } from './caseStatus';
import { CaseDocumentListViewModel } from './caseDocumentListViewModel';


export interface CasesDetailsViewModel { 
    id?: number;
    title?: string | null;
    createDate?: string | null;
    startRange?: string;
    endRange?: string;
    description?: string | null;
    status?: CaseStatus;
    modified?: string | null;
    caseStatusChangeDescription?: string | null;
    documents?: Array<CaseDocumentListViewModel> | null;
    documentsToRemoveFromCase?: Array<CaseDocumentListViewModel> | null;
    createdByStr?: string | null;
    canEditCase?: boolean;
    ownerId?: number;
    ownerName?: string | null;
    retention?: number;
    sharedUsersCanEdit?: boolean;
}
export namespace CasesDetailsViewModel {
}


