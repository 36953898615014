<h2 mat-dialog-title i18n>Own Document Permissions Help</h2>
<mat-dialog-content class="mat-typography">
    <div class="list-group">
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Upload Directly</h4>
            <p class="mb-1" i18n>Provides access to the Upload button located in the top toolbar on the site. This allows users to browse for any file on their computer or network and manually upload that file to the site. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Can be Assigned to Device</h4>
            <p class="mb-1" i18n>Allows user to be associated to a recording device (In-car DVR or Body camera). A user associated to a device will take ownership of the uploaded documents from that device.</p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>View Own Documents</h4>
            <p class="mb-1" i18n>Allows user to search for and load the details page of a document that is uploaded from a device associated to their account.</p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Share Own Documents</h4>
            <p class="mb-1" i18n>Allows user to utilize the sharing function from the details page of a document. They will be able to share the details page (URL) of a document to another user account or email address. Setting an expiration date and permissions of the share
                at the time of sharing. </p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Edit Metadata on Own Documents</h4>
            <p class="mb-1" i18n>Allows user to edit the title, description, and ownership of the document. Also allows user to add or remove tags and categories to a document.</p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Download Own Documents</h4>
            <p class="mb-1" i18n>Allows user to download a copy of the document from SecuraMax. The original document will stay on SecuraMax until removed via retention policy or approved deletion request. </p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial i18n>Close</button>
</mat-dialog-actions>