<h2 mat-dialog-title i18n>Time Based Auto Review Rules</h2>
<mat-dialog-content class="mat-typography">
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Overview">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>What are Time Based Auto Review Rules?</h3>
                    <p class="mb-1" i18n>Auto Review allows users to make a rule that will auto-review incoming documents from the specified time range.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>How does it work?</h3>
                    <p class="mb-1" i18n>When a user creates a rule for a specified time range, incoming documents (videos, photos, etc) that were created in that time range will be automatically reviewed and tagged with the given Title, Description, Tags, and Categories.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Does my rule affect all documents being uploaded?</h3>
                    <p class="mb-1" i18n>No. The rule you create will only affect documents from devices that are assigned to you. If a document is uploaded in your rules time range but you aren't assigned to that device, that document will not be affected by your rule.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Why can't I overlap rules?</h3>
                    <p class="mb-1" i18n>You cannot create rules that overlap in time range. If you need to create a rule that does overlap, you will have to disable the active rule in that time range then create a new rule.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>What are the different statuses?</h3>
                    <p class="mb-1" i18n>Active means the tag is currently auto-reviewing new documents. Disabled means that you have explicitly stopped the auto-review in that time range. Expired means that the auto-review is no longer active because each tag has a certain amount of time being active.</p>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Create">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Timeline</h3>
                    <p class="mb-1" i18n>The timeline shows any Auto-Review rules that have been made and their status. You can double click on the timeline to create a new rule. The new rule can me dragged across the timeline as well as expanded in its range.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Start/End Date Pickers</h3>
                    <p class="mb-1" i18n>The date and time pickers reflect the range on the timeline of the new auto-review rule. Any documents that are created in this timerange will be automatically reviewed.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Category</h3>
                    <p class="mb-1" i18n>This is a mandatory option that assigns a category to the auto-reviewed document. These categories are designed by your system administrator.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Title (Optional)</h3>
                    <p class="mb-1" i18n>This assigns a title to the the document that is auto-reviewed.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Description (Optional)</h3>
                    <p class="mb-1" i18n>This assigns a description to the the document that is auto-reviewed.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Tag Type (Optional)</h3>
                    <p class="mb-1" i18n>This assigns a tag to the document that is auto-reviewed. These tags are designed by your system administrator.</p>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Details">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Rule Info</h3>
                    <p class="mb-1" i18n>This information describes the date range of the auto-review rule, the expiration date, and the status of the rule.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Details</h3>
                    <p class="mb-1" i18n>This information describes the Title and Description that is being applied to the auto-reviewed documents.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Tags</h3>
                    <p class="mb-1" i18n>Gives a list of tags that are being applied to the auto-reviewed documents.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Category</h3>
                    <p class="mb-1" i18n>Gives a list of categories that are being applied to the auto-reviewed documents.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Description</h3>
                    <p class="mb-1" i18n>This assigns a description to the the document that is auto-reviewed.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Auto Reviewed Documents</h3>
                    <p class="mb-1">Lists all the documents that have been currently reviewed with this tag.</p>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial i18n>Close</button>
</mat-dialog-actions>