import { Component, OnInit } from '@angular/core';
import { CategoryRuleDVREventViewModel, CategoryRuleViewModel, CategoryViewModel, ICategoryRuleDVREventViewModel, ICategoryRuleViewModel, ICategoryViewModel, RuleType, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { DvrEventTypes, RuleLookups } from 'src/app/components/categories/dvr-event-types';
import { TitleService } from 'src/app/services/title.service';
import { ApiService } from 'src/app/services/api.service';



class CategoryRuleDVREventViewModelWrapper {
  wrappedObject!: ICategoryRuleDVREventViewModel;
  name!: string;

  constructor(data: ICategoryRuleDVREventViewModel) {
    this.wrappedObject = data;
    this.onWrappedObjectChanged();
  }

  onWrappedObjectChanged() {
    this.name  = DvrEventTypes.dvrEventTypes.find(x => x.id === this.wrappedObject.eventType).name;
  }
}



class CategoryRuleViewModelWrapper {
  wrappedObject!: ICategoryRuleViewModel;
  name!: string;
  dvrEvents: CategoryRuleDVREventViewModelWrapper[] = [];
  selectedDvrEventTypes: number[] = [];

  constructor(data: ICategoryRuleViewModel = new CategoryRuleViewModel()) {
    this.wrappedObject = data;
    this.onWrappedObjectChanged();
  }

  onWrappedObjectChanged() {
    this.name = DvrEventTypes.ruleTypes[this.wrappedObject.ruleType].name;
    this.dvrEvents = this.wrappedObject.eventsToLookFor.map(x => new CategoryRuleDVREventViewModelWrapper(x));
    this.selectedDvrEventTypes = this.dvrEvents.map(x => x.wrappedObject.eventType);
  }

  onChanged() {
    this.wrappedObject.eventsToLookFor = this.selectedDvrEventTypes.map((x, i) => new CategoryRuleDVREventViewModel({id: i, eventType: x, categoryRuleId: this.wrappedObject.id}));
  }
}



class CategoryViewModelWrapper {
  wrappedObject: ICategoryViewModel;
  rules: CategoryRuleViewModelWrapper[] = [];

  constructor(data: ICategoryViewModel) {
    this.wrappedObject = data;
    if (!this.wrappedObject.rules) {
      this.wrappedObject.rules = [];
    }
    this.onWrappedObjectChanged();
  }


  onWrappedObjectChanged() {
    this.rules = this.wrappedObject.rules.map(x => new CategoryRuleViewModelWrapper(x));
  }

  onChanged() {
    this.wrappedObject.rules = this.rules.map(x => new CategoryRuleViewModel(x.wrappedObject));
  }
}



@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: CategoryViewModelWrapper[];
  selected: CategoryViewModelWrapper;
  isSelected: boolean = false;
  lookups: RuleLookups = DvrEventTypes;
  newRule: CategoryRuleViewModelWrapper = null;
  showLoading: boolean = true;
  isAddingNewRule: boolean = false;

  constructor(private titleService: TitleService, private api: SecuraMaxApiService, private toastr: SnackbarService) { }

  ngOnInit(): void {
    let dvrEventTypesWithBeginFilteredOut = [];
    for (let ev of DvrEventTypes.dvrEventTypes) {
      if (ev.name === "begin") {
        continue;
      }

      dvrEventTypesWithBeginFilteredOut.push(ev);
    }

    this.lookups = {
      dvrEventTypes: dvrEventTypesWithBeginFilteredOut,
      ruleTypes: DvrEventTypes.ruleTypes
    };

    this.titleService.setTitle('Manage Categories');
    this.refreshCategories();
  }

  select(item: CategoryViewModelWrapper) {
    this.selected = item;
    this.isSelected = true;
    this.isAddingNewRule = false;
  }

  add() {
    this.selected = new CategoryViewModelWrapper(new CategoryViewModel());
    this.isSelected = true;
  }

  startAddRule() {
    this.isAddingNewRule = true;
    const newRuleUnwrapped = new CategoryRuleViewModel();
    newRuleUnwrapped.id = this.selected.rules.length;
    newRuleUnwrapped.ruleType = this.lookups.dvrEventTypes[0].id;
    newRuleUnwrapped.eventsToLookFor = [];
    this.newRule = new CategoryRuleViewModelWrapper(newRuleUnwrapped);
  }

  endAddRule() {
    this.newRule.onChanged();
    this.newRule.onWrappedObjectChanged();
    this.selected.rules.push(this.newRule);
    this.isAddingNewRule = false;
  }

  removeRule(rule: CategoryRuleViewModelWrapper) {
    var idx = 0;
    var found = false;
    for (var i = 0; i < this.selected.rules.length; i++) {
      if (this.selected.rules[i].wrappedObject.id === rule.wrappedObject.id) {
        idx = i;
        found = true;
      } else if (this.selected.rules[i]['$$hashKey'] === rule['$$hashKey']) {
        idx = i;
        found = true;
      }
    }
    if (found === true) {
      this.selected.rules.splice(idx, 1);
    }
  }

  private update() {
    this.selected.onChanged();
    this.api.categories_Put(new CategoryViewModel(this.selected.wrappedObject))
      .toPromise()
      .then((res: CategoryViewModel) => {
        for (var i = 0; i < this.categories.length; i++) {
          if (this.categories[i].wrappedObject.id == res.id) {
            this.categories[i].wrappedObject = res;
            this.categories[i].onWrappedObjectChanged();
            break;
          }
        }
        this.toastr.success('The category was successfully updated.');
        this.selected = null;
        this.isSelected = false;
      }).catch((err) => {
        this.toastr.error('Error saving category. Please try again later.');
      });
  }

  save() {
    var that = this;
    if (!that.selected.wrappedObject.id) {
      that.selected.onChanged();
      that.api.categories_PostCategory(new CategoryViewModel(this.selected.wrappedObject))
        .toPromise()
        .then((res: CategoryViewModel) => {
          this.categories.push(new CategoryViewModelWrapper(res));
          this.toastr.success('The category was successfully saved.');
          this.selected = null;
          this.isSelected = false;
        }, (err) => {
          this.toastr.error('Error saving category.');
        });
    } else {
      that.update();
    }
  }

  delete() {
    var that = this;
    this.api.categories_Delete(this.selected.wrappedObject.id).toPromise().then((res) => {
      this.toastr.success('The category was successfully deleted.');

      var index = that.categories.indexOf(that.categories.find(x => x.wrappedObject.id === this.selected.wrappedObject.id));
      if (index > -1) {
        that.categories.splice(index, 1);
      }
      that.selected = null;
      this.isSelected = false;
    }, (err) => {
      this.toastr.error("An error occurred while deleting the category.");
    });
  }

  cancel() {

    this.selected = null;
    this.isSelected = false;
  }

  refreshCategories() {
    var that = this;

    this.api.categories_GetAll().toPromise().then(function (data) {
      that.categories = data.map(x => new CategoryViewModelWrapper(x));
      that.showLoading = false;
    }, (err) => {
      this.toastr.error('An error occurred while refreshing categories.');
    });
  }

}



