/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BC300SystemSettings { 
    lcdBrightness?: number;
    volume?: number;
    autoPowerOff?: string | null;
    lcdAutoOff?: string | null;
    powerTones?: string | null;
    recordTones?: string | null;
    buttonTones?: string | null;
    vibrations?: string | null;
    wifi?: string | null;
    colorSystem?: string | null;
    fileDuration?: string | null;
    fileOverwrite?: string | null;
    irMode?: string | null;
}

