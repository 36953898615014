<div class="outer-container">
    <div class="d-flex">
        <app-searchbar (search)="onSearch($event)"></app-searchbar>
    </div>
    <div class="inner-container">
        <table mat-table matSort class="table" [dataSource]="datasource" (matSortChange)="sortTable($event)">

            <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Type</th>
                <td mat-cell *matCellDef="let element">
                    <a routerLink="/admin/devices/{{element.id}}/details">
                        <app-device-icon deviceType="{{element.deviceType}}" className=""></app-device-icon>
                    </a>
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Name</th>
                <td mat-cell *matCellDef="let element" class="align-items-center">
                    <div>
                        <a routerLink="/admin/devices/{{element.id}}/details">
                            <div i18n>
                                {{element.unitId}}<br />
                                <small>{{element.serial}}</small>
                            </div>
                        </a>
                    </div>
                </td>
            </ng-container>

            <!-- Last Connected Column -->
            <ng-container matColumnDef="associatedUser">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Associated User</th>
                <td mat-cell *matCellDef="let element" i18n> {{element.associatedUser}} </td>
            </ng-container>

            <!-- Last Connected Column -->
            <ng-container matColumnDef="lastConnected">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Last Connected</th>
                <td mat-cell *matCellDef="let element" i18n> {{element.lastConnected | date:'MMM dd YYYY, h:mm:ss a'}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Status</th>
                <td mat-cell *matCellDef="let element">
                            <span class="statusText" *ngIf="!element.isDisabled" i18n>
                                Enabled
                            </span>
                    <span class="statusText" *ngIf="element.isDisabled" i18n>
                                Disabled
                            </span>
                </td>
            </ng-container>

            <!-- Modify Column -->
            <ng-container matColumnDef="modifyButton">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div class="header-align-right d-flex align-items-center">
                        <button mat-button *ngIf="environment.featureFlag_newCreateRequestDialog && canRequestVideo(element)" class="smx-primary-button mt-1"
                           (click)="handleVideoRequestClick(element)" i18n>Video Request</button>
                        <a mat-button *ngIf="environment.featureFlag_newCreateRequestDialog === false && canRequestVideo(element)" class="smx-primary-button mt-1"
                           routerLink="/transfer/{{element.id}}/request" i18n>Video Request</a>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</div>
