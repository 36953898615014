<div class="main-container">
    <div class="details-card">
        <span *ngIf="!usageHistory">
          <mat-spinner color="accent"></mat-spinner>
        </span>
        <div *ngIf="usageHistory">
            Note: the data in this report updates once a day at midnight.
        </div>
        <table *ngIf="usageHistory" class="table">
            <thead>
            <tr>
                <th i18n>Period</th>
                <th i18n>Uploads</th>
                <th i18n>Total Archived</th>
                <th i18n>Daily Avg Total Stored</th>
                <th i18n>Plan Allocation</th>
                <th i18n>Overages</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let usage of usageHistory">
                <td>{{firstDay(usage) | date: 'MM/dd/yyyy'}} - {{lastDay(usage) | date: 'MM/dd/yyyy'}}</td>
                <td>{{usage.documentsUploadedBytes | bytes:1}} ({{usage.documentsUploaded}}
                    File{{usage.documentsUploaded != 1 ? "s" : ""}})
                </td>
                <td>{{usage.documentsArchivedBytes | bytes:1}} ({{usage.documentsArchived}}
                    File{{usage.documentsArchived != 1 ? "s" : ""}})
                </td>
                <td>{{usage.avgDocumentsStoredBytes | bytes:1}} ({{usage.avgDocumentsStored}}
                    File{{usage.avgDocumentsStored != 1 ? "s" : ""}})
                </td>
                <td>{{usage.allowedBytes | bytesalt:1}}</td>
                <td *ngIf="usage.avgDocumentsStoredBytes > usage.allowedBytes">{{calcOverage(usage.avgDocumentsStoredBytes, usage.allowedBytes) | bytes:1}}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <span *ngIf="usageHistory">{{totalItems}}
        <ng-container i18n>Results</ng-container></span>

    <app-s-paginate [modelFromJS]="modelFromJS" (collection)="onDataSourceUpdate($event)"
                    [url]="'/api/api/Usage/History'" [urlParams]="filter"
                    (numItems)="numItemsUpdated($event)"></app-s-paginate>
</div>
