import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { SnackbarService } from 'src/app/services/snackbar.service';
import {
    DevicesModifyDialogComponent,
    DialogData,
} from '../devices-modify-dialog/devices-modify-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import {
    PagingApiService,
    PagingModel,
} from 'src/app/services/paging-api.service';
import { TitleService } from 'src/app/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DeviceListInfo,
    DeviceViewModel,
    DevicesService,
} from 'src/app/services/api2';
import { MatCheckboxChange } from '@angular/material/checkbox';

export class DeviceListFilter {
    searchTerm: string;
    hideDisabledDevices: boolean;
}

@Component({
    selector: 'app-devices-list',
    templateUrl: './devices-list.component.html',
    styleUrls: ['./devices-list.component.scss'],
})
export class DevicesListComponent implements OnInit {
    displayedColumns: string[] = [
        'name',
        'associatedUser',
        'lastConnected',
        'status',
        'modifyButton',
    ]; // Header for the table columns

    datasource: DeviceViewModel[]; // Contains the devices list data.
    pageSizeOptions: number[] = [5, 10, 25, 100];
    resultsLength: number = 0; // 0 is arbitrary, this value will get loaded OnInit.
    pageEvent: PageEvent = new PageEvent(); // Keep a variable so we can use it when loading data via SearchText.
    filter: DeviceListFilter = new DeviceListFilter();
    pagingModel: PagingModel = {
        sortDir: '',
        sortProp: '',
    };
    dateTimeFormat = Intl.DateTimeFormat(undefined, { //undefined locale will grab runtime's default locale
        year: "numeric",
        month: "short",
        day: "numeric",
        hourCycle: "h12",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short"
    })

    deviceListInfo: DeviceListInfo = {};
    shouldShowOnlyEnabledAndAssignedToClosedUsers: boolean = false;

    constructor(
        private titleService: TitleService,
        private toastr: SnackbarService,
        public modifyDialog: MatDialog,
        private pagingAPIService: PagingApiService,
        private route: ActivatedRoute,
        private router: Router,
        private devicesService: DevicesService
    ) {}

    /**
     * Loads the initial data and paging info.
     */
    ngOnInit(): void {
        this.titleService.setTitle('Device List');
        this.route.queryParams.subscribe((x) => {
            this.shouldShowOnlyEnabledAndAssignedToClosedUsers =
                x.shouldShowOnlyEnabledAndAssignedToClosedUsers ? true : false;
            this.updateDeviceListInfo();
            this.pageEvent.pageIndex = 0; //The zero-based page index of the displayed list of items. Defaulted to 0.
            this.pageEvent.pageSize = 10; // Initial number of items to show on a page.
            this.onPageFired(this.pageEvent);
        });
    }

    /**
     * Converts the lastConnected UTC value to the user's locale
     * @param element
     */
    lastConnectedtoLocale(element: DeviceViewModel) {
        if (!element.lastConnected) return '';

        const dateStringUTC = element.lastConnected + "Z"; // "Z causes date to parsed as UTC"

        if (Number.isNaN(Date.parse(dateStringUTC))) {
            return '';
        }

        const lastConnected = new Date(dateStringUTC)   

        return this.dateTimeFormat.format(lastConnected);
    }

    /**
     * Retrieves the device list info (total devices, total enabled, etc);
     */
    private updateDeviceListInfo() {
        this.devicesService.apiApiDevicesGetDevicesListInfoGet().subscribe(
            (data) => {
                this.deviceListInfo = data;
            },
            () => {
                this.toastr.error('An error occurred while loading devices.');
            }
        );
    }

    /**
     * When a page event fires, this retrieves the next set of data from the
     * server depending on page index.
     *
     * @param event PageEvent that contains the page index, page size, etc.
     */
    onPageFired(event?: PageEvent) {
        // Keep a local copy so we can search via SearchText;
        this.pageEvent = event;

        const from = event.pageIndex * event.pageSize;
        const to = from + event.pageSize - 1;

        this.devicesService
            .apiApiDevicesGet(
                from,
                to,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                this.filter.searchTerm,
                this.shouldShowOnlyEnabledAndAssignedToClosedUsers,
                this.filter.hideDisabledDevices
            )
            .subscribe(
                (data) => {
                    this.resultsLength = data.total;
                    this.datasource = data.data;
                },
                (err: HttpErrorResponse) => {
                    this.toastr.error(err.message);
                }
            );
    }

    /**
     * Opens a dialog box to update device name or associated user.
     *
     * @param element DeviceViewModel to update either device name or associated user.
     */
    openModifyDialog(element: DeviceViewModel) {
        const startingData: DialogData = {
            id: element.id,
            name: element.name,
            associatedUserId: element.associatedUserId,
            associatedUser: element.associatedUser,
            deviceType: element.deviceType,
            alarmEventNetworksEnabled: element.alarmEventNetworksEnabled,
            requestNetworksEnabled: element.requestNetworksEnabled,
            wasCancelled: true,
            liveFeatures: element.liveEnabled
        };
        const dialogRef = this.modifyDialog.open(DevicesModifyDialogComponent, {
            data: startingData,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((data: DialogData) => {
            if (data.wasCancelled) {
                return;
            }

            const oldName = element.name;
            const oldId = element.associatedUserId;
            const olduserName = element.associatedUser;

            element.name = data.name;
            element.associatedUser = data.associatedUser;
            element.associatedUserId = data.associatedUserId;
            element.alarmEventNetworksEnabled = data.alarmEventNetworksEnabled;
            element.requestNetworksEnabled = data.requestNetworksEnabled;
            element.liveEnabled = data.liveFeatures;
            this.devicesService.apiApiDevicesIdPut(data.id, element).subscribe(
                () => {
                    this.toastr.success('Successfully updated device.');
                },
                (err) => {
                    if (err.status == 400) {
                        this.toastr.error(err.response);
                    } else {
                        this.toastr.error('Error updating device.');
                    }

                    element.name = oldName;
                    element.associatedUserId = oldId;
                    element.associatedUser = olduserName;
                }
            );

            //if (data && data.stationName != '') {
            // let oldName = element.name;
            // let oldId = element.associatedUserId;
            // let olduserName = element.associatedUser;

            // element.name = data.name;
            // element.associatedUser = data.associatedUser;
            // element.associatedUserId = data.associatedUserId;
            // element.isAlarmTransferEnabled = data.alarmTransferEnabled;

            // this.apiService.devices_PutDevice(element.id, element)
            //   .subscribe(resp => {
            //     this.toastr.success("Successfully updated device.");
            //   }, err => {
            //     if (err.status == 400)
            //     {
            //       this.toastr.error(err.response);
            //     }
            //     else
            //     {
            //       this.toastr.error("Error updating device.");
            //     }

            //     // Server error occurred, revert UI to old data.
            //     element.name = oldName;
            //     element.associatedUserId = oldId;
            //     element.associatedUser = olduserName;
            //   });
            //}
        });
    }

    /**
     * Toggles the state of the device between enable and disable.
     *
     * @param element DeviceViewModel to enable/disable the device.
     */
    toggleStateOfDevice(element: DeviceViewModel) {
        if (element.isDisabled) {
            this.devicesService
                .apiApiDevicesIdActivatePut(element.id)
                .subscribe(
                    () => {
                        this.toastr.success('Successfully activated device.');
                        element.isDisabled = !element.isDisabled;
                        this.updateDeviceListInfo();
                    },
                    () => {
                        this.toastr.error('Unable to activate device.');
                    }
                );
        } else {
            this.devicesService
                .apiApiDevicesIdDeactivatePut(element.id)
                .subscribe(
                    () => {
                        this.toastr.success('Successfully deactivated device.');
                        element.isDisabled = !element.isDisabled;
                        this.updateDeviceListInfo();
                    },
                    () => {
                        this.toastr.error('Unable to deactivate device.');
                    }
                );
        }
    }

    handleFilterWarningBannerClick() {
        this.router.navigate(['.'], {
            relativeTo: this.route,
        });
    }

    sortTable(sort: Sort) {
        switch (sort.active) {
            case 'associatedUser':
                this.pagingModel.sortProp = 'User';
                break;
            case 'name':
                this.pagingModel.sortProp = 'Name';
                break;
            case 'status':
                this.pagingModel.sortProp = 'IsDisabled';
                break;
        }

        switch (sort.direction) {
            case 'desc':
                this.pagingModel.sortDir = 'Desc';
                break;
            case 'asc':
                this.pagingModel.sortDir = 'Asc';
                break;
        }

        this.pagingAPIService
            .fetchData<DeviceViewModel>(
                '/api/api/Devices',
                this.filter,
                () => {
                    // todo reimpl
                },
                this.pagingModel
            )
            .subscribe(
                function (data) {
                    this.datasource = data.data;
                },
                () => {
                    this.toastr.error(
                        'An error occurred while loading devices.'
                    );
                }
            );
    }

    onSearch(searchText: string) {
        this.filter.searchTerm = searchText;
        this.pageEvent.pageIndex = 0;
        this.onPageFired(this.pageEvent);
        //this.pagingAPIService.fetchData<UserQueryResponse>('/api/api/Users/All', this.filter, this.modelFromJS, undefined).subscribe(function (data) {
        //  this.datasource = data.data;
        //}, (err) => {
        //  this.toastr.error('Error occurred while getting the reports.');
        //});
    }

    onSearchCleared() {
        this.filter.searchTerm = '';
        this.pageEvent.pageIndex = 0;
        this.onPageFired(this.pageEvent);
    }

    onShowDisabledDeviceChange(toggleState: MatCheckboxChange) {
        this.filter.hideDisabledDevices = toggleState.checked;
        this.pageEvent.pageIndex = 0;
        this.onPageFired(this.pageEvent);
    }
}
