import {
    AfterViewInit,
    Component
} from '@angular/core';
import { TitleService } from 'src/app/services/title.service';
import { SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';


@Component({
    selector: 'app-live-auth',
    templateUrl: './live-auth.component.html',
    styleUrls: ['./live-auth.component.css']
})
export class LiveAuthComponent implements AfterViewInit {
    message: string = 'Authorizing...';


    constructor(
        private titleService: TitleService,
        private apiService: SecuraMaxApiService,
    ) {}



    ngAfterViewInit(): void {
        this.titleService.setTitle('Live Authentication');
        this.apiService.liveAuth_GetAuth().toPromise().then((res) => {
            this.message = "PRO-VISION Live View application login successful!";

            setTimeout(function () {
                window.location.href = "pv-live://" + res;
                //window.close();
            }, 1000);



        }).catch((err) => {
            this.message = 'Unable to log in!';
        });
    }
}

