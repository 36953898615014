<div>
    <div *ngIf="tag?.tagTypeId===1">
        <!--<label for="valueTypeSelector" i18n>TypeOfValue</label>
        <select class="form-control" ng-model="vm.typeOfValue">
            <option value="text" i18n>Text</option>
            <option value="number" i18n>Number</option>
        </select>-->
    </div>
    <div *ngIf="tag?.tagTypeId===3">
        <label i18n>Options</label>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th i18n>Value</th>
                    <th i18n>Text</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let opt of options">
                    <td>{{opt.value}}</td>
                    <td>{{opt.text}}</td>
                    <td>
                      <button mat-mini-fab style="background-color:rgb(238, 20, 20); color:white;" (click)="delete(opt)">
                        <fa-icon [icon]="['fas','trash']"></fa-icon>
                      </button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr ng-form="">
                    <td>
                        <mat-form-field class="w-75" appearance="outline">
                            <input matInput type="text" [(ngModel)]="selectedOption.value" name="tagTypeOptionValue" id="tagTypeOptionValue" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="w-75" appearance="outline">
                            <input matInput type="text" [(ngModel)]="selectedOption.text" name="tagTypeOptionText" id="tagTypeOptionText" />
                        </mat-form-field>
                    </td>
                    <td>
                        <button mat-mini-fab style="background-color:rgb(123, 201, 89); color:white;" (click)="add()" [disabled]="!selectedOption.text||!selectedOption.value">
                            <fa-icon [icon]="['fas','plus']"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>

</div>
