<h1 mat-dialog-title i18n>Download Multiple Documents</h1>
<mat-dialog-content>
    <div *ngIf="data.totalDocumentCount > data.totalArchivedDocumentsCount" class="alert alert-warning" role="alert" i18n>
        Your selection includes at least one archived document. This document will be excluded from the list of documents to download. If this document 
        must be downloaded, you must retrieve it from the archive first.
    </div>
    <div *ngIf="data.totalDocumentCount === data.totalArchivedDocumentsCount" class="alert alert-warning" role="alert" i18n>
        Your selection only includes archived documents. Archived documents cannot be downloaded. You must retreive them from the archive first.
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close i18n>Cancel</button>
    <button color="primary" *ngIf="data.totalDocumentCount > data.totalArchivedDocumentsCount" mat-raised-button [mat-dialog-close]="data" i18n>Download {{ data.totalDocumentCount - data.totalArchivedDocumentsCount }} of {{ data.totalDocumentCount }}</button>
</mat-dialog-actions>