import {Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-daterangepicker',
    templateUrl: './daterangepicker.component.html',
    styleUrls: ['./daterangepicker.component.css']
})
export class DateRangePickerComponent {
    private daterangepickerInstance: any;
    private $inputRef: any;

    @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;

    @Input() start: string = null;
    @Output() startChange: EventEmitter<string> = new EventEmitter();
    @Input() end: string = null;
    @Output() endChange: EventEmitter<Date> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();

    @Input() tabindex: number | null = null;

    @Input() rangeMode: boolean = true;

    @Input() placeholder: string = null;

    constructor(private ngZone: NgZone
    ) {
    }

    ngAfterViewInit() {
        var that = this;
        const daterangepickerOptions = {
            singleDatePicker: false,
            "timePicker": true,
            ranges: {
                'Today': [moment().startOf('day'), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            "locale": {
                "format": "MM/DD/YYYY LT",
                "separator": " - ",
                "applyLabel": "Apply",
                "cancelLabel": "Clear",
                "fromLabel": "From",
                "toLabel": "To",
                "customRangeLabel": "Custom",
                "weekLabel": "W",
                "daysOfWeek": [
                    "Su",
                    "Mo",
                    "Tu",
                    "We",
                    "Th",
                    "Fr",
                    "Sa"
                ],
                "monthNames": [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ],
                "firstDay": 1
            },
        };

        if (!this.rangeMode) {
            daterangepickerOptions.singleDatePicker = true;
            delete daterangepickerOptions.ranges;
        }

        if (that.start) {
            daterangepickerOptions["startDate"] = moment(that.start);
        }
        if (that.end) {
            daterangepickerOptions["endDate"] = moment(that.end);
        }
        that.$inputRef = $(this.inputRef.nativeElement).daterangepicker(daterangepickerOptions, function (start, end) {
            that.startChange.emit(start.toISOString());
            that.endChange.emit(end.toISOString());
            that.change.emit(null);
        });
        that.$inputRef.on('apply.daterangepicker', function(ev, picker) {
            that.startChange.emit(picker.startDate.toISOString());
            that.endChange.emit(picker.endDate.toISOString());
            that.change.emit(null);
        });
        that.$inputRef.on('cancel.daterangepicker', function(ev, picker) {
            that.reset();
        });
        this.daterangepickerInstance = that.$inputRef.data('daterangepicker');

        if (!that.start && !that.end) {
            that.$inputRef.val('');
        }
    }

    ngOnDestroy() {
        this.daterangepickerInstance.remove();
    }

    reset() {
        this.start = null;
        this.end = null;
        // this.daterangepickerInstance.setStartDate(this.start);
        // this.daterangepickerInstance.setEndDate(this.end);
        this.startChange.emit(null);
        this.endChange.emit(null);
        this.change.emit(null);
        this.$inputRef.val('');
    }
}
