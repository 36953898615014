/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRightViewModel } from './userRightViewModel';
import { GroupPermissionViewModel } from './groupPermissionViewModel';


export interface EditUserViewModel { 
    id?: string;
    key?: number;
    userName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    agencyOrg?: string | null;
    country?: string | null;
    state?: string | null;
    email: string;
    timeZone?: string | null;
    mobilePhone?: string | null;
    rights?: Array<UserRightViewModel> | null;
    groups?: Array<string> | null;
    isAccountClosed?: boolean;
    isAccountVerified?: boolean;
    isLoginAllowed?: boolean;
    isPasswordExpired?: boolean;
    password?: string | null;
    acceptedEULA?: boolean;
    groupPermissions?: Array<GroupPermissionViewModel> | null;
    group?: string | null;
    redactionSeatAvailable?: number | null;
    redactionSeat?: boolean;
    rfid?: string | null;
    syncUserNameAlias?: string | null;
    isUnlimitedPlan?: boolean;
    isLivePlan?: boolean;
    defaultHomepage?: string | null;
}

