<div class="card mt-3">
    <div class="card-body card-container">

        <h2 i18n>
            Tags
        </h2>
        <p><small i18n>Tags are additional metadata that can be assigned to documents, ranging from key value pair data
            to
            geographic coordinates. Tagging helps search for videos in the future.</small></p>

        <div class="outer-container">
            <div *ngIf="!showLoading" >
                <div *ngIf="tags?.length===0 && !showLoading">
                    <p class="text-center" i18n>None Defined</p>
                </div>
                <mat-action-list>
                    <button mat-list-item (click)="select(tag)" *ngFor="let tag of tags">
                        {{tag.name}}
                    </button>
                </mat-action-list>
                <p class="text-center" *ngIf="showLoading">
                    <mat-spinner></mat-spinner>
                </p>
                <button mat-button class="smx-primary-button mt-2" *ngIf="!showLoading" (click)="add()"
                        [disabled]="!!selected" i18n>Add
                    One
                </button>
            </div>

            <div *ngIf="selected" >
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h2 class="panel-title">
                            <span *ngIf="selected.id" i18n>Edit</span><span *ngIf="!selected.id" i18n>Add</span>
                            <span i18n> Tag</span>
                        </h2>
                    </div>
                    <div class="panel-body">
                        <form name="tagForm" #tagForm="ngForm" novalidate>
                            <mat-form-field class="w-50" appearance="outline">
                                <mat-label i18n>Name</mat-label>
                                <input matInput type="text" required id="tagName" name="name"
                                       [(ngModel)]="selected.name">
                            </mat-form-field>

                            <div class="checkbox mb-4">
                                <mat-checkbox name="isRequired" [(ngModel)]="selected.isRequired" i18n>
                                    <label class="mt-2">Required</label>
                                </mat-checkbox>
                            </div>

                            <mat-form-field class="w-50" appearance="outline">
                                <mat-label i18n>Type</mat-label>
                                <mat-select [(value)]="selected.tagTypeId">
                                    <mat-option [value]="item.id"
                                                *ngFor="let item of tagTypes">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div>
                                <app-tag-type-editor *ngIf="selected" [tag]="selected"
                                                     [tagTypeId]="selected.tagTypeId"
                                                     (tagTypeConfigurationEvent)="updateTagTypeConfiguration($event)"></app-tag-type-editor>
                                <!--<tag-type-editor ng-if="selected" tagtypeid="selected.TagTypeId" tag-type-configuration="selected.TagTypeConfigurationData"></tag-type-editor>-->
                            </div>
                        </form>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="panel-footer">
                        <div class="d-flex justify-content-start">
                            <button mat-button class="smx-primary-button ml-2 mt-2" (click)="save()"
                                    [disabled]="tagForm.invalid" i18n>Save
                            </button>
                            <button mat-button class="smx-secondary-button ml-2 mt-2" (click)="cancel()" i18n>
                                Cancel
                            </button>
                            <button mat-button class="smx-critical-button ml-5 mt-2" (click)="delete()"
                                    *ngIf="selected.id" i18n>Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</div>
