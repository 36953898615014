/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeBasedTagDocumentViewModel } from './timeBasedTagDocumentViewModel';
import { CategoryViewModel } from './categoryViewModel';
import { CasesDetailsViewModel } from './casesDetailsViewModel';
import { DocumentTagDisplayViewModel } from './documentTagDisplayViewModel';


export interface TimeBasedTagDetailsViewModel { 
    id?: number;
    startRange?: string;
    endRange?: string;
    isActive?: boolean;
    expiration?: string;
    title?: string | null;
    description?: string | null;
    tags?: Array<DocumentTagDisplayViewModel> | null;
    categories?: Array<CategoryViewModel> | null;
    cases?: Array<CasesDetailsViewModel> | null;
    autoTaggedDocuments?: Array<TimeBasedTagDocumentViewModel> | null;
}

