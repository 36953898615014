import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

export interface CasesDownloadMultipleArchiveWarningDialogData {
  totalDocumentCount: number;
  totalArchivedDocumentsCount: number;
}

@Component({
  selector: 'app-cases-download-multiple-archive-warning',
  templateUrl: './cases-download-multiple-archive-warning.component.html',
  styleUrls: ['./cases-download-multiple-archive-warning.component.css']
})
export class CasesDownloadMultipleArchiveWarningComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CasesDownloadMultipleArchiveWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CasesDownloadMultipleArchiveWarningDialogData) { }

  CloseCasesDownloadDialogSelected() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
