<h2 mat-dialog-title i18n class="dialogContent">Modify Device</h2>
<mat-dialog-content>
    <mat-form-field class="w-100 px-2" *ngIf="data.deviceType == 'BC4'">
        <mat-label i18n>Device Name</mat-label>
        <input
            matInput
            #deviceName="ngModel"
            [(ngModel)]="data.name"
            name="deviceName"
            maxlength="50"
            pattern="[a-zA-Z0-9]+"
        />
        <mat-hint i18n
            >Note: Maximum of 25 characters will display on the
            device.</mat-hint
        >
        <mat-error *ngIf="deviceName.errors?.pattern" i18n
            >Note: Please use only letters, numbers, hyphens, and
            spaces.</mat-error
        >
    </mat-form-field>

    <mat-form-field class="w-100 px-2" *ngIf="data.deviceType != 'BC4'">
        <mat-label i18n>Device Name</mat-label>
        <input matInput [(ngModel)]="data.name" />
    </mat-form-field>

    <div class="w-100 px-2 mt-3" *ngIf="data.deviceType === 'BC4'">
        <mat-label i18n>User Mode</mat-label>
        <mat-form-field class="w-100" appearance="outline">
            <mat-select [(value)]="userMode" name="userModeId">
                <mat-option [value]="0" i18n> Single User </mat-option>
                <mat-option [value]="1" i18n> Multi User </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="w-100 px-2 mb-4" *ngIf="userMode != 1">
        <mat-label i18n>Associated User</mat-label>
        <app-user-select
            class="w-100"
            [(userId)]="data.associatedUserId"
            [(associatedUserName)]="data.associatedUser"
        ></app-user-select>
    </div>

    <div class="w-100 px-2">
        <mat-label i18n
            >Select Allowed Network Types for Alarms/Events</mat-label
        >
        <mat-form-field
            class="w-100"
            appearance="outline"
            subscriptSizing="dynamic"
        >
            <mat-select [(value)]="data.alarmEventNetworksEnabled" multiple>
                <mat-option [value]="0" i18n> LAN (default) </mat-option>
                <mat-option [value]="1" i18n> Wi-Fi </mat-option>
                <mat-option [value]="2" i18n> 4G </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="alert alert-info mt-2 mb-4">
            If no networks are selected automatic alarm/event transfer will be
            disabled.
        </div>
    </div>

    <div class="w-100 px-2">
        <mat-label i18n
            >Select Allowed Network Types for Video Requests</mat-label
        >
        <mat-form-field
            class="w-100"
            appearance="outline"
            subscriptSizing="dynamic"
        >
            <mat-select
                [(value)]="data.requestNetworksEnabled"
                multiple
                (valueChange)="validateRequestNetworkSelection($event)"
            >
                <mat-option [value]="0" i18n> LAN (default) </mat-option>
                <mat-option [value]="1" i18n> Wi-Fi </mat-option>
                <mat-option [value]="2" i18n> 4G </mat-option>
            </mat-select>
        </mat-form-field>
        <div
            class="alert alert-danger mt-2 mb-4"
            *ngIf="validationMissingAtLeastOneRequestNetwork"
        >
            You must select at least one network for request transfers.
        </div>
    </div>

    <div
        *ngIf="
            data.deviceType !== 'BC4' &&
            data.deviceType !== 'BC300' &&
            data.deviceType !== 'BC100'
        "
    ></div>
    <div class="mt-3 px-2" *ngIf="problemDescriptions.length > 0">
        <div class="alert alert-danger">
            <div *ngFor="let problem of problemDescriptions">{{ problem }}</div>
        </div>
    </div>

    <div class="mt-3 px-2" *ngIf="userService.isLivePlan()">
        <mat-label i18n>Live specific settings</mat-label>

        <mat-checkbox
            appearance="outline"
            subscriptSizing="dynamic"
            class="w-100"
            [(ngModel)]="data.liveFeatures"
        >
            Enable "Live" features
        </mat-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-button
        class="smx-secondary-button"
        (click)="tryClose(true)"
        cdkFocusInitial
        i18n
    >
        Cancel
    </button>
    <button
        mat-button
        class="smx-primary-button ml-2"
        (click)="tryClose(false)"
        [disabled]="
            (data.associatedUserId === null && userMode == 0) ||
            data.name === ''
        "
        i18n
    >
        Save
    </button>
</mat-dialog-actions>
