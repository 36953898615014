import { Component } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-live-inactivity-dialog',
    standalone: false,
    templateUrl: './live-inactivity-dialog.component.html',
    styleUrl: './live-inactivity-dialog.component.css',
})
export class LiveInactivityDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<LiveInactivityDialogComponent>,
    ) {

    }


    stillHereClick() {
        this.dialogRef.close();
    }
}
