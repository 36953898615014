<div class="p-image-1">
    <img class="p-image" src="/assets/images/ProVision_Mark_Grey_RGB.svg">
</div>

<div class="outer-container">
    <div class="row" *ngIf="showDeviceAttentionBanner">
        <div class="col card-container-container">
            <div class="alert alert-danger mt-3" style="cursor: pointer;" role="alert"
                 (click)="handleDeviceAttentionBannerClick()" i18n>
                There are devices which are enabled but assigned to closed users. Click here to go to the devices page
                to fix.
            </div>
        </div>
    </div>
    <div class="card-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title i18n>Awaiting Review</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p *ngIf="!statsLoaded" class="card-spinner-container">
                    <mat-spinner color="accent" diameter="40"></mat-spinner>
                </p>
                <div *ngIf="statsLoaded">
                    <p>
                        {{stats.daysAwaitingMetadata}}
                        <ng-container i18n>Upload(s)</ng-container> -
                        {{stats.filesAwaitingMetadata}}
                        <ng-container i18n>Files</ng-container>
                    </p>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <a mat-button routerLink="/annotate2/step1" class="smx-primary-button">Review Now</a>
            </mat-card-actions>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title i18n>Active Shares</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngIf="statsLoaded && (stats.activeShares > 0 || stats.activeSharesWithMe > 0)">
                    <p>
                        <a routerLink="/search/documents" [queryParams]="{sharedByMeOnly: true}">
                            <ng-container i18n>Shared By Me</ng-container>
                            : {{stats.activeShares}}
                        </a>
                    </p>
                    <p>
                        <a routerLink="/search/documents" [queryParams]="{sharedWithMeOnly: true}">
                            <ng-container i18n>Shared With Me</ng-container>
                            : {{stats.activeSharesWithMe}}
                        </a>
                    </p>
                </div>
                <p *ngIf="!statsLoaded" class="card-spinner-container">
                    <mat-spinner color="accent" diameter="40"></mat-spinner>
                </p>
                <p *ngIf="statsLoaded && (stats.activeShares === 0 && stats.activeSharesWithMe === 0)" i18n>
                    Nothing
                    is shared
                </p>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title i18n>Cases</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p *ngIf="statsLoaded && stats.casesMetaData.caseCount>0">
                    <ng-container i18n>My Active Cases:</ng-container>
                    {{stats.casesMetaData.caseCount}}
                    <ng-container i18n>(Shared With Others:</ng-container>
                    {{stats.casesMetaData.casesSharedWithOthersCount}})
                </p>
                <p *ngIf="statsLoaded  && (stats.casesMetaData.caseCount===0)" i18n>No Active Cases</p>
                <p *ngIf="statsLoaded  && (stats.casesMetaData.casesSharedWithMeCount===0)" i18n>
                    No Cases Shared
                    With Me
                </p>
                <p *ngIf="statsLoaded && stats.casesMetaData.casesSharedWithMeCount>0">
                    <ng-container i18n>Cases Shared With Me:</ng-container>
                    {{stats.casesMetaData.casesSharedWithMeCount}}
                </p>
            </mat-card-content>
            <mat-card-actions>
                <a mat-button routerLink="/cases" class="smx-primary-button" style="margin-top: -30px;">
                    View Cases
                </a>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="statsLoaded && canManageDevices">
            <mat-card-header>
                <mat-card-title i18n>Devices</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p *ngIf="stats.filesPendingTransfer > 0">{{stats.filesPendingTransfer}} Pending Video Request<ng-container *ngIf="stats.filesPendingTransfer > 1">s</ng-container></p>
                <p *ngIf="stats.filesPendingTransfer === 0">No Pending Video Requests</p>
            </mat-card-content>
            <mat-card-actions>
                <a mat-button routerLink="/transfer/list" class="smx-primary-button">
                    View Devices
                </a>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="statsLoaded && stats.filesAwaitingDeleteApproval>0">
            <mat-card-header>
                <mat-card-title i18n>Awaiting Delete Approval</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p *ngIf="statsLoaded">
                    {{stats.filesAwaitingDeleteApproval}} <span *ngIf="stats.filesAwaitingDeleteApproval>0"
                                                                i18n>Document(s)</span>
                </p>
            </mat-card-content>
            <mat-card-actions>
                <a routerLink="/admin/reports/awaitingDeleteApproval" mat-button class="smx-primary-button"
                   [disabled]="stats.filesAwaitingDeleteApproval===0" i18n>Review Now &raquo;</a>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
