<div class="card">
  <div class="card-body">
    <h1 i18n class="mt-2" i18n>Document Search</h1>
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/search/documents" [queryParams]="filter" i18n>List</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/search/timeline" [queryParams]="filter" i18n>Timeline</a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</div>