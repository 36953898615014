import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDuplicate',
  pure: false
})
export class FilterDuplicatePipe implements PipeTransform {

  /**
   * Pipe that filters out duplicate items from two separate lists. The filter takes
   * a string 'property' as the name of the property on the object that should be 
   * compared for duplication. If an item exists in both lists with the same value on
   * the given 'property', it will be removed in the result.
   * 
   * @param array Original array of objects that will need filtering down.
   * @param items Second array of objects that is a subset of the original array.
   * @param property Property on the object that you want to use for filtering if they are the same.
   */
  transform(array: any[], items: any[], property: string): any {
    var out = [];

    if (Array.isArray(array) && Array.isArray(items)) {
      out = <any>array.filter(function (o1) {
        // filter out (!) items in items list.
        return !items.some(function (o2) {
          return o1[property] === o2[property];   
        })
      });

    }
    else{
      // Return original array if something is wrong.
      out = array;
    }

    return out;
  }
}
