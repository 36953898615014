import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceExpandedViewModel, SecuraMaxApiService, TimelineSearchViewModel } from 'src/app/services/api/securamaxapi.service';
import { DocumentSearchService } from 'src/app/services/document-search.service';
import { DataSet } from 'vis-data';
import { TimelineOptions, DataItem, DataGroup, DateType } from 'vis-timeline/peer';
import * as moment from 'moment';
import { VisTimelineService } from 'src/app/shared/components/vis-timeline/vis-timeline.service';
import { ChangeDetectorRef } from '@angular/core';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { PagingApiService, PagingModel } from 'src/app/services/paging-api.service';
import { SearchFilter } from 'src/app/models/search-filter.model';
import { DocumentListViewModel } from 'src/app/services/api/securamaxapi.service';
import { PageEvent } from '@angular/material/paginator';

export interface TimelineData {
  groups: DataSet<DataGroup, "id">,
  items: DataSet<DataItem, "id">,
  id: string,
  selectedDocumentId: number,
  device: DeviceExpandedViewModel,
  options: TimelineOptions
}

export interface VisSelectEventProps {
  items: number[];
}

export interface VisRangeChangedEventProps {
  start: number;
  end: number;
  byUser: boolean;
}

export class TimelineDocumentViewModel {
  id: string;
  parentId: string;
  channel: number;
  start: Date;
  end: Date;
  name: string;
  important: boolean;
  children: TimelineDocumentViewModel[];
}

class DocumentListViewModelExtended extends DocumentListViewModel {
  children: DocumentListViewModel[];
}

@Component({
  selector: 'app-documents-search-timeline',
  templateUrl: './documents-search-timeline.component.html',
  styleUrls: ['./documents-search-timeline.component.css']
})
export class DocumentsSearchTimelineComponent implements OnInit, OnDestroy {
  webUploadTimelineId: string="webuploads";
  //just the ones that are displayed
  currentTimelinesDataArray: TimelineData[]=[];
  //makes sure we don't load event data twice
  loadingTracker: any = {};

  deviceViewEnabled: boolean = false;

  searchFilter: any = { searchTerm: '' };
  totalItems: number;
  modelFromJS: any = TimelineSearchViewModel.fromJS;
  perPage:number = 2
  currentPageIndex: number;
  pageSizeOptions: number[] = [2, 4, 6, 8];

  datasource: DocumentListViewModelExtended[];
  filter: SearchFilter = {};
  pagingModel: PagingModel = {
    from: 0,
    to: 1,
    totalRecordCount: 0
  };

  constructor(private securaMaxAPIService: SecuraMaxApiService,
    private documentSearchService: DocumentSearchService,
    private router: Router,
    private route: ActivatedRoute,
    private pagingApiService: PagingApiService,
    private visTimelineService: VisTimelineService,
    private cdr: ChangeDetectorRef,
    private titleService: TitleService,
    private toastr: SnackbarService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Search Timeline');
    // this.documentSearchService.isSearchButtonClick.subscribe(value => {
    //   this.searchFilter = Object.assign({}, value);
    //   this.refreshResults(this.searchFilter);
    // }, (err) => {
    //   this.toastr.error("An error occurred while searching for documents.");
    // });


    this.route.queryParams.pipe(switchMap((params) => {
      //paging model use existing filter unless params override
      //filter use params filter always

      this.filter = params;

      if (this.pagingModel !== undefined) {
          if (params.totalRecordCount != undefined) {
              this.pagingModel.totalRecordCount = parseInt(params.totalRecordCount, 10);
          }
          if (params.to != undefined) {
              this.pagingModel.to = parseInt(params.to, 10);
          }
          if (params.from != undefined) {
              this.pagingModel.from = parseInt(params.from, 10);
          }
      }

      //need defaults
      return this.pagingApiService.fetchData<TimelineSearchViewModel>('/api/api/documents/searchTimelineDevices', params, TimelineSearchViewModel.fromJS, this.pagingModel);
    })).subscribe((res) => {
      this.onDataSourceUpdate(res.data);
      this.pagingModel.totalRecordCount = res.totalRecords;
    }, (err) => {
      this.toastr.error("An error occurred while searching for documents.");
    });

    this.setupTimelineEventHandlers();
  }

  refreshResults(params) {
    return this.pagingApiService.fetchData<TimelineSearchViewModel>('/api/api/documents/searchTimelineDevices', params, TimelineSearchViewModel.fromJS, this.pagingModel).subscribe((res) => {
      this.onDataSourceUpdate(res.data);
      this.pagingModel.totalRecordCount = res.totalRecords;
    }, (err) => {
      this.toastr.error("An error occurred while searching for documents.");
    });
  }

  onPageFired(event: PageEvent) {
    this.currentPageIndex = event.pageIndex;
    this.perPage = event.pageSize;

    let allParams: any = {
    };

    Object.assign(allParams, this.filter);

    allParams.from = this.currentPageIndex > 0 ? this.currentPageIndex * this.perPage - 1 : 0;
    allParams.to = allParams.from + this.perPage - 1;

    this.router.navigate(['/search/timeline'], { queryParams: allParams })
    //todo: add page to route params
  }

  ngOnDestroy(): void{
    this.currentTimelinesDataArray.forEach((item)=>{
      this.visTimelineService.destroy(item.id);
    });
  }

  getTimelineOptions(tdData: TimelineData){
    let opts: TimelineOptions = {
      align: 'center',
      autoResize: true,
      editable: false,
      selectable: true,
      stack: false,
      orientation: 'bottom',
      showCurrentTime: true,
      showMajorLabels: true,
      showMinorLabels: true,
      groupTemplate: function (data: any, element: any) {
        if(!data){
          return '';
        }
        return '<div class="timelineGroupBox">' +
          '<h5 class="timelineGroupContent">Channel: ' + data.id + ' </h5></div>';
      },
      moment: function (date) {
        return moment.utc(date).local();
      },
      min: this.makeUtcLocal(tdData.device.firstDocumentDate).subtract(6, 'hours').toDate(),
      max: this.makeUtcLocal(tdData.device.lastDocumentDate).add(6, 'hours').toDate(),
      start: this.makeUtcLocal(tdData.device.lastDocumentDate).startOf('day').toDate(),
      end: this.makeUtcLocal(tdData.device.lastDocumentDate).endOf('day').toDate()
    };

    if (this.searchFilter.start) {
      opts.min = this.makeUtcLocal(this.searchFilter.start).toDate();
      opts.start = this.makeUtcLocal(this.searchFilter.start).toDate();
    }
    if (this.searchFilter.end) {
      opts.max = this.makeUtcLocal(this.searchFilter.end).add(1, 'day').toDate();
      opts.end = this.makeUtcLocal(this.searchFilter.end).add(1, 'day').toDate();
    }

    return opts;
  }

  registerTimelineEventHandlers(timelineId){
    this.visTimelineService.on(timelineId, 'click');
    this.visTimelineService.on(timelineId, 'timechanged');
    this.visTimelineService.on(timelineId, 'select');
    this.visTimelineService.on(timelineId, 'doubleClick');
    this.visTimelineService.on(timelineId, 'rangechanged');
  }

  setupTimelineEventHandlers(){
    this.visTimelineService.click.subscribe((eventData: any[])=>{
      //console.log(eventData);
    });

    this.visTimelineService.timechanged.subscribe((eventData:any[])=>{
      //console.log(eventData);
    });

    this.visTimelineService.select.subscribe((eventData:any[])=>{
      if(eventData.length==2){
        let tlId = eventData[0];
        let items = eventData[1].items;

        if (items.length > 0) {
          this.currentTimelinesDataArray.forEach((item)=>{
            if(item.id===tlId){
              item.selectedDocumentId=items[0];
              this.cdr.detectChanges();
              return;
            }
          });
       } else {
          this.currentTimelinesDataArray.forEach((item)=>{
            if(item.id===tlId){
              item.selectedDocumentId=null;
              this.cdr.detectChanges();
              return;
            }
          });
       }
      }
    });

    this.visTimelineService.doubleClick.subscribe((eventData:any[])=>{
      let tlId=eventData[0];
      console.log(eventData);
      //this.getTimelineDoubleClick
    //   var that = this;
    // return function (evt: any) {
    //     var itemId = evt.item;
    //     if (itemId && itemId !== '') {
    //         //that.$state.go('documents.details', { id: itemId }, { reload: true });
    //     }
    // };
    });

    this.visTimelineService.rangechanged.subscribe((eventData:any[])=>{
      if(eventData.length===2){
        let tlId=eventData[0];
        let evt: VisRangeChangedEventProps = eventData[1];
        this.currentTimelinesDataArray.forEach((item)=>{
          if(item.id===tlId){
            this.loadMonth(item,evt.start);
            return;
          }
        });
      }
      //sample eventData[1]
      //byUser: true
      //end: Wed May 19 2021 08:48:04 GMT-0400 (Eastern Daylight Time) {}
      //event: {pointers: Array(1), changedPointers: Array(1), pointerType: "mouse", srcEvent: PointerEvent, isFirst: false, …}
      //start: Tue May 18 2021 08:48:04 GMT-0400 (Eastern Daylight Time) {}
    });
  }

  transformDocumentResponse(docuResponse: TimelineDocumentViewModel[]) {
    let docs = [];
    for (var i = 0; i < docuResponse.length; i++) {
      if (docuResponse[i].parentId == null) {
        docuResponse[i].children = [];
        docs.push(docuResponse[i]);
      }
    }

    for (var i = 0; i < docuResponse.length; i++) {
      if (docuResponse[i].parentId != null) {
        var parentFound = false;
        for (var z = 0; z < docs.length; z++) {
          if (docs[z].id === docuResponse[i].parentId) {
            docs[z].children.push(docuResponse[i]);
            parentFound = true;
          }
        }
        //don't indent if parent not found
        if (parentFound === false) {
          docs.push(docuResponse[i]);
        }
      }
    }
    return docs;
  }

  makeUtcLocal(date) {
    return moment.utc(date).local();
  }

  setType(fileName: string, start?: any, end?: any) {
    if (fileName.endsWith('.mp4') || fileName.endsWith('.avi') || fileName.endsWith('.MOV')) {
      if (start != null && end != null) {
        return 'range';
      }
    }

    return 'point';
  }

  loadMonth(timeline: TimelineData, dateInMonthLocal: DateType) {

    var monthStart = moment(moment(dateInMonthLocal).startOf('month').format());
    var dataKey = timeline.id + '_' + monthStart.format('YYYY-MM');

    if (dataKey in this.loadingTracker) {
      return;
    }

    this.loadingTracker[dataKey] = true;

    var docFilter = Object.assign({}, this.searchFilter);

    var tlf: any = {
      deviceId: timeline.id,
      start: monthStart.utc().toDate(),
      end: monthStart.endOf('month').utc().toDate()
    };

    if (docFilter.groups && docFilter.groups !== '') {
      tlf.groups = docFilter.groups;
    }

    if (docFilter.categories && docFilter.categories !== '') {
      tlf.categories = docFilter.categories;
    }

    if (docFilter.status && docFilter.status !== '') {
      tlf.status = docFilter.status;
    }

    if (docFilter.searchTerm && docFilter.searchTerm !== '') {
      tlf.searchTerm = docFilter.searchTerm;
    }

    if (docFilter.sharedByMeOnly) {
      tlf.sharedByMeOnly = docFilter.sharedByMeOnly;
    }

    if (docFilter.sharedWithMeOnly) {
      tlf.sharedWithMeOnly = docFilter.sharedWithMeOnly;
    }

    if (docFilter.userIds && docFilter.userIds !== '') {
      tlf.userIds = docFilter.userIds;
    }

    if (docFilter.includeDeleted) {
      tlf.includeDeleted = docFilter.includeDeleted;
    }

    if (docFilter.importantOnly) {
      tlf.importantOnly = docFilter.importantOnly;
    }

    this.securaMaxAPIService.documents_GetAllTimeline(timeline.id === this.webUploadTimelineId ? undefined : timeline.id,
      tlf.searchTerm,
      tlf.start,
      tlf.end,
      tlf.userIds,
      tlf.groups,
      tlf.categories,
      tlf.status,
      tlf.sharedWithMeOnly,
      tlf.sharedByMeOnly,
      false,
      tlf.includeDeleted,
      tlf.importantOnly).subscribe((data) => {
        //this.loadingTracker[dataKey] = true;
        var its:DataItem[] = [];

        for (var i = 0; i < data.length; i++) {
          var element: DataItem = {
            id: data[i].id,
            group: data[i].channel,
            content: data[i].name,
            start: this.makeUtcLocal(data[i].start).toDate(),
            end: this.makeUtcLocal(data[i].end).toDate(),
            className: '',
            type: this.setType(data[i].name, data[i].start, data[i].end)
          };

          if (data[i].important === true) {
            element.className = 'important';
          }
          its.push(element);
        }

        timeline.items.add(its);
        this.visTimelineService.setItems(timeline.id, its);

    });
  }

  onPageChanged(){
    this.currentTimelinesDataArray.forEach((elem)=>{
      this.visTimelineService.destroy(elem.id);
    });

    this.currentTimelinesDataArray=null;
  }

  onDataSourceUpdate(newTimelines: TimelineSearchViewModel[]) {
    if(this.currentTimelinesDataArray?.length > 0) {
      this.currentTimelinesDataArray.forEach((elem)=>{
        this.visTimelineService.destroy(elem.id);
      });
    }
    this.currentTimelinesDataArray=[];
    this.loadingTracker={};
    for(let deviceIndex = 0; deviceIndex < newTimelines.length; deviceIndex++) {
      var that = this;
      var groups = new DataSet<DataGroup, "id">({});

      for(let i = 0; i < newTimelines[deviceIndex].deviceExpandedVM.channels.length; i++) {
        groups.add({
          id: newTimelines[deviceIndex].deviceExpandedVM.channels[i],
          content: newTimelines[deviceIndex].deviceExpandedVM.channels[i] + ''
        });
      }

      var items = new DataSet<DataItem, "id">({});

      var tempTimelineData: TimelineData = {
        groups: groups,
        items: items,
        id: newTimelines[deviceIndex].deviceExpandedVM.id ?? this.webUploadTimelineId,
        device: newTimelines[deviceIndex].deviceExpandedVM,
        selectedDocumentId:null,
        options: null
      };
      tempTimelineData.options = this.getTimelineOptions(tempTimelineData);

      that.currentTimelinesDataArray.push(tempTimelineData);
      that.loadMonth(tempTimelineData, tempTimelineData.options.start);
    }
  }

  numItemsUpdated(event: number) {
    this.totalItems = event;
  }
}
