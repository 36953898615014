<h2 mat-dialog-title i18n>Are you sure?</h2>

<mat-dialog-content>
    <p display-2>{{data.message}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="smx-critical-button" (click)="onDismiss()" i18n>No</button>
    <button mat-button class="smx-primary-button" (click)="onConfirm()" i18n>Yes</button>
</mat-dialog-actions>
