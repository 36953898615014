/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BC300ConfigViewModel { 
    id?: number;
    name: string;
    videoMode?: string | null;
    videoQuality?: string | null;
    preEventRecord?: string | null;
    postEventRecord?: string | null;
    recordMode?: string | null;
    preEventMode?: string | null;
    motionRecording?: boolean;
    photoResolution?: string | null;
    photoQuality?: string | null;
    burstPhotoCapture?: string | null;
    autoExposure?: string | null;
    lcdBrightness?: number;
    speakerVolume?: number;
    autoPowerOff?: string | null;
    lcdAutoOff?: string | null;
    powerTones?: boolean;
    recordTones?: boolean;
    buttonTones?: boolean;
    vibration?: boolean;
    wiFi?: boolean;
    colorSystem?: string | null;
    videoFileDuration?: string | null;
    fileOverwrite?: boolean;
    irMode?: string | null;
    irControl?: string | null;
    usbMode?: string | null;
    mainMenu?: boolean;
    playbackMenu?: boolean;
    watermark?: boolean;
}

