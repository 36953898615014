<div class="outer-container">
    <h2 i18n>Groups</h2>
    <div class="inner-container">
        <div class="row">
            <div class="col-sm-4">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title" i18n>System Groups</h3>
                        <mat-nav-list>
                            <a mat-list-item routerLinkActive="active" [routerLink]="['/admin/users/groups/',group.iD]"
                                *ngFor="let group of systemGroups">
                                <h5 matLine>{{group.name}}</h5>
                                <h6 class="ml-3" matLine>{{group.description}}</h6>
                            </a>
                            <div mat-list-item *ngIf="!groupsLoaded">
                                <mat-spinner color="accent"></mat-spinner>
                            </div>
                        </mat-nav-list>
                    </div>

                </div>
                <div class="card mt-3">
                    <div class="card-body">
                        <h3 class="card-title" i18n>Custom Groups</h3>
                        <mat-nav-list>
                            <a mat-list-item [routerLink]="['/admin/users/groups/',group.iD]" routerLinkActive="active"
                                *ngFor="let group of customGroups">
                                <h5 matLine>{{group.name}}</h5>
                                <h6 class="ml-3" matLine>{{group.description}}</h6>
                            </a>
                            <div mat-list-item *ngIf="!groupsLoaded">
                                <mat-spinner color="accent"></mat-spinner>
                            </div>
                            <div mat-list-item *ngIf="groupsLoaded && customGroups.length===0" i18n>
                                No Results
                            </div>
                        </mat-nav-list>

                        <a mat-button routerLink="/admin/users/groups/create" class="smx-primary-button mt-2"
                            i18n>Add</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
