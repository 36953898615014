import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ShareViewModel} from 'src/app/services/api/securamaxapi.service';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-share-modify-dialog',
    templateUrl: './share-modify-dialog.component.html',
    styleUrls: ['./share-modify-dialog.component.css']
})
export class ShareModifyDialogComponent implements OnInit {

    shareDate: string;
    shareTime: string;
    daterangepicker_start: string;
    daterangepicker_end: string;

    constructor(
        private dialogRef: MatDialogRef<ShareModifyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ShareViewModel) {
    }

    ngOnInit(): void {
        this.shareDate = new DatePipe('en-US').transform(this.data.expires, 'yyyy-MM-dd');
        this.shareTime = new DatePipe('en-US').transform(this.data.expires, 'HH:mm');
        this.daterangepicker_start = moment(this.shareDate + " " + this.shareTime).toISOString();
    }

    onClose() {
        this.data.expires = moment(this.shareDate + " " + this.shareTime).utc().toDate();
        this.dialogRef.close(this.data);
    }

    daterangepicker_change($event: any) {
        this.shareDate = moment(this.daterangepicker_start).format('yyyy-MM-DD');
        this.shareTime = moment(this.daterangepicker_start).format('HH:mm');
    }
}
