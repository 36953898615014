import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecuraMaxApiService, UploadLocationViewModel } from '../../services/api/securamaxapi.service';
import { UploadStationsModifyDialogComponent } from './upload-stations-modify-dialog/upload-stations-modify-dialog.component';
import { UploadStationsAddDialogComponent } from './upload-stations-add-dialog/upload-stations-add-dialog.component';
import { Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { TitleService } from '../../services/title.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-upload-stations',
    templateUrl: './upload-stations.component.html',
    styleUrls: ['./upload-stations.component.css']
})
export class UploadStationsComponent implements OnInit {
    private readonly bc300DriverLocation : string = "https://files.provisionusa.com/software/bodycam/bc300drivers/setup.exe";
    displayedColumns: string[] = ['name', 'lastConnected', 'status', 'modifyButton']; // Header for the table columns
    uploadLocationsList: UploadLocationViewModel[];   // Contains the list up upload stations displayed in table.
    downloadLink: string;

    constructor(private apiService: SecuraMaxApiService, private api: ApiService, private _snackBar: MatSnackBar, 
        private toastr: SnackbarService, public modifyDialog: MatDialog, private router: Router, 
        @Inject(DOCUMENT) private document: Document, private titleService: TitleService) {
        api.uploaderUrl().subscribe((url) => {
            this.downloadLink = url;
        });
    }

    ngOnInit(): void {
        this.titleService.setTitle('Manage Upload Stations');
        this.apiService.uploadLocations_GetAll(false).subscribe(data => {
            this.uploadLocationsList = data;
        });
    }

    /**
     * Toggles the state of the upload station between enabled/disabled.
     * On Error it will switch the toggle back to the original state.
     */
    toggleStateOfStation(element: UploadLocationViewModel) {
        this.apiService.uploadLocations_PutUploadLocation(element).subscribe(
            result => {
                this._snackBar.open('Successfully updated upload station.', '', {
                    duration: 3000,
                    horizontalPosition: "end",
                    verticalPosition: "top",
                    panelClass: ['notify-success']
                });
            },
            error => {
                // Resets the upload station to its original state cause an error occurred.
                element.enabled = !element.enabled;

                this._snackBar.open('Error occurred while updating upload station.', '', {
                    duration: 3000,
                    horizontalPosition: "end",
                    verticalPosition: "top",
                    panelClass: ['notify-error']
                });
            }
        );
    }

    /**
     * Opens a dialog box to change the upload station name. Once the dialog is closed
     * we submit the name change to the server. On Error it will revert back to its 
     * original name.
     * @param element Upload Station we want to modify.
     */
    openModifyDialog(element: UploadLocationViewModel) {
        const dialogRef = this.modifyDialog.open(UploadStationsModifyDialogComponent, {
            data: {
                stationName: element.name
            }
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (data && data.stationName != "") {
                let previousName = element.name;
                element.name = data.stationName;

                this.apiService.uploadLocations_PutUploadLocation(element).subscribe(
                    result => {
                        this._snackBar.open('Successfully updated upload station.', '', {
                            duration: 3000,
                            horizontalPosition: "end",
                            verticalPosition: "top",
                            panelClass: ['notify-success']
                        });
                    },
                    error => {
                        // Resets the upload station to its original name cause an error occurred.
                        element.name = previousName;

                        this._snackBar.open('Error occurred while updating upload station.', '', {
                            duration: 3000,
                            horizontalPosition: "end",
                            verticalPosition: "top",
                            panelClass: ['notify-error']
                        });
                    }
                );
            }
        });
    }

    add() {
        const dialogRef = this.modifyDialog.open(UploadStationsAddDialogComponent, {
            data: {
                stationName: ''
            }
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (data && data.stationName != "") {

                let newLocation = new UploadLocationViewModel();
                newLocation.name = data.stationName;

                this.apiService.uploadLocations_PostLocation(newLocation).toPromise().then(
                    result => {
                        this.toastr.success('Successfully added upload station.');
                    },
                    error => {
                        this.toastr.error('Error occurred while adding upload station.');
                    }
                );
            }
        });
    }

    downloadUploader(): void {
        var that = this;
        this.api.uploaderUrl().subscribe((data) => {
            that.document.location.href = data;
        }, (err) => {

        });
    }

    downloadDrivers(): void {
        this.document.location.href = this.bc300DriverLocation;
    }
}
