import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BC300ListItemViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-bc300configs-delete',
  templateUrl: './bc300configs-delete.component.html',
  styleUrls: ['./bc300configs-delete.component.css']
})
export class Bc300configsDeleteComponent implements OnInit {

  config: BC300ListItemViewModel;
  configs: BC300ListItemViewModel[];
  deleteForm = new UntypedFormGroup({
    replaceWithConfigId: new UntypedFormControl('')
  });

  loading: boolean = true;
  id: number;

  confirmDelete() {
    var that = this;
    if (that.config.name !== 'default') {

      let replaceWithConfigId = this.deleteForm.controls.replaceWithConfigId.value;
      if (this.config.assignedToGroups === 0) {
        for (let i = 0; i < this.configs.length; i++) {
          if (this.configs[i].name === 'default') {
            replaceWithConfigId = this.configs[i].id;
          }
        }
      }
      this.loading = true;
      that.api.deviceConfigurations_Delete(this.id, replaceWithConfigId).toPromise().then(() => {
        that.loading = false;
        this.toastr.success('Deleted!');
        this.router.navigate(['/admin/lookups/bc300configs']);
      }).catch((err) => {
        this.loading = false;
        this.toastr.error('Error deleting!.');
      });
    }
  }

  constructor(private api: SecuraMaxApiService, private toastr: SnackbarService, private location: Location, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = +this.route.snapshot.params.id;
    this.api.deviceConfigurations_GetAll().toPromise().then((resp) => {
      this.configs = resp;

      for (let i = 0; i < this.configs.length; i++) {
        if (this.configs[i].id === this.id) {
          this.config = this.configs[i];
        }
      }

      if (this.config.assignedToGroups > 0) {
        this.deleteForm = new UntypedFormGroup({
          replaceWithConfigId: new UntypedFormControl('', Validators.required)
        });
      }

      this.loading = false;
    }).catch((err) => {
      this.toastr.error('Could not load config!');
    });;

  }

  get f() { return this.deleteForm.controls; }

}
