import { Component, OnInit } from '@angular/core';
import { SecuraMaxApiService, SiteSettings } from '../../../services/api/securamaxapi.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-api-settings',
  templateUrl: './api-settings.component.html',
  styleUrls: ['./api-settings.component.css']
})

export class ApiSettingsComponent implements OnInit {

  clientSecret: string;
  copied: boolean = false;

  constructor(private titleService: TitleService, private api: SecuraMaxApiService, private _matSnackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('API Settings');
    this.copied = false;
  }

  generateClientSecret(): void {
    this.copied = false;
    this.api.settings_GetClientSecret().subscribe((data) => {
      this.clientSecret = data.secret;
    });
  }

  copyToClipboard(): void{
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.clientSecret;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
  }
}
