
export interface DvrEventType {
    id: number;
    name: string;
};

export interface RuleType {
    id: number;
    name: string;
}

export interface RuleLookups {
    dvrEventTypes: Array<DvrEventType>;
    ruleTypes: Array<RuleType>;
}

export const DvrEventTypes: RuleLookups = {
    "dvrEventTypes": [
        {
            "name": "Sensor1",
            "id": 0
        },
        {
            "name": "Sensor2",
            "id": 1
        },
        {
            "name": "Sensor3",
            "id": 2
        },
        {
            "name": "Sensor4",
            "id": 3
        },
        {
            "name": "EventMarkerButton",
            "id": 5
        },
        {
            "name": "GPSOverSpeed",
            "id": 6
        },
        {
            "name": "IdleTooLong",
            "id": 7
        },
        {
            "name": "VideoLost",
            "id": 8
        },
        {
            "name": "VideoLost1",
            "id": 8
        },
        {
            "name": "VideoLost2",
            "id": 9
        },
        {
            "name": "VideoLost3",
            "id": 10
        },
        {
            "name": "VideoLost4",
            "id": 11
        },
        {
            "name": "VideoLost5",
            "id": 12
        },
        {
            "name": "VideoLost6",
            "id": 13
        },
        {
            "name": "VideoLost7",
            "id": 14
        },
        {
            "name": "VideoLost8",
            "id": 15
        },
        {
            "name": "MotionDetection",
            "id": 16
        },
        {
            "name": "MotionDetection1",
            "id": 16
        },
        {
            "name": "MotionDetection2",
            "id": 17
        },
        {
            "name": "MotionDetection3",
            "id": 18
        },
        {
            "name": "MotionDetection4",
            "id": 19
        },
        {
            "name": "MotionDetection5",
            "id": 20
        },
        {
            "name": "MotionDetection6",
            "id": 21
        },
        {
            "name": "MotionDetection7",
            "id": 22
        },
        {
            "name": "MotionDetection8",
            "id": 23
        },
        {
            "name": "GSensor",
            "id": 24
        },
        {
            "name": "GSensorX",
            "id": 24
        },
        {
            "name": "GSensorY",
            "id": 25
        },
        {
            "name": "GSensorZ",
            "id": 26
        },
        {
            "name": "DVR 700 Trigger1",
            "id": 40
        },
        {
            "name": "DVR 700 Trigger2",
            "id": 41
        },
        {
            "name": "DVR 700 Trigger3",
            "id": 42
        },
        {
            "name": "DVR 700 Trigger4",
            "id": 43
        },
        {
            "name": "DVR 700 Microphone",
            "id": 44
        },
        {
            "name": "DVR 700 LightBar",
            "id": 45
        }
    ],
    "ruleTypes": [
        {
            "name": "GPS Overspeed",
            "id": 0
        },
        {
            "name": "Erratic Driving",
            "id": 1
        },
        {
            "name": "Event Marker",
            "id": 2
        },
        {
            "name": "Triggers",
            "id": 3
        },
        {
            "name": "Events",
            "id": 4
        }
    ]
}