import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BC300ListItemViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-bc300configs-create',
  templateUrl: './bc300configs-create.component.html',
  styleUrls: ['./bc300configs-create.component.css']
})
export class Bc300configsCreateComponent implements OnInit {
  configs: BC300ListItemViewModel[];
  loading: boolean = true;
  configForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    basedOffId: new UntypedFormControl('', Validators.required)
  });

  constructor(private api: SecuraMaxApiService,
    private toastr: SnackbarService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Create BC-300 Configuration');
    this.api.deviceConfigurations_GetAll().toPromise().then((data) => {
      this.loading = false;
      this.configs = data;
    }).catch((err) => {
      this.loading = false;
      this.toastr.error('Error loading configurations');
    });
  }

  get f() { return this.configForm.controls; }

  create() {
    var that = this;
    that.loading = true;
    that.api.deviceConfigurations_Get(this.configForm.controls.basedOffId.value).toPromise().then((data) => {
      let config = data;
      config.name = this.configForm.controls.name.value;
      config.id = null;
      config.wiFi = false;
      that.api.deviceConfigurations_Post(config).toPromise().then((newId) => {
        this.configForm.reset();
        that.loading = false;
        //navigate to edit
        this.router.navigate(['/admin/lookups/bc300configs/', newId, 'edit']);
      }).catch((err) => {
        this.loading = false;
        this.toastr.error('Error loading configurations');
      });
    }, function (err) {
      that.loading = false;
      this.toastr.error('Error loading configurations');
    });
  };
}
