import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-admin-rights-dialog',
  templateUrl: './help-admin-rights-dialog.component.html',
  styleUrls: ['./help-admin-rights-dialog.component.css']
})
export class HelpAdminRightsDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
