import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SnackbarService } from '../../../services/snackbar.service';
import {
    BC4ConfigViewModel,
    TimeZoneModel,
    SecuraMaxApiService,
} from '../../../services/api/securamaxapi.service';
import { ActivatedRoute } from '@angular/router';
import { Bc4WifiComponent } from '../bc4-wifi/bc4-wifi.component';

@Component({
    selector: 'app-bc4configs-edit',
    templateUrl: './bc4configs-edit.component.html',
    styleUrls: ['./bc4configs-edit.component.css'],
})
export class Bc4configsEditComponent implements OnInit {
    id: number = null;
    config: BC4ConfigViewModel;
    loading: boolean = false;
    errMessage: string[] = [];
    wifiNetworks: any;
    timeZones: TimeZoneModel[];

    constructor(
        private api: SecuraMaxApiService,
        private toastr: SnackbarService,
        private location: Location,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        //this.id = $stateParams.id
        this.id = this.route.snapshot.params.id;
        this.loading = true;
        this.api
            .bC400_GetConfig(this.id)
            .toPromise()
            .then(
                (data) => {
                    this.config = data;
                    //this.wifiNetworks = [ { ssid: 'test', password: 'test' } ];
                    this.loading = false;
                },
                function (err) {
                    this.loading = false;
                    this.toastr.error('Error loading configuration.');
                }
            );
    }

    save() {
        this.loading = true;
        this.errMessage.splice(0, this.errMessage.length);

        if (!this.config.wifi && this.config.wifi === false) {
            if (this.config.wifiNetworks) {
                this.config.wifiNetworks.splice(0, this.config.wifiNetworks.length);
            }
        }

        if (this.config.wifiNetworks.length > 0) {
            let hasInvalidWifiNetworkConfig = false;
            for (const network of this.config.wifiNetworks) {
                if (!network.password || network.password === '') {
                    this.errMessage.push('Invalid WiFi Password');
                    hasInvalidWifiNetworkConfig = true;
                }

                if (!network.sSID || network.sSID === '') {
                    this.errMessage.push('Invalid WiFi SSID');
                    hasInvalidWifiNetworkConfig = true;
                }
            }

            if (hasInvalidWifiNetworkConfig) {
                this.loading = false;
                this.toastr.error('Error saving!');
                return;
            }
        }

        this.api
            .bC400_PutConfig(this.config)
            .toPromise()
            .then(() => {
                this.loading = false;
                this.toastr.success('Saved!');
            })
            .catch((err) => {
                if (err.data.ModelState) {
                    this.errMessage = [];
                    for (const prop in err.data.ModelState) {
                        if (err.data.ModelState.hasOwnProperty(prop)) {
                            for (
                                let i = 0;
                                i < err.data.ModelState[prop].length;
                                i++
                            ) {
                                this.errMessage.push(
                                    prop + ': ' + err.data.ModelState[prop][i]
                                );
                            }
                        }
                    }
                }
                this.loading = false;
                this.toastr.error('Error saving!');
            });
    }
}
