<div id="wifiContainer">
    <div *ngFor="let network of wifiNetworks; let i = index" class="wifi-row">
        <mat-form-field>
            <input matInput placeholder="SSID (Network Name)" [(ngModel)]="network.sSID" name="WifiNetworks[{{i}}].sSID" required autocomplete="one-time-code">
        </mat-form-field>
        <mat-form-field class="ml-4">
            <input matInput placeholder="Wifi Password" [(ngModel)]="network.password" name="wifiNetworks[{{i}}].password" [type]="hide ? 'password' : 'text'" required autocomplete="one-time-code">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="removeNetwork(i)" class="mb-4 ml-4">Remove</button>
     
    </div>
    <button mat-raised-button color="primary" (click)="addNetwork()">Add Wifi Network</button>

</div>
