import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timezoneList } from 'src/app/shared/helpers/timezone-list-const';

export class wifiItem {
  SSID: string;
  Password: string;
  Username: string;

  constructor(data?: wifiItem) {
      if (data) {
          for (var property in data) {
              if (data.hasOwnProperty(property))
                  (<any>this)[property] = (<any>data)[property];
          }
      }
  }

  init(_data?: any) {
      if (_data) {
          this.SSID = _data["SSID"];
          this.Password = _data["Password"];
      }
  }

  static fromJS(data: any): wifiItem {
      data = typeof data === 'object' ? data : {};
      let result = new wifiItem();
      result.init(data);
      return result;
  }

  toJSON(data?: any) {
      data = typeof data === 'object' ? data : {};
      data["SSID"] = this.SSID;
      data["Password"] = this.Password;
      return data; 
  }
}

export enum DVRQuickConfigDeviceType {
  PD1902DR200v2 = 1,
  PD1900 = 2
}

export enum WiFiConfigType {
  APMode = 1,
  ClientMode = 2
}

interface DeviceInformation {
  value: DVRQuickConfigDeviceType,
  name: string
}

export class Timezone {
  value: string
  name: string
}

export interface DVRQuickConfigData {
  targetDeviceType: DVRQuickConfigDeviceType,
  timezone: string,
  wifiEnable: boolean,
  LANEnabled: boolean,
  DHCPEnabled: boolean,
  FourGEnabled: boolean,
  dialCmd: string,
  apn: string,
  username: string,
  password: string,
  wifiSSIDPasswordList: wifiItem[],
  securamaxServerURL: string,
  staticIP: string,
  subnetMask: string,
  gateway: string
}

@Component({
  selector: 'app-dvr-quick-config-dialog',
  templateUrl: './dvr-quick-config-dialog.component.html',
  styleUrls: ['./dvr-quick-config-dialog.component.css']
})
export class DvrQuickConfigDialogComponent implements OnInit {
  devices: DeviceInformation[] = [
    { name: "PD-1902/DR-200v2", value: DVRQuickConfigDeviceType.PD1902DR200v2 }
  ]
  timezones: typeof timezoneList = timezoneList;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DVRQuickConfigData) { }

  ngOnInit(): void {
    this.data.targetDeviceType = 1;
    this.data.wifiSSIDPasswordList = [];
    this.data.wifiSSIDPasswordList.push(new wifiItem());
    this.data.timezone = "EST+5EDT,M3.2.0,M11.1.0";
  }

  addWiFiNetwork(): void {
    this.data.wifiSSIDPasswordList.push(new wifiItem());
  }

  removeWiFiNetwork(wiFiNetwork: wifiItem): void {
    var index = this.data.wifiSSIDPasswordList.findIndex(x => x.SSID === wiFiNetwork.SSID);

    if (index > -1) {
      this.data.wifiSSIDPasswordList.splice(index, 1);
    }
  }
}
