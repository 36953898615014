<div class="card mt-3">
    <div class="card-body">
        <div class="row mt-3 justify-content-center" *ngIf="document">

            <!--Left column. Video and Metadata form.-->
            <div class="col-xl-6 col-md-6">

                <!--<div ng-if="vm.document.MimeType==='audio/wav' && vm.document.Versions.length>0">
        <div style="margin:10px;">
            <ng-wavesurfer url="{{vm.ws.url}}" wave-color="#337ab7" progress-color="#23527c" height="350">
            </ng-wavesurfer>
        </div>
        <div>
            <button class="btn btn-success btn-block" ng-click="vm.wavesurferObj.playPause()">
                <span id="play" ng-show="vm.ws.paused">
                    <i class="glyphicon glyphicon-play"></i>
                    Play
                </span>

                <span id="pause" ng-show="!vm.ws.paused">
                    <i class="glyphicon glyphicon-pause"></i>
                    Pause
                </span>
            </button>
        </div>
    </div>-->

                <div *ngIf="document.statusId === documentStatus.archived"
                     class="alert alert-warning d-flex justify-content-center" role="alert">
                    <fa-icon class="mr-2" icon="file-archive"></fa-icon>
                    <div i18n>
                        This document has been archived. This video is not available for download or redactions and must
                        first be retrieved from the archive storage.
                        You may still share the document, edit metadata, and view the lower resolution preview of the
                        video.
                    </div>
                </div>

                <div
                    *ngIf="(document.versions.length>0 || document.parentId!=null) && document.isDeleted===false && document.mimeType.substring(0, 5) == 'video'">
                    <vg-player *ngIf="videoSrcUrl">
                        <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                            <source [src]="videoSrcUrl" type="video/mp4">
                        </video>
                    </vg-player>
                </div>

                <div *ngIf="document.parentId!=null" class="alert alert-warning mt-3" role="alert">
                    <ng-container i18n>The original video has been redacted and can be viewed in 'Document Search' or by clicking </ng-container>
                    <a routerLink="/documents/{{document.parentId}}" i18n>here</a>.
                </div>
                <div
                    *ngIf="(document.versions.length<=0 && document.parentId==null) && document.isDeleted===false && document.mimeType.substring(0, 5) == 'video'"
                    class="alert alert-warning d-flex justify-content-center mt-3" role="alert">
                    <fa-icon class="mr-2" icon="file-excel"></fa-icon>
                    <div i18n>
                        Video preview is unavailable. The original file was corrupted during the recording process and
                        is incomplete. Portions of the file may still be playable if downloaded and viewed locally.
                    </div>
                </div>

                <div *ngIf="document.mimeType.substring(0,5)==='image'" class="d-flex justify-content-center">
                    <img [src]="getDownloadUrl() | async" class="img-fluid" *ngIf="document.versions.length === 0"/>
                    <img [src]="imageSrcUrl" class="img-fluid" *ngIf="document.versions.length > 0"/>
                </div>
                <div *ngIf="document.statusId == documentStatus.pendingTransferFromTempStorage"
                     class="mt-3 alert alert-documentProcessing" role="alert">
                    <strong i18n>Document is processing. It will be available soon</strong>
                </div>
                <div *ngIf="document.isDeleted === true" class="mt-3 alert alert-warning" role="alert" i18n>
                    <strong>Deleted</strong> This document has been permanently deleted and can no longer be viewed.
                    Some options may no longer be available.
                </div>
                <div *ngIf="!document.initialTagComplete" class="mt-3 alert alert-warning" role="alert" i18n>
                    <strong>Warning</strong> Untagged! Initial tagging is not complete. Please complete initial tagging.
                </div>

                <ul class="list-group">
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>
                                    Title<span *ngIf="document.parentId != null">Of Original</span>:
                                </label>
                            </div>
                            <div class="col-9">
                                <span *ngIf="!document.title" i18n>Not Set</span>
                                <span *ngIf="document.title" i18n>{{document.title}}</span>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="w-100"></div>
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label  mx-0" i18n>File Name:</label>
                            </div>
                            <div class="col-9" i18n>{{document.name}}</div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>Created:</label>
                            </div>
                            <div class="col-9" i18n>
                                {{document.createdDate | date: 'MMM dd YYYY, h:mm:ss a z'}}
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label  mx-0" i18n>Uploaded:</label>
                            </div>
                            <div class="col-9" i18n>{{document.uploaded | date: 'MMM dd YYYY, h:mm:ss a z'}}</div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>File Size:</label>
                            </div>
                            <div class="col-9" i18n>
                                {{document.length | bytes:1}}
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>Owner:</label>
                            </div>
                            <div class="col-8" i18n>{{document.ownerName}}</div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>Description:</label>
                            </div>
                            <div class="col-9">
                                <span *ngIf="!document.description" i18n>Not Set</span>
                                {{document.description}}
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>Categories:</label>
                            </div>
                            <div class="col-9  d-flex align-items-center">
                                <mat-chip-set>
                                    <mat-chip *ngFor="let category of document.categories" color="accent" selected i18n>
                                        {{category.name}}
                                    </mat-chip>
                                </mat-chip-set>
                                <span *ngIf="document.categories.length == 0" i18n>Not Set</span>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">

                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>Tags: </label>
                            </div>
                            <div class="col-9  d-flex align-items-center">
                                <mat-chip-set>
                                    <mat-chip *ngFor="let tag of document.tags" color="accent" selected i18n>
                                        {{tag.typeName}}
                                    </mat-chip>
                                </mat-chip-set>
                                <span *ngIf="document.tags.length == 0" i18n>Not Set</span>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item document-metadata-list-item">
                        <div class="row">
                            <div class="col-3 text-right" style="word-break: break-word;">
                                <label class="details-label mx-0" i18n>Cases:</label>
                            </div>
                            <div class="col-9">
                                <mat-chip-set>
                                    <mat-chip [routerLink]="['/cases']"
                                              *ngFor="let case of document.documentCaseItemList"
                                              color="accent" selected i18n>{{case.title}}</mat-chip>
                                </mat-chip-set>
                                <!--
                    <a ui-sref="#" *ngFor="case of document.documentCaseItemList">
                        <span case.Title></span>
                    </a>-->
                                <span *ngIf="document.documentCaseItemList.length == 0" i18n>Not Set</span>
                            </div>

                        </div>
                    </li>
                </ul>


            </div>

            <!--Right Column-->
            <div class="col-xl-4 col-lg-5 col-md-6 pr-4">
                <div class="mb-2">
                    <div>
                        <h5 class="mb-0">
                            <button mat-raised-button color="accent" class="w-100" (click)="showShare = !showShare"
                                    [disabled]="!document.permissions.canShare" i18n>
                                <fa-icon icon="share"></fa-icon>
                                Share
                                <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                        </h5>
                    </div>
                    <div *ngIf="(shares && shares.length>0) || showShare">
                        <ul class="list-group">
                            <li class="list-group-item d-flex flex-wrap justify-content-between align-items-center"
                                *ngFor="let share of shares">
                      <span style="word-break: break-word;">
                        <span>
                          <fa-icon *ngIf="share.isUser" icon="user" i18n-title title="User"></fa-icon>
                          <fa-icon *ngIf="!share.isUser" icon="user-plus" i18n-title title="Share User"></fa-icon>
                          <fa-icon class="ml-1" *ngIf="share.caseId != null" icon="folder" i18n-title
                                   title="Shared via Case"></fa-icon>
                        </span>
                          {{share.displayName}} <span class="badge badge-warning" *ngIf="isShareExpired(share)" i18n>Expired</span>
                        <span *ngIf="!isShareExpired(share)" i18n>({{share.expires | timeAgo : true}})</span>
                      </span>
                                <div class="shareListButtons d-flex flex-wrap"
                                     *ngIf="document.permissions.canShare===true && document.permissions.canShareModifyOtherShares">
                                    <button mat-button class="smx-critical-button mb-2" *ngIf="!isShareExpired(share)"
                                            (click)="openCancelShareDialog(share)" i18n>
                                        Cancel
                                    </button>
                                    <button mat-button class="smx-primary-button ml-2 mb-2"
                                            *ngIf="!isShareExpired(share)"
                                            (click)="openModifyShareDialog(share)" i18n>
                                        Modify
                                    </button>
                                </div>
                            </li>
                            <li class="list-group-item" *ngIf="document.permissions.canShare===true">
                                <div class="mt-2">
                                    <label i18n>Share With</label>
                                    <mat-radio-group [(ngModel)]="shareUserType" class="mr-3">
                                        <mat-radio-button [value]="0">
                                            <div class="text-wrap" (change)="shareButtonAllowed()" i18n>Existing User
                                            </div>
                                        </mat-radio-button>
                                        <mat-radio-button class="ml-2" [value]="1" (change)="shareButtonAllowed()">
                                            <div class="text-wrap" i18n>New Email</div>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <app-user-select [(userId)]="shareSelect.associatedUserId"
                                                     [(associatedUserName)]="shareSelect.associatedUser"
                                                     [(userEmail)]="shareSelect.associatedUserEmail"
                                                     [includeCurrentUser]="false"
                                                     [(userSelectName)]="shareUserSelectName"
                                                     *ngIf="shareUserType == 0"
                                                     (change)="shareButtonAllowed()"></app-user-select>

                                    <mat-form-field appearance="outline" *ngIf="shareUserType == 1" class="full-width">
                                        <input matInput class="px-2 py-2" i18n-placeholder
                                               placeholder="Email: john.doe@gmail.com"
                                               [(ngModel)]="newShareUserInput" type="email" email
                                               (change)="shareButtonAllowed()">
                                    </mat-form-field>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div>
                                            <label i18n>
                                                <fa-icon icon="calendar">Active User</fa-icon>
                                                Expires
                                            </label>
                                            <br/>
                                            <label>Choose expiration date</label>
                                            <app-daterangepicker #dateRangePicker [rangeMode]="false"
                                                                 (change)="daterangepicker_change($event)"
                                                                 [(start)]="daterangepicker_start"
                                                                 [(end)]="daterangepicker_end"></app-daterangepicker>
                                        </div>
                                    </div>
                                    <div style="word-break: break-word;" class="col-lg-12">
                                        <label i18n>Permissions</label>
                                        <div class="checkbox">
                                            <mat-checkbox [checked]="true" [disabled]="true">
                                                <div class="text-wrap" i18n>View</div>
                                            </mat-checkbox>
                                        </div>
                                        <div class="checkbox">
                                            <mat-checkbox [(ngModel)]="shareCanDownload">
                                                <div class="text-wrap" i18n>Download</div>
                                            </mat-checkbox>
                                        </div>
                                        <div class="checkbox">
                                            <mat-checkbox [(ngModel)]="shareCanAddMetadata">
                                                <div class="text-wrap" i18n>Add Metadata</div>
                                            </mat-checkbox>
                                        </div>
                                        <div class="checkbox">
                                            <mat-checkbox [(ngModel)]="shareCanReshare">
                                                <div class="text-wrap" i18n>Reshare</div>
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <button mat-button class="smx-primary-button" (click)="saveShare()"
                                        [disabled]="shareSaveDisabled || isSavingShareChanges" i18n>Save
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div *ngIf="document.mimeType.substring(0, 5) == 'video' && isWindows" class="mb-2">
                    <div style="text-align: center">
                        <button mat-raised-button color="accent" class="w-100"
                                [disabled]="!document.permissions.canDownload || document.statusId === documentStatus.archived"
                                (click)="streamDocument()"
                                [attr.title]="document.statusId === documentStatus.archived ? 'You cannot watch an archived video. You must first request retrieval.' : null"
                                i18n-title i18n>
                            <fa-icon [icon]="['fab','youtube']"></fa-icon>
                            Watch in HD
                        </button>
                        <span *ngIf="this.showViewerLink"
                              i18n>HD Playback requires the HD Viewer app to be installed. <a
                            href="https://pvsupport.blob.core.windows.net/software/HDViewer/PV.HDViewerSetup.exe">Get the App</a></span>
                    </div>
                </div>

                <div class="mb-2">
                    <div>
                        <button mat-raised-button color="accent" class="w-100"
                                [disabled]="!document.permissions.canDownload || document.statusId === documentStatus.archived"
                                (click)="downloadDocument()"
                                [attr.title]="document.statusId === documentStatus.archived ? 'You cannot download an archived video. You must first request retrieval.' : null"
                                i18n-title i18n>
                            <fa-icon icon="download"></fa-icon>
                            Download Video
                        </button>
                    </div>
                </div>

                <div class="mb-2" *ngIf="document.parentId == null">
                    <div>
                        <button mat-raised-button color="accent" class="w-100"
                                (click)="editMetaDataClick();"
                                [disabled]="editMetaData || !document.permissions.canEditMetadata" i18n>
                            <fa-icon icon="edit"></fa-icon>
                            Edit
                            Metadata
                        </button>
                    </div>
                </div>

                <div class="mb-2">
                    <div>
                        <button mat-raised-button color="accent" class="download-audit-button w-100"
                                [disabled]="!document.permissions.canDownloadAuditReport"
                                (click)="downloadAuditReport()" i18n>
                            <fa-icon icon="key"></fa-icon>
                            Download Audit Report
                        </button>
                    </div>
                </div>

                <div class="mb-2" *ngIf="!document.isDeleted">
                    <div *ngIf="document.deletionDate && !document.isDeletionLocked">
                        <div>
                            <div i18n>Projected Deletion
                                Date: {{ document.deletionDate.toString() | timeAgo : true }}</div>
                        </div>
                        <div *ngIf="docInOpenCaseWithRetentionList.length>0" i18n>
                            <b>Warning: </b> Document is part of an open case.
                            <span class="badge badge-info" *ngFor="let case of docInOpenCaseWithRetentionList">
                      {{case.title}}
                    </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div *ngIf="document.isDeletionLocked" i18n>
                                Document will not be deleted while locked.
                            </div>
                            <div class="row" *ngIf="isRequestDelete">
                                <div class="col">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label i18n>Reason</mat-label>
                                        <textarea matInput placeholder="Enter reason for deletion."
                                                  [(ngModel)]="deleteRequestReason" i18n-placeholder></textarea>
                                    </mat-form-field>

                                </div>
                            </div>
                            <div class="row" *ngIf="isReviewDeletionRequest">
                                <div class="col">

                                    <div class="mt-3">
                                        <div i18n>
                                            <h5>Approve Deletion Request?</h5>

                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label><b>Requested By</b></label>
                                                    <p>{{document.deletionRequest.requestorName}}</p>
                                                </div>
                                            </div>

                                            <label><b>Reason</b></label>
                                            <p>{{document.deletionRequest.reason}}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mt-2" *ngIf="!document.isPendingDelete">
                                <div class="col">
                                    <button mat-button
                                            *ngIf="!document.isDeletionLocked && !isRequestDelete && !isReviewDeletionRequest"
                                            class="smx-primary-button mb-2 mr-2" (click)="lockFromDeletion()"
                                            [disabled]="!document.permissions.canDeletionLock" i18n-title title="Lock"
                                            i18n>
                                        <fa-icon [icon]="'unlock'"></fa-icon>
                                        Lock from Deletion
                                    </button>
                                    <button mat-button
                                            *ngIf="document.isDeletionLocked && !isRequestDelete && !isReviewDeletionRequest"
                                            class="smx-primary-button mb-2 mr-2" (click)="unlockFromDeletion()"
                                            [disabled]="!document.permissions.canDeletionLock" i18n-title title="Unlock"
                                            i18n>
                                        <fa-icon [icon]="'lock'"></fa-icon>
                                        Unlock from Deletion
                                    </button>

                                    <button mat-button
                                            *ngIf="!isRequestDelete && !document.isDeletionLocked && !document.deletionRequest"
                                            class="smx-critical-button mb-2 mr-2"
                                            (click)="isRequestDelete = true"
                                            [disabled]="!document.permissions.canRequestDelete || document.isDeletionLocked || requestDeleteSubmitClicked"
                                            i18n-title title="Request Deletion" i18n>
                                        Request Deletion
                                    </button>

                                    <button mat-button *ngIf="isRequestDelete" class="smx-primary-button mr-2 mb-2"
                                            (click)="requestDeleteSubmit();" i18n>
                                        Submit Deletion Request
                                    </button>
                                    <button mat-button *ngIf="isRequestDelete" class="smx-critical-button"
                                            (click)="isRequestDelete = false" i18n>
                                        Cancel
                                    </button>


                                    <button mat-button class="smx-primary-button mb-2"
                                            [disabled]="!document.permissions.canApproveDelete || document.isDeletionLocked"
                                            *ngIf="!isReviewDeletionRequest && !document.isDeletionLocked && document.deletionRequest"
                                            (click)="isReviewDeletionRequest = true" i18n>
                                        Review Deletion Request
                                    </button>
                                    <button mat-button *ngIf="isReviewDeletionRequest" class="smx-primary-button mr-2"
                                            (click)="approveDeleteRequeset()" i18n>
                                        Yes,
                                        Approve
                                    </button>

                                    <button mat-button *ngIf="isReviewDeletionRequest" class="smx-critical-button"
                                            (click)="denyDeleteRequeset()" i18n>
                                        No,
                                        Deny
                                    </button>
                                </div>

                            </div>

                            <div class="row mt-2" *ngIf="document.isPendingDelete">
                                <div class="col">
                                    <p i18n>Document is pending deletion. No further action is required.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-2"
                     *ngIf="document.versions.length > 0 && document.isDeleted === false && document.mimeType.substring(0, 5) == 'video'">
                    <div class="mb-2">

                        <button mat-raised-button color="accent" class="w-100"
                                (click)="showRedactions = !showRedactions">
                            <div class="d-flex justify-content-around" i18n>
                                <fa-icon icon="highlighter" class="mr-1"></fa-icon>
                                Redactions
                                <span class="mr-3 ml-1 mt-3 badge-spacing" matBadge="{{document.redactions.length}}"
                                      matBadgeOverlap="false" matBadgeSize="medium"></span>
                                <mat-icon style="float: right;">arrow_drop_down</mat-icon>
                            </div>
                        </button>

                    </div>
                    <div *ngIf="showRedactions">
                        <button mat-button class="smx-primary-button create-redaction-button mb-2 mr-2"
                                routerLink="/documents/{{document.id}}/redactions/create"
                                [disabled]="document.statusId === documentStatus.archived"
                                [attr.title]="document.statusId === documentStatus.archived ? 'You cannot create a redaction on an archived video. You must first request retrieval.' : null"
                                i18n-title i18n>
                            Create Redaction
                        </button>
                        <button mat-button class="smx-primary-button create-advanced-redaction-button mb-2"
                                routerLink="/documents/{{document.id}}/redactions/advanced"
                                [disabled]="document.statusId === documentStatus.archived || !document.permissions.canAdvancedRedact"
                                [attr.title]="document.statusId === documentStatus.archived ? 'You cannot create an advanced redaction on an archived video. You must first request retrieval.' : null"
                                i18n-title i18n>
                            Create Advanced
                            Redaction
                        </button>

                        <ul class="mt-1 list-group" *ngIf="document.redactions.length > 0">
                            <li *ngFor="let redaction of document.redactions"
                                class="mb-1 list-group-item d-flex align-items-center justify-content-between flex-wrap">
                      <span>
                        <strong i18n>{{redaction.created | date:'short'}} by {{redaction.createdByUsername}}</strong>
                      </span>
                                <div>
                                    <span class="badge badge-warning" *ngIf="redaction.redactionStatus===0" i18n>Awaiting Processing</span>
                                    <span class="badge badge-success" *ngIf="redaction.redactionStatus===1" i18n>Ready for Download</span>
                                    <span class="badge badge-success" *ngIf="redaction.redactionStatus===3" i18n>In Progress</span>
                                </div>
                                <a mat-button *ngIf="redaction.redactionDocumentId === null"
                                   routerLink="/documents/{{document.id}}/redactions/{{redaction.id}}"
                                   class="smx-secondary-button btn btn-link btn-xs ml-1" i18n>
                                    Details
                                </a>

                                <button mat-button
                                        *ngIf="redaction.redactionDocumentId !== null && redaction.redactionStatus===3"
                                        routerLink="/documents/{{document.id}}/redactions/advanced/{{redaction.id}}"
                                        [disabled]="!document.permissions.canAdvancedRedact || document.statusId === documentStatus.archived"
                                        [attr.title]="document.statusId === documentStatus.archived ? 'You cannot edit a redaction on an archived video. You must first request retrieval.' : null"
                                        class="smx-primary-button btn btn-link btn-xs ml-1" i18n-title i18n>
                                    Continue
                                    Editing
                                </button>

                                <a mat-button
                                   *ngIf="redaction.redactionDocumentId !== null && redaction.redactionStatus===1"
                                   routerLink="/documents/{{redaction.redactionDocumentId}}"
                                   class="smx-secondary-button btn btn-link btn-xs ml-1" i18n>
                                    Details
                                </a>

                            </li>
                        </ul>
                    </div>
                </div>

                <div class="mb-2" *ngIf="document.statusId === documentStatus.active && isUnlimitedPlanUser">
                    <div>
                        <button mat-raised-button color="accent" class="storage-status w-100"
                                style="pointer-events: none;" i18n>
                            Storage Status: Active
                        </button>
                    </div>
                </div>

                <div class="mb-2" *ngIf="document.statusId === documentStatus.archived && isUnlimitedPlanUser">
                    <div>
                        <button mat-raised-button color="accent" class="storage-status w-100"
                                style="pointer-events: none;" i18n>
                            Storage Status: Archived
                        </button>
                    </div>
                </div>

                <div class="mb-2"
                     *ngIf="document.statusId === documentStatus.pendingArchiveRetrieval && isUnlimitedPlanUser">
                    <div>
                        <button mat-raised-button color="accent" class="storage-status w-100"
                                style="pointer-events: none;" i18n>
                            Storage Status: Pending Archive Retrieval
                        </button>
                    </div>
                </div>

                <div class="mb-2" *ngIf="document.statusId === documentStatus.archived && document.permissions.canView"
                     class="card">
                    <div>
                        <button [disabled]="requestedFromArchive" mat-raised-button color="accent"
                                class="request-from-archive" (click)="isRequestRetrieve = true" i18n>
                            Request Archive Retrieval
                        </button>
                        <div class="alert alert-warning mt-2 mb-2" role="alert" *ngIf="isRequestRetrieve" i18n>This
                            retrieval may take up to 48 hours. The video will be available in Active Storage for 30
                            days, then will automatically return to Archive Storage.
                        </div>
                        <button *ngIf="isRequestRetrieve" mat-raised-button class="mr-2 mb-2"
                                (click)="requestArchiveRetrieval();" [disabled]="requestedFromArchive" i18n>
                            Submit
                        </button>
                        <button *ngIf="isRequestRetrieve" mat-raised-button (click)="isRequestRetrieve = false"
                                [disabled]="requestedFromArchive" i18n>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!document">
                <div class="col-md-12">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
            </div>

        </div>

        <div id="editMetaDataContainer" #scrollPoint
             class="row justify-content-center mt-3 mr-1 ml-1 editMetaDataContainer" *ngIf="editMetaData">
            <div class="col-lg-10">
                <div class="card">
                    <div class="card-header">
                        <button class="smx-critical-button mr-1 mb-1"
                                (click)="editMetaData = !editMetaData" i18n>
                            Cancel
                        </button>
                        <button class="smx-primary-button mr-1 mb-1"
                                [disabled]="isSavingMetadata"
                                (click)="onSaveMetadata()" i18n>
                            Save
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-md-3 col-lg-3">
                                        <label class="details-label" i18n>Title:</label>
                                    </div>
                                    <div class="col-md-9 col-lg-8 mb-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <input matInput
                                                   [placeholder]="document.requireDocumentTitle && document.documentTitleRegex ? '' : 'Ex. New Video'"
                                                   #title="ngModel"
                                                   [(ngModel)]="titleToEdit"
                                                   [required]="document.requireDocumentTitle"
                                                   [pattern]="document.requireDocumentTitle ? document.documentTitleRegex : ''"
                                                   (blur)="titleToEditError = validateMetadataTitle()"
                                                   i18n-placeholder>
                                        </mat-form-field>
                                        <div class="mb-2" style="margin-top: -25px;">
                                          <span *ngIf="titleToEditError"
                                                class="error-text" i18n>{{titleToEditError}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-lg-3" style="word-break: break-word;">
                                        <label class="details-label" i18n>Categories:</label>
                                    </div>
                                    <div class="col-md-9 col-lg-8">
                                        <mat-chip-set class="w-100" #chipList aria-label="category selection">
                                            <mat-chip *ngFor="let category of categoryEditList" [removable]="true"
                                                      (removed)="removeCategory(category)" i18n>
                                                {{category.name}}
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-set>

                                        <mat-form-field matLine class="w-100 ca80" appearance="outline">
                                            <mat-select [(ngModel)]="selectedCategory"
                                                        (selectionChange)="addCategory(selectedCategory)">
                                                <mat-option
                                                    *ngFor="let category of categoryList | filterDuplicate:categoryEditList:'name'"
                                                    [value]="category" i18n> {{category.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3 col-lg-3">
                                        <label class="details-label" i18n>Owner:</label>
                                    </div>
                                    <div class="col-md-9 col-lg-8">
                                        <app-user-select class="" [(userId)]="ownerSelect.associatedUserId"
                                                         [(associatedUserName)]="ownerSelect.associatedUser"></app-user-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-lg-3 mt-2">
                                        <label class="details-label" i18n>Important:</label>
                                    </div>
                                    <div class="col-md-9 col-lg-8">
                                        <mat-checkbox class="mr-3" [(ngModel)]="importantToEdit" i18n></mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-md-3 col-lg-3" style="word-break: break-word;">
                                        <label class="details-label" i18n>Description:</label>
                                    </div>
                                    <div class="col-md-9 col-lg-8">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <textarea matInput placeholder="Ex. New Video"
                                                      [(ngModel)]="descriptionToEdit" i18n-placeholder></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-lg-3">
                                        <label class="details-label" i18n>Cases:</label>
                                    </div>
                                    <div class="col-md-9 col-lg-8">
                                        <mat-chip-set class="w-100" #chipList aria-label="case selection">
                                            <mat-chip *ngFor="let case of caseEditList" [removable]="true"
                                                      (removed)="removeCase(case)" i18n>
                                                {{case.title}}
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-set>
                                        <app-case-select class="w-100" [(caseListItem)]="caseListItem"
                                                         [duplicateFilterList]="caseEditList"
                                                         [duplicateFilterProperty]="'title'"
                                                         (change)="addCase()"></app-case-select>
                                        <button class="smx-primary-button create-case-button"
                                                (click)="openCreateCaseDialog()" i18n>
                                            Create New Case
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-md-3 col-lg-3">
                                        <label class="details-label" i18n>Tags:</label>
                                    </div>
                                    <div class="col-md-9 col-lg-8">
                                        <mat-form-field matLine class="w-100 ca80" appearance="outline">
                                            <mat-select [(ngModel)]="selectedTag"
                                                        (selectionChange)="addTag(selectedTag)">
                                                <mat-option *ngFor="let tag of tagsList" [value]="tag"
                                                            [disabled]="!tag.isActive" i18n>
                                                    {{tag.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-tag-types *ngFor="let tag of tagsEditList; let i = index" [tag]=tag
                                                       (deleteClick)="removeTag(i)"></app-tag-types>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
