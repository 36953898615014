/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BC300DeviceSettings } from './bC300DeviceSettings';


export interface DeviceRegistrationResponse { 
    deviceKey?: string | null;
    name?: string | null;
    assignedUserId?: number | null;
    assignedUserName?: string | null;
    isRegistered?: boolean;
    isEnabled?: boolean;
    config?: BC300DeviceSettings;
}

