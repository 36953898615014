<div class="outer-container">
    <div class="inner-container">
        <div class="card mt-2 p-3">
            <div class="row">
                <div *ngIf="loading" class="col">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
                <div *ngIf="!loading" class="col-sm-9 col-md-8 col-lg-5 col-xl-4">
                    <h2>{{config.name}} <small i18n>Edit</small></h2>
                    <hr />
                    <div *ngIf="errMessage && errMessage.length>0" class="alert alert-danger">
                        <ul>
                            <li *ngFor="let err of errMessage">{{err}}</li>
                        </ul>
                    </div>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Video Mode</mat-label>
                        <select matNativeControl [(ngModel)]="config.videoMode">
                            <optgroup title="30fps" label="30fps">
                                <option value="W1080P-30fps" i18n>W1080P (default)</option>
                                <option value="1080P-30fps">1080P</option>
                                <option value="1296P-30fps">1296P</option>
                                <option value="720P-30fps">720P</option>
                                <option value="720P-30fps B+" i18n>720P Battery+</option>
                                <option value="480P-30fps">480P</option>
                                <option value="480P-30fps B+" i18n>480P Battery+</option>
                                <option value="VGA-30fps">VGA</option>
                            </optgroup>
                            <optgroup title="45fps" label="45fps">
                                <option value="1080P-45fps">1080P</option>
                            </optgroup>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Video Quality</mat-label>
                        <select matNativeControl [(ngModel)]="config.videoQuality">
                            <option value="Good" i18n>Good</option>
                            <option value="Better" i18n>Better</option>
                            <option value="Best" i18n>Best</option>
                        </select>
                    </mat-form-field>
                    <div class="d-flex flex-column mb-4">
                        <label id="preEventRecord-label" i18n>Pre-Event Record</label>
                        <mat-radio-group aria-labelledby="preEventRecord-label" [(ngModel)]="config.preEventRecord"
                            name="preEventRecord">
                            <mat-radio-button [value]="'Off'">
                                <label class="mt-2">Off</label>
                            </mat-radio-button>
                            <mat-radio-button class="ml-4" [value]="'On'" (ngModelChange)="preEventRecordChanged($event)">
                                <label class="mt-2">On</label>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Post-Event Record</mat-label>
                        <select matNativeControl [(ngModel)]="config.postEventRecord">
                            <option value="Off" i18n>Off</option>
                            <option value="5-Sec." i18n>5 Sec.</option>
                            <option value="10-Sec." i18n>10 Sec.</option>
                            <option value="30-Sec." i18n>30 Sec.</option>
                            <option value="200-Sec." i18n>200 Sec.</option>
                        </select>
                    </mat-form-field>
                    <div class="d-flex flex-column mb-4">
                        <label id="preEventMode-label" i18n>Pre-Event Mode</label>
                        <mat-radio-group aria-labelledby="preEventMode-enabled" name="preEventMode"
                            [(ngModel)]="config.preEventMode">
                            <mat-radio-button class="mr-4" [value]="'Video-Only'">
                                <fa-icon class="mt-2" [icon]="'video'"></fa-icon>
                                <fa-icon class="ml-1 mr-2 mt-2" [icon]="'microphone-slash'"></fa-icon>
                                <label class="mt-2">Video Only</label>
                            </mat-radio-button>
                            <mat-radio-button [value]="'Video/Audio'">
                                <fa-icon class="mt-2" [icon]="'video'"></fa-icon>
                                <fa-icon class="ml-1 mr-2 mt-2" [icon]="'microphone'"></fa-icon>
                                <label class="mt-2">Video/Audio</label>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="d-flex flex-column mb-4">
                        <label id="recordMode-label" i18n>Record Mode</label>
                        <mat-radio-group aria-labelledby="recordMode-enabled" name="recordMode" [(ngModel)]="config.recordMode">
                            <mat-radio-button class="mr-4" [value]="'Video-Only'">
                                <fa-icon class="mt-2" [icon]="'video'"></fa-icon>
                                <fa-icon class="ml-1 mr-2 mt-2" [icon]="'microphone-slash'"></fa-icon>
                                <label class="mt-2">Video Only</label>
                            </mat-radio-button>
                            <mat-radio-button [value]="'Video/Audio'">
                                <fa-icon class="mt-2" [icon]="'video'"></fa-icon>
                                <fa-icon class="ml-1 mr-2 mt-2" [icon]="'microphone'"></fa-icon>
                                <label class="mt-2">Video/Audio</label>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="checkbox mb-4">
                        <mat-checkbox name="motionRecording" [(ngModel)]="config.motionRecording"
                            [disabled]="config.preEventRecord==='On'" i18n>
                            <label class="mt-2">Motion Recording</label>
                        </mat-checkbox>
                        <!-- <label><input type="checkbox" name="motionRecording" [(ngModel)]="config.motionRecording"
                            [disabled]="config.preEventRecord==='On'" />Motion Recording</label> -->
                    </div>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Photo Resolution</mat-label>
                        <select matNativeControl [(ngModel)]="config.photoResolution">
                            <option value="34M">34M</option>
                            <option value="23M">23M</option>
                            <option value="18M">18M</option>
                            <option value="13M">13M</option>
                            <option value="9M">9M</option>
                            <option value="6M">6M</option>
                            <option value="4M">4M</option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Photo Quality</mat-label>
                        <select matNativeControl [(ngModel)]="config.photoQuality">
                            <option value="Good" i18n>Good</option>
                            <option value="Better" i18n>Better</option>
                            <option value="Best" i18n>Best</option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Burst Photo Capture</mat-label>
                        <select matNativeControl [(ngModel)]="config.burstPhotoCapture"
                            [disabled]="config.preEventRecord==='On'">
                            <option value="Off" i18n>Off</option>
                            <option value="2pcs" i18n>2 Frames/Sec</option>
                            <option value="3pcs" i18n>3 Frames/Sec</option>
                            <option value="5pcs" i18n>5 Frames/Sec</option>
                            <option value="10pcs" i18n>10 Frames/Sec</option>
                            <option value="15pcs" i18n>15 Frames/Sec</option>
                            <option value="20pcs" i18n>20 Frames/Sec</option>
                            <option value="30pcs" i18n>30 Frames/Sec</option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Auto Exposure</mat-label>
                        <select matNativeControl [(ngModel)]="config.autoExposure">
                            <option value="-2.0">-2.0</option>
                            <option value="-1.7">-1.7</option>
                            <option value="-1.3">-1.3</option>
                            <option value="-0.7">-0.7</option>
                            <option value="-0.3">-0.3</option>
                            <option value="0.0">0.0</option>
                            <option value="+0.3">+0.3</option>
                            <option value="+0.7">+0.7</option>
                            <option value="+1.3">+1.3</option>
                            <option value="+1.7">+1.7</option>
                            <option value="+2.0">+2.0</option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>Auto Power Off</mat-label>
                        <select matNativeControl [(ngModel)]="config.autoPowerOff">
                            <option value="Off" i18n>Off</option>
                            <option value="1-Min." i18n>1 Min.</option>
                            <option value="2-Min." i18n>2 Min.</option>
                            <option value="3-Min." i18n>3 Min.</option>
                            <option value="5-Min." i18n>5 Min.</option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>LCD Auto Off</mat-label>
                        <select matNativeControl [(ngModel)]="config.lCDAutoOff">
                            <option value="Off" i18n>Off</option>
                            <option value="30-Sec." i18n>30 Sec.</option>
                            <option value="1-Min." i18n>1 Min.</option>
                            <option value="3-Min." i18n>3 Min.</option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>LCD Brightness</mat-label>
                        <select matNativeControl [(ngModel)]="config.lCDBrightness" convert-to-number>
                            <option value="8" i18n>1 Low</option>
                            <option value="16">2</option>
                            <option value="24">3</option>
                            <option value="32">4</option>
                            <option value="40">5</option>
                            <option value="48" i18n>6 Default</option>
                            <option value="56">7</option>
                            <option value="64">8</option>
                            <option value="72">9</option>
                            <option value="80">10</option>
                            <option value="88">11</option>
                            <option value="96">12</option>
                            <option value="104">13</option>
                            <option value="112">14</option>
                            <option value="120">15</option>
                            <option value="128" i18n>16 High</option>
                        </select>
                    </mat-form-field>

                    <div class="d-flex flex-column mb-4">
                        <label i18n>Sound</label>
                        <mat-checkbox name="powerTones" [(ngModel)]="config.powerTones" i18n><label class="mt-2">Power
                                Tones</label></mat-checkbox>
                        <mat-checkbox name="recordTones" [(ngModel)]="config.recordTones" i18n><label class="mt-2">Record
                                Tones</label></mat-checkbox>
                        <mat-checkbox name="buttonTones" [(ngModel)]="config.buttonTones" i18n><label class="mt-2">Button
                                Tones</label></mat-checkbox>
                        <mat-checkbox name="vibration" [(ngModel)]="config.vibration" i18n><label
                                class="mt-2">Vibration</label></mat-checkbox>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label i18n>Speaker Volume</mat-label>
                        <input matInput type="number" min="0" max="16" name="speakerVolume"
                            [(ngModel)]="config.speakerVolume" />
                    </mat-form-field>
                    <!--
        <div class="form-group">
            <label for="wifi" i18n>WIFI</label>
            <div>
                <label class="radio-inline">
                    <input type="radio" name="wifi" id="wifi1" ng-value="false" [(ngModel)]="config.WiFi" i18n>Off
                </label>
                <label class="radio-inline">
                    <input type="radio" name="wifi" id="wifi2" ng-value="true" [(ngModel)]="config.WiFi" i18n>On
                </label>
            </div>
        </div>
            -->
                    <div class="d-flex flex-column mb-4">
                        <label id="colorSystem-label" i18n>Color System</label>
                        <mat-radio-group aria-labelledby="colorSystem-label" name="colorSystem"
                            [(ngModel)]="config.colorSystem">
                            <mat-radio-button class="mr-4" [value]="'NTSC'" i18n><label
                                    class="mt-2">NTSC</label></mat-radio-button>
                            <mat-radio-button [value]="'PAL'" i18n><label class="mt-2">PAL</label></mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label for="videoFileDuration" i18n>Video File Duration</mat-label>
                        <select matNativeControl [(ngModel)]="config.videoFileDuration">
                            <option value="Off" i18n>Off</option>
                            <option value="5-Min." i18n>5 Min.</option>
                            <option value="10-Min." i18n>10 Min.</option>
                            <option value="20-Min." i18n>20 Min.</option>
                            <option value="30-Min." i18n>30 Min.</option>
                        </select>
                    </mat-form-field>

                    <div class="checkbox mb-4">
                        <mat-checkbox name="fileOverwrite" [(ngModel)]="config.fileOverwrite" i18n><label class="mt-2">File
                                Overwrite</label></mat-checkbox>
                    </div>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label i18n>IR Mode</mat-label>
                        <select matNativeControl [(ngModel)]="config.iRMode">
                            <option value="Auto" i18n>Auto</option>
                            <option value="Manual" i18n>Manual</option>
                            <option value="Disable" i18n>Disabled</option>
                        </select>
                    </mat-form-field>
                    <div class="d-flex flex-column mb-4">
                        <label id="main-menu-enabled" i18n>Main Menu</label>
                        <mat-radio-group aria-labelledby="main-menu-enabled" name="mainMenu" [(ngModel)]="config.mainMenu">
                            <mat-radio-button class="mr-4" [value]="false">
                                <fa-icon class="mr-2 mt-2" [icon]="'times'"></fa-icon>
                                <label class="mt-2">Disabled</label>
                            </mat-radio-button>
                            <mat-radio-button [value]="true">
                                <fa-icon class="mr-2 mt-2" [icon]="['far','list-alt']"></fa-icon>
                                <label class="mt-2">Enabled</label>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="d-flex flex-column mb-4">
                        <label id="playbackMenu-label" i18n>Playback Menu</label>
                        <mat-radio-group aria-labelledby="playbackMenu-enabled" name="playbackMenu"
                            [(ngModel)]="config.playbackMenu">
                            <mat-radio-button class="mr-4" [value]="false">
                                <fa-icon class="mr-2 mt-2" [icon]="'times'"></fa-icon>
                                <label class="mt-2">Disabled</label>
                            </mat-radio-button>
                            <mat-radio-button [value]="true">
                                <fa-icon class="mr-2 mt-2" [icon]="['far','list-alt']"></fa-icon>
                                <label class="mt-2">Enabled</label>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="d-flex flex-column mb-4">
                        <label id="watermark-label" i18n>Watermark</label>
                        <mat-radio-group aria-labelledby="watermark-label" name="watermark" [(ngModel)]="config.watermark">
                            <mat-radio-button class="mr-4" [value]="false">
                                <label class="mt-2">Off</label>
                            </mat-radio-button>
                            <mat-radio-button [value]="true">
                                <label class="mt-2">On</label>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-divider></mat-divider>
                    <button mat-button class="smx-primary-button mt-2" (click)="save()" [disabled]="loading" i18n>Save</button>
                    <button mat-button class="smx-critical-button ml-2" routerLink="/admin/devices/bc300configs" i18n>Cancel</button>

                </div>
            </div>
        </div>

    </div>
</div>
