import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-group-permissions-dialog',
  templateUrl: './help-group-permissions-dialog.component.html',
  styleUrls: ['./help-group-permissions-dialog.component.css']
})
export class HelpGroupPermissionsDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
