/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeSpan } from './timeSpan';
import { DocumentPermissionsViewModel } from './documentPermissionsViewModel';


export interface DeviceDetailTransferModel { 
    id?: number;
    completed?: string;
    created?: string | null;
    transferTime?: TimeSpan;
    transferTimeSeconds?: number;
    filename?: string | null;
    contentLength?: number;
    documentId?: string;
    isAlarm?: boolean;
    permissions?: DocumentPermissionsViewModel;
}

