<h2 mat-dialog-title i18n>Create New Case</h2>
<hr/>

<div class="p-3">
    <mat-label class="pr-3" i18n>Title</mat-label>
    <mat-form-field class="w-100">
        <input matInput required [(ngModel)]="caseCreateVM.title">
    </mat-form-field>

    <div >
      <mat-label class="pr-3" i18n>Description</mat-label>

      <mat-form-field class="w-100" appearance="outline">
        <textarea  matInput placeholder="(Optional)" [(ngModel)]="caseCreateVM.description" i18n-placeholder></textarea>
      </mat-form-field>
    </div>

</div>
<mat-dialog-actions align="end">
    <button mat-button class="smx-critical-button mr-2" mat-dialog-close cdkFocusInitial i18n>Cancel</button>
    <button mat-button class="smx-primary-button" (click)="onClose()" [disabled]="caseCreateVM.title === undefined || caseCreateVM.title === ''" i18n>Save</button>
  </mat-dialog-actions>
