/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BC300InfoSettings { 
    batteryPower?: number;
    freeSpace?: number;
    totalSpace?: number;
    firmwareVersion?: string | null;
    hardwareVersion?: string | null;
    model?: string | null;
    serialNumber?: string | null;
    userID?: string | null;
}

