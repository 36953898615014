import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { CasesListViewModel, CasesService } from 'src/app/services/api2';

@Component({
    selector: 'app-case-select2',
    templateUrl: './case-select2.component.html',
    styleUrls: ['./case-select2.component.css'],
})
export class CaseSelect2Component implements OnInit, OnChanges {
    @ViewChild('caseInput', { static: false }) caseSelectField: ElementRef;

    // Optional input for disabling the dropdown box.
    @Input() disabled = false;
    @Output() change = new EventEmitter<any>();

    // Binds a case list item that will be changed on the parent component.
    @Input() caseListItem: CasesListViewModel;
    @Output() caseListItemChange = new EventEmitter<any>();

    // Binds to a list that will be used to filter out results that have already
    // been selected by a user.
    @Input() duplicateFilterList: CasesListViewModel[] = [];
    @Input() duplicateFilterProperty: string = '';

    caseList: CasesListViewModel[] = []; // Stores the list of cases from the server.

    constructor(
        private apiService: SecuraMaxApiService,
        private toastr: SnackbarService,
        private casesService: CasesService
    ) {}

    /**
     * Initially fill the caseList so it can be used in the for loop.
     */
    ngOnInit(): void {
        this.casesService.apiApiCasesSearchV2Get('', 10, true).subscribe(
            (data) => {
                this.caseList = data;
            },
            () => {
                this.toastr.error('Error occurred getting list of cases.');
            }
        );
    }

    /**
     * Emits event when one of the properties has changed.
     * @param changes The object that has changed.
     */
    ngOnChanges() {
        this.change.emit();
    }

    /**
     * Receive user input and send to search method.
     * @param value String search term.
     */
    onKey(value) {
        this.casesService.apiApiCasesSearchV2Get(value, 10, true).subscribe(
            (data) => {
                this.caseList = data;
            },
            () => {
                this.toastr.error('Error occurred getting list of cases.');
            }
        );
    }

    /**
     * Used when user selects a new case from the drop down list.
     * Will emit that the variable passed in will have updated data.
     */
    selectedCaseChanged() {
        if (this.caseListItem.title != null)
            this.caseListItemChange.emit(this.caseListItem);
    }

    /**
     * Used to determine when the select drop down closed. If it closed with
     * the data list being empty it will re-populate with an empty user
     * so the drop down is still visible.
     *
     * Also, used to set focus to the search input on drop down open.
     *
     * @param event Boolean - True drop down opened, False drop down closed.
     */
    dropDownChanged(event) {
        this.caseSelectField.nativeElement.focus();

        if (this.caseList.length == 0 && !event) {
            this.caseList.push({});
        }
    }
}
