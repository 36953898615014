import { Component, Input } from '@angular/core';
import { WifiNetwork } from '../../../services/api/securamaxapi.service';

@Component({
    selector: 'bc4-wifi',
    templateUrl: './bc4-wifi.component.html',
    styleUrls: ['./bc4-wifi.component.css']
})
export class Bc4WifiComponent {
    @Input() wifiNetworks: WifiNetwork[]; // = [ { ssid: 'test', password: 'test' } ];

    hide = true;

    addNetwork(): void {
        this.wifiNetworks.push(new WifiNetwork());
    }

    removeNetwork(index: number): void {
        this.wifiNetworks.splice(index, 1);
    }
}
