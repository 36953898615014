<div class="card mt-3">
    <div class="card-body card-container">
        <div class="d-flex justify-content-between">
            <h1 i18n>Upload Stations</h1>
            <div>
                <button mat-button class="smx-secondary-button" (click)="downloadDrivers()" i18n>Download Drivers
                </button>
                <button mat-button class="smx-secondary-button ml-1" (click)="downloadUploader()" i18n>Download Windows
                    Upload
                    Software
                </button>

                <button mat-button class="smx-primary-button ml-1" (click)="add()" i18n>Add</button>
            </div>
        </div>


        <div class="outer-container mt-1">

            <div class="d-flex justify-content-between mb-2">
                <small i18n>Upload Stations that are disabled will not upload new videos.</small>
            </div>

            <table mat-table [dataSource]="uploadLocationsList" class="mat-elevation-z8 w-100">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef i18n> Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Last Connected Column -->
                <ng-container matColumnDef="lastConnected">
                    <th mat-header-cell *matHeaderCellDef i18n> Last Connected</th>
                    <td mat-cell *matCellDef="let element"> {{element.lastConnectedIPAddress}} </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef i18n> Status</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-slide-toggle [checked]="element.enabled"
                                          (change)="element.enabled = !element.enabled; toggleStateOfStation(element)">
                                <span class="statusText" *ngIf="element.enabled" i18n>
                                    <label class="mt-2">Enabled</label>
                                </span>
                            <span class="statusText" *ngIf="!element.enabled" i18n>
                                  <label class="mt-2">Disabled</label>
                                </span>
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <!-- Modify Column -->
                <ng-container matColumnDef="modifyButton">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div class="header-align-right">
                            <button mat-button class="smx-secondary-button" (click)="openModifyDialog(element)"
                                    i18n>Modify
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
