import {animate, state, style, transition, trigger} from '@angular/animations';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {
    DeviceDetailsModel,
    DeviceDetailTransferModel,
    RequestResponseModel,
    SecuraMaxApiService
} from 'src/app/services/api/securamaxapi.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {TitleService} from 'src/app/services/title.service';
import {parseRange} from 'src/app/util/common-functions';
import {environment} from "../../../../environments/environment";
import {
    CreateRequestDialogComponent
} from "../../../shared/components/create-request-dialog/create-request-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-devices-details',
    templateUrl: './devices-details.component.html',
    styleUrls: ['./devices-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class DevicesDetailsComponent implements OnInit {

    deviceDetails: DeviceDetailsModel = new DeviceDetailsModel(); // Initial device details.
    deviceId: string; // ID of the device we are displaying.

    // Paging for Requests.
    displayedColumnsRequests: string[] = ['chevron', 'created', 'cameras', 'requestedStart', 'requestedEnd', 'completed', 'filesTransfer', 'cancel']; // Header for the table columns
    resultsLengthRequests: number = 0;
    requestsInfoList: RequestResponseModel[];     // List of files requested.

    // Paging for Transfers/
    displayedColumns: string[] = ['uploaded', 'file', 'created', 'fileSize', 'transferSpeed']; // Header for the table columns
    pageSizeOptions: number[] = [5, 10, 25, 100];    // Options for # items per page.
    pageEvent: PageEvent = new PageEvent();
    resultsLengthTransfers: number = 0;              // 0 is arbitrary, this value will get loaded OnInit.
    transfersInfoList: DeviceDetailTransferModel[]; // List of files transfered.

    constructor(
        private titleService: TitleService,
        private apiService: SecuraMaxApiService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: SnackbarService
    ) {
    }

    ngOnInit(): void {
        this.titleService.setTitle('Device Details');

        this.deviceId = this.route.snapshot.paramMap.get("id")
        this.pageEvent.pageIndex = 0; //The zero-based page index of the displayed list of items. Defaulted to 0.
        this.pageEvent.pageSize = 10; // Initial number of items to show on a page.

        this.apiService.device_Details(this.deviceId).subscribe(data => {
            this.deviceDetails = data;
            this.titleService.setTitle('Device ' + this.deviceDetails.serial + ' Details');
        }, (err) => {
            if (err.status == 404) {
                this.router.navigate(['/admin/devices/list'])
            } else
                this.toastr.error(err.body.Message);
        });

        this.onTransferPageFired(this.pageEvent);
        this.onRequestsPageFired(this.pageEvent);
    }

    /**
     * When a page event fires, this retrieves the next set of data from the
     * server depending on page index.
     *
     * @param event PageEvent that contains the page index, page size, etc.
     */
    onTransferPageFired(event) {
        let from = (event.pageIndex * event.pageSize);
        let to = from + event.pageSize - 1;

        this.apiService.device_GetDeviceTransfers(this.deviceId, from, to, this.pageEvent.length, this.pageEvent.pageIndex, this.pageEvent.pageSize, "", "").subscribe(tData => {
            if (tData.status === 204) {
                this.transfersInfoList = [];
                this.resultsLengthTransfers = 0;
            } else {
                let contentRange = parseRange(tData.headers['content-range']);
                this.transfersInfoList = tData.result;
                this.resultsLengthTransfers = contentRange.total;
            }
        }, (err: HttpErrorResponse) => {
            this.toastr.error(err.message);
        });
    }

    onRequestsPageFired(event) {
        let from = (event.pageIndex * event.pageSize);
        let to = from + event.pageSize - 1;

        this.apiService.device_GetRequestTransfer(this.deviceId, from, to, this.resultsLengthRequests, 1, event.pageSize, "", "").subscribe(data => {
            if (data.status === 204) {
                this.requestsInfoList = [];
                this.resultsLengthRequests = 0;
            } else {
                let contentRange = parseRange(data.headers['content-range']);
                this.requestsInfoList = data.result;
                this.resultsLengthRequests = contentRange.total;
            }
        }, (err: HttpErrorResponse) => {
            this.toastr.error(err.message);
        });
    }

    prettyPrintBytes(bytes: number) {
        if (bytes == 0) {
            return "N/A";
        }
        var si = true;
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }

    transferSpeed() {
        if (!this.deviceDetails)
            return 0;

        if (this.deviceDetails.totalTimeUploadingSeconds === undefined || this.deviceDetails.totalTimeUploadingSeconds === 0 || this.deviceDetails.totalBytesTransferred === undefined) {
            return "N/A";
        }

        return this.prettyPrintBytes(this.deviceDetails.totalBytesTransferred / this.deviceDetails.totalTimeUploadingSeconds) + "/s";
    }

    createTransfer() {
        if (environment.featureFlag_newCreateRequestDialog) {
            const dialogRef = this.dialog.open(CreateRequestDialogComponent, {
                data: {id: this.deviceId},
            });
            dialogRef.afterClosed().subscribe((result: any) => {
            });
        } else {
            this.router.navigateByUrl(`/transfer/${this.deviceDetails.deviceId}/request`);
        }
    }

    cancelRequest(requestId) {
        var that = this;
        this.apiService.device_PutCancelRequest(requestId).subscribe((data) => {
            that.toastr.success("Successfully canceled request.");

            // Update the status so the cancel button is removed.
            var request = that.requestsInfoList.find(req => req.id == requestId);
            if (request)
                request.status = data;
        }, (err) => {
            that.toastr.error(err.body.Message);
        });
    }

}
