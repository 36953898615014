import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SecuraMaxApiService, TimeBasedTagDetailsViewModel } from 'src/app/services/api/securamaxapi.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpReviewByScheduleDialogComponent } from '../review-by-time-help-dialog/review-by-time-help-dialog.component';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
    selector: 'app-review-by-time-details',
    templateUrl: './review-by-time-details.component.html',
    styleUrls: ['./review-by-time-details.component.css']
})
export class ReviewByScheduleDetailsComponent implements OnInit {
    datasource: TimeBasedTagDetailsViewModel = new TimeBasedTagDetailsViewModel();
    id: number;
    isActiveError: boolean;
    isReviewExpired: boolean = false;

    constructor(private securaMaxApiService: SecuraMaxApiService, private titleService: TitleService, private route: ActivatedRoute, public dialog: MatDialog, private toastr: SnackbarService) {
        this.id = Number(this.route.snapshot.paramMap.get('id'));
    }

    ngOnInit(): void {
        this.titleService.setTitle('Review by Schedule');
        var vm = this;
        this.securaMaxApiService.timeBasedTags_Details(this.id).subscribe(function (data) {
            vm.datasource = data;
            vm.isExpired();
        }, (err) => {
            this.toastr.error('Error occurred while getting the time based tags.');
        });
    }

    isExpired(): void {
        if (this.datasource.expiration === undefined) {
            this.isReviewExpired = false;
        }
        this.isReviewExpired = (this.datasource.expiration < new Date());
    }

    onSetState(state: boolean) {
        var vm = this;
        this.datasource.isActive = state;

        this.securaMaxApiService.timeBasedTags_Post(this.datasource).subscribe(function (data) {
            vm.isActiveError = null;
        }, (err) => {
            this.toastr.error('Error occurred while posting the time based tag.');
        });
    }

    openHelpDialog() {
        const dialogRef = this.dialog.open(HelpReviewByScheduleDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
        });
    }

}
