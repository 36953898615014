import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CreateCaseComponent } from './create-case/create-case.component';
import { SecuraMaxApiService, CategoryViewModel, CasesListViewModel, TagViewModel, DocumentMetadataViewModel } from '../../services/api/securamaxapi.service';
import { ReviewService } from '../../services/review.service';
import { DialogResult, DialogResultData } from '../../models/dialog-result.model';
import { DocumentMetadataStatusSelectedViewModel } from '../shell/shell.component';
import { ProgressSpinnerDialogComponent } from '../../shared/components/progress-spinner-dialog/progress-spinner-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
    selector: 'app-metadata',
    templateUrl: './metadata.component.html',
    styleUrls: ['./metadata.component.css']
})
export class MetadataComponent implements OnInit {

    // Stores the items retrieved from server.
    casesList: CasesListViewModel[]
    categoriesList: CategoryViewModel[];
    tagsList: TagViewModel[];

    // Stores the selected item value from user.
    categoriesSelectedList: number[] = [];

    documentCheckedList: DocumentMetadataStatusSelectedViewModel[] = []
    documentMetadataList: DocumentMetadataViewModel[] = [];

    isSaveButtonEnabled: boolean = false;

    // Form Group.
    metadataForm = this.fb.group({
        title: [''],
        description: [''],
        tags: this.fb.array([]),
        categoriesForm: new UntypedFormControl(this.categoriesSelectedList),
        casesForm: new UntypedFormControl([])
    });
    constructor(private apiService: SecuraMaxApiService, private fb: UntypedFormBuilder, private reviewService: ReviewService, private caseDialog: MatDialog, private toastr: SnackbarService) { }

    ngOnInit(): void {
        this.apiService.tags_GetAll().subscribe(tags => {
            this.tagsList = tags;
        }, (err) => {
            this.toastr.error('Error occurred while getting tags');
        });

        this.apiService.categories_GetAll().subscribe(categories => {
            this.categoriesList = categories;
        }, (err) => {
            this.toastr.error('Error occurred while getting categories');
        });

        this.apiService.cases_Search("", 100).subscribe(cases => {
            this.casesList = cases;
        }, (err) => {
            this.toastr.error('Error occurred while getting cases');
        });

        // Subscribes to the shell components review list for any item that are checked will
        // enable/disable the 'save' button.
        this.reviewService.reviewDocumentsChanged.subscribe(value => {
            this.documentCheckedList = value;

            if (value.length > 0)
                this.isSaveButtonEnabled = true;
            else
                this.isSaveButtonEnabled = false;
        });
    }

    /**
     * When a tag item is selected in the drop down box, a new form control is added
     * to the metadata form to allow for input.
     * 
     * tagValue - The value of the selected tag.
     * name - The name of the selected tag to be displayed to the user.
     * 
     * @param tag - Tag item that was selected by the user.
     */
    tagSelectionChange(tag) {
        let group = new UntypedFormGroup({
            'tagValue': new UntypedFormControl(''),
            'name': new UntypedFormControl(tag.name)
        });
        this.tags.push(group);
    }

    /**
     * Removes tag from tag group of form.
     * 
     * @param index - Index of the selected tag that is to be removed from the form.
     */
    deleteTag(index) {
        this.tags.removeAt(index);
    }

    get tags() {
        return this.metadataForm.get('tags') as UntypedFormArray;
    }

    /**
     * Creates a modal dialog for generating a new case.
     * */
    createNewCase() {
        const dialogRef = this.caseDialog.open(CreateCaseComponent);
        dialogRef.afterClosed().subscribe((data: DialogResultData) => {
            if (data.result == DialogResult.ok) {
                // Refresh the cases list with the new case.
                this.apiService.cases_Search("", 100).subscribe(cases => {
                    this.casesList = cases;

                    // Get the new case and auto add it to the selecte cases.
                    let caseItem = this.casesList.find(x => x.title == data.data)
                    if (caseItem) {
                        this.metadataForm.controls['casesForm'].value.push(caseItem.id);
                    }
                }, (err) => {
                    this.toastr.error('Error occurred while getting cases');
                });
            }
        });
    }

    /**
     * Saves the metadata to the server.
     * */
    saveMetadata() {
        let dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.caseDialog.open(ProgressSpinnerDialogComponent, {
            panelClass: 'transparent',
            disableClose: true
        });

        // Emit that the save button was clicked so the review list will refresh.
        this.reviewService.isSaveButtonClick.next(true);

        this.apiService.metadata_Get(this.documentCheckedList.map(d => d.id)).subscribe(
            result => {
                dialogRef.close();
            },
            error => {
                this.toastr.error('Error occurred while getting the document metadata.');
                dialogRef.close();
            });
    }
}
