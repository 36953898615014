<div class="card mt-3">
    <div class="card-body outer-container">
        <div class="d-flex justify-content-between">
            <h1 i18n>Create Case</h1>
            <div>
                <button mat-button class="smx-secondary-button help ml-1" i18n>
                    <i class="fas fa-question-sign"></i>
                    <fa-icon [icon]="['fas','question-circle']"></fa-icon>
                    Help
                </button>
            </div>
        </div>

        <form class="case-controls-container" [formGroup]="form">


            <div>
                <div class="case-information-controls-container ">
                    <h2>Case Information</h2>

                    <mat-form-field style="grid-column: span 2;">
                        <mat-label>Case Identifier</mat-label>
                        <input matInput type="text" required [formControl]="form.controls.identifier">
                        <mat-error *ngIf="form.controls.identifier.value === undefined || form.controls.identifier.value === ''">Case Identifier is required.</mat-error>
                    </mat-form-field>

                    <mat-form-field style="grid-column: span 2;">
                        <mat-label>Description</mat-label>
                        <textarea matInput [formControl]="form.controls.description"></textarea>
                    </mat-form-field>

                    <mat-radio-group class="case-information-retention-choices-container"
                                     [formControl]="form.controls.useCaseRetention">
                        <mat-radio-button class="mb-2" style="grid-column: span 2;" [value]="false" i18n>
                            <label>Use Document Retention</label>
                        </mat-radio-button>

                        <mat-radio-button class="d-flex" [value]="true" i18n>
                            <label>Use Case Retention</label>
                        </mat-radio-button>
                        <mat-form-field style="margin-left: 26px;">
                            <mat-label i18n>
                                # of Days
                            </mat-label>
                            <input type="number" [formControl]="form.controls.caseRetentionDays" [disabled]="form.controls.useCaseRetention.value === false" matInput
                                   placeholder="0"/>
                        </mat-form-field>
                    </mat-radio-group>


                    <mat-slide-toggle class="mt-3" style="grid-column: span 2; margin-left: 10px;"
                                      [formControl]="form.controls.canEditsBeSharedByUsers" i18n>Enable Case Editing For
                        Shared Users?
                    </mat-slide-toggle>
                </div>
            </div>


            <div class="document-selection-container">
                <h2 style="grid-column: span 2;">Document Selection</h2>

                <div class="search-controls filter-settings-container">
                    <app-searchbar (search)="search($event)"></app-searchbar>
                    <div class="label-and-control-container ml-3">
                        <label>Date and Time Range</label>
                        <app-daterangepicker #dateRangePicker (change)="daterangepicker_change($event)" [(start)]="daterangepicker_start" [(end)]="daterangepicker_end"></app-daterangepicker>
                    </div>
                </div>
                <div class="document-list">
                    <h3>Search Results</h3>
                    <div class="dropscroll-area-0" style="height: 475px; margin-right: 1rem;">
                        <div class="dropscroll-area-1">
                            <div *ngFor="let document of possibleDocuments">
                                <mat-card>
                                    <mat-card-content class="document-item">
                                        <app-doc-thumbnail [id]="document.id"></app-doc-thumbnail>
                                        <div *ngIf="!checkIfDocumentSelected(document)" class="d-flex w-100 justify-content-end">
                                            <button mat-mini-fab
                                                    class="smx-primary-button"
                                                    (click)="selectDocument(document)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                        <div style="grid-column: span 2">
                                            <strong i18n>Owner: </strong>{{document.ownerName}}<br/>
                                            <strong i18n>File: </strong>{{document.name}}<br/>
                                            <strong i18n>Created: </strong>{{document.created}}<br/>
                                            <div *ngIf="document.categories.length != 0">
                                                <strong>Categories: </strong>
                                                <label *ngFor="let cat of document.categories" class="badge badge-default"
                                                       i18n>{{cat}}</label>
                                            </div>
                                            <div *ngIf="document.statusId === documentStatus.archived">
                                                <label class="badge badge-info" i18n>Archived</label>
                                            </div>
                                        </div>
                                        <div *ngIf="(document.children !== undefined && document.children.length > 0)"
                                             class="document-item-child-container">
                                            <div *ngFor="let redact of document.children">
                                                <div class="d-flex justify-content-between" style="margin-top: 1rem;">
                                                    <div>
                                                        <app-doc-thumbnail [id]="redact.id"></app-doc-thumbnail>
                                                        <strong i18n>Owner: </strong>{{redact.ownerName}}<br/>
                                                        <strong i18n>File: </strong>{{redact.name}}<br/>
                                                        <strong i18n>Created: </strong>{{redact.created}}<br/>
                                                        <div *ngIf="redact.categories.length != 0">
                                                            <strong i18n>Categories: </strong>
                                                            <label *ngFor="let cat of redact.categories"
                                                                   class="badge badge-default" i18n>{{cat}}</label>
                                                        </div>
                                                        <div *ngIf="document.statusId === documentStatus.archived">
                                                            <label class="badge badge-info" i18n>Archived</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <mat-paginator #paginator
                                   [length]="resultsLength"
                                   [pageIndex]="pageEvent.pageIndex"
                                   [pageSize]="pageEvent.pageSize"
                                   [pageSizeOptions]="pageSizeOptions"
                                   (page)="onPageFired($event)">
                    </mat-paginator>
                </div>

                <div class="document-list">
                    <h3>Documents In Case</h3>
                    <div class="dropscroll-area-0" style="height: 475px; margin-right: 1rem; grid-row: span 2;">
                        <div class="dropscroll-area-1">
                            <div *ngFor="let document of selectedDocuments">
                                <mat-card>
                                    <mat-card-content class="document-item">
                                        <app-doc-thumbnail [id]="document.id"></app-doc-thumbnail>
                                        <div class="d-flex w-100 justify-content-end">
                                            <button *ngIf="document.isDeletionLocked" mat-mini-fab
                                                    style="background-color:rgb(123, 201, 89); color:white;"
                                                    (click)="toggleDocumentLock(document)">
                                                <mat-icon>lock_outline</mat-icon>

                                            </button>
                                            <button *ngIf="!document.isDeletionLocked" mat-mini-fab
                                                    style="background-color:rgb(123, 201, 89); color:white;"
                                                    (click)="toggleDocumentLock(document)">
                                                <mat-icon>lock_open</mat-icon>
                                            </button>
                                            <button mat-mini-fab style="margin-left: 4px;" (click)="deselectDocument(document)">
                                                <mat-icon>remove</mat-icon>
                                            </button>

                                        </div>
                                        <div style="grid-column: span 2">
                                            <strong i18n>Owner: </strong>{{document.ownerName}}<br/>
                                            <strong i18n>File: </strong>{{document.name}}<br/>
                                            <strong i18n>Created: </strong>{{document.created}}<br/>
                                            <div *ngIf="document.statusId === documentStatus.archived">
                                                <label class="badge badge-info" i18n>Archived</label>
                                            </div>
                                        </div>
                                        <div *ngIf="(document.children !== undefined && document.children.length > 0)"
                                             class="document-item-child-container">
                                            <div *ngFor="let redact of document.children">
                                                <div class="d-flex justify-content-between" style="margin-top: 1rem;">
                                                    <div>
                                                        <app-doc-thumbnail [id]="redact.id"></app-doc-thumbnail>
                                                        <strong i18n>Owner: </strong>{{redact.ownerName}}<br/>
                                                        <strong i18n>File: </strong>{{redact.name}}<br/>
                                                        <strong i18n>Created: </strong>{{redact.created}}<br/>
                                                        <div *ngIf="redact.categories.length != 0">
                                                            <strong i18n>Categories: </strong>
                                                            <label *ngFor="let cat of redact.categories"
                                                                   class="badge badge-default" i18n>{{cat}}</label>
                                                        </div>
                                                        <div *ngIf="document.statusId === documentStatus.archived">
                                                            <label class="badge badge-info" i18n>Archived</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="buttons-container">

                <button mat-button class="smx-critical-button cancel-save-case mt-3" (click)="OnCancel()" [disabled]="isSaving" i18n>Cancel</button>
                <button mat-button class="smx-primary-button save-case mt-3 ml-2" (click)="OnSave()" [disabled]="isSaving || !form.valid" i18n>Save</button>
            </div>


        </form>

    </div>
</div>
