import { Component, OnInit, HostListener, ChangeDetectorRef, NgZone } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { RedactionItem, DocumentDetailsViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
    selector: 'app-redaction-advanced-create',
    templateUrl: './redaction-advanced-create.component.html',
    styleUrls: ['./redaction-advanced-create.component.css']
})
export class RedactionAdvancedCreateComponent implements OnInit {
    id: number;
    redactionId: number;
    documentId: string;
    document: DocumentDetailsViewModel;
    redaction: RedactionItem;
    iframeUrl: SafeUrl;
    newId: string;
    showGeneratingProgress: boolean = false;
    showGeneratingProgressValue: number = 0;
    isServerError: boolean = false;
    initialLoadComplete: boolean = false;

    @HostListener('window:message', ['$event'])
    onMessage(event) {
        let that = this;
        if (!event.data) {
            return;
        }

        if (event.data.substring(3, 9) === 'action') {
            const eventRes = JSON.parse(event.data);
            switch (eventRes.action) {
                case 'returned_metadata': {
                    const videoID = eventRes.video_id;
                    let metadata = eventRes.metadata;
                    metadata = JSON.parse(metadata);
                    metadata.videoID = videoID;
                    console.log('smx: metadata loaded');
                    // Store the metadata in your data store for future audit purposes
                    break;
                }
                case 'returned_generate_redacted_video_progress': {
                    const progress = parseInt(eventRes.progress, 10);
                    that.zone.run(() => {
                        that.showGeneratingProgressValue = progress;
                        //vm.$scope.$apply();
                        if (progress === 100) {
                            that.showGeneratingProgress = true;
                            that.securamaxApiService.redaction_CompleteAdvanced(this.redaction.id).subscribe((data) => {
                                //redirect to document details
                            });
                            console.log('smx: redacted video successfully generated');
                        }
                        that.cd.detectChanges();
                    });
                    // User "progress" to show the UI progress bar
                    break;
                }
                case 'video_is_loaded_result': {
                    console.log(eventRes.progress);
                    if (eventRes.result === true) {
                        console.log('smx: video finally loaded');
                        // Video is loaded in the iframe
                    }
                    break;
                }
                default: {
                    console.log('----other event----');
                    console.log(eventRes);
                    // Defaults
                }
            }
        }
    }

    constructor(private securamaxApiService: SecuraMaxApiService,
        private route: ActivatedRoute,
        private titleService: TitleService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private sanitizer: DomSanitizer,
        private toastr: SnackbarService) {
        this.redactionId = Number(this.route.snapshot.paramMap.get('redactionId'));
        this.documentId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
        this.titleService.setTitle('Create Redaction');
        if (this.documentId !== undefined) {
            this.securamaxApiService.documents_Get(this.documentId).subscribe((data) => {
                this.document = data;
            }, (err) => {
                this.toastr.error('Error occurred while getting the document.');
            });
        }

        if (this.redactionId === 0) {
            this.securamaxApiService.redaction_CreateAdvanced(this.documentId).subscribe((data) => {
                if (data.iFrameUrl == '' || !data.iFrameUrl) {
                    this.isServerError = true;
                }
                else {
                    this.redactionId = data.id;

                    this.securamaxApiService.redaction_Get(this.redactionId).subscribe((data2) => {
                        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(decodeURIComponent(data.iFrameUrl));
                        this.redaction = data2;
                        this.initialLoadComplete = true;
                    }, (err) => {
                        this.toastr.error('Error occurred while getting the redaction.');
                    });
                }
            }, (err) => {
                this.toastr.error('Error occurred while creating the redaction.');
            });
        }
        else {
            this.securamaxApiService.redaction_Get(this.redactionId).subscribe((data) => {
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(decodeURIComponent(data.iFrameUrl));//decodeURIComponent(data.iFrameUrl);
                this.redaction = data;
                this.initialLoadComplete = true;
            }, (err) => {
                this.toastr.error('Error occurred while getting the redaction.');
            });
        }
    }

    sendPostMessage(command: string) {
        document.getElementById('mainframe')['contentWindow'].postMessage(JSON.stringify({ action: command }), '*');
    }

    generateRedactedVideo() {
        this.showGeneratingProgress = true;

        this.securamaxApiService.redaction_MarkAsStartedProcessingAdvanced(this.redaction.id).subscribe((data) => {
            document.getElementById('mainframe')['contentWindow'].postMessage(JSON.stringify({ action: 'generate_redacted_video' }), '*');
            this.showGeneratingProgressValue = 0;
        }, function () {
            this.showGeneratingProgress = false;
        });
    }

    private getSuspectEventHandler() {
        var that = this;
        return function (event) {
            
        };
    }
}
