/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceCamerasViewModel } from './deviceCamerasViewModel';


export interface TransferRequestModel { 
    startTime?: string | null;
    endTime?: string | null;
    cameras?: Array<DeviceCamerasViewModel> | null;
}

