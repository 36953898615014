import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) { }

  success(message: string) {
    return this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ['notify-success']
    });
  }

  error(message: string) {
    return this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ['notify-error']
    });
  }
}
