import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AnonymousCredential, BlobServiceClient, newPipeline, BlockBlobClient } from '@azure/storage-blob';
import { SecuraMaxApiService } from '../../services/api/securamaxapi.service';
import { TitleService } from '../../services/title.service';

interface UploadFile {
    file: File;
    progress: number;
    isError: boolean;
    isComplete: boolean;
}


@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

    constructor(private titleService: TitleService, private securamaxApiService: SecuraMaxApiService) { }


    //TODO: support cancellation on these. Right now the uploads will continue.

    ngOnInit(): void {
        this.titleService.setTitle('Upload');
    }

    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    files: UploadFile[] = [];

    /**
     * on file drop handler
     */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files: Array<File>) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        if (this.files[index].progress < 100) {
            //do not allow this when uploading
            return;
        }
        this.files.splice(index, 1);
    }

    async upload(currentFile: UploadFile) {
        try {
            var url = '';
            var sasObj = await this.securamaxApiService.fileUpload_AuthorizeWebUpload(currentFile.file.name).toPromise();
            url = sasObj.url + sasObj.sAS;

            const pipeline = newPipeline(new AnonymousCredential(), {
                retryOptions: { maxTries: 4 }, // Retry options
                userAgentOptions: { userAgentPrefix: "SecuraMax Web" }, // Customized telemetry string
                keepAliveOptions: {
                    // Keep alive is enabled by default, disable keep alive by setting false
                    enable: false
                }
            });

            const client = new BlockBlobClient(url, pipeline);
            const response = await client.uploadBrowserData(currentFile.file, {
                blockSize: 4 * 1024 * 1024, // 4MB block size
                concurrency: 20, // 20 concurrency
                onProgress: (ev) => currentFile.progress = ev.loadedBytes / currentFile.file.size * 100,
                blobHTTPHeaders: { blobContentType: currentFile.file.type }
            });
            const lastModified = currentFile.file.lastModified ? new Date(currentFile.file.lastModified) : currentFile.file["lastModiedDate"] as Date;
            await this.securamaxApiService.fileUpload_CompleteWebUpload(sasObj.id, '', '', lastModified, currentFile.file.size, currentFile.file.name, currentFile.file.type, false, 0).toPromise();
            currentFile.isComplete = true;
        } catch (e) {
            currentFile.isError = true;
            //TODO: do we add an error message
        }

    }
    async uploadAll() {
        try {
            const promises = [];
            for (const fi of this.files) {
                promises.push(this.upload(fi));
            }
            await Promise.all(promises);
        }
        finally {
            this.files.splice(0, this.files.length);
        }
    }
    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    async prepareFilesList(files: Array<File>) {
        for (const item of files) {
            let fi: UploadFile = {
                file: item,
                progress: 0,
                isError: false,
                isComplete: false
            };
            this.files.push(fi);
        }

        this.fileDropEl.nativeElement.value = "";
        await this.uploadAll();
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return "0 Bytes";
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

}
