/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceCode { 
    code?: string | null;
    approved?: boolean;
    created?: string;
    ip?: string | null;
    tenant?: string | null;
    associatedUserId?: number | null;
    enableAlarmTransfers?: boolean;
    configurationId?: number | null;
    isCompletedRegistration?: boolean;
    serial?: string | null;
    deviceName?: string | null;
    deviceId?: string;
}

