/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShareViewModel } from './shareViewModel';


export interface CaseDocumentListViewModel { 
    id?: string;
    tags?: Array<string> | null;
    categories?: Array<string> | null;
    name?: string | null;
    statusId?: number;
    uploaded?: string;
    ownerId?: number;
    thumbnailUrl?: string | null;
    created?: string | null;
    important?: boolean;
    ownerName?: string | null;
    title?: string | null;
    device?: string | null;
    isDeletionLocked?: boolean;
    shares?: Array<ShareViewModel> | null;
    permissions?: any | null;
    parentId?: string | null;
    isDeleted?: any | null;
}

