<h2 mat-dialog-title i18n>Invite User</h2>
<hr />

<p i18n>Enter in the new user email address to invite the user to join SecuraMax.</p>

<div>
    <form formGroup="inviteForm" class="d-flex flex-column">
        <mat-form-field appearance="fill">
            <mat-label i18n>Email</mat-label>
            <input matInput type="email" required [formControl]="inviteForm.controls.email" emailExistsValidator />
            <mat-error *ngIf="inviteForm.controls.email.errors?.required" i18n>Email is required.</mat-error>
            <mat-error *ngIf="inviteForm.controls.email.errors?.emailExistsValidator" i18n>You must have a default
                retention set.
            </mat-error>
            <mat-error *ngIf="inviteForm.controls.email.errors?.emailValid===false" i18n>Please enter a valid email.
            </mat-error>
            <mat-error *ngIf="inviteForm.controls.email.errors?.emailExists===true" i18n>A user with that email already
                exists.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label i18n>Group</mat-label>
            <mat-select required [formControl]="inviteForm.controls.group">
                <mat-option *ngFor="let option of groups" [value]="option.name">
                    <strong>{{option.name}}</strong> {{option.description}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="inviteForm.controls.group.errors?.required" i18n>You must choose a group for this user.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label i18n>RFID</mat-label>
            <input matInput type="text" placeholder="Ex: B4000001" i18n-placeholder [formControl]="inviteForm.controls.rfid"
                minlength="8" maxlength="8" />
            <mat-error *ngIf="inviteForm.controls.rfid.errors?.maxlength || inviteForm.controls.rfid.errors?.minlength"
                i18n>RFID must be exactly 8 characters.</mat-error>
        </mat-form-field>
    </form>
</div>

<a mat-button class="smx-critical-button" mat-dialog-close cdkFocusInitial routerLink="/admin/users/list" i18n>Cancel</a>

<button mat-button class="smx-primary-button ml-2" (click)="invite()"
    [disabled]="inviteForm.invalid || loaded === false || inviting === true" i18n>Invite</button>

<button mat-button class="smx-secondary-button ml-2" (click)="inviteAndNew()"
    [disabled]="inviteForm.invalid || loaded === false || inviting === true" i18n>Invite and
    Invite
    Another</button>

<p *ngIf="inviting">
    <mat-spinner color="accent"></mat-spinner>
</p>
