import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface CloseDialogData {
  caseCloseReason: string;
}

@Component({
  selector: 'app-cases-edit-close-dialog',
  templateUrl: './cases-edit-close-dialog.component.html',
  styleUrls: ['./cases-edit-close-dialog.component.css']
})
export class CasesEditCloseDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CasesEditCloseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CloseDialogData) { }

  ngOnInit(): void {
  }

  CloseSelected() {
    this.dialogRef.close();
  }

}
