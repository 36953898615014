export const timezoneList = [{ name: "Pacific/Niue", value:"NUT+11" },
  { name: "Pacific/Honolulu", value: "HAST+10" },
  { name: "America/Anchorage", value: "AKST+9AKDT,M3.2.0,M11.1.0" },
  { name: "America/Los_Angeles", value: "PST+8PDT,M3.2.0,M11.1.0" },
  { name: "America/Tijuana", value: "PST+8PDT,M3.2.0,M11.1.0" },
  { name: "America/Phoenix", value: "MST+7" },
  { name: "America/Denver", value: "MST+7MDT,M3.2.0,M11.1.0" },
  { name: "America/Mazatlan", value: "MST+7MDT,M4.1.0,M10.5.0" },
  { name: "America/Chicago", value: "CST+6CDT,M3.2.0,M11.1.0" },
  { name: "America/Regina", value: "CST+6" },
  { name: "America/Mexico_City", value: "CST+6CDT,M4.1.0,M10.5.0" },
  { name: "America/Bogota", value: "COT+5" },
  { name: "America/New_York", value: "EST+5EDT,M3.2.0,M11.1.0" },
  { name: "America/Caracas", value: "VET+4:30" },
  { name: "America/Halifax", value: "AST+4ADT,M3.2.0,M11.1.0" },
  { name: "America/Santiago", value: "CLT+4CLST,M10.2.0,M3.2.0" },
  { name: "America/La_Paz", value: "BOT+4" },
  { name: "America/St_Johns", value: "NST+3:30NDT,M3.2.0,M11.1.0" },
  { name: "America/Sao_Paulo", value: "BRT+3BRST,M10.3.0,M2.3.0" },
  { name: "America/Buenos_Aires", value: "ART+3" },
  { name: "America/Montevideo", value: "UYT+3UYST,M10.1.0,M3.2.0" },
  { name: "Atlantic/South_Georgia", value: "GST+2" },
  { name: "Atlantic/Azores", value: "AZOT+1AZOST,M3.5.0,M10.5.0" },
  { name: "Atlantic/Cape_Verde", value: "CVT+1" },
  { name: "Africa/Casablanca", value: "WET+0" },
  { name: "Europe/London", value: "GMT+0BST,M3.5.0,M10.5.0" },
  { name: "Europe/Berlin", value: "CET-1CEST,M3.5.0,M10.5.0" },
  { name: "Africa/Kinshasa", value: "WAT-1" },
  { name: "Europe/Istanbul", value: "EET-2EEST,M3.5.0,M10.5.0" },
  { name: "Africa/Cairo", value: "EET-2" },
  { name: "Asia/Jerusalem", value: "IST-2IDT,M3.5.5,M9.4.0" },
  { name: "Africa/Johannesburg", value: "SAST-2" },
  { name: "Europe/Moscow", value: "MSD-4" },
  { name: "Africa/Nairobi", value: "EAT-3" },
  { name: "Asia/Baghdad", value: "AST-3" },
  { name: "Asia/Tehran", value: "IRST-3:30IRDT,M3.3.3,M9.3.5" },
  { name: "Asia/Muscat", value: "GST-4" },
  { name: "Asia/Baku", value: "AZT-4AZST,M3.5.0,M10.5.0" },
  { name: "Asia/Kabul", value: "AFT-4:30" },
  { name: "Asia/Karachi", value: "PKT-5" },
  { name: "Asia/Kolkata", value: "IST-5:30" },
  { name: "Asia/Katmandu", value: "NPT-5:45" },
  { name: "Asia/Almaty", value: "ALMT-6" },
  { name: "Asia/Dhaka", value: "BST-6" },
  { name: "Asia/Rangoon", value: "MMT-6:30" },
  { name: "Asia/Bangkok", value: "ICT-7" },
  { name: "Asia/Hong_Kong", value: "HKT-8" },
  { name: "Asia/Singapore", value: "SGT-8" },
  { name: "Australia/Perth", value: "WST-8" },
  { name: "Asia/Taipei", value: "CST-8" },
  { name: "Asia/Tokyo", value: "JST-9" },
  { name: "Asia/Seoul", value: "KST-9" },
  { name: "Australia/Adelaide", value: "CST-9:30CDT,M10.1.0,M4.1.0" },
  { name: "Australia/Darwin", value: "CST-9:30" },
  { name: "Australia/Sydney", value: "EST-10EDT,M10.1.0,M4.1.0" },
  { name: "Australia/Brisbane", value: "EST-10" },
  { name: "Pacific/Noumea", value: "NCT-11" },
  { name: "Pacific/Auckland", value: "NZST-12NZDT,M9.5.0,M4.1.0" },
  { name: "Pacific/Kwajalein", value: "MHT-12" },
  { name: "Pacific/Apia", value: "WST-13WST,M9.5.0,M4.1.6" },
  { name: "Pacific/Line_Islands", value: "LINT-14"},
  { name: "UTC", value: "UTC" }] as const;