<div class="card mt-2 p-3">

    <div>
        <h2 i18n>Create BC4 Config</h2>
        <hr />
        <form [formGroup]="configForm" (ngSubmit)="create()" *ngIf="!loading">
            <div class="form-group">
                <mat-form-field class="w-25" appearance="outline">
                    <mat-label i18n>Name</mat-label>
                    <input matInput type="text" formControlName="name" maxlength="100" required>
                    <mat-error *ngIf="f.name.errors?.required" i18n>Name is required.</mat-error>
                    <mat-error *ngIf="f.name.errors?.maxlength" i18n>Name cannot be more than 100 characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-25 form-item" appearance="outline">
                    <mat-label i18n>Base This Config Off Of</mat-label>
                    <mat-select formControlName="basedOffId" required>
                        <mat-option *ngFor="let item of configs" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.basedOffId.errors?.required" i18n>You must select a config to base this off of.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>
            <button mat-button class="smx-primary-button mt-2" [disabled]="configForm.invalid" type="submit" i18n>Create</button>
            <a mat-button class="smx-critical-button ml-2 mt-2" routerLink="/admin/devices/bc4configs" i18n>Cancel</a>

        </form>
        <div *ngIf="loading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
    </div>
</div>
