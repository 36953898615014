import { Component, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlOptions, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DeviceCamerasViewModel, DeviceDetailsModel, SecuraMaxApiService, TransferRequestModel } from '../../../services/api/securamaxapi.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
    selector: 'app-create-transfer',
    templateUrl: './create-transfer.component.html',
    styleUrls: ['./create-transfer.component.css']
})
export class CreateTransferComponent implements OnInit {
    details: DeviceDetailsModel;
    createTransferForm: UntypedFormGroup;

    daterangepicker_start: string;
    daterangepicker_end: string;

    get cameras() {
        return this.createTransferForm.get('cameras') as UntypedFormArray;
    }

    constructor(
        private securaMaxApiService: SecuraMaxApiService,
        private toastr: SnackbarService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder
    ) {
        let startDateTimeDefault = new Date();
        startDateTimeDefault.setMinutes(startDateTimeDefault.getMinutes() - 2);
        let endDateTimeDefault = new Date();
        this.createTransferForm = this.fb.group({
            startDateTime: [startDateTimeDefault, Validators.required],
            endDateTime: [endDateTimeDefault, Validators.required],
            submitNotProgressOrComplete: [true, Validators.requiredTrue],
            cameras: this.fb.array([
            ], requireCheckboxesToBeCheckedValidator())
        }, { validators: requireStartBeforeEndAnd12HrMaxValidator('startDateTime', 'endDateTime') } as AbstractControlOptions);
    }


    async onSubmit(): Promise<void> {
        let value = this.createTransferForm.value;
        let model = new TransferRequestModel();
        model.startTime = value.startDateTime;
        model.endTime = value.endDateTime;
        model.cameras = [];
        for (let i = 0; i < this.details.cameras.length; i++) {
            if (value.cameras[i]) {
                model.cameras.push(this.details.cameras[i]);
            }
        }

        try {
            this.createTransferForm.patchValue({ submitNotProgressOrComplete: false });

            await this.securaMaxApiService.device_PostRequestTransfer(
                this.details.deviceId,
                model
            ).toPromise();
            this.toastr.success("Request Successfully Submitted.");
            this.viewTransferDetails();
        }
        catch (err) {
            this.toastr.error("An error occurred while requesting file transfer.");
            this.createTransferForm.patchValue({ submitNotProgressOrComplete: true });
        }

    }


    async ngOnInit(): Promise<void> {
        let id = this.route.snapshot.params.id;
        this.details = await this.securaMaxApiService.device_Details(id).toPromise();
        for (let i of this.details.cameras) {
            this.cameras.push(this.fb.control(i.isSelected));
        }

        if (this.cameras.length === 0 && this.details.deviceType === 'BC4') {
            this.cameras.push(this.fb.control(true));
            this.details.cameras.push(new DeviceCamerasViewModel({ cameraName: 'Front', cameraNumber: 1, isSelected: true }));
        }
    }

    viewTransferDetails(): void {
        this.router.navigateByUrl(`/admin/devices/${this.details.deviceId}/details`);
    }

    daterangepicker_change($event: any) {
        this.createTransferForm.controls.startDateTime.setValue(moment(this.daterangepicker_start).toDate());
        this.createTransferForm.controls.endDateTime.setValue(moment(this.daterangepicker_end).toDate());
    }
}


function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: UntypedFormGroup) {
        let checked = 0;

        Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.controls[key];

            if (control.value === true) {
                checked++;
            }
        });

        if (checked < minRequired) {
            return {
                requireCheckboxesToBeChecked: true,
            };
        }

        return null;
    };
}



function requireStartBeforeEndAnd12HrMaxValidator(startControlName: string, endControlName: string): ValidatorFn {
    return function validate(parentControl: AbstractControl) {
        let startControl = parentControl.get(startControlName);
        let endControl = parentControl.get(endControlName);

        if (!startControl || !endControl) {
            return {
                requireStartBeforeEnd: true,
            };
        }

        if (!startControl.value) {
            return {
                requireStartBeforeEnd: true,
            };
        }

        if (!endControl.value) {
            return {
                requireStartBeforeEnd: true,
            };
        }

        let possibleStartDate = Date.parse(startControl.value);
        if (isNaN(possibleStartDate)) {
            return {
                requireStartBeforeEnd: true,
            };
        }
        let startDate = new Date(startControl.value);

        let possibleEndDate = Date.parse(endControl.value);
        if (isNaN(possibleEndDate)) {
            return {
                requireStartBeforeEnd: true,
            };
        }
        let endDate = new Date(endControl.value);

        if (startDate >= endDate) {
            return {
                requireStartBeforeEnd: true,
            };
        }


        let startDateAsMoment = moment(startDate);
        let endDateAsMoment = moment(endDate);
        let diff = moment.duration(endDateAsMoment.diff(startDateAsMoment));
        if (diff.asHours() > 12) {
            return {
                requireLessThan12HourRange: true,
            };
        }

        return null;
    };
}
