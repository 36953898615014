import { ApiModule } from 'src/app/services/api2/api.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angularx-qrcode';
import { NgModule } from '@angular/core';
import { MaterialModule } from './modules/material/material.module';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UserService } from './services/user/user.service';
import { UserSelectClearService } from './services/user/user-select-clear.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { CasesComponent } from './components/cases/cases/cases.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MetadataComponent } from './components/metadata/metadata.component';
import { ShellComponent } from './components/shell/shell.component';
import {
    SecuraMaxApiService,
    API_BASE_URL,
} from './services/api/securamaxapi.service';
import { CreateCaseComponent } from './components/metadata/create-case/create-case.component';
import { ProgressSpinnerDialogComponent } from './shared/components/progress-spinner-dialog/progress-spinner-dialog.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { EmailExistsValidator } from './shared/validators/email-exists.validator';
import { UserAliasValidator } from './shared/validators/user-alias.validator';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { SoftwareComponent } from './components/software/software.component';
import {
    FontAwesomeModule,
    FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { HelpComponent } from './components/help/help.component';
import { UploadComponent } from './components/upload/upload.component';
import { DragDropFileUploadDirective } from './shared/directives/drag-drop/drag-drop-file-upload.directive';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UploadStationsComponent } from './components/upload-stations/upload-stations.component';
import { UploadStationsModifyDialogComponent } from './components/upload-stations/upload-stations-modify-dialog/upload-stations-modify-dialog.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { UploadStationsAddDialogComponent } from './components/upload-stations/upload-stations-add-dialog/upload-stations-add-dialog.component';
import { DevicesNavigationComponent } from './components/devices/devices-navigation/devices-navigation.component';
import { DevicesListComponent } from './components/devices/devices-list/devices-list.component';
import { GroupsComponent } from './components/users/groups/groups.component';
import { UsersComponent } from './components/users/users.component';
import { GroupDetailComponent } from './components/users/group-detail/group-detail.component';
import { Bc300configsComponent } from './components/devices/bc300configs/bc300configs.component';
import { Bc300configsAssignComponent } from './components/devices/bc300configs-assign/bc300configs-assign.component';
import { DevicesFileTransferComponent } from './components/devices/devices-file-transfer/devices-file-transfer.component';
import { DevicesFileTransferComponent2 } from './components/devices/devices-file-transfer2/devices-file-transfer2.component';
import { DevicesModifyDialogComponent } from './components/devices/devices-modify-dialog/devices-modify-dialog.component';
import { UserSelectComponent } from './shared/components/user-select/user-select.component';
import { Bc300configsCreateComponent } from './components/devices/bc300configs-create/bc300configs-create.component';
import { Bc300configsEditComponent } from './components/devices/bc300configs-edit/bc300configs-edit.component';
import { Bc300configsDeleteComponent } from './components/devices/bc300configs-delete/bc300configs-delete.component';
import { Bc4configsComponent } from './components/devices/bc4configs/bc4configs.component';
import { Bc4configsAssignComponent } from './components/devices/bc4configs-assign/bc4configs-assign.component';
import { Bc4configsCreateComponent } from './components/devices/bc4configs-create/bc4configs-create.component';
import { Bc4configsEditComponent } from './components/devices/bc4configs-edit/bc4configs-edit.component';
import { Bc4configsDeleteComponent } from './components/devices/bc4configs-delete/bc4configs-delete.component';
import { DevicesBc4SetupComponent } from './components/devices/devices-bc4-setup/devices-bc4-setup.component';
import { TagsComponent } from './components/tags/tags.component';
import { TagTypeEditorComponent } from './components/tags/tag-type-editor/tag-type-editor.component';
import { PendingdeletionComponent } from './components/reports/pendingdeletion/pendingdeletion.component';
import { ReportsComponent } from './components/reports/reports.component';
import { DeviceUsageComponent } from './components/reports/device-usage/device-usage.component';
import { UsageHistoryComponent } from './components/reports/usage-history/usage-history.component';
import { AwaitingDeleteApprovalComponent } from './components/reports/awaiting-delete-approval/awaiting-delete-approval.component';
import { AuditUserComponent } from './components/reports/audit-user/audit-user.component';
import { DevicesDetailsComponent } from './components/devices/devices-details/devices-details.component';
import { DetailRowDirective } from './shared/directives/detail-row.directive';
import { SPaginateComponent } from './shared/s-paginate/s-paginate.component';
import { BytesPipe } from './shared/pipes/bytes.pipe';
import { BytesAltPipe } from './shared/pipes/bytesalt.pipe';
import { TimeAgoPipe } from './shared/pipes/time-ago.pipe';
import { DocumentsSearchListComponent } from './components/documents/documents-search-list/documents-search-list.component';
import { DocumentDetailsComponent } from './components/documents/document-details/document-details.component';
import { DocumentDetails2Component } from './components/documents/document-details2/document-details2.component';
import { UserAuditReportSelectDialogComponent } from './components/reports/audit-user/user-audit-report-select-dialog/user-audit-report-select-dialog.component';
import { UsersListComponent } from './components/users/users-list/users-list.component';
import { UsersEditComponent } from './components/users/users-edit/users-edit.component';
import { MemberRedeemComponent } from './components/members/member-redeem/member-redeem.component';
import { DocumentSearchService } from './services/document-search.service';
import { UserInviteComponent } from './components/users/user-invite/user-invite.component';
import { CaseSelectComponent } from './shared/components/case-select/case-select.component';
import { FilterDuplicatePipe } from './shared/pipes/filter-duplicate.pipe';
import { CreateCaseDialogComponent } from './components/documents/document-details/create-case/create-case-dialog/create-case-dialog.component';
import { CreateCaseDialog2Component } from './components/documents/document-details2/create-case/create-case-dialog2/create-case-dialog2.component';
import { TagTypesComponent } from './shared/components/tag-types/tag-types.component';
import { HelpGroupPermissionsDialogComponent } from './components/users/users-edit/help-group-permissions-dialog/help-group-permissions-dialog.component';
import { HelpAdminRightsDialogComponent } from './components/users/users-edit/help-admin-rights-dialog/help-admin-rights-dialog.component';
import { HelpDocumentRightsDialogComponent } from './components/users/users-edit/help-document-rights-dialog/help-document-rights-dialog.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { ShareModifyDialogComponent } from './components/documents/document-details/share-modify-dialog/share-modify-dialog.component';
import { ShareModifyDialog2Component } from './components/documents/document-details2/share-modify-dialog2/share-modify-dialog2.component';
import { ShareCreateDialog2Component } from './components/documents/document-details2/share-create-dialog2/share-create-dialog2.component';
import { CasesDetailsShareDialogComponent } from './components/cases/cases-details-share-dialog/cases-details-share-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CasesEditCloseDialogComponent } from './components/cases/cases-edit-close-dialog/cases-edit-close-dialog.component';
import { UsersRfidComponent } from './components/users/users-rfid/users-rfid.component';
import { AnnotateComponent } from './components/annotate/annotate/annotate.component';
import { RedactionCreateComponent } from './components/redaction/redaction-create/redaction-create.component';
import { RedactionAdvancedCreateComponent } from './components/redaction/redaction-advanced-create/redaction-advanced-create.component';
import { DocumentsSearchTimelineComponent } from './components/documents/documents-search-timeline/documents-search-timeline.component';
import { DocumentsSearchComponent } from './components/documents/documents-search/documents-search.component';
import { ReviewByScheduleComponent } from './components/review/review-by-time/review-by-time.component';
import { VisTimelineService } from './shared/components/vis-timeline/vis-timeline.service';
import { VisTimelineDirective } from './shared/components/vis-timeline/vis-timeline.directive';
import { ReviewByScheduleListComponent } from './components/review/review-by-time-list/review-by-time-list.component';
import { HelpCasesDialogComponent } from './components/cases/help-cases-dialog/help-cases-dialog.component';
import { TransferDevicesListComponent } from './components/devices/transfer-devices-list/transfer-devices-list.component';
import { DeviceIconComponent } from './shared/components/device-icon/device-icon.component';
import { ReviewByScheduleDetailsComponent } from './components/review/review-by-time-details/review-by-time-details.component';
import { FeedbackComponent } from './shared/components/feedback/feedback.component';
import { FeedbackButtonComponent } from './shared/components/feedback-button/feedback-button.component';
import { PagingApiService } from './services/paging-api.service';
import { HelpReviewByScheduleDialogComponent } from './components/review/review-by-time-help-dialog/review-by-time-help-dialog.component';
import { DocThumbnailComponent } from './shared/components/doc-thumbnail/doc-thumbnail.component';
import { CreateTransferComponent } from './components/devices/create-transfer/create-transfer.component';
import { DevicesPendingTransferComponent } from './components/devices/devices-pending-transfer/devices-pending-transfer.component';
import { TransferComponent } from './components/devices/transfer/transfer.component';
import { ForbiddenComponent } from './components/error/forbidden/forbidden.component';
import { RedactionDetailsComponent } from './components/redaction/redaction-details/redaction-details.component';
import { ShareVerifyComponent } from './components/share/share-verify/share-verify.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CasesDownloadMultipleArchiveWarningComponent } from './components/cases/cases-download-multiple-archive-warning/cases-download-multiple-archive-warning.component';
import { DocumentStorageUsageComponent } from './components/reports/document-storage-usage/document-storage-usage.component';
import { AntiforgeryInterceptor } from './util/antiforgery-interceptor';
import { DvrQuickConfigDialogComponent } from './components/devices/dvr-quick-config-dialog/dvr-quick-config-dialog.component';
import { UsersBulkCreateComponent } from './components/users/users-bulk-create/users-bulk-create.component';
import { environment } from 'src/environments/environment';
import { ApiSettingsComponent } from './components/settings/api-settings/api-settings.component';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomMaterialFormsMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null): boolean {
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched)
        );
    }
}

import { GoogleMapsModule } from '@angular/google-maps';
import { LiveComponent } from './components/live/live.component';
import { LiveAuthComponent } from './components/live/live-auth.component';
import { HttpClientJsonpModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { ReviewMetadataControlsComponent } from './components/review/review-metadata-controls/review-metadata-controls.component';
import { ReviewMetadataControls2Component } from './components/review/review-metadata-controls2/review-metadata-controls2.component';
import { Annotate2Component } from './components/annotate/annotate2/annotate2.component';
import { Annotate2Step1Component } from './components/annotate/annotate2-step1/annotate2-step1.component';
import { Annotate2Step2Component } from './components/annotate/annotate2-step2/annotate2-step2.component';
import { CasesCreate2Component } from './components/cases/cases-create2/cases-create2.component';
import { DocumentsSearchList2Component } from './components/documents/documents-search-list2/documents-search-list2.component';
import { DocumentsSearch2Component } from './components/documents/documents-search2/documents-search2.component';
import { DocumentsSearchTimeline2Component } from './components/documents/documents-search-timeline2/documents-search-timeline2.component';
import { DateRangePickerComponent } from './shared/components/daterangepicker/daterangepicker.component';
import { CasesDetails2Component } from './components/cases/cases-details2/cases-details2.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CasesEdit2Component } from './components/cases/cases-edit2/cases-edit2.component';
import { Bc4WifiComponent } from './components/devices/bc4-wifi/bc4-wifi.component';
import { TagTypeSelectionModalComponent } from './components/review/tag-type-selection-modal/tag-type-selection-modal.component';
import { CreateRequestDialogComponent } from './shared/components/create-request-dialog/create-request-dialog.component';
import { LiveInactivityDialogComponent } from './components/live/live-inactivity-dialog/live-inactivity-dialog.component';
import { UsersListStateService } from './components/users/users-list/users-list-state.service';
import { SPaginate2Component } from './shared/s-paginate2/s-paginate2.component';
import { BASE_PATH } from './services/api2';
import { MetadataModifyDialogComponent } from './components/documents/document-details2/metadata-modify-dialog/metadata-modify-dialog.component';
import { LiveDownloadDialogComponent } from './components/live/live-download-dialog/live-download-dialog.component'; 
import { ApproveDialogComponent } from './components/devices/devices-file-transfer2/approve-device-dialog/approve-device-dialog.component';
import { CaseSelect2Component } from './shared/components/case-select2/case-select2.component';
import { LiveCameraSelectDialogComponent } from './components/live/live-camera-select-dialog/live-camera-select-dialog.component';
import { DocumentDetailsDialogComponent } from './components/documents/document-details2/document-details-dialog/document-details-dialog.component';
import { DevicesBc4Setup2Component } from './components/devices/devices-bc4-setup2/devices-bc4-setup2.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CasesComponent,
        NavbarComponent,
        MetadataComponent,
        ShellComponent,
        CreateCaseComponent,
        ProgressSpinnerDialogComponent,
        MetadataModifyDialogComponent,
        WelcomeComponent,
        LiveComponent,
        LiveAuthComponent,
        SoftwareComponent,
        HelpComponent,
        UploadComponent,
        DragDropFileUploadDirective,
        ProgressBarComponent,
        SettingsComponent,
        UploadStationsComponent,
        UploadStationsModifyDialogComponent,
        CategoriesComponent,
        UploadStationsAddDialogComponent,
        DevicesNavigationComponent,
        DevicesListComponent,
        GroupsComponent,
        UsersComponent,
        GroupDetailComponent,
        Bc300configsComponent,
        Bc300configsAssignComponent,
        DevicesFileTransferComponent,
        DevicesFileTransferComponent2,
        DevicesModifyDialogComponent,
        ApproveDialogComponent,
        UserSelectComponent,
        Bc300configsCreateComponent,
        Bc300configsEditComponent,
        Bc300configsDeleteComponent,
        Bc4configsComponent,
        Bc4configsAssignComponent,
        Bc4configsCreateComponent,
        Bc4configsEditComponent,
        Bc4configsDeleteComponent,
        DevicesBc4SetupComponent,
        DevicesBc4Setup2Component,
        Bc4WifiComponent,
        TagsComponent,
        TagTypeEditorComponent,
        DevicesDetailsComponent,
        PendingdeletionComponent,
        ReportsComponent,
        DeviceUsageComponent,
        UsageHistoryComponent,
        AwaitingDeleteApprovalComponent,
        AuditUserComponent,
        DetailRowDirective,
        SPaginateComponent,
        SPaginate2Component,
        BytesPipe,
        BytesAltPipe,
        TimeAgoPipe,
        DocumentsSearchListComponent,
        DocumentDetailsComponent,
        DocumentDetails2Component,
        UserAuditReportSelectDialogComponent,
        UsersListComponent,
        UsersEditComponent,
        MemberRedeemComponent,
        UserInviteComponent,
        EmailExistsValidator,
        UserAliasValidator,
        CaseSelectComponent,
        CaseSelect2Component,
        FilterDuplicatePipe,
        CreateCaseDialogComponent,
        CreateCaseDialog2Component,
        TagTypesComponent,
        HelpGroupPermissionsDialogComponent,
        HelpAdminRightsDialogComponent,
        HelpDocumentRightsDialogComponent,
        HelpCasesDialogComponent,
        ConfirmDialogComponent,
        ShareModifyDialogComponent,
        ShareModifyDialog2Component,
        ShareCreateDialog2Component,
        CasesDetailsShareDialogComponent,
        DocumentDetailsDialogComponent,
        CasesEditCloseDialogComponent,
        UsersRfidComponent,
        AnnotateComponent,
        RedactionCreateComponent,
        RedactionAdvancedCreateComponent,
        DocumentsSearchTimelineComponent,
        DocumentsSearchComponent,
        ReviewByScheduleComponent,
        VisTimelineDirective,
        ReviewByScheduleListComponent,
        TransferDevicesListComponent,
        DeviceIconComponent,
        ReviewByScheduleDetailsComponent,
        FeedbackComponent,
        FeedbackButtonComponent,
        HelpReviewByScheduleDialogComponent,
        DocThumbnailComponent,
        CreateTransferComponent,
        DevicesPendingTransferComponent,
        TransferComponent,
        ForbiddenComponent,
        RedactionDetailsComponent,
        ShareVerifyComponent,
        ProfileComponent,
        CasesDownloadMultipleArchiveWarningComponent,
        DocumentStorageUsageComponent,
        DvrQuickConfigDialogComponent,
        UsersBulkCreateComponent,
        ApiSettingsComponent,
        SearchbarComponent,
        ReviewMetadataControlsComponent,
        ReviewMetadataControls2Component,
        Annotate2Component,
        Annotate2Step1Component,
        Annotate2Step2Component,
        CasesCreate2Component,
        DocumentsSearchList2Component,
        DocumentsSearch2Component,
        DocumentsSearchTimeline2Component,
        DateRangePickerComponent,
        CasesDetails2Component,
        CasesEdit2Component,
        TagTypeSelectionModalComponent,
        CreateRequestDialogComponent,
        LiveInactivityDialogComponent,
        LiveDownloadDialogComponent,
        LiveCameraSelectDialogComponent,
    ],
    imports: [
        ApiModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([]),
        AppRoutingModule,
        HttpClientModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        VgCoreModule,
        VgControlsModule,
        VgBufferingModule,
        MatDatepickerModule,
        MatInputModule,
        QRCodeModule,
        GoogleMapsModule,
        HttpClientJsonpModule,
        CommonModule,
        MatTooltipModule,
    ],
    providers: [
        { provide: BASE_PATH, useValue: environment.apiUrl },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AntiforgeryInterceptor,
            multi: true,
        },
        SecuraMaxApiService,
        {
            provide: API_BASE_URL,
            useValue: environment.apiUrl,
        },
        DocumentSearchService,
        VisTimelineService,
        UserService,
        UserSelectClearService,
        UsersListStateService,
        PagingApiService,
        Title,
        {
            provide: ErrorStateMatcher,
            useClass: CustomMaterialFormsMatcher,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        // Add an icon to the library for convenient access in other components
        library.addIconPacks(fas, far, fab);
    }
}
