import { Component, OnInit } from '@angular/core';
import { SecuraMaxApiService, TagTypeViewModel, TagViewModel } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from '../../services/title.service';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

    tags: TagViewModel[];

    tagTypes: TagTypeViewModel[];

    selected: TagViewModel;

    showLoading: boolean = true;

    constructor(private api: SecuraMaxApiService, private toastr: SnackbarService, private titleService: TitleService) { }

    ngOnInit(): void {
        this.titleService.setTitle('Manage Tags');
        this.refresh();
    }

    loadLookups() {
        var that = this;

        if (!that.tagTypes) {
            this.api.tagTypes_GetAll().toPromise().then((data) => {
                this.tagTypes = data;
            }, (err) => {
                this.toastr.error("An error occurred while loading tags.");
            });
        }
    }

    updateTagTypeConfiguration(newTagTypeConfiguration) {
        this.selected.tagTypeConfigurationData = newTagTypeConfiguration;
    }

    select(item: TagViewModel) {
        this.loadLookups();
        this.selected = item;
    }

    add() {
        this.selected = new TagViewModel();
        this.loadLookups();
    }

    save() {
        var that = this;
        this.api.tags_Save(that.selected).toPromise().then((newId) => {
            this.toastr.success('The tag was successfully saved.');
            if (that.selected.id) {
                that.tags.forEach((value, index, arr) => {
                    if (value.id === newId) {
                        that.tags[index] = that.selected;
                    }
                });
            } else {
                that.selected.id = newId;
                that.tags.push(that.selected);
            }
            that.selected = null;
            that.refresh();
        }, (err) => {
            this.toastr.error("An error occurred while saving the tag.");
        });
    }

    delete() {
        this.api.tags_Delete(this.selected.id).toPromise().then((res) => {
            this.toastr.success('The tag was successfully deleted.');
            let index = this.tags.indexOf(this.selected, 0);
            if (index > -1) {
                this.tags.splice(index, 1);
            }
            this.selected = null;
        }).catch((err) => {
            this.toastr.error("An error occurred while deleting the tag");
        })
    }

    cancel() {
        this.selected = null;
    }

    refresh() {
        this.api.tags_GetAllActiveTags().toPromise().then((data) => {
            this.tags = data;
            this.showLoading = false;
        }, (err) => {
            this.toastr.error("An error occurred while loading tags.");
        });
    }

}
