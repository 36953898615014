import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppConstants } from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private titleService: Title) { }

  setTitle(title: string) {
    this.titleService.setTitle(AppConstants.ApplicationName + ' - ' + title);
  }
}
