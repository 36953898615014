/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ShareViewModel { 
    id?: string;
    expires?: string;
    canDownload?: boolean;
    canAddMetaData?: boolean;
    canReshare?: boolean;
    email?: string | null;
    sharedByUserId?: number;
    sharedWithUserId?: number | null;
    isUser?: boolean;
    created?: string | null;
    documentId?: string;
    displayName?: string | null;
    requiresVerification?: boolean;
    caseId?: number | null;
}

