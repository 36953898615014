/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeSpan } from './timeSpan';
import { RedactionType } from './redactionType';
import { RedactionStatus } from './redactionStatus';


export interface RedactionItem { 
    id?: number;
    documentId?: string;
    documentName?: string | null;
    redactionTypeId?: RedactionType;
    redactionStatus?: RedactionStatus;
    videoStart?: TimeSpan;
    videoEnd?: TimeSpan;
    audioStart?: TimeSpan;
    audioEnd?: TimeSpan;
    blurAmount?: number;
    iFrameUrl?: string | null;
    created?: string;
    redactionDocumentId?: string | null;
}
export namespace RedactionItem {
}


