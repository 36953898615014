<div class="card mt-3">
    <div class="card-body card-container">
        <h1 i18n>Download Software</h1>
        <div class="outer-container">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h3 i18n>HD Viewer</h3>
                        <p class="font-weight-light" i18n>
                            Install HD Viewer to stream HD videos from the
                            SecuraMax website.
                        </p>
                        <button mat-button
                                class="smx-primary-button"
                                (click)="downloadHDViewer()"
                                i18n>
                            <fa-icon class="mr-2" icon="download"></fa-icon>
                            Download
                        </button>
                        <hr />

                        <h3 i18n>PV Live View</h3>
                        <p class="font-weight-light" i18n>
                            Install PV Live View app to view live video and audio from capable devices.
                        </p>
                        <button mat-button
                                class="smx-primary-button"
                                (click)="downloadLiveView()"
                                i18n>
                            <fa-icon class="mr-2" icon="download"></fa-icon>
                            Download
                        </button>
                        <hr />

                        <h3 i18n>PVHD Sync</h3>
                        <p class="font-weight-light" i18n>
                            Law Enforcement HD In-Car Software
                        </p>
                        <button mat-button
                                class="smx-primary-button"
                                (click)="downloadPVHDSync()"
                                i18n>
                            <fa-icon class="mr-2" icon="download"></fa-icon>
                            Download
                        </button>
                        <hr />

                        <h3 i18n>PVHD Sync Guide</h3>
                        <p class="font-weight-light" i18n>
                            Law Enforcement HD In-Car Software Guide
                        </p>
                        <a mat-button
                           class="smx-primary-button"
                           href="http://files.provisionusa.com/software/pvhdsync/guides/DVR-808CLE%20System%20Guide.pdf"
                           target="_blank"
                           i18n>
                            <fa-icon class="mr-2" icon="download"></fa-icon>
                            Download
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h2 class="mt-5 mb-3">Legacy Downloads</h2>
                        <h3 i18n>Watch Folders</h3>
                        <p class="font-weight-light" i18n>
                            Download our software for Windows 7+ that will watch
                            folders and upload files to SecuraMax.
                        </p>
                        <button
                            mat-button
                            class="smx-primary-button"
                            (click)="downloadWatchFolders()"
                            i18n
                        >
                            <fa-icon class="mr-2" icon="download"></fa-icon>
                            Download
                        </button>
                        <hr />
                        <h3 i18n>Desktop Uploader Tool</h3>
                        <p class="font-weight-light" i18n>
                            The desktop uploader tool is installed on computers
                            to be used as upload stations and will automatically
                            upload video from connection upload stations.
                        </p>
                        <p>
                            <button
                                mat-button
                                class="smx-primary-button"
                                (click)="downloadUploader()"
                                i18n
                            >
                                <fa-icon class="mr-2" icon="download"></fa-icon>
                                Download Windows Upload Software
                            </button>
                        </p>
                        <hr />
                        <h3 i18n>BC-300 Drivers</h3>
                        <p class="font-weight-light" i18n>
                            Download Windows drivers for the BODYCAM BC-300
                            Camera.
                        </p>
                        <button
                            mat-button
                            class="smx-primary-button"
                            (click)="downloadDrivers()"
                            i18n
                        >
                            <fa-icon class="mr-2" icon="download"></fa-icon>
                            Download Drivers
                        </button>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
