import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecuraMaxApiService, CasesCreateViewModel } from '../../../services/api/securamaxapi.service';
import { DialogResult, DialogResultData } from '../../../models/dialog-result.model';

@Component({
    selector: 'app-create-case',
    templateUrl: './create-case.component.html',
    styleUrls: ['./create-case.component.css']
})
export class CreateCaseComponent implements OnInit {

    form: UntypedFormGroup;
    submitError: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<CreateCaseComponent>,
        private apiService: SecuraMaxApiService,
        @Inject(MAT_DIALOG_DATA) data) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            title: [''],
            description: [''],
        });
    }

    save() {
        let newCase = new CasesCreateViewModel({
            description: this.form.get('description').value,
            title: this.form.get('title').value,
            documents: [],
            retention: 0,
            sharedUsersCanEdit: false
        });

        this.apiService.cases_Create(newCase).subscribe(
            result => {
                let diagResult: DialogResultData = {
                    result: DialogResult.ok,
                    data: newCase.title
                };

                this.dialogRef.close(diagResult);
            },
            error => {
                this.submitError = true;
            });
    }

    close() {
        this.dialogRef.close(DialogResult.cancel);
    }
}
