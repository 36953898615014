<div class="outer-container">
    <div class="inner-container">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6 col-md-12">
                    <h1>Step 1 - Configure Device</h1>
                    <div class="mt-1">
                        Each device must be configured to connect to SecuraMax.
                        Configuration can be done through the device's web
                        interface or by using a Quick Setup file.
                    </div>

                    <h5 class="mt-12">
                        Configure through Device's Web Interface:
                    </h5>
                    <ol>
                        <li>
                            Connect to the device (Find your devices instruction
                            guide
                            <a
                                target="_blank"
                                href="https://provisionusa.com/support"
                                >here</a
                            >)
                        </li>
                        <li>
                            Configure and connect your device to the internet
                            through Wi-Fi, 4G, or LAN
                        </li>
                        <li>
                            <span>
                                <span
                                    >Set&nbsp;the&nbsp;device's&nbsp;File&nbsp;Transfer&nbsp;Server&nbsp;URL&nbsp;to:</span
                                >
                                <span
                                    ><span class="url-textbox">{{
                                        fileTransferServerUrl
                                    }}</span>
                                    <button
                                        id="copyButton"
                                        type="button"
                                        class="smx-secondary-button"
                                        (click)="copyServerUrl()"
                                    >
                                        Copy
                                        <mat-icon style="vertical-align: middle"
                                            >content_copy</mat-icon
                                        >
                                    </button>
                                </span>
                            </span>
                        </li>
                        <li>Turn On/Enable File Transfer</li>
                        <li>
                            Ensure the File Transfer Setup Status changes to
                            "Pending Server Approval"
                        </li>
                    </ol>

                    <h5 class="mt-12">Configure using Quick Setup File:</h5>
                    <ol>
                        <li>
                            Click
                            <a
                                target="_blank"
                                asp-action="@nameof(DeviceRegistrationController.CreateQuickSetupFile)"
                                >here</a
                            >
                            to create a Quick Setup File
                        </li>
                        <li>Place the file on an SD card</li>
                        <li>Place the setup SD card into the device</li>
                    </ol>
                    <div class="ps-4 mt-1">
                        Click
                        <a
                            target="_blank"
                            href="https://files.provisionusa.com/guides/software/QuickSetupFileGuide.pdf"
                            >here</a
                        >
                        to see the full Quick Setup file instruction guide (PDF
                        document)
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-sm-6 col-md-12">
                    <h1>Step 2 - Approve Device</h1>

                    <ng-container *ngIf="state === 'loading'">
                        <mat-spinner class="mt-2" color="accent"></mat-spinner>
                    </ng-container>

                    <ng-container
                        *ngIf="state === 'ready' || state === 'approving'"
                    >
                        <div
                            *ngIf="
                                !pendingDevices || pendingDevices.length === 0
                            "
                            class="alert alert-info my-6"
                        >
                            There are no devices pending approval. Follow the
                            steps above to configure new devices.
                        </div>

                        <!-- todo do we want this in smx? -->

                        <!-- <div class="alert alert-danger my-6">
                            There are no available DVR device licenses, disable
                            inactive units from the Units page or contact sales
                            to purchase additional licenses.
                        </div> -->

                        <ul class="list-unstyled mt-6">
                            <li
                                *ngFor="let device of pendingDevices"
                                class="pending-device-row"
                            >
                                <span
                                    >{{ device.unitId }} [SN:
                                    {{ device.serialNumber }}]</span
                                >
                                <button
                                    *ngIf="device.isApproved === false"
                                    class="smx-primary-button ml-3"
                                    (click)="approveDevice(device)"
                                >
                                    Approve Device
                                </button>
                                <button
                                    *ngIf="device.isApproved"
                                    class="smx-primary-button ml-3"
                                    disabled
                                >
                                    Device Approved
                                </button>
                            </li>
                        </ul>

                        <!-- todo do we want this in smx? -->

                        <!-- <div class="mt-8">
                        Use the Auto Approve feature to simplify adding
                        devices by not requiring each device to be confirmed
                        here after configuration.
                    </div>
                    <div>
                        <button
                            id="enableAutoApproveButton"
                            type="button"
                            class="btn btn-link"
                        >
                            Enable Auto Approve
                        </button>
                    </div> -->
                        <!-- } @*@if (Model.Items == null || Model.Items.Count == 0)  { -->

                        <!-- todo do we want this in smx? -->

                        <!-- <div class="alert alert-info">
                            <p>
                                There are no devices pending. Go to the Transfer
                                configuration page on your DVR and enter in the
                                URL/IP address of this server to connect it.
                            </p>
                            <p>
                                If you have
                                <a asp-action="Https" asp-controller="Home"
                                    >auto approve</a
                                >
                                turned on, no devices will show up here. They
                                will automatically show up on the home page.
                            </p>
                        </div> -->
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
