/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoryViewModel } from './categoryViewModel';
import { TimeBasedTagsAutoTaggedTagsViewModel } from './timeBasedTagsAutoTaggedTagsViewModel';
import { CasesDetailsViewModel } from './casesDetailsViewModel';


export interface TimeBasedTagsViewModel { 
    id?: number | null;
    eventStart?: string;
    eventEnds?: string;
    title?: string | null;
    eventRetentionDays?: number;
    isActive?: boolean;
    expiration?: string;
    description?: string | null;
    tags?: Array<TimeBasedTagsAutoTaggedTagsViewModel> | null;
    categories?: Array<CategoryViewModel> | null;
    cases?: Array<CasesDetailsViewModel> | null;
}

