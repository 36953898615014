<div class="main-container">
    <p i18n>Files are deleted at midnight (PST), the following day they are projected to be deleted.
    </p>

    <div class="d-flex">
        <app-searchbar (search)="onSearch($event)" (clear)="onSearchCleared()" placeholder="Pending Deletion Search"
                       isPrimary="false"></app-searchbar>
    </div>

    <div class="details-card">

        <table class="table">
            <thead>
            <tr>
                <th i18n>Document</th>
                <th i18n>Projected</th>
                <th i18n>Time Until Deletion</th>
                <th i18n>Reason</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let doc of datasource">
                <td><a [routerLink]="['/documents/details',{id: doc.id}]"
                       ui-sref="documents.details({ id: doc.Id })">{{doc.name}}</a></td>
                <td>
                    {{doc.projectedDate | date: 'full'}}
                </td>
                <td>
                    {{doc.projectedDate | timeAgo}}
                </td>
                <td>{{doc.displayReason}}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <span *ngIf="datasource">{{totalItems}}
        <ng-container i18n>Results</ng-container></span>
    <span *ngIf="!datasource">
            <mat-spinner></mat-spinner>
        </span>


    <app-s-paginate [modelFromJS]="modelFromJS" (collection)="onDataSourceUpdate($event)"
                    (numItems)="numItemsUpdated($event)" [url]="'/api/api/DocumentTask/GetPendingDeletionList'"
                    [urlParams]="filter">
    </app-s-paginate>
</div>
