<div class="main-container">

    <div>

        <div class="message alert alert-warning" role="alert">To register a BC-300 or older DVR, you must use the desktop utility. Once registered, you can associate with the users listed on this page.</div>
    </div>

    <div class="list-container">
    <div class="d-flex align-items-center">
            <app-searchbar class="mr-5" (search)="onSearch($event)" (clear)="onSearchCleared()"
                           placeholder="Devices Search" isPrimary="false"></app-searchbar>
            <div class="device-license-info mr-5">
                Total Number of Devices (Licenses): {{deviceListInfo.totalEnabledDevices}} of {{deviceListInfo.maxDevicesForLicense}}
            </div>
            <mat-checkbox (change)="onShowDisabledDeviceChange($event)">Hide Disabled Devices</mat-checkbox>
        </div>

        <mat-card class="details-card">
            <div class="col" *ngIf="shouldShowOnlyEnabledAndAssignedToClosedUsers">
                <div class="alert alert-warning mt-3" style="cursor: pointer;" role="alert"
                     (click)="handleFilterWarningBannerClick()" i18n>
                    Showing only devices which are enabled but assigned to closed users. Click here to see all
                    devices.
                </div>

            </div>

            <table mat-table matSort [dataSource]="datasource" (matSortChange)="sortTable($event)">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Name</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.isPVTransfer">
                            <a routerLink="/admin/devices/{{element.id}}/details">
                                <img src="assets/images/icons/{{element.iconName}}" i18n-alt alt="device image"
                                     style="float:left; padding-right:5px"/>
                                <div style="display: flex; flex-direction: column; padding-right: 5px;" i18n>
                                    <div>{{element.name}}</div>
                                    <small style="white-space: nowrap;">{{element.serialNumber}}</small>
                                </div>
                            </a>
                        </div>
                        <div *ngIf="!element.isPVTransfer">
                            <img src="assets/images/icons/{{element.iconName}}" i18n-alt alt="device image"
                                 style="float:left; padding-right:5px"/>
                            <div style="display: flex; flex-direction: column; padding-right: 5px;" i18n>
                                <div>{{element.name}}</div>
                                <small style="white-space: nowrap;">{{element.serialNumber}}</small>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Associated User Column -->
                <ng-container matColumnDef="associatedUser">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Associated User</th>
                    <td mat-cell *matCellDef="let element" i18n> {{element.associatedUser}} </td>
                </ng-container>

                <!-- Last Connected Column -->
                <ng-container matColumnDef="lastConnected">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Last Connected</th>
                    <td mat-cell *matCellDef="let element"
                        i18n> {{lastConnectedtoLocale(element)}} </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> Status</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-slide-toggle [checked]="!element.isDisabled"
                                          (change)="toggleStateOfDevice(element)">
                                <span class="statusText" *ngIf="!element.isDisabled" i18n>
                                  <label class="mt-2">Enabled</label>
                                </span>
                            <span class="statusText" *ngIf="element.isDisabled" i18n>
                                  <label class="mt-2">Disabled</label>
                                </span>
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <!-- Modify Column -->
                <ng-container matColumnDef="modifyButton">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div class="header-align-right">
                            <button mat-button class="smx-secondary-button" (click)="openModifyDialog(element)"
                                    i18n>Modify
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>

        <mat-paginator #paginator
                       [length]="resultsLength"
                       [pageIndex]="pageEvent.pageIndex"
                       [pageSize]="pageEvent.pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       (page)="onPageFired($event)">
        </mat-paginator>
    </div>
</div>
