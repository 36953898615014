<div class="card mt-3">
    <div class="card-body">
        <h1 i18n> Upload Files</h1>
        <div class="card-container">
            <div class="uploadContainer" appDragDropFileUpload (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" multiple
                       (change)="fileBrowseHandler($event.target.files)"/>
                <img src="assets/images/dnd/ic-upload-file.svg">
                <h3 i18n>Drag and drop file here</h3>
                <h3 i18n>or</h3>
                <label for="fileDropRef" style="display: inline-block;">
                    <div class="mt-1" i18n>Browse for file</div>
                </label>
            </div>
            <div class="files-list col-md-6 offset-md-3">
                <div class="single-file" *ngFor="let file of files; let i = index">
                    <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file" i18n-alt>
                    <div class="info">
                        <h4 class="name">
                            {{ file?.file?.name }}
                        </h4>
                        <p class="size">
                            {{ formatBytes(file?.file?.size) }}
                        </p>

                        <app-progress-bar [progress]="file?.progress" [complete]="file?.isComplete"
                                          [error]="file?.isError"></app-progress-bar>

                    </div>
                    <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" i18n-alt alt="file"
                         *ngIf="file?.isComplete || file?.isError" (click)="deleteFile(i)">
                </div>
            </div>
        </div>
    </div>
</div>
