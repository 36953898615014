<div class="outer-container">
    <a *ngIf="showGoToIcon && !openInNewTab" style="margin-right: 4px;" [routerLink]="['/documents', id]">
        <mat-icon>open_in_new</mat-icon>
    </a>
    <a *ngIf="showGoToIcon && openInNewTab" style="margin-right: 4px;" [href]="'/documents/'+id" target="_blank" (click)="openDocInNewTab($event)">
        <mat-icon>open_in_new</mat-icon>
    </a>
    <mat-spinner color="accent" class="missing-image-spacer" diameter="20" *ngIf="!hasLoaded"></mat-spinner>
    <img *ngIf="hasLoaded && url !== null" [src]="url" [class]="big ? 'big' : ''">
    <img *ngIf="hasLoaded && url === null" src="assets/images/nothumbnail.png" [class]="big ? 'big' : ''">
</div>
