import { Component, HostListener } from '@angular/core';
import {
    DeviceRegisterControllerV2Service,
    GetPendingItemModel,
    PostApproveDeviceRequest,
} from 'src/app/services/api2';
import GUI from 'lil-gui';
import { PvTransferDeviceRegistration } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserSelectModel } from 'src/app/models/user-select.model';
import { MatDialog } from '@angular/material/dialog';
import {
    ApproveDialogComponent,
    DialogData
} from './approve-device-dialog/approve-device-dialog.component';

@Component({
    selector: 'app-devices-file-transfer2',
    templateUrl: './devices-file-transfer2.component.html',
    styleUrls: ['./devices-file-transfer2.component.scss'],
})
export class DevicesFileTransferComponent2 {
    debugGui: GUI;
    debugState = {
        addDeviceDVR: () => {
            this.deviceRegisterService
                .apiPVTransferApiDeviceRegisterV2DebugAddTestingDevicePost()
                .subscribe((response) => {
                    const model = new PvTransferDeviceRegistration();
                    model.code = response.Code;
                    model.serial = `123H9HD${this.debug_getRandomSerial()}`;
                    model.unitId = 'someunit';
                    model.numberOfCameras = 1;
                    model.timezone = 'America/Chicago';
                    this.deviceRegisterService
                        .apiPVTransferApiDeviceRegisterV2Post(model)
                        .subscribe(() => {});
                });
        },
        addDeviceBC4: () => {
            this.deviceRegisterService
                .apiPVTransferApiDeviceRegisterV2DebugAddTestingDevicePost()
                .subscribe((response) => {
                    const model = new PvTransferDeviceRegistration();
                    model.code = response.Code;
                    model.serial = `123B4${this.debug_getRandomSerial()}`;
                    model.unitId = 'someunit';
                    model.numberOfCameras = 1;
                    model.timezone = 'America/Chicago';
                    this.deviceRegisterService
                        .apiPVTransferApiDeviceRegisterV2Post(model)
                        .subscribe(() => {});
                });
        },
        clearDevices: () => {
            this.deviceRegisterService
                .apiPVTransferApiDeviceRegisterV2DebugClearPendingDevicesForTenantPost()
                .subscribe(() => {});
        },
    };

    state: string = 'loading';

    autoRefreshTimerId: NodeJS.Timeout | null = null;
    pendingDevices: GetPendingItemModel[] = [];
    fileTransferServerUrl: string = 'https://securamax.com';

    constructor(
        private deviceRegisterService: DeviceRegisterControllerV2Service,
        public approveDialog: MatDialog,
        private toastr: SnackbarService
    ) {}

    ngOnInit() {
        this.debugCreateGui();

        this.autoRefreshTimerId = setInterval(() => {
            this.updatePendingList();
        }, 1000);

        this.fileTransferServerUrl =
            new URL(window.location.href).origin + '/api/PVTransfer';
    }

    ngOnDestroy() {
        if (this.debugGui) {
            this.debugGui.destroy();
        }

        clearInterval(this.autoRefreshTimerId);
    }

    debug_getRandomSerial() {
        return `${Math.floor(Math.random() * 9999)}`.padStart(4, '0');
    }

    updatePendingList() {
        this.deviceRegisterService
            .apiPVTransferApiDeviceRegisterV2PendingGet()
            .subscribe((result) => {
                if (this.state === 'approving') {
                    return;
                }
                this.pendingDevices = result.pending.filter(x => {
                    return x.serialNumber && !x.serialNumber.includes("B4");
                });
                this.state = 'ready';
            });
    }

    approveDevice(device: GetPendingItemModel) {
        console.log(device);
        this.state = 'approving';

        const startingData: DialogData = {
            deviceId: device.unitId,
        };

        const dialogRef = this.approveDialog.open(ApproveDialogComponent, {
            data: startingData,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((data: DialogData) => {
            if (data.dialogResult && data.dialogResult === 'cancel') {
                return;
            }

            const request: PostApproveDeviceRequest = {
                associatedUserId: data.associatedUserId,
                code: device.code,
            };
            this.deviceRegisterService
                .apiPVTransferApiDeviceRegisterV2ApproveDevicePost(request)
                .subscribe(
                    () => {
                        this.toastr.success('Approved device.');
                        device.isApproved = true;
                        this.state = 'ready';
                    },
                    () => {
                        this.toastr.error('Failed to approve device.');
                        this.state = 'ready';
                    }
                );
        });
    }

    copyServerUrl() {
        const tempInput = document.createElement('input');
        document.body.appendChild(tempInput);
        tempInput.value = this.fileTransferServerUrl;
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.toastr.success('Copied server URL to clipboard.');
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.shiftKey && event.key === 'F1') {
            event.preventDefault();
            if (this.debugGui) {
                if (this.debugGui._hidden) {
                    this.debugGui.show();
                } else {
                    this.debugGui.hide();
                }
            } else {
                this.debugCreateGui();
            }
        }
    }

    debugCreateGui() {
        this.debugGui = new GUI({ title: 'Debug (Shift+F1 to open/close)' });
        this.debugGui.add(this.debugState, 'addDeviceDVR').name('Add DVR Device');
        this.debugGui.add(this.debugState, 'addDeviceBC4').name('Add BC4 Device');
        this.debugGui
            .add(this.debugState, 'clearDevices')
            .name('Clear Devices');
    }
}
