import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    FormArray,
    Validators,
    ValidationErrors,
} from '@angular/forms';
import { VisTimelineService } from 'src/app/shared/components/vis-timeline/vis-timeline.service';
import { DataSet } from 'vis-data';
import { ActivatedRoute, Router } from '@angular/router';
import { TimelineOptions, DataItem } from 'vis-timeline/peer';
import {
    SecuraMaxApiService,
    CasesListViewModel,
    TimeBasedTagsViewModel,
    CategoryViewModel,
    TagViewModel,
    TimeBasedTagsListViewModel,
    TimeBasedTagDetailsViewModel,
    TimeBasedTagsAutoTaggedTagsViewModel,
} from 'src/app/services/api/securamaxapi.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReviewMetadataControlsComponent } from '../review-metadata-controls/review-metadata-controls.component';

interface TimelineData {
    items: DataSet<RuleDataItem, 'id'>;
    id: string;
    options: TimelineOptions;
}

interface RuleDataItem extends DataItem {
    rule: TimeBasedTagDetailsViewModel;
}

interface ReviewByScheduleForm {
    metadata: FormGroup<IReviewMetadataForm>;
    start: FormControl<string>;
    end: FormControl<string>;
}

interface IReviewMetadataForm {
    title: FormControl<string>;
    description: FormControl<string>;
    tags: FormArray<FormControl>;
    newTagTypeToCreateControlsFor: FormControl<TagViewModel>;
    categoriesForm: FormControl<CategoryViewModel[] | null>;
    casesForm: FormControl<CasesListViewModel[] | null>;
}

enum ReviewByScheduleComponentState {
    Loading = 'Loading',
    NoSelection = 'NoSelection',
    CreatingNew = 'CreatingNew',
    SelectingExisting = 'SelectingExisting',
    Selected = 'Selected',
    Clearing = 'Clearing',
    Saving = 'Saving',
}

enum ReviewByScheduleComponentState_Selected_State {
    Invalid = 'Invalid',
    SelectedNew = 'SelectedNew',
    SelectedExisting = 'SelectedExisting',
}

enum ReviewByScheduleComponent_Save_State {
    NoChanges = 'NoChanges',
    ValidationIssue = 'ValidationIssue',
    Ready = 'Ready',
}

function validateAtLeastOne(x: FormControl<number[]>): ValidationErrors | null {
    if (!x.value) {
        return { atLeastOne: { value: x.value } };
    }

    if (x.value.length === 0) {
        return { atLeastOne: { value: x.value } };
    }

    return null;
}

@Component({
    selector: 'app-review-by-time',
    templateUrl: './review-by-time.component.html',
    styleUrls: ['./review-by-time.component.css'],
})
export class ReviewByScheduleComponent implements OnInit {
    readonly eventRetention: number = 60; // Default number of days for retention. Make this readonly for now unless the drop down list is re-enabled.

    state: ReviewByScheduleComponentState =
        ReviewByScheduleComponentState.Loading;
    state_loading_areExistingRulesLoaded: boolean = false;
    state_loading_isTimlineInitialized: boolean = false;
    state_selected_state: ReviewByScheduleComponentState_Selected_State =
        ReviewByScheduleComponentState_Selected_State.Invalid;

    form: FormGroup<ReviewByScheduleForm>;

    save_state: ReviewByScheduleComponent_Save_State =
        ReviewByScheduleComponent_Save_State.NoChanges;

    timeline: TimelineData;
    timelineId: string;

    allCategories: CategoryViewModel[] = [];
    allCases: CasesListViewModel[] = [];
    allTags: TagViewModel[] = [];

    items: DataSet<RuleDataItem, 'id'> = new DataSet<RuleDataItem, 'id'>();
    items_selected: RuleDataItem = null;

    daterangepicker_start: string;
    daterangepicker_end: string;

    constructor(
        private securaMaxApiService: SecuraMaxApiService,
        private titleService: TitleService,
        private tfb: FormBuilder,
        private visTimelineService: VisTimelineService,
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        private toastr: SnackbarService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.timeline = {
            id: 'reviewByScheduleTimeline',
            items: this.items,
            options: {
                align: 'center',
                autoResize: false,
                editable: false,
                selectable: false,
                stack: false,
                orientation: 'bottom',
                showCurrentTime: true,
                showMajorLabels: true,
                showMinorLabels: true,
                moment: function (date) {
                    return moment.utc(date).local();
                },
            },
        };

        this.form = this.tfb.group({
            metadata: ReviewMetadataControlsComponent.buildFormPart(this.tfb),
            start: ['', Validators.required],
            end: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        const that = this;

        this.titleService.setTitle('Create New Review Rule');

        this.securaMaxApiService.categories_GetAll().subscribe(
            (categories) => {
                this.allCategories = categories;
            },
            (err) => {
                this.toastr.error('Error occurred while getting categories.');
            }
        );

        this.securaMaxApiService.cases_Search('', 100).subscribe(
            (cases) => {
                this.allCases = cases;
            },
            (err) => {
                this.toastr.error('Error occurred while getting cases.');
            }
        );

        this.securaMaxApiService.tags_GetAll().subscribe(
            (tags) => {
                this.allTags = tags;
            },
            (err) => {
                this.toastr.error('Error occurred while getting tags.');
            }
        );

        this.form.controls.metadata.controls.title.valueChanges.subscribe(
            (x) => {
                if (that.state !== ReviewByScheduleComponentState.Selected) {
                    return;
                }

                let it = that.items.get(that.items_selected.id);
                it.content = x;
                that.items.remove(it);
                that.items.add(it);
                that.visTimelineService.setSelectionToId(
                    that.timelineId,
                    that.items_selected.id
                );
                that.items_selected = it;
            }
        );

        this.form.controls.start.valueChanges.subscribe((x) => {
            if (that.state !== ReviewByScheduleComponentState.Selected) {
                return;
            }

            let a = moment(x).toDate();
            let it = that.items.get(that.items_selected.id);
            it.start = a;
            that.items.remove(it);
            that.items.add(it);
            that.visTimelineService.setSelectionToId(
                that.timelineId,
                that.items_selected.id
            );
            that.items_selected = it;
        });

        this.form.controls.end.valueChanges.subscribe((x) => {
            if (that.state !== ReviewByScheduleComponentState.Selected) {
                return;
            }

            let a = moment(x).toDate();
            let it = that.items.get(that.items_selected.id);
            it.end = a;
            that.items.remove(it);
            that.items.add(it);
            that.visTimelineService.setSelectionToId(
                that.timelineId,
                that.items_selected.id
            );
            that.items_selected = it;
        });

        this.somethingChanged(
            'the form completed ngOnInit and wants to load timeline'
        );
    }

    clear_clicked(): void {
        this.somethingChanged('the form wants to be cleared');
    }

    goToNow_clicked(): void {
        this.visTimelineService.setWindow(
            this.timelineId,
            moment().add(-2, 'days').toDate(),
            moment().add(2, 'days').toDate()
        );
    }

    new_clicked(): void {
        this.somethingChanged('a new rule wants to be created');
    }

    handleANewRuleWantsToBeCreated(): void {
        if (this.state_loading_isTimlineInitialized === false) {
            return;
        }

        const newItem: RuleDataItem = {
            start: moment().toDate(),
            end: moment().add(1, 'days').toDate(),
            content: 'New',
            rule: new TimeBasedTagsListViewModel(),
        };
        newItem.rule.categories = [];
        newItem.rule.tags = [];
        const x = this.items.add([newItem]);
        const id = x[0];
        this.visTimelineService.setSelectionToId(this.timelineId, id);
        this.items_selected = newItem;
        this.state = ReviewByScheduleComponentState.Selected;
        this.state_selected_state =
            ReviewByScheduleComponentState_Selected_State.SelectedNew;
    }

    somethingChanged(what: string, arg?: any): void {
        var that = this;
        console.group(`somethingChanged: ${what}`);
        console.log(arg);
        console.log(this.state);
        let wasChangeHandled = false;

        if (what === 'the form completed ngOnInit and wants to load timeline') {
            this.securaMaxApiService
                .timeBasedTags_GetAll_NoPagination(true, false, false)
                .subscribe(
                    (data) => {
                        that.somethingChanged(
                            'all existing rules were loaded',
                            data
                        );
                    },
                    (err) => {
                        this.toastr.error(
                            'Error occurred while getting existing rules.'
                        );
                    }
                );
            wasChangeHandled = true;
        }

        if (what === 'all existing rules were loaded') {
            for (let rawItem of arg) {
                let item: RuleDataItem = {
                    content: rawItem.title != null ? rawItem.title : '',
                    start: moment(rawItem.eventStart).toDate(),
                    end: moment(rawItem.eventEnds).toDate(),
                    rule: rawItem,
                };
                this.items.add(item);
            }
        }

        if (what === 'a new rule wants to be created') {
            this.handleANewRuleWantsToBeCreated();
            wasChangeHandled = true;
        }

        if (what === 'the timeline was initialized') {
            this.state_loading_isTimlineInitialized = true;
            this.somethingChanged('a new rule wants to be created');
            wasChangeHandled = true;
        }

        if (what === 'all existing rules were loaded') {
            this.state_loading_areExistingRulesLoaded = true;
            wasChangeHandled = true;
        }

        if (what === 'the form wants to be cleared') {
            this.state = ReviewByScheduleComponentState.Clearing;
            this.somethingChanged(
                'the form completed ngOnInit and wants to load timeline'
            );
            wasChangeHandled = true;
        }

        if (what === 'save attempted') {
            let canSave = true;

            this.form.markAllAsTouched();

            if (!this.form.valid) {
                this.toastr.error('There are issues that need addressing.');
                canSave = false;
            }

            if (this.doesSelectedEventOverlapExisting()) {
                this.toastr.error(
                    'The selected rule overlaps an existing rule - please fix before attempting to save again.'
                );
                canSave = false;
            }

            if (canSave) {
                this.state = ReviewByScheduleComponentState.Saving;
                const timeBasedTagVm = new TimeBasedTagsViewModel();
                timeBasedTagVm.title =
                    this.form.controls.metadata.controls.title.value;
                timeBasedTagVm.description =
                    this.form.controls.metadata.controls.description.value;
                timeBasedTagVm.eventRetentionDays = this.eventRetention;
                timeBasedTagVm.eventStart = moment(
                    this.items_selected.start
                ).toDate();
                timeBasedTagVm.eventEnds = moment(
                    this.items_selected.end
                ).toDate();
                timeBasedTagVm.categories =
                    this.form.controls.metadata.controls.categoriesForm.value;
                timeBasedTagVm.cases = this.form.controls.metadata.controls.casesForm.value;
                timeBasedTagVm.id = this.items_selected.rule.id;

                timeBasedTagVm.tags = [];
                for (let rawTag of this.form.controls.metadata.controls.tags
                    .controls) {
                    const tag = rawTag as any;
                    const x = new TimeBasedTagsAutoTaggedTagsViewModel();
                    x.tagId = this.allTags.filter(
                        (x) => x.name === tag.name
                    )[0].id;
                    if (Array.isArray(tag.value)) {
                        x.value = null
                    } else {
                        x.value = tag.value;
                    }
                    timeBasedTagVm.tags.push(x);
                }

                this.state = ReviewByScheduleComponentState.Saving;
                this.securaMaxApiService
                    .timeBasedTags_CreateOrUpdate(timeBasedTagVm)
                    .subscribe(
                        function (data) {
                            that.somethingChanged('saved', arg);
                        },
                        (err) => {
                            if (err.status == 400) {
                                this.toastr.error(err.response);
                            } else {
                                this.toastr.error(
                                    'Error occurred while creating the review by schedule.'
                                );
                            }
                            that.somethingChanged('error saving');
                        }
                    );
            }

            wasChangeHandled = true;
        }

        if (what === 'saved') {
            this.toastr.success('Changes saved.');
            if (arg === 'create another') {
                this.router
                    .navigate(['..', 'list'], {
                        relativeTo: this.route,
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.router.navigate(['..', 'new'], {
                                relativeTo: this.route,
                            });
                        }, 0);
                    });
            } else {
                this.router.navigate(['..', 'list'], {
                    relativeTo: this.route,
                });
            }
            wasChangeHandled = true;
        }

        if (what === 'error saving') {
            wasChangeHandled = true;
        }

        if (wasChangeHandled === false) {
            console.log(this.state);
            console.groupEnd();
            this.changeDetectorRef.detectChanges();
            throw new Error(
                `Unhandled change: current state: ${this.state} what: ${what}`
            );
        }

        console.log(this.state);
        console.groupEnd();
        this.changeDetectorRef.detectChanges();
    }

    momiso(mod?: (x: any) => any, param?: any): string {
        let result = '';
        if (!mod) {
            result = moment(param).format('YYYY-MM-DDTHH:mm');
        } else {
            result = mod(moment(param).local()).format('YYYY-MM-DDTHH:mm');
        }
        return result;
    }

    timeline_initialized(timelineId) {
        this.timelineId = timelineId;
        this.visTimelineService.setWindow(
            this.timelineId,
            moment().add(-2, 'days').toDate(),
            moment().add(2, 'days').toDate()
        );
        this.visTimelineService.setOptions(timelineId, this.timeline.options);
        this.somethingChanged('the timeline was initialized');
    }

    doesSelectedEventOverlapExisting() {
        const timeline_items = this.timeline.items.get();
        for (let item of timeline_items) {
            if (item.id === this.items_selected.id) {
                continue;
            }
            const isSelectedStartBetweenItemStartAndEnd = moment(
                this.items_selected.start
            ).isBetween(item.start, item.end);
            const isSelectedEndBetweenItemStartAndEnd = moment(
                this.items_selected.end
            ).isBetween(item.start, item.end);
            const isSelectedStartSameAsItemStartOrEnd =
                moment(this.items_selected.start).isSame(item.start) ||
                moment(this.items_selected.start).isSame(item.end);
            const isSelectedEndSameAsItemStartOrEnd =
                moment(this.items_selected.end).isSame(item.start) ||
                moment(this.items_selected.start).isSame(item.end);
            if (
                isSelectedStartBetweenItemStartAndEnd ||
                isSelectedEndBetweenItemStartAndEnd ||
                isSelectedStartSameAsItemStartOrEnd ||
                isSelectedEndSameAsItemStartOrEnd
            ) {
                return true;
            }
        }

        return false;
    }

    save() {
        this.somethingChanged('save attempted');
    }

    daterangepicker_change($event: any) {
        this.form.controls.start.setValue(this.daterangepicker_start);
        this.form.controls.end.setValue(this.daterangepicker_end);
    }

    saveAndCreateAnother_click() {
        this.somethingChanged('save attempted', 'create another');
    }
}
