import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import {
    PVTransferDeviceViewModel,
    SecuraMaxApiService,
} from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from '../../../services/title.service';
import { CreateRequestDialogComponent } from 'src/app/shared/components/create-request-dialog/create-request-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-transfer-devices-list',
    templateUrl: './transfer-devices-list.component.html',
    styleUrls: ['./transfer-devices-list.component.css'],
})
export class TransferDevicesListComponent implements OnInit {
    displayedColumns: string[] = [
        'type',
        'name',
        'associatedUser',
        'lastConnected',
        'status',
        'modifyButton',
    ]; // Header for the table columns
    datasource: PVTransferDeviceViewModel[]; // Contains the devices list data.
    dataSourceOriginal: PVTransferDeviceViewModel[];
    searchText: string = '';
    sort: Sort;
    
    protected readonly environment = environment;

    constructor(
        private api: SecuraMaxApiService,
        private toastr: SnackbarService,
        private dialog: MatDialog,
        private titleService: TitleService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle('Devices');
        this.api.device_List().subscribe(
            (data) => {
                this.datasource = data;
                this.dataSourceOriginal = data.slice();
            },
            (err) => {
                this.toastr.error('An error occurred while loading devices.');
            }
        );
    }

    onSearch(search: string) {
        this.searchText = search;
        this.datasource = this.dataSourceOriginal.filter((val) => {
            return (
                val.deviceType?.indexOf(this.searchText) > -1 ||
                val.lastConnected?.toString()?.indexOf(this.searchText) > -1 ||
                val.associatedUser?.indexOf(this.searchText) > -1 ||
                val.serial?.indexOf(this.searchText) > -1 ||
                val.unitId?.indexOf(this.searchText) > -1
            );
        });

        this.sortTable(this.sort);
    }

    canRequestVideo(device: PVTransferDeviceViewModel) {
        return device.lastConnected != null && device.isDisabled === false;
    }

    sortTable(sort: Sort) {
        let data = this.datasource.slice();
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            this.datasource = data;
        }

        let sorted = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'type':
                    return this.compare(a.deviceType, b.deviceType, isAsc);
                case 'name':
                    return this.compare(a.unitId, b.unitId, isAsc);
                case 'serial':
                    return this.compare(a.serial, b.serial, isAsc);
                case 'associatedUser':
                    return this.compare(
                        a.associatedUser,
                        b.associatedUser,
                        isAsc
                    );
                case 'lastConnected':
                    return this.compareDate(
                        a.lastConnected,
                        b.lastConnected,
                        isAsc
                    );
            }
        });
        this.datasource = sorted;
    }

    compare(a: string, b: string, isAsc: boolean): number {
        if (a == b) {
            return 0;
        } else if (a > b) {
            return isAsc ? 1 : -1;
        } else {
            return isAsc ? -1 : 1;
        }
    }

    compareDate(a: Date, b: Date, isAsc: boolean): number {
        if (a == b) {
            return 0;
        } else if (a > b) {
            return isAsc ? 1 : -1;
        } else {
            return isAsc ? -1 : 1;
        }
    }

    handleVideoRequestClick(element: PVTransferDeviceViewModel) {
        const dialogRef = this.dialog.open(CreateRequestDialogComponent, {
            data: { id: element.id },
        });
        dialogRef.afterClosed().subscribe((result: any) => {});
    }
}
