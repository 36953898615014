<h2 mat-dialog-title i18n>Cases Help</h2>
<mat-dialog-content class="mat-typography">
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Overview">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>What are Cases?</h3>
                    <p class="mb-1" i18n>Cases allows users an easy way to group multiple documents together and share with any user.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>How does it work?</h3>
                    <p class="mb-1" i18n>When a user creates a case they have the ability to add any document to the case that they have the permissions to access . The user can then choose to share the case to any user to be viewed. Cases created by the user or shared with
                        the user are displayed in the cases home page list.</p>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Create">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Identifier</h3>
                    <p class="mb-1" i18n> A reference name that represents the case.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Description</h3>
                    <p class="mb-1" i18n> An optional explaination of the case. </p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Retention</h3>
                    <p class="mb-1" i18n>Retention settings affect the document retention period in a case. There are two options for retention in a case. 1) Use Document Retention: A document is retained in SecuraMax either using the 'Site Settings' or the retention setting
                        in a category assined to a document. 2) Case Retention: This will retain all documents in the case overriding the 'Site Settings' or 'Category' retention setting in a document.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Case Can Be Edited By Shared User</h3>
                    <p class="mb-1" i18n>When a Case is share with a user the user inherently has permission to view that case but not edit the case. Checking the box will allow the shared users to be able to Add/Remove documents from the case and change Case details.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Adding or Removing Documents.</h3>
                    <p class="mb-1" i18n>Documents can be added or removed from a case. They can be searched by name or start/end date. When a document is added to a case the user has the ability to lock the document from being deleted.</p>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Details">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Status</h3>
                    <p class="mb-1" i18n>Displays if a case is currently open or has been closed. If a case is closed then any retention setting for the documents on the case will revert to using either the Site Settings Retention or the Categories Retention for the document.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Identifier</h3>
                    <p class="mb-1" i18n> A reference name that represents the case.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Description</h3>
                    <p class="mb-1" i18n> An optional explanation of the case </p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Retention</h3>
                    <p class="mb-1" i18n>Retention settings affect the document retention period in a case. There are two options for retention in a case. 1) Use Document Retention: A document is retained in SecuraMax either using the 'Site Settings' or the retention setting
                        in a category assined to a document. 2) Case Retention: This will retain all documents in the case overriding the 'Site Settings' or 'Category' retention setting in a document.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Case Owner</h3>
                    <p class="mb-1" i18n> The current owner of the case.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Created By</h3>
                    <p class="mb-1" i18n> The creator of the case. </p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Sharing A Case</h3>
                    <h4 i18n>
                        While creating or editing a case a user can share the case with any other user. A share can provide certain permissions to a case.
                    </h4>

                    <ol>
                        <li i18n>Edit Documents in a case allows a user to edit the metadata on the documents.</li>
                        <li i18n>Download Documents allows the user to download the case documents.</li>
                        <li i18n>Edit Case allows a user to add/remove documents or shares from a case.</li>
                        <li i18n>Reshare Case allows a user to reshare a case that has been shared with them.</li>
                    </ol>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Download Selected</h3>
                    <p class="mb-1" i18n>Select the checkboxes of the documents you wish to download and click 'Download Selected' button. This will create individual pop-ups in your browser to select where to save each video.
                        <b>If you are having issues downloading all files please consult the FAQ section on how to allow
                            multiple pop-ups.</b>
                    </p>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="FAQ">
            <div class="list-group">
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Can I share a case to multiple users at once</h3>
                    <p class="mb-1" i18n>Yes, On the details page of the Case you can select the checkboxes of the documents you wish to share and click 'Share Selected' button. The permissions and expire time will be applied to the shared user at the same time.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Can I download multiple documents at once?</h3>
                    <p class="mb-1" i18n>Yes, On the details page of the Case you can select the checkboxes of the documents you wish to download and click 'Download Selected' button.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Why am I not getting all of the popup windows when downloading multiple documents?
                    </h3>
                    <p class="mb-1" i18n>Certain browsers block multiple pop-ups for security reasons. You can create an exception to allow your this site to have multiple pop-ups.</p>
                    <h4 i18n>
                        For Firefox:
                    </h4>

                    <pre i18n>
1) Select the 'Open Menu' icon and click on 'Options'.
2) There will be a setting called 'Block pop-up windows' with an 'Exceptions...' button. (If you don't see this option try searching for it in the search bar of the page.)
3) Select 'Exceptions...' button and add the url of this website to the list.
4) Click 'Save Changes' and close the window. You should be able to download multiple documents.
                </pre>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>What is the difference between Case 'Audit' and the Document 'Audit'?</h3>
                    <p class="mb-1" i18n>A Case Audit contains only audit information that explicitly happened on the case. I.E. If someone edited the details or if someone removed a share. A Document Audit contains only audit information that explicitly occured on the document
                        outside of the Case.</p>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>Why am I seeing a Case in the case list that isn't explictly shared with me?</h3>
                    <h4 class="mb-1" i18n>There are 2 ways to be able to view a case.</h4>
                    <pre i18n>
1) A user can explictly share a case with you via the share mechanism on the Case Details page.
2) As a user, you belong to a 'Group' that the system administrator has setup. The group has permissions like 'view' and 'edit'. If your system administrator has given you the 'view' permission you will see cases of other users in your group even without them explicitly sharing the case with you.
                </pre>
                </div>
                <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
                    <h3 class="mb-1" i18n>What are the Document Start Time and Document End Time columns?</h3>
                    <p class="mb-1" i18n>The Document Start Time displays the start time of the earliest document start time in the case. The Document End Time displays the start time of the latest document start time in the case.</p>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="smx-critical-button" mat-dialog-close cdkFocusInitial i18n>Close</button>
</mat-dialog-actions>
