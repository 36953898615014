import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-by-time-help-dialog',
  templateUrl: './review-by-time-help-dialog.component.html',
  styleUrls: ['./review-by-time-help-dialog.component.css']
})
export class HelpReviewByScheduleDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
