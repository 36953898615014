import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
    systemGroups: GroupViewModel[];
    customGroups: GroupViewModel[];

    groupsLoaded: boolean = false;

    constructor(private api: SecuraMaxApiService,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: TitleService,
        private toastr: SnackbarService
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle('Manage Groups');
        //reload when child route says to.
        this.route.url.subscribe((segments) => {
            if (this.router.getCurrentNavigation()?.extras?.state?.reload) {
                this.load();
            }
        });

        this.load();
    }

    load() {
        this.api.group_GetAll().toPromise().then((data) => {
            this.systemGroups = [];
            this.customGroups = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].isSystemGroup === true) {
                    this.systemGroups.push(data[i]);
                } else {
                    this.customGroups.push(data[i]);
                }
            }

            this.groupsLoaded = true;
        }, (err) => {
            this.toastr.error('Error occurred while getting the groups');
        });
    }

}
