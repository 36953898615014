import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-devices-navigation',
  templateUrl: './devices-navigation.component.html',
  styleUrls: ['./devices-navigation.component.scss']
})
export class DevicesNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
