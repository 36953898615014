import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { EditUserViewModel, UsersService } from 'src/app/services/api2';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-profile',
    //template: '<div><mat-form-field></mat-form-field></div>',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
    user: EditUserViewModel;
    userLoaded: boolean = false;
    isSaving: boolean = false;

    constructor(
        private titleService: TitleService,
        private toastr: SnackbarService,
        public userService: UserService,
        private usersService: UsersService
    ) {}

    ngOnInit() {
        this.titleService.setTitle('Profile');

        this.usersService.apiApiUsersCurrentUserV2Get().subscribe((x) => {
            this.user = x;
            if (this.user.defaultHomepage !== "review" && this.user.defaultHomepage !== "live") {
                this.user.defaultHomepage = "review";
            }
            this.userLoaded = true;
        });
    }

    save() {
        if (this.isSaving) {
            return;
        }

        this.isSaving = true;
        this.toastr.success('Saving...');
        this.usersService.apiApiUsersUpdateCurrentUserPut(this.user).subscribe(
            () => {
                this.isSaving = false;
                this.toastr.success('Saved!');
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            },
            () => {
                this.isSaving = false;
                this.toastr.error('Failed to save changes.');
            }
        );
    }
}
