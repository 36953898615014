<div class="outer-container">
    <mat-card class="details-card">
        <mat-card-header>
            <mat-card-title i18n
                >Step 1: Power on camera, point lens 8" from QR codes below,
                then press "F1" on the side of the camera</mat-card-title
            >
        </mat-card-header>
        <mat-card-content class="p-3">
            <div class="d-flex justify-content-around">
                <img height="275" src="/assets/images/BC4ScanSetup.jpg" />
                <div
                    class="qr-container d-flex justify-content-center align-items-center mb-3"
                >
                    <qrcode
                        [qrdata]="pvTransferUrl"
                        [width]="125"
                        [errorCorrectionLevel]="'M'"
                    ></qrcode>
                    <qrcode
                        [qrdata]="pvTransferUrl"
                        [width]="175"
                        [errorCorrectionLevel]="'M'"
                    ></qrcode>
                    <qrcode
                        [qrdata]="pvTransferUrl"
                        [width]="250"
                        [errorCorrectionLevel]="'M'"
                    ></qrcode>
                </div>
            </div>
            <p class="px-4 text-center" i18n>
                Multiple QR codes may appear above, they are the same content
                but different sizes to improve scanning. Hold camera about 8"
                from the screen, if scan does not occur immediately, tilt or
                move the camera slightly. Pressing the "F1" button on the side
                of the camera starts a scan for 5 seconds, you may need to press
                it again if it does not scan the first time.
            </p>
        </mat-card-content>

        <mat-card-footer>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-footer>
    </mat-card>

    <mat-card class="details-card">
        <mat-card-header>
            <mat-card-title i18n
                >Step 2: Dock camera to contact your SecuraMax
                server</mat-card-title
            >
        </mat-card-header>
    </mat-card>

    <mat-card class="details-card">
        <mat-card-header>
            <mat-card-title i18n
                >Step 3: When camera appears below, select user assignment to
                complete setup
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="pr-3 mx-3 mt-2">
            <div style="display: flex; text-align: center; margin: 30px; width: 100%">
                <label i18n style="margin-right: 20px;"> Checking for new devices...</label>
                <mat-spinner color="accent" [diameter]="30"></mat-spinner>
            </div>

            <table mat-table [dataSource]="devicePendingApprovalModelList" #pendingTable>
                <!-- Serial Number Column -->
                <ng-container matColumnDef="serialNumber">
                    <th mat-header-cell *matHeaderCellDef i18n>
                        Serial Number
                    </th>

                    <td mat-cell *matCellDef="let element">
                        <label>{{ element.serialNumber }}</label>
                    </td>
                </ng-container>

                <!-- Status Connected Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef i18n> Status</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.isApproved">
                            <span i18n>Not Setup</span>
                        </div>
                        <div *ngIf="element.isApproved">
                            <span i18n>Finalizing Setup...</span>
                        </div>
                    </td>
                </ng-container>

                <!-- User Mode Column -->
                <ng-container matColumnDef="userMode">
                    <th mat-header-cell *matHeaderCellDef i18n> User Mode </th>
                    <td mat-cell *matCellDef="let element" class="pt-3">
                        <mat-form-field appearance="outline" class="px-4">
                            <mat-select [(value)]="userMode" name="userModeId" [disabled]="element.isApproved">
                                <mat-option [value]="0" i18n>
                                    Single User
                                </mat-option>
                                <mat-option [value]="1" i18n>
                                    Multi User
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <!-- User Assignment Column -->
                <ng-container matColumnDef="userAssignment">
                    <th mat-header-cell *matHeaderCellDef i18n>
                        User Assignment
                    </th>
                    <td mat-cell *matCellDef="let element" class="pt-3">
                        <div *ngIf="userMode == 0">
                            <app-user-select
                                class="px-4"
                                [(userId)]="element.associatedUserId"
                                includeShareUsers="false"
                                [disabled]="element.isApproved"
                            ></app-user-select>
                        </div>
                        <div *ngIf="userMode == 1">
                            <span i18n
                                >Assigned through RFID scan before each
                                use.</span
                            >
                        </div>
                    </td>
                </ng-container>

                <!-- Finish Column -->
                <ng-container matColumnDef="finishButton">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div class="header-align-right">
                            <button
                                mat-button
                                class="smx-primary-button approve-button"
                                (click)="OnApproveDevice(element)"
                                [disabled]="element.isApproved"
                                i18n
                            >
                                Finish & Assign
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
