import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    SecuraMaxApiService
} from 'src/app/services/api/securamaxapi.service';
import {DocumentSearchService} from 'src/app/services/document-search.service';
import {UserService} from 'src/app/services/user/user.service';
import {UserSelectModel} from 'src/app/models/user-select.model';
import {documentStatus} from 'src/app/shared/helpers/document-status-const';
import {UserSelectClearService} from 'src/app/services/user/user-select-clear.service';
import {environment} from "../../../environments/environment";
import { EditUserViewModel, UsersService } from 'src/app/services/api2';

export class DocumentStatusViewModel {
    name: string;
    statusId: number;
}

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    navbarUserSelectName: string = "navbarUserSelect";
    user: EditUserViewModel;
    data: UserSelectModel = new UserSelectModel();      // Contains the user selected for searching.

    isReadyForRender: boolean = false;

    logoLink: string = "/";

    constructor(
        public route: ActivatedRoute,
        public userService: UserService,
        private usersService: UsersService,

        private apiService: SecuraMaxApiService,
        public documentSearchService: DocumentSearchService,
        public router: Router,
        private userSelectClearService: UserSelectClearService) {
    }

    ngOnInit(): void {
        this.usersService.apiApiUsersCurrentUserV2Get().subscribe(x => {
            this.user = x;

            if (this.userService.hasAccessToLive()) {
                if (this.user.defaultHomepage && this.user.defaultHomepage === "live") {
                    this.logoLink = "/live";
                    if (this.router.url === "/") {
                        this.router.navigateByUrl(this.logoLink).then(() => {
                            this.isReadyForRender = true;
                        });
                    } else {
                        this.isReadyForRender = true;
                    }
                } else {
                    this.isReadyForRender = true;
                }
            } else {
                this.isReadyForRender = true;
            }
        });
    }

    getSearchableDocumentStatuses(): DocumentStatusViewModel[] {
        var statusArray = [];

        var active = new DocumentStatusViewModel();
        active.name = "Active";
        active.statusId = documentStatus.active;
        statusArray.push(active);

        var archived = new DocumentStatusViewModel();
        archived.name = "Archived";
        archived.statusId = documentStatus.archived;
        statusArray.push(archived);

        var pendingRetrieval = new DocumentStatusViewModel();
        pendingRetrieval.name = "Pending Archive Retrieval";
        pendingRetrieval.statusId = documentStatus.pendingArchiveRetrieval;
        statusArray.push(pendingRetrieval);

        var deleted = new DocumentStatusViewModel();
        deleted.name = "Deleted";
        deleted.statusId = documentStatus.deleted;
        statusArray.push(deleted);

        return statusArray;
    }

    siteToggle_click() {
        if (this.router.url.startsWith("/live")) {
            this.router.navigateByUrl("/");
        } else {
            this.router.navigateByUrl("/live");
        }
    }

    onSearch(searchText: string) {
        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        currentSearch.searchTerm = searchText;
        this.documentSearchService.updateSearch(currentSearch);
    }

    protected readonly environment = environment;
}
