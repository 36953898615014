import { Component, OnInit } from '@angular/core';
import { UsageHistoryViewModel } from '../../../services/api/securamaxapi.service';
import { PagingApiService } from 'src/app/services/paging-api.service';
import { TitleService } from 'src/app/services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-usage-history',
  templateUrl: './usage-history.component.html',
  styleUrls: ['./usage-history.component.css']
})
export class UsageHistoryComponent implements OnInit {
  usageHistory: UsageHistoryViewModel[];
  filter: any = { searchTerm: '' };
  totalItems: number;
  modelFromJS: any = UsageHistoryViewModel.fromJS;
  isUnlimitedPlanUser: boolean = false;

;

  constructor(private pagingAPIService: PagingApiService, private titleService: TitleService, private toastr: SnackbarService, private userService: UserService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Usage History Report');

    this.userService.getCurrentUser().then((user) => {
      if (user.isUnlimitedPlan)
      {
        this.isUnlimitedPlanUser = true;
      }
    });
  }

  onDataSourceUpdate(data: UsageHistoryViewModel[]) {
    this.usageHistory = data;
  }

  numItemsUpdated(data: number) {
    this.totalItems = data;
  }

  onSearch() {
    this.pagingAPIService.fetchData<UsageHistoryViewModel>('/api/api/Usage/History', this.filter, this.modelFromJS, undefined).subscribe(function (data) {
      this.datasource = data.data;
    }, (err) => {
      this.toastr.error('Error occurred while getting the reports.');
    });
  }

  calcOverage(bytesUsed: number, bytesAllowed: number) {
    return Math.max(bytesUsed - bytesAllowed, 0);
  }

  firstDay(data) {
    return new Date(data.year, data.month - 1, 1);
  }

  lastDay(data) {
    return new Date(data.year, data.month, 0);
  }

  filterChanged() {
    this.filter = Object.assign({}, this.filter);
  }
}
