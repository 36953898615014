import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    NgxQrcodeElementTypes,
    NgxQrcodeErrorCorrectionLevels,
    NgxQRCodeModule,
} from '@techiediaries/ngx-qrcode';
import { interval } from 'rxjs';
import {
    DeviceCodeModel,
    IDeviceCode,
    SecuraMaxApiService,
} from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from '../../../services/title.service';
import { DeviceService } from 'src/app/services/api2/api/device.service';
import { GetPendingItemModel, DeviceRegisterControllerV2Service } from 'src/app/services/api2';
import GUI from 'lil-gui';
import { PvTransferDeviceRegistration } from 'src/app/services/api/securamaxapi.service';

export class PVTransferQRCode {
    pvUrl: string;
}

export class DeviceCodeItem implements IDeviceCode {
    code: string;
    approved: boolean;
    created: Date;
    IP: string;
    associatedUserId: number;
    enabledAlarmTransfers: boolean;
    isCompletedRegistration: boolean;
    serial: string;
    deviceName: string;
    deviceId: string;
    configurationType: number = 0;
    configurationItem: any;
}

@Component({
    selector: 'app-devices-bc4-setup2',
    templateUrl: './devices-bc4-setup2.component.html',
    styleUrls: ['./devices-bc4-setup2.component.scss'],
})
export class DevicesBc4Setup2Component implements OnInit, OnDestroy {
    debugGui: GUI;
    debugState = {
        addDeviceDVR: () => {
            this.deviceRegisterService
                .apiPVTransferApiDeviceRegisterV2DebugAddTestingDevicePost()
                .subscribe((response) => {
                    const model = new PvTransferDeviceRegistration();
                    model.code = response.Code;
                    model.serial = `123H9HD${this.debug_getRandomSerial()}`;
                    model.unitId = 'someunit';
                    model.numberOfCameras = 1;
                    model.timezone = 'America/Chicago';
                    this.deviceRegisterService
                        .apiPVTransferApiDeviceRegisterV2Post(model)
                        .subscribe(() => {});
                });
        },
        addDeviceBC4: () => {
            this.deviceRegisterService
                .apiPVTransferApiDeviceRegisterV2DebugAddTestingDevicePost()
                .subscribe((response) => {
                    const model = new PvTransferDeviceRegistration();
                    model.code = response.Code;
                    model.serial = `123B4${this.debug_getRandomSerial()}`;
                    model.unitId = 'someunit';
                    model.numberOfCameras = 1;
                    model.timezone = 'America/Chicago';
                    this.deviceRegisterService
                        .apiPVTransferApiDeviceRegisterV2Post(model)
                        .subscribe(() => {});
                });
        },
        clearDevices: () => {
            this.deviceRegisterService
                .apiPVTransferApiDeviceRegisterV2DebugClearPendingDevicesForTenantPost()
                .subscribe(() => {});
        },
    };
    displayedColumns: string[] = [
        'serialNumber',
        'status',
        'userMode',
        'userAssignment',
        'finishButton',
    ];
    devicePendingApprovalModelList: GetPendingItemModel[] = [];
    devicesPendingApprovalInterval: any = null; // Holds the $interval object so the interval can be canceled.
    pvTransferQrCode: PVTransferQRCode = new PVTransferQRCode();
    pvTransferUrl: string = '';
    elementType: string = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
    userMode: number = 0;
    @ViewChild("pendingTable") pendingTable = null;

    constructor(
        private deviceRegisterService: DeviceRegisterControllerV2Service,
        private toastr: SnackbarService,
        private titleService: TitleService,
        private route: ActivatedRoute,
        private deviceService: DeviceService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle('Add BODYCAM 4');

        this.pvTransferQrCode.pvUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            '/api/PVTransfer';
        this.pvTransferUrl = JSON.stringify(this.pvTransferQrCode);

        this.route.queryParams.subscribe((params) => {
            if (params.ip && params.ip.length > 0) {
                this.pvTransferQrCode.pvUrl =
                    window.location.protocol +
                    '//' +
                    params.ip +
                    '/api/PVTransfer';
                this.pvTransferUrl = JSON.stringify(this.pvTransferQrCode);
            }
        });

        // Kick off polling to check Devices Waiting Approval.
        this.devicesPendingApprovalInterval = interval(5000).subscribe((x) => {
            this.CheckDevicesPendingApprovalInterval();
        });
    }

    /**
     * Stops the interval that checks for devices awaiting approval.
     */
    ngOnDestroy(): void {
        this.devicesPendingApprovalInterval.unsubscribe();
    }

    /**
     * Background interval that polls for the Server for Devices Pending Approval.
     * */
    CheckDevicesPendingApprovalInterval() {
        this.deviceService
            .apiPVTransferApiDeviceGetDevicesPendingApprovalV2Get()
            .subscribe((update) => {
                const bc4Pending = update.pending.filter(pending => {
                     return pending.serialNumber && pending.serialNumber.includes("B4");
                }); 

                const addedPending: GetPendingItemModel[] = [];
                const removedPending: GetPendingItemModel[] = [];
                const updatedPending: GetPendingItemModel[] = [];

                for (const newPending of bc4Pending) {
                    const possibleMatchingPendingForUpdate =
                        this.devicePendingApprovalModelList.find(
                            (x) => x.serialNumber === newPending.serialNumber
                        );
                    if (possibleMatchingPendingForUpdate) {
                        updatedPending.push(newPending);
                    } else {
                        addedPending.push(newPending);
                    }
                }

                for (const pending of this.devicePendingApprovalModelList) {
                    if (
                        !addedPending.find(
                            (x) => x.serialNumber === pending.serialNumber
                        ) &&
                        !updatedPending.find(
                            (x) => x.serialNumber === pending.serialNumber
                        )
                    ) {
                        removedPending.push(pending);
                    }
                }

                for (const pending of addedPending) {
                    this.devicePendingApprovalModelList.push(pending);
                }

                for (const pending of updatedPending) {
                    const matching = this.devicePendingApprovalModelList.find(x => x.serialNumber === pending.serialNumber);
                    matching.isApproved = pending.isApproved;
                }

                for (const pending of removedPending) {
                    const i = this.devicePendingApprovalModelList.findIndex(x => x.serialNumber === pending.serialNumber);
                    this.devicePendingApprovalModelList.splice(i, 1);
                }
                
                this.pendingTable.renderRows();


                // // Creates list that only contains EXISTING items in the UI (devicePendingApprovalModel);
                // const exisitingItemsList = data.filter((o1) => {
                //     return this.devicePendingApprovalModelList.some((o2) => {
                //         return o1.serialNumber === o2.serialNumber;
                //     });
                // });

                // if (exisitingItemsList.length > 0) {
                //     exisitingItemsList.forEach((obj) => {
                //         const index =
                //             this.devicePendingApprovalModelList.findIndex(
                //                 (dev) => dev.serialNumber == obj.serialNumber
                //             );
                //         if (index > -1) {
                //             this.devicePendingApprovalModelList[index].code =
                //                 obj.code;
                //             this.devicePendingApprovalModelList[
                //                 index
                //             ].isApproved = obj.isApproved;
                //         }
                //     });
                // }

                // // Reduced list only leaves NEW items to be added to the UI.
                // // Filters items from retuned data that exist in devicePndingApprovalModel so there are no duplicates in the UI.
                // const reducedList = data.filter((o1) => {
                //     // filter out (!) items in result2
                //     return !this.devicePendingApprovalModelList.some((
                //         o2
                //     ) => {
                //         return o1.serialNumber === o2.serialNumber; // assumes unique id
                //     });
                // });

                // // If there is a new item in the reducedList we set ConfigurationType to '0' for 'Multiple Users'.
                // // and add it to the list in the UI.
                // if (reducedList.length > 0) {
                //     reducedList.map((obj) => {
                //         (obj as any).configurationType = 0;
                //     });

                //     this.devicePendingApprovalModelList =
                //         this.devicePendingApprovalModelList.concat(
                //             reducedList as any
                //         );
                // }

                // // No data at all, reset array.
                // if (data.length == 0) {
                //     this.devicePendingApprovalModelList = [];
                // }
            });
    }

    OnApproveDevice(device: GetPendingItemModel) {
        const deviceCodeModel = new DeviceCodeModel();
        deviceCodeModel.code = device.code;
        deviceCodeModel.enableAlarmTransfers = true;

        // if (device.configurationType == 0)
        //     deviceCodeModel.associatedUserId = device.associatedUserId;

        // if (device.configurationType == 1)
        //     deviceCodeModel.associatedUserId = null;

        this.deviceService
            .apiPVTransferApiDeviceApproveV2Post(deviceCodeModel)
            .subscribe(
                () => {
                    this.toastr.success(
                        'Successfully approved. Waiting for device to finish...'
                    );
                    device.isApproved = true;
                },
                (err) => {
                    console.log(err);
                    this.toastr.error(
                        'A server error occurred while submitting the device approval form. ' +
                            err.response
                    );
                }
            );
    }

    debug_getRandomSerial() {
        return `${Math.floor(Math.random() * 9999)}`.padStart(4, '0');
    }

    debugCreateGui() {
        this.debugGui = new GUI({ title: 'Debug (Shift+F1 to open/close)' });
        this.debugGui.add(this.debugState, 'addDeviceDVR').name('Add DVR Device');
        this.debugGui.add(this.debugState, 'addDeviceBC4').name('Add BC4 Device');
        this.debugGui
            .add(this.debugState, 'clearDevices')
            .name('Clear Devices');
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.shiftKey && event.key === 'F1') {
            event.preventDefault();
            if (this.debugGui) {
                if (this.debugGui._hidden) {
                    this.debugGui.show();
                } else {
                    this.debugGui.hide();
                }
            } else {
                this.debugCreateGui();
            }
        }
    }
}
