import { Component} from '@angular/core';
import {Router} from "@angular/router";
import {AnnotateService} from "../../../services/annotate.service";
import { MetadataDayViewModel, MetadataService } from 'src/app/services/api2';


@Component({
    selector: 'app-annotate2-step1',
    templateUrl: './annotate2-step1.component.html',
    styleUrls: ['./annotate2-step1.component.css']
})
export class Annotate2Step1Component {
    metadataPendingDays: MetadataDayViewModel[] = [];
    metadataPendingDays_loaded: boolean = false;

    constructor(
        private router: Router,
        private metadataService: MetadataService,
        private annotateService: AnnotateService
    ) {
    }

    ngAfterContentInit() {
        const x = this.annotateService.currentAnnotation.getValue();
        x.step = 1;
        x.metadataPendingDay = null;
        this.annotateService.currentAnnotation.next(x);
        console.log(this.annotateService.currentAnnotation.getValue());

        this.metadataService.apiApiMetadataPendingDaysV2Get().subscribe(data => {
            this.metadataPendingDays = data;
            this.metadataPendingDays_loaded = true;
        });
    }

    selectDay(dayIndex) {
        const x = this.annotateService.currentAnnotation.getValue();
        x.metadataPendingDay = this.metadataPendingDays[dayIndex];
        this.annotateService.currentAnnotation.next(x);
        console.log(this.annotateService.currentAnnotation.getValue());
        this.router.navigate(['/annotate2/step2']).then(() => {
        });
    }
}
