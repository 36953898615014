import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SearchFilter} from 'src/app/models/search-filter.model';
import {IAnnotationState} from "./annotate.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import * as moment from "moment";
import {filter} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class DocumentSearchService {
    public currentSearchFilter: BehaviorSubject<SearchFilter>;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute
    ) {
        // This seems to work ok, but it feels like it could run into race conditions.

        this.currentSearchFilter = new BehaviorSubject<SearchFilter>({});
        this.activatedRoute.queryParams.subscribe(x => {
            const newSearchFilter: SearchFilter = {};
            Object.assign(newSearchFilter, this.activatedRoute.snapshot.queryParams);
            this.cleanSearch(newSearchFilter);
            this.currentSearchFilter.next(newSearchFilter);
        });
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            const newSearchFilter: SearchFilter = {};
            Object.assign(newSearchFilter, this.activatedRoute.snapshot.queryParams);
            this.cleanSearch(newSearchFilter);
            this.currentSearchFilter.next(newSearchFilter);
        });

    }

    public cleanSearch(possiblyDirtySearch: SearchFilter)
    {
        if (possiblyDirtySearch.isListView === undefined) {
            possiblyDirtySearch.isListView = true;
        }

        if (typeof possiblyDirtySearch.groups === 'string') {
            possiblyDirtySearch.groups = [possiblyDirtySearch.groups];
        }
        if (typeof possiblyDirtySearch.status === 'string') {
            possiblyDirtySearch.status = [possiblyDirtySearch.status];
        }
        if (typeof possiblyDirtySearch.categories === 'string') {
            possiblyDirtySearch.categories = [possiblyDirtySearch.categories];
        }
        for (let [key, value] of Object.entries(possiblyDirtySearch)) {
            if (value === "" || value === undefined || (Array.isArray(value) && value.length === 0)) {
                delete possiblyDirtySearch[key];
                continue;
            }

            if (value === "true") {
                possiblyDirtySearch[key] = true;
                continue;
            }

            if (value === "false") {
                possiblyDirtySearch[key] = false;
                continue;
            }
        }
    }

    public updateSearch(newSearch: SearchFilter) {
        this.cleanSearch(newSearch);
        this.router.navigate(['/search/documents'], {
            queryParams: newSearch
        });
    }
}
