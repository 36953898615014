import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CasesListViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from '../../../services/title.service';
import { HelpCasesDialogComponent } from '../help-cases-dialog/help-cases-dialog.component';
import {Observable} from "rxjs-compat/Observable";
import * as moment from "moment";

export class CaseFilter {
    searchTerm: string;
    caseType: number = 0;
    eventStart: string;
    eventEnd: string;
}

@Component({
    selector: 'app-cases',
    templateUrl: './cases.component.html',
    styleUrls: ['./cases.component.css']
})

export class CasesComponent implements OnInit {

    daterangepicker_start: string;
    daterangepicker_end: string;

    datasource: CasesListViewModel[];
    filter: CaseFilter = new CaseFilter();
    totalItems: number;
    modelFromJS: any = CasesListViewModel.fromJS;
    isLoading: boolean = true;

    onDataSourceUpdate(data: CasesListViewModel[]) {
        this.datasource = data;
    }

    numItemsUpdated(data: number) {
        this.totalItems = data;
    }

    filterChanged() {
        this.filter = Object.assign({}, this.filter);
    }

    search(event: any) {
        this.filter.searchTerm = event;
        this.filterChanged();
    }

    constructor(private api: SecuraMaxApiService, private toastr: SnackbarService, public dialog: MatDialog, private titleService: TitleService, private cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.titleService.setTitle('Cases');
    }

    openHelpDialog() {
        const dialogRef = this.dialog.open(HelpCasesDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    onIsLoadingChange($event: boolean) {
        this.isLoading = $event;
        this.cd.detectChanges();
    }

    daterangepicker_change($event: any) {
        this.filter.eventStart = moment(this.daterangepicker_start).toISOString();
        this.filter.eventEnd = moment(this.daterangepicker_end).toISOString();
        this.filterChanged();
    }
}
