/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ShortTermAccessInformation { 
    isRead?: boolean;
    isWrite?: boolean;
    expiresIn?: string;
    sas?: string | null;
    url?: string | null;
    id?: string | null;
}

