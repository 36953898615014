import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tag-types',
  templateUrl: './tag-types.component.html',
  styleUrls: ['./tag-types.component.css']
})
export class TagTypesComponent implements OnInit {

  @Input() tag: any;
  @Output() deleteClick = new EventEmitter<any>();

  tagListOptions: any[] = [];
  latlongs: any = {};

  constructor() { }

  ngOnInit(): void {
    if (this.tag.tagTypeId ? this.tag.tagTypeId===3 : this.tag.tagTypeId === 3) {
        this.tagListOptions = JSON.parse(this.tag.tagTypeConfigurationData);
    }
    else if (this.tag.tagTypeId ? this.tag.tagTypeId === 2 : this.tag.tagTypeId === 2) {
      
      if (this.tag.value) {
        this.latlongs = JSON.parse(this.tag.value);
      } else {
        this.latlongs = { latitude: 0, longitude: 0 };
      }
    }
  }

  deleteTag(tag){
    this.deleteClick.emit(tag);
  }

  latLongChange(){    
    this.tag.value = JSON.stringify(this.latlongs);
  }

}
