<!-- <div class="pv-feedback-group">
    <button type="button" class="btn btn-warning btn-sm dropdown-toggle" data-placement="left" data-animation="am-flip-x" bs-dropdown aria-haspopup="true" aria-expanded="false">
        <div>{{'Feedback' | translate}}</div>
    </button>
    <ul class="dropdown-menu" role="menu">
        <li>
            <a href="#" ng-click="suggestion()">{{'Make a Suggestion' | translate}}</a>
        </li>
        <li>
            <a href="#" ng-click="bug()">{{'Report a Bug' | translate}}</a>
        </li>
    </ul>
</div> -->

<div class="pv-feedback-group">
    <button class="feedback-button" mat-stroked-button [matMenuTriggerFor]="menu" color="warn">
        <div class="feedback-text" i18n>Feedback</div>
    </button>
    <mat-menu #menu="matMenu">
        <button (click)="selectFeedbackType(feedbackTypes.feedback)" mat-menu-item i18n>Make a Suggestion</button>
        <button (click)="selectFeedbackType(feedbackTypes.bug)" mat-menu-item i18n>Report a Bug</button>
    </mat-menu>
</div>