import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from 'src/app/services/title.service';
import { BC300ListItemViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';

@Component({
  selector: 'app-bc300configs',
  templateUrl: './bc300configs.component.html',
  styleUrls: ['./bc300configs.component.scss']
})
export class Bc300configsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'assignedToGroups', 'devices', 'actions']; // Header for the table columns

  datasource: BC300ListItemViewModel[];     // Contains the devices list data.

  constructor(private api: SecuraMaxApiService, private titleService: TitleService, private toastr: SnackbarService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Manage BC-300 Configurations');
    this.api.deviceConfigurations_GetAll().toPromise().then((res) => {
      this.datasource = res;
    }).catch((err) => {
      this.toastr.error('Error! Please try again later.');
      //log?
    });
  }

}
