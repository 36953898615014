/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LiveViewDeviceCameraViewModel } from './liveViewDeviceCameraViewModel';


export interface LiveViewDeviceViewModel { 
    deviceId?: string;
    deviceName?: string | null;
    serialNumber?: string | null;
    assignedUsername?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    isOnline?: boolean;
    isBodycam?: boolean;
    hasValidLocation?: boolean;
    lastConnected?: string | null;
    deviceTypeName?: string | null;
    deviceModelNumber?: string | null;
    hasLiveMapPermission?: boolean;
    hasLiveViewPermission?: boolean;
    livePw?: string | null;
    cameras?: Array<LiveViewDeviceCameraViewModel> | null;
}

