import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BC300ConfigViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { ActivatedRoute } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-bc300configs-edit',
  templateUrl: './bc300configs-edit.component.html',
  styleUrls: ['./bc300configs-edit.component.css']
})
export class Bc300configsEditComponent implements OnInit {

  id: number = null;
  config: BC300ConfigViewModel;
  loading: boolean = false;
  errMessage: string[];

  constructor(private api: SecuraMaxApiService, private toastr: SnackbarService, private location: Location, private route: ActivatedRoute) { }

  ngOnInit(): void {
    //this.id = $stateParams.id
    this.route.paramMap.subscribe((parammap) => {
      this.id = parseInt(parammap.get('id'));
      this.loading = true;
      this.api.deviceConfigurations_Get(this.id).toPromise().then((data) => {
        this.config = data;
        this.loading = false;
      }, function (err) {
        this.loading = false;
        this.toastr.error('Error loading configuration.');
      });
    });
    
  }

  preEventRecordChanged($event: MatRadioChange) {
    var that = this;
    if ($event.value === 'On') {
      that.config.burstPhotoCapture = 'Off';
      that.config.motionRecording = false;
    }
  }

  save() {
    var that = this;
    that.loading = true;
    that.config.wiFi = false;
    that.api.deviceConfigurations_Put(that.id, that.config).toPromise().then(() => {
      this.loading = false;
      this.toastr.success('Saved!');
      // that.$state.go('lookups.bc300configs', null, { reload: true });
    }).catch((err) => {
      if (err.data.ModelState) {
        that.errMessage = [];
        for (var prop in err.data.ModelState) {
          if (err.data.ModelState.hasOwnProperty(prop)) {
            for (var i = 0; i < err.data.ModelState[prop].length; i++) {
              that.errMessage.push(prop + ': ' + err.data.ModelState[prop][i]);
            }
          }
        }
      }
      that.loading = false;
      this.toastr.error('Error saving!.');
    });
  }


}
