import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  public feedbackTypes: typeof FeedbackType = FeedbackType;

  dialogData: FeedbackDialogData;
  description: string;
  feedbackSending: boolean = false;

  constructor(private userService: UserService,
    private http: HttpClient,
    public dialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeedbackDialogData) {
    this.dialogData = data;
  }

  ngOnInit() {

  }

  onSubmit(): void {
    this.feedbackSending = true;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'x-www-form-urlencoded');

    const body = new FormData();
    body.append('ApplicationId', 'securamax')
    body.append('Referrer', window.location.href)
    body.append('FeedbackType', (this.data.feedbackType == FeedbackType.bug ? 'bug' : 'feedback'))
    body.append('Email', this.userService.user.email)
    body.append('Name', this.userService.user.firstName + ' ' + this.userService.user.lastName)
    body.append('Description', this.description);

    this.http.post('https://files.provisionusa.com/api/Feedback', body, { headers: headers }).subscribe((resp) => {
      this.dialogRef.close(true);
      this.feedbackSending = false;
    });

    // Close the dialog, return true
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}

export enum FeedbackType {
  bug,
  feedback
}
export interface FeedbackDialogData {
  feedbackType: FeedbackType;
}