<div class="container-0">
    <div class="dropscroll-area-0" *ngIf="isLoading">
        <div class="dropscroll-area-1">

            <div class="spinner-0">
                <mat-spinner color="accent"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="dropscroll-area-0" *ngIf="!isLoading && currentTimelinesDataArray">
        <div class="dropscroll-area-1">
            <div class="container-2" *ngIf="currentTimelinesDataArray.length === 0">
                <mat-card>
                    <mat-card-content class="document-item">
                        <div style="grid-column: 1/-1;">No results found for current search and filters.</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="container-2" *ngIf="currentTimelinesDataArray.length > 0">
                <ng-container *ngFor="let timeline of currentTimelinesDataArray">

                    <mat-card>
                        <mat-card-content class="document-item">
                            <ng-container *ngIf="timeline.device">
                                <img src="/assets/images/icons/{{timeline.device.iconName}}" i18n-alt
                                     alt="device image"/>
                                <button mat-raised-button class="ml-2" i18n>{{timeline.device.name}}</button>
                                <small class="ml-2" i18n>{{timeline.device.ownerUserName}}</small>
                            </ng-container>

                            <div class="ml-3" style="grid-column: -2/-1;">
                                <button mat-button class="smx-primary-button" [class.is-disabled]="!timeline.selectedDocumentId"
                                        routerLink="/documents/{{timeline.selectedDocumentId}}" i18n>View Selected
                                    Document
                                </button>
                            </div>

                            <div class="timeline" [visTimeline]="timeline.id" [visTimelineItems]="timeline.items"
                                 [visTimelineGroups]="timeline.groups" [visTimelineOptions]="timeline.options"
                                 (initialized)="registerTimelineEventHandlers($event)"></div>


                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="controls-0">
        <div>
            <mat-radio-button (click)="list_click()">
                <label>List</label>
            </mat-radio-button>
            <mat-radio-button checked>
                <label>Timeline</label>
            </mat-radio-button>
        </div>

        <mat-paginator style="grid-column: -2/-1;" #paginator [length]="pagingModel.totalRecordCount"
                       [pageIndex]="pagingModel_currentPageIndex"
                       [pageSize]="pagingModel_perPage" [pageSizeOptions]="pageSizeOptions" (page)="onPageFired($event)"
        >
        </mat-paginator>
    </div>

</div>
