/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DailyUserTenantUsage { 
    tenantId?: number;
    userId?: number;
    userName?: string | null;
    uploadedBytes?: number;
    numberOfDocumentsUploaded?: number;
    currentDocumentsStoredBytes?: number;
    currentOtherStoredBytes?: number;
    numberOfDocumentsStored?: number;
    numberOfDevices?: number;
    dataDownloaded?: number;
    subdomain?: string | null;
}

