import { ITagViewModel } from 'src/app/services/api/securamaxapi.service';

export class TagSelectViewModel implements ITagViewModel {
    id?: number | undefined;
    name!: string;
    isRequired?: boolean | undefined;
    tagTypeId?: number | undefined;
    tagTypeName?: string | undefined;
    tagTypeConfigurationData?: string | undefined;
    value: string = "";
    tagId: number = null;
  
    constructor(data?: ITagViewModel, id?: number, value?: string) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }

        // Because this is a View Model for the app-tag-types directive we set the 'tagId'
        // to the unique tag id in the database, where as this.id represents a record in the 
        // DocumentTags table.
        this.id = id;
        this.tagId = data.id;
        this.value = value;
    }
  }