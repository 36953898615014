<div *ngIf="loading" class="d-flex flex-column justify-content-center align-items-center mt-3">
    <mat-spinner color="accent"></mat-spinner>
    <h1 i18n>Loading...</h1>
</div>
<div *ngIf="!loading">
    <div class="card mt-3">

        <div class="card-body card-container">

            <h1 i18n>Redaction Details</h1>

            <hr />
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <dl class="dl-horizontal">
                        <dt i18n>Document</dt>
                        <dd>{{redaction.documentName}}</dd>
                        <dt i18n>
                            Type
                        </dt>
                        <dd i18n>Basic Redaction</dd>
                        <dt i18n>Video Trim</dt>
                        <dd>
                            {{redaction.videoStart}} to {{redaction.videoEnd}}
                        </dd>
                        <dt i18n>Blur</dt>
                        <dd>{{redaction.blurAmount}}</dd>
                        <dt i18n>Audio Trim</dt>
                        <dd>{{redaction.audioStart}} to {{redaction.audioEnd}}</dd>
                        <dt><span *ngIf="redaction.redactionStatus===0" i18n>Status</span></dt>
                        <dd *ngIf="redaction.redactionStatus===1">
                            <!--should this be a way to delete? <br />
                    <button mat-raised-button color="danger" type="button" (click)="delete()" i18n>Delete</button>
                    <button mat-raised-button color="primary" type="button" (click)="getDownloadLink()" i18n>Download</button>-->
                        </dd>
                        <dd *ngIf="redaction.redactionStatus===0">
                            <span class="badge badge-warning" i18n>Awaiting Processing</span>
                        </dd>
                    </dl>
                    <a mat-button routerLink="/documents/{{documentId}}" class="smx-secondary-button mr-1" i18n><i
                        class="fa fa-arrow-left"></i> Back to Document</a>
                </div>
            </div>
        </div>
    </div>
</div>
