import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent, FeedbackType } from '../feedback/feedback.component';

@Component({
  selector: 'app-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.css']
})
export class FeedbackButtonComponent implements OnInit {
  
  public  feedbackTypes: typeof FeedbackType = FeedbackType;

  constructor(private feedbackDialog: MatDialog) { }

  ngOnInit(): void {
    
  }

  selectFeedbackType(feedbackType: FeedbackType){
    const dialogRef = this.feedbackDialog.open(FeedbackComponent, {
      maxWidth: "400px",
      width:"360px",
      disableClose: true,
      data: {
          feedbackType: feedbackType
        }
    });
  }

  
}
