<div class="outer-container">
    <div class="inner-container">
        <div class="card mt-2 p-3">
            <div>
                <h2>
                    <label class="mr-3" i18n>BODYCAM 4 Configurations</label> <small i18n>Assign To Groups</small>
                </h2>
                <hr />
                <div *ngIf="(groups && configs)">
                    <form name="assignGroups" #groupForm="ngForm">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th i18n>Name</th>
                                    <th i18n>Config</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dt of groups" ui-sref-active="active">
                                    <td>{{dt.name}}</td>
                                    <td>
                                        <mat-select [(value)]="dt.configId" name="configId">
                                            <mat-option *ngFor="let item of configs" [value]="item.id">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>

                    <mat-divider></mat-divider>
                    <button mat-button class="smx-primary-button mt-2" (click)="save()" [disabled]="groupForm.invalid || loading"
                        type="button" i18n>Save</button>
                    <button mat-button class="smx-critical-button mt-2 ml-2" type="button" (click)="cancel()" i18n>Cancel</button>

                </div>
                <div *ngIf="!(groups && configs)">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
            </div>
        </div>

    </div>
</div>
