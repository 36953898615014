import { Component } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-live-download-dialog',
    standalone: false,
    templateUrl: './live-download-dialog.component.html',
    styleUrl: './live-download-dialog.component.css',
})
export class LiveDownloadDialogComponent {
    timeRemaining: number;

    constructor(
        public dialogRef: MatDialogRef<LiveDownloadDialogComponent>,
    ) {
        this.timeRemaining = 30;

        var downloadTimer = setInterval(() => {
            if (this.timeRemaining <= 0) {
                clearInterval(downloadTimer);
                this.dialogRef.close();
            }
            else {
                this.timeRemaining -= 1;
            }
            
        }, 1000);
    }
}
