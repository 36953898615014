import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import {
    PendingTransferList,
    SecuraMaxApiService,
} from '../../../services/api/securamaxapi.service';
import { CreateRequestDialogComponent } from 'src/app/shared/components/create-request-dialog/create-request-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-devices-pending-transfer',
    templateUrl: './devices-pending-transfer.component.html',
    styleUrls: ['./devices-pending-transfer.component.css'],
})
export class DevicesPendingTransferComponent implements OnInit {
    pendingRequestList: PendingTransferList[] = [];
    transfersLoaded: boolean = false;
    protected readonly environment = environment;

    constructor(
        private api: SecuraMaxApiService,
        private dialog: MatDialog,
        private toastr: SnackbarService
    ) {}

    ngOnInit(): void {
        this.api.device_GetPendingRequests().subscribe((data) => {
            this.pendingRequestList = data;
            this.transfersLoaded = true;
        });
    }

    handleVideoRequestClick(deviceId) {
        const dialogRef = this.dialog.open(CreateRequestDialogComponent, {
            width: '90dvw',
            data: { id: deviceId },
        });
        dialogRef.afterClosed().subscribe((result: any) => {});
    }

    handleCancelClick(transferRequest, transfer) {
        this.api.device_PutCancelRequest(transferRequest.id).subscribe((x) => {
            this.toastr.success('Request cancelled.');
            const i = transfer.transferRequestList.findIndex(x => x.id === transferRequest.id);
            transfer.transferRequestList.splice(i, 1);
        });
    }
}
