<h2 mat-dialog-title i18n>Group Permissions Help</h2>
<mat-dialog-content class="mat-typography">
    <div class="list-group">
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>View</h4>
            <p class="mb-1" i18n>Allows user to load the details page of documents that are associated to user accounts within this group.</p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Share</h4>
            <p class="mb-1" i18n>Allows user to use the sharing function on documents that are associated to user accounts within this group.</p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Download</h4>
            <p class="mb-1" i18n>Allows user to download a copy of documents that are associated to user accounts within this group.</p>
        </div>
        <div class="list-group-item list-group-item-action flex-column align-items-star d-flex w-100 justify-content-between">
            <h4 class="mb-1" i18n>Edit</h4>
            <p class="mb-1" i18n>Allows user to edit the metadata on documents that are associated to user accounts within this group. </p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial i18n>Close</button>
</mat-dialog-actions>