<div class="card mt-2 p-3">

    <div>
        <h2 i18n>Delete BC300 Config</h2>
        <hr />
        <div *ngIf="!loading">
            <p i18n>Confirm: Delete</p>
            <p>
                <ng-container i18n>Configuration Name</ng-container>: {{config.name}}
            </p>
        </div>
        <form [formGroup]="deleteForm" (ngSubmit)="confirmDelete()" *ngIf="!loading">
            <div *ngIf="config.assignedToGroups>0">
                <p class="help-text" i18n>This config is assigned to at least one group. These configs will be
                    reassigned to the following group.</p>
                <div class="form-group">
                    <mat-form-field class="form-item" appearance="outline">
                        <mat-label i18n>Config To Replace With</mat-label>
                        <mat-select formControlName="replaceWithConfigId" required>
                            <mat-option *ngFor="let item of configs" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.replaceWithConfigId.errors?.required" i18n>You must select a config to
                            reassign the existing devices to.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <p>
                <button mat-button type="submit" class="smx-critical-button mr-2"
                    [disabled]="config.name==='default' || deleteForm.invalid" i18n>Delete</button>
                <a mat-button class="smx-secondary-button" routerLink="/admin/devices/bc300configs" i18n>Cancel</a>
            </p>
        </form>
    </div>
</div>
