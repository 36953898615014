import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
export interface FilterDialogData {
  addressDisplay: string;
  sortField: string;
  sortDirection: string;
  filter: FilterData;
}

export interface FilterData {
  showOffline: boolean;
  showBodycams: boolean;
  showVehicles: boolean;
}

@Component({
  selector: 'filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatSelectModule, MatCheckboxModule, MatDividerModule],
})
export class FilterDialog {

  constructor(
    public dialogRef: MatDialogRef<FilterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: FilterDialogData,
  ) { }

    onCancel(): void {
        // Close the dialog, return false
        this.dialogRef.close(false);
    }

}
