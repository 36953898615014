import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ApproveDeletionListViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { PagingApiService } from 'src/app/services/paging-api.service';
import { TitleService } from 'src/app/services/title.service';


@Component({
    selector: 'app-awaiting-delete-approval',
    templateUrl: './awaiting-delete-approval.component.html',
    styleUrls: ['./awaiting-delete-approval.component.css']
})
export class AwaitingDeleteApprovalComponent implements OnInit {
    datasource: ApproveDeletionListViewModel[];
    filter: any = { searchTerm: '' };
    totalItems: number;
    modelFromJS: any = ApproveDeletionListViewModel.fromJS;

    constructor(private api: SecuraMaxApiService, private titleService: TitleService, private toastr: SnackbarService, private pagingAPIService: PagingApiService) { }

    ngOnInit(): void {
        this.titleService.setTitle('Awaiting Delete Approval Report');
    }

    onSearch(searchText: string) {
        this.filter.searchTerm = searchText;
        this.filterChanged();
        //this.pagingAPIService.fetchData<ApproveDeletionListViewModel>('/api/api/DocumentTask/GetDeleteApprovalList', this.filter, this.modelFromJS, undefined).subscribe(function (data) {
        //    this.datasource = data.data;
        //}, (err) => {
        //    this.toastr.error('Error occurred while getting the reports.');
        //});
    }

    onSearchCleared() {
        this.filter.searchTerm = '';
        this.filterChanged();
    }

    onDataSourceUpdate(data: ApproveDeletionListViewModel[]) {
        this.datasource = data;
    }

    numItemsUpdated(data: number) {
        this.totalItems = data;
    }

    filterChanged() {
        this.filter = Object.assign({}, this.filter);
    }

    approveDelete(item: ApproveDeletionListViewModel) {
        var that = this;

        this.api.documentTask_PostApproveDeletionRequest(item.requestDeleteId, true).toPromise()
            .then((newDeletionDate) => {
                item.hasBeenReviewed = true;

                this.toastr.success('Delete Request Successfully Approved.');
            }, (err) => {
                this.toastr.error('Error occurred while approving the delete request.');
            });

    }

    disapproveDelete(item: ApproveDeletionListViewModel) {
        var that = this;

        that.api.documentTask_PostApproveDeletionRequest(item.requestDeleteId, false).toPromise().then((newDeletionDate) => {
            item.hasBeenReviewed = true;
            this.toastr.success('Delete Request Successfully Denied.');
        }, (err) => {
            this.toastr.error('Error occurred while denying the delete request.');
        });
    }

}
