<div>
    <h2 i18n>Users - Bulk Create</h2>
    <hr />
    <div class="panel panel-default">
        <div class="panel-body">
            <p i18n>Create a csv file following the sample format below. Fill out as much as you can, but email and username are required. The username you enter will attempt to be used, but if it is invalid one will be created in place of it.</p>
            <p><a mat-button class="smx-secondary-button" href="/static_downloads/sample_bulk_create.csv" target="_blank" i18n>Download Sample</a></p>
            <form>
                <div class="form-group">
                    <input class="form-control" #file type="file" (change)="uploadFileChanged($event)" value="Select CSV File" accept=".csv" i18n-value />
                </div>
                <input class="smx-primary-button" class="mt-2" type="submit" value="Upload" (click)="uploadPost(file.files)" [disabled]="!content || uploading===true" />
            </form>
        </div>
    </div>

    <div *ngIf="uploading===true">
        <i class="fa fa-spin fa-spinner"></i>
    </div>

    <div class="mt-2" *ngIf="response">
        <div class="alert alert-success" role="alert" *ngIf="response.Succeeded > 0" >
            <strong>{{response.succeeded}}</strong> {{'Records Succeeded'}}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="response.Errors !== undefined && response.Errors.length > 0">
            <strong>{{response.Errors.length}}</strong> {{'Records Failed'}}
        </div>
        <table class="table table-responsive" *ngIf="response.Errors !== undefined && response.Errors.length > 0">
            <thead>
                <tr>
                    <th>{{'Line Number'}}</th>
                    <th>{{'Record'}}</th>
                    <th>{{'Error'}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let error of response.Errors">
                    <td>{{error.LineNumber}}</td>
                    <td>{{error.RecordString}}</td>
                    <td>{{error.ExceptionInfo}}</td>
                </tr>

            </tbody>
        </table>
    </div>

</div>
