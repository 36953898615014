import {
    AfterRenderPhase,
    Component,
    Inject,
    OnInit,
    afterNextRender,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSelectModel } from 'src/app/models/user-select.model';
import {
    CasesListViewModel,
    CategoryViewModel,
    DocumentDetailsViewModel,
    DocumentMetadataViewModel,
    DocumentTagViewModel,
    SecuraMaxApiService,
    TagTypeViewModel,
    TagViewModel,
} from 'src/app/services/api/securamaxapi.service';

import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserSelectClearService } from 'src/app/services/user/user-select-clear.service';
import { TagSelectViewModel } from 'src/app/models/tag-select-view-model.model';

export interface IMetadataModifyDialogComponentDialogDataViewModel {
    document: DocumentDetailsViewModel;
}

@Component({
    selector: 'app-metadata-modify-dialog',
    templateUrl: './metadata-modify-dialog.component.html',
    styleUrls: ['./metadata-modify-dialog.component.css'],
})
export class MetadataModifyDialogComponent implements OnInit {
    isInitialMetadataLoaded: boolean = false;

    editMetaData: boolean = false;
    isSavingMetadata: boolean = false;
    titleToEdit: string = '';
    titleToEditError: string = null;
    descriptionToEdit: string = '';
    importantToEdit: boolean;
    ownerSelect: UserSelectModel = new UserSelectModel();

    tagsList: TagViewModel[] = [];
    selectedTag: TagViewModel = new TagViewModel();
    tagsEditList: TagSelectViewModel[] = [];

    categoryList: CategoryViewModel[] = [];
    selectedCategory: CategoryViewModel = new CategoryViewModel();
    categoryEditList: CategoryViewModel[] = [];

    caseListItem: CasesListViewModel = new CasesListViewModel();
    caseEditList: CasesListViewModel[] = [];

    constructor(
        private dialogRef: MatDialogRef<MetadataModifyDialogComponent>,
        private apiService: SecuraMaxApiService,
        private toastr: SnackbarService,
        private userSelectClearService: UserSelectClearService,
        @Inject(MAT_DIALOG_DATA)
        public data: IMetadataModifyDialogComponentDialogDataViewModel
    ) {
        // todo this is hacky - figure out why normal init isn't working
        afterNextRender(
            () => {
                this.ownerSelect.associatedUser = this.data.document.ownerName;
                this.ownerSelect.associatedUserId = this.data.document.ownerId;
            },
            { phase: AfterRenderPhase.Write }
        );
    }

    ngOnInit() {
        this.loadTags();
        Promise.all([this.apiService.categories_GetAll().toPromise()])
            .then(([catetgoriesResult]) => {
                this.categoryList = catetgoriesResult;

                this.titleToEdit = this.data.document.title;
                this.descriptionToEdit = this.data.document.description;

                this.categoryEditList = [...this.data.document.categories];
                this.caseEditList = [
                    ...this.data.document.documentCaseItemList,
                ];

                this.importantToEdit = this.data.document.important;

                this.isInitialMetadataLoaded = true;
            })
            .catch((err) => {
                console.log(err);
                this.toastr.error(
                    'An error occurred while loading supporting data.'
                );
            });
    }

    onClose() {
        this.dialogRef.close(this.data);
    }

    addTag(tag, id?: number, value?: string) {
        const tagSelectVm = new TagSelectViewModel(tag, id, value);
        this.tagsEditList.push(tagSelectVm);
    }

    removeTag(index: any) {
        if (index >= 0) {
            this.tagsEditList.splice(index, 1);
        }
    }

    addCategory(category) {
        const index = this.categoryEditList.findIndex(
            (x) => x.name == category.name
        );

        if (index == -1) {
            this.categoryEditList.push(category);
        }
    }

    removeCategory(category) {
        const index = this.categoryEditList.indexOf(category);

        if (index >= 0) {
            this.categoryEditList.splice(index, 1);
        }
    }

    addCase() {
        const index = this.caseEditList.findIndex(
            (x) => x.title == this.caseListItem.title
        );

        if (index == -1 && this.caseListItem.title != null) {
            this.caseEditList.push(this.caseListItem);
        }
    }

    removeCase(caseItem) {
        const index = this.caseEditList.indexOf(caseItem);

        if (index >= 0) {
            this.caseEditList.splice(index, 1);
        }
    }

    validateMetadataTitle() {
        if (this.data.document.requireDocumentTitle) {
            if (!this.titleToEdit) return 'Title is Required';

            if (this.data.document.documentTitleRegex) {
                // Do a regex check on the title.
                const patt = new RegExp(this.data.document.documentTitleRegex);
                const isValid = patt.test(this.titleToEdit);

                if (!isValid)
                    return 'Title does not match the required format.';
            }
        }

        return;
    }

    loadTags() {
        if (this.tagsList.length <= 0) {
            // Get all tags to show in the list. This will include tags that have
            // been deleted from the 'lookups' page but are still part of the document.
            this.apiService.tags_GetAll().subscribe(
                (data) => {
                    this.tagsList = data;

                    // Get each tag in the document and display it in the tags list.
                    // We pass the tag.id to be sent back to the server incase the value is updated.
                    // tag.value will display the value in the input field.
                    this.data.document.tags.forEach((tag) => {
                        const tagItem = this.tagsList.find(
                            (x) => x.id == tag.tagId
                        );
                        this.addTag(tagItem, tag.id, tag.value);
                    });
                    // Now only display active tags that haven't been deleted from the lookups page.
                    this.apiService.tags_GetAllActiveTags().subscribe(
                        (data) => {
                            this.tagsList = data;
                        },
                        () => {
                            this.toastr.error(
                                'An error occurred while loading tags.'
                            );
                        }
                    );
                },
                () => {
                    this.toastr.error('An error occurred while loading tags.');
                }
            );
        }
    }


    onSaveMetadata() {
        const error = this.validateMetadataTitle();
        if (error) {
            this.titleToEditError = error;
            return;
        }

        const dataToBeSaved = new DocumentMetadataViewModel();
        dataToBeSaved.documentId = this.data.document.id;
        dataToBeSaved.title = this.titleToEdit;
        dataToBeSaved.description = this.descriptionToEdit
        dataToBeSaved.ownerId = this.ownerSelect.associatedUserId;
        dataToBeSaved.categories = this.categoryEditList;
        dataToBeSaved.cases = this.caseEditList;
        dataToBeSaved.important = this.importantToEdit;

        dataToBeSaved.tags = this.tagsEditList.map((x) => {
            const item = new DocumentTagViewModel();
            item.id = x.id;
            item.tagId = x.tagId;
            item.tagType = new TagTypeViewModel()
            item.tagType.id = x.tagId;
            item.tagType.name = x.name;
            item.value = x.value;
            return item;
        });

        const documentMetadataViewModelList: DocumentMetadataViewModel[] = [];
        documentMetadataViewModelList.push(dataToBeSaved);

        this.isSavingMetadata = true;
        this.apiService.metadata_Put(documentMetadataViewModelList).subscribe(() => {
            this.dialogRef.close();
        }, () => {
            this.toastr.error("An error occurred while saving the metadata.");
            this.isSavingMetadata = false;
        });
    }


}
