<div class="card">
    <div class="card-body">
        <h1 i18n>API Settings</h1>
        <hr />
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                Click the button below to generate a new secret. Keep this secret secure and never share with anyone outside your organization. To enable
                Truleo integration, place the secret in the appropriate place inside your Truleo account.

                <button mat-raised-button color="primary" [disabled]="clientSecret !== undefined" (click)="generateClientSecret()" i18n>Generate</button>
            </div>

            
        </div>
        <div class="row justify-content-center">
            <div class="mt-2 mb-2 mr-2" *ngIf="clientSecret !== undefined">
                <span>
                    Your client secret: <strong>{{clientSecret}}</strong>
                </span>
            </div>
            <button *ngIf="clientSecret !== undefined" mat-button (click)="copyToClipboard()" title="Copy to Clipboard">
                <mat-icon>content_copy</mat-icon>
            </button>
            <div *ngIf="copied" class="copiedText">
                
                <div>
                    <mat-icon style="color: rgb(23, 180, 23);" class="pt-2">check</mat-icon>
                    <strong>Copied</strong>
                </div>
            </div>
        </div>
    </div>
</div>