import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesCreateViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-create-case-dialog2',
  templateUrl: './create-case-dialog2.component.html',
  styleUrls: ['./create-case-dialog2.component.css']
})
export class CreateCaseDialog2Component implements OnInit {
  caseCreateVM: CasesCreateViewModel = new CasesCreateViewModel();

  constructor(private apiService: SecuraMaxApiService, private dialogRef: MatDialogRef<CreateCaseDialog2Component>, private toastr: SnackbarService) { }

  ngOnInit(): void {
    // Set '0' to be put in the DB.
    this.caseCreateVM.sharedUsersCanEdit = false;
    this.caseCreateVM.retention = 0;
    this.caseCreateVM.documents = [];
  }

  onClose(){
    this.apiService.cases_Create(this.caseCreateVM).subscribe(()=>{
      this.dialogRef.close(this.caseCreateVM);
    },(err) =>{
      this.toastr.error("Unable to create the new case.")
    });
  }

}
