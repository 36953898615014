import {
    Component,
    Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    AbstractControl,
    ControlContainer,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { TagTypeSelectionModalComponent } from '../tag-type-selection-modal/tag-type-selection-modal.component';
import { CasesListViewModel, CasesService, CategoriesService, CategoryViewModel, SettingsService, TagViewModel } from 'src/app/services/api2';

export interface IReviewMetadataControlsComponentForm {
    title: FormControl<string>;
    description: FormControl<string>;
    tags: FormArray<FormControl>;
    newTagTypeToCreateControlsFor: FormControl<TagViewModel>;
    categoriesForm: FormControl<CategoryViewModel[] | null>;
    casesForm: FormControl<CasesListViewModel[] | null>;
}

function regexValidator(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const valid = regex.test(control.value);
        return valid ? null : { regexInvalid: { value: control.value } };
    };
}

@Component({
    selector: 'app-review-metadata-controls2',
    templateUrl: './review-metadata-controls2.component.html',
    styleUrls: ['./review-metadata-controls2.component.css'],
})
export class ReviewMetadataControls2Component {
    allCategories: CategoryViewModel[] = [];
    allCases: CasesListViewModel[] = [];
    allTags: TagViewModel[] = [];

    titleRegexDescription =
        'Your title is invalid according to the settings set by your admin.';

    @Input() rule: any;
    @Input() wide: boolean;

    form: FormGroup<IReviewMetadataControlsComponentForm>;

    caseListItem: CasesListViewModel = {};
    caseEditList: CasesListViewModel[] = [];

    static buildFormPart(
        tfb: FormBuilder
    ): FormGroup<IReviewMetadataControlsComponentForm> {
        return tfb.group({
            title: [
                '',
                [Validators.required, regexValidator(new RegExp('\\d+'))],
            ],
            description: [''],
            tags: tfb.array([]),
            newTagTypeToCreateControlsFor: [null],
            categoriesForm: [[] as CategoryViewModel[], [Validators.required]],
            casesForm: [[] as CasesListViewModel[]],
        });
    }

    constructor(
        private fb: FormBuilder,
        private controlContainer: ControlContainer,
        private dialog: MatDialog,
        private toastr: SnackbarService,
        private categoriesService: CategoriesService,
        private casesService: CasesService,
        private settingsService: SettingsService
    ) {}

    ngOnInit(): void {

        this.form = this.controlContainer
            .control as FormGroup<IReviewMetadataControlsComponentForm>;

        this.categoriesService.apiApiCategoriesV2Get().subscribe(
            (categories) => {
                this.allCategories = categories;
            },
            () => {
                this.toastr.error('Error occurred while getting categories.');
            }
        );

        this.casesService.apiApiCasesSearchV2Get('', 100).subscribe(
            (cases) => {
                this.allCases = cases;
            },
            () => {
                this.toastr.error('Error occurred while getting cases.');
            }
        );

        this.settingsService.apiApiSettingsV2Get().subscribe((x) => {
            const documentTitleValidators = [];

            if (x.requireDocumentTitle) {
                documentTitleValidators.push(Validators.required);
            }

            let regExp = new RegExp('.*');
            try {
                regExp = new RegExp(x.documentTitleRegex);
            } catch (error) {}
            if (x.documentTitleRegex && x.documentTitleRegex != '') {
                this.titleRegexDescription = x.titleRegexDescription;
                documentTitleValidators.push(regexValidator(regExp));
            }

            this.form.controls.title.setValidators(documentTitleValidators);
        });

        this.form.controls.newTagTypeToCreateControlsFor.valueChanges.subscribe(
            (x) => {
                if (!x) {
                    return;
                }

                this.tags_add(x.name);
                this.form.controls.newTagTypeToCreateControlsFor.setValue(null);
            }
        );
    }

    tags_add(name: string, value?: string): void {
        const tags = <FormArray>this.form.controls.tags;
        const newTagControl = <any>this.fb.control(['']);
        newTagControl.name = name;
        if (value) {
            newTagControl.value = value;
        }
        tags.push(newTagControl);
    }

    tag_delete_clicked(i: number): void {
        (<FormArray>this.form.controls.tags).removeAt(i);
    }

    cases_add() {
        const index = this.caseEditList.findIndex(
            (x) => x.title == this.caseListItem.title
        );

        if (index == -1 && this.caseListItem.title != null) {
            this.caseEditList.push(this.caseListItem);
            this.form.controls.casesForm.setValue(this.caseEditList);
        }
    }

    case_delete_clicked(caseItem) {
        const index = this.caseEditList.indexOf(caseItem);

        if (index >= 0) {
            this.caseEditList.splice(index, 1);
            this.form.controls.casesForm.setValue(this.caseEditList);
        }
    }

    openTagTypeSelection() {
        const dialogRef = this.dialog.open(TagTypeSelectionModalComponent, {
            width: '50dvw',
        });
        dialogRef.afterClosed().subscribe((result: TagViewModel) => {
            this.tags_add(result.name);
        });
    }
}
