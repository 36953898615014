import { ShareViewModel, ICaseDocumentListViewModel } from 'src/app/services/api/securamaxapi.service';

export class CaseDocumentListViewModelExtension implements ICaseDocumentListViewModel {
    id?: string | undefined;
    tags?: string[] | undefined;
    categories?: string[] | undefined;
    name?: string | undefined;
    statusId?: number;
    uploaded?: Date | undefined;
    ownerId?: number | undefined;
    thumbnailUrl?: string | undefined;
    created?: Date | undefined;
    important?: boolean | undefined;
    ownerName?: string | undefined;
    title?: string | undefined;
    device?: string | undefined;
    isDeletionLocked?: boolean | undefined;
    shares?: ShareViewModel[] | undefined;
    permissions?: any | undefined;
    parentId?: string | undefined;
    isDeleted?: any | undefined;
    isShareChecked?: boolean | undefined;
    children?: CaseDocumentListViewModelExtension[] | undefined;

    constructor(data?: ICaseDocumentListViewModel, isShareSelected?: boolean, children?: CaseDocumentListViewModelExtension[]) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }

        this.isShareChecked = isShareSelected;

        if (children != undefined) {
            this.children = children;
        } 
        else {
            this.children = new Array<CaseDocumentListViewModelExtension>();
        }
        
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["Id"];
            if (Array.isArray(_data["Tags"])) {
                this.tags = [] as any;
                for (let item of _data["Tags"])
                    this.tags!.push(item);
            }
            if (Array.isArray(_data["Categories"])) {
                this.categories = [] as any;
                for (let item of _data["Categories"])
                    this.categories!.push(item);
            }
            this.name = _data["Name"];
            this.statusId = _data["StatusId"];
            this.uploaded = _data["Uploaded"] ? new Date(_data["Uploaded"].toString()) : <any>undefined;
            this.ownerId = _data["OwnerId"];
            this.thumbnailUrl = _data["ThumbnailUrl"];
            this.created = _data["Created"] ? new Date(_data["Created"].toString()) : <any>undefined;
            this.important = _data["Important"];
            this.ownerName = _data["OwnerName"];
            this.title = _data["Title"];
            this.device = _data["Device"];
            this.isDeletionLocked = _data["IsDeletionLocked"];
            if (Array.isArray(_data["Shares"])) {
                this.shares = [] as any;
                for (let item of _data["Shares"])
                    this.shares!.push(ShareViewModel.fromJS(item));
            }
            this.permissions = _data["Permissions"];
            this.parentId = _data["ParentId"];
            this.isDeleted = _data["IsDeleted"];
        }

        this.isShareChecked = false;
    }

    static fromJS(data: any): CaseDocumentListViewModelExtension {
        data = typeof data === 'object' ? data : {};
        let result = new CaseDocumentListViewModelExtension();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Id"] = this.id;
        if (Array.isArray(this.tags)) {
            data["Tags"] = [];
            for (let item of this.tags)
                data["Tags"].push(item);
        }
        if (Array.isArray(this.categories)) {
            data["Categories"] = [];
            for (let item of this.categories)
                data["Categories"].push(item);
        }
        data["Name"] = this.name;
        data["StatusId"] = this.statusId;
        data["Uploaded"] = this.uploaded ? this.uploaded.toISOString() : <any>undefined;
        data["OwnerId"] = this.ownerId;
        data["ThumbnailUrl"] = this.thumbnailUrl;
        data["Created"] = this.created ? this.created.toISOString() : <any>undefined;
        data["Important"] = this.important;
        data["OwnerName"] = this.ownerName;
        data["Title"] = this.title;
        data["Device"] = this.device;
        data["IsDeletionLocked"] = this.isDeletionLocked;
        if (Array.isArray(this.shares)) {
            data["Shares"] = [];
            for (let item of this.shares)
                data["Shares"].push(item.toJSON());
        }
        data["Permissions"] = this.permissions;
        data["ParentId"] = this.parentId;
        data["IsDeleted"] = this.isDeleted;
        data["IsShareChecked"] = this.isShareChecked;
        return data; 
    }
}