<div class="card mt-3">
    <div class="card-body">
        <h2 class="card-title" i18n>
            Imports Awaiting Review
        </h2>
        <div class="container">
            <div class="row">
                <div class="col">

                    <ul class="nav nav-pills nav-fill mb-3">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="[]" [class]="{'active': step===1}" (click)="unselectDay()"
                               i18n>1.
                                Choose an Upload Day</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [class]="{'disabled': step<2,'active': step===2}" [routerLink]="[]"
                               i18n> 2. Choose
                                Files From Day</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [class]="{'disabled': step<3,'active': step===3}" [routerLink]="[]"
                               i18n>3. Add
                                Metadata to files</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col" *ngIf="step===1">
                    <table class="table">
                        <thead>
                        <tr>
                            <th i18n>Date</th>
                            <th i18n># Remaining</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody *ngIf="metadataPendingDays?.length>0 && pendingDaysLoaded===true">
                        <tr *ngFor="let day of metadataPendingDays;let dayIndex = index">
                            <td i18n>
                                {{day.date | date:'fullDate':'GMT'}}
                            </td>
                            <td i18n>
                                {{day.files.length}}
                            </td>
                            <td>
                                <button class="smx-primary-button" (click)="selectDay(dayIndex)" i18n>Review
                                </button>
                            </td>
                        </tr>

                        </tbody>
                        <tbody *ngIf="metadataPendingDays?.length===0 && pendingDaysLoaded===true">
                        <tr>
                            <td colspan="3" i18n>
                                None. All caught up!
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col" *ngIf="step>1">
                    <h2 i18n>{{selectedDay | date:'fullDate'}}</h2>

                    <div class="row">
                        <div class="col">
                            <mat-selection-list>
                                <mat-list-option *ngFor="let document of documents">
                                    <div class="document-row">
                                        <app-doc-thumbnail id="{{document.id}}"></app-doc-thumbnail>
                                        <div class="document-row-text">
                                            {{document.name}} {{document.created | date:'short'}}
                                            <mat-chip class="ml-2">{{document.mimeType}}</mat-chip>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col">
                            <div style="display: grid; grid-template-columns: 1fr auto;">
                                <div class="d-flex">
                                    <button class="smx-secondary-button select-all" (click)="selectAllClicked()" i18n>
                                        Select
                                        All
                                    </button>
                                    <button class="smx-secondary-button ml-1 deselect-all"
                                            (click)="deselectAllClicked()"
                                            i18n>Select None
                                    </button>
                                </div>
                                <button class="smx-primary-button ml-1 review-selected"
                                        (click)="reviewSelectedClicked()"
                                        i18n>Next
                                </button>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="col" *ngIf="reviewMode">
                    <form class="example-form" [formGroup]="metadataForm">
                        <mat-form-field appearance="fill" class="w-75">
                            <mat-label i18n>Title</mat-label>
                            <input matInput type="text" formControlName="title"
                                   [required]="documents[0].requireDocumentTitle"
                                   [pattern]="documents[0].requireDocumentTitle ? documents[0].documentTitleRegex : ''">
                            <mat-error *ngIf="metadataForm.controls['title'].hasError('required')" i18n>
                                Title is required
                            </mat-error>
                            <mat-error *ngIf="metadataForm.controls['title'].hasError('pattern')" i18n>
                                {{documents[0].titleRegexDescription}}
                            </mat-error>
                        </mat-form-field>

                        <p></p>

                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label i18n>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                        </mat-form-field>

                        <p></p>

                        <p></p>

                        <div formArrayName="tags">
                            <div *ngFor="let tag of tags.value; let i=index" class="tag-item">
                                <!-- The repeated alias template -->
                                <mat-form-field class="full-width w-50">
                                    <mat-label i18n>{{tag.name}}</mat-label>
                                    <input matInput [value]="tag.tagValue"
                                           (input)="tag.tagValue = $event.target.value">
                                </mat-form-field>

                                <button mat-raised-button (click)="removeTag(i)" class="tag-delete-button"
                                        style="height:50%">
                                    <mat-icon class="tag-delete-button-icon">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <h4 class="labels" i18n>Tags</h4>
                        <mat-form-field appearance="fill" class="full-width w-50">
                            <mat-label i18n>Select a Tag Type</mat-label>
                            <mat-select (selectionChange)="tagSelectionChange($event.value)">
                                <mat-option *ngFor="let tags of allTags" [value]="tags"
                                            i18n>{{tags.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <p></p>
                        <h4 i18n>Categories</h4>
                        <mat-form-field class="w-50">
                            <mat-select multiple formControlName="categoriesForm">
                                <mat-option *ngFor="let categories of allCategories" [value]="categories.id"
                                            i18n>{{categories.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <h4 class="labels" i18n>Cases</h4>
                        <mat-form-field class="w-50">
                            <mat-select multiple formControlName="casesForm">
                                <mat-option *ngFor="let cases of allCases" [value]="cases.id"
                                            i18n>{{cases.title}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <p></p>
                        <button mat-raised-button color="primary" class="cancel-save-metadata"
                                (click)="cancelMetaDataEdit()" [disabled]="isSaving" i18n>Cancel
                        </button>
                        <button mat-raised-button color="primary" class="save-metadata ml-1"
                                (click)="saveMetaData()"
                                [disabled]="isSaving || metadataForm.controls['title'].hasError('required') || metadataForm.controls['title'].hasError('pattern')"
                                i18n>Save
                        </button>
                    </form>
                </div>
            </div>
        </div>


    </div>
</div>
