import { Component, OnInit } from '@angular/core';
import { DocumentStorageViewModel } from 'src/app/services/api/securamaxapi.service';
import { PagingApiService } from 'src/app/services/paging-api.service';
import { TitleService } from 'src/app/services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-document-storage-usage',
  templateUrl: './document-storage-usage.component.html',
  styleUrls: ['./document-storage-usage.component.css']
})
export class DocumentStorageUsageComponent implements OnInit {
  documentStorageUsages: DocumentStorageViewModel[];
  filter: any = { searchTerm: '' };
  totalItems: number;
  modelFromJS: any = DocumentStorageViewModel.fromJS;
  isUnlimitedPlanUser: boolean = false;
  constructor(private pagingAPIService: PagingApiService, private titleService: TitleService, private toastr: SnackbarService, private userService: UserService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Large Documents Report');

    this.userService.getCurrentUser().then((user) => {
      if (user.isUnlimitedPlan)
      {
        this.isUnlimitedPlanUser = true;
      }
    });
  }

  onDataSourceUpdate(data: DocumentStorageViewModel[]) {
    this.documentStorageUsages = data;
  }

  numItemsUpdated(data: number) {
    this.totalItems = data;
  }

  onSearch(searchText: string) {
    this.filter.searchTerm = searchText;
    this.filterChanged();
    //this.pagingAPIService.fetchData<DocumentStorageViewModel>('/api/api/Usage/DocumentStorage', this.filter, this.modelFromJS, undefined).subscribe(function (data) {
    //  this.datasource = data.data;
    //}, (err) => {
    //  this.toastr.error('Error occurred while getting the report.');
    //});
  }

  onSearchCleared() {
    this.filter.searchTerm = '';
    this.filterChanged();
  }

  filterChanged() {
    this.filter = Object.assign({}, this.filter);
  }

}
