<div class="card mt-3">
    <div class="card-body">
        <div class="d-flex justify-content-between">
            <h1 i18n>Rule Details</h1>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">

                    <h4 i18n>Info</h4>
                    <table class="table table-bordered">
                        <tbody>
                        <tr>
                            <td class="col-md-2">Start</td>
                            <td>{{ datasource.startRange | date:'long' }}</td>
                        </tr>
                        <tr>
                            <td class="col-md-2">End</td>
                            <td>{{ datasource.endRange | date:'long' }}</td>
                        </tr>
                        <tr>
                            <td class="col-md-2">Expiration Date</td>
                            <td>{{ datasource.expiration | date:'long' }}</td>
                        </tr>
                        <tr>
                            <td class="col-md-2">Status</td>
                            <td colspan="2">
                                <span [class]="(datasource.isActive? 'badge badge-success' : 'badge badge-danger')"
                                      class="mr-2" aria-hidden="true">
                                    <span *ngIf="datasource.isActive" i18n>Active</span>
                                    <span *ngIf="!datasource.isActive" i18n>Not Active</span>
                                </span>
                                <span *ngIf="isReviewExpired" [class]="'badge badge-danger'" aria-hidden="true">
                                    <span i18n>Expired</span>
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td class="col-md-2" i18n>Title</td>
                            <td>{{ datasource.title }}</td>
                        </tr>
                        <tr>
                            <td class="col-md-2" i18n>Description</td>
                            <td>{{ datasource.description }}</td>
                        </tr>
                        </tbody>
                    </table>


                    <h4 i18n>Tags</h4>
                    <p *ngIf="datasource.tags == undefined || datasource.tags.length === 0" i18n>None Found</p>
                    <table class="table table-bordered" *ngIf="datasource.tags != undefined && datasource.tags.length > 0">
                        <tbody>
                        <tr *ngFor="let tag of datasource.tags">
                            <td class="col-md-2">{{ tag.typeName }}</td>
                            <td>{{ tag.value }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <h4 i18n>Categories</h4>
                    <p *ngIf="datasource.categories == undefined || datasource.categories.length === 0" i18n>None Found</p>
                    <table class="table table-bordered"
                           *ngIf="datasource.categories != undefined && datasource.categories.length != 0">
                        <tbody>
                        <tr *ngFor="let category of datasource.categories">
                            <td i18n>{{ category.name }} - Retain For: {{ category.retainForDays }} Day(s)</td>
                        </tr>
                        </tbody>
                    </table>

                    <h4 i18n>Auto Reviewed Documents</h4>


                    <p *ngIf="datasource.autoTaggedDocuments == undefined || datasource.autoTaggedDocuments.length === 0"
                       i18n>
                        None Found</p>
                    <table class="table table-bordered"
                           *ngIf="datasource.autoTaggedDocuments != undefined && datasource.autoTaggedDocuments.length != 0">
                        <tbody>
                        <tr *ngFor="let doc of datasource.autoTaggedDocuments">
                            <td class="col-md-2" *ngIf="doc.statusId == 1"><a routerLink="/doc.DocumentId">[{{
                                doc.deviceName }}] <span *ngIf="doc.title">"{{ doc.title }}" - {{
                                    doc.initialCreateDate }}</span></a></td>
                            <td class="col-md-2" *ngIf="doc.statusId != 1">[{{doc.deviceName}}] <span
                                *ngIf="doc.title" i18n>"{{ doc.title }}" - {{ doc.initialCreateDate }} Document
                                    Unavailble</span></td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <button mat-button routerLink="/review/autoreviewrules/list" class="smx-secondary-button" i18n>Back</button>
                    <button mat-button *ngIf="datasource.isActive" type="button" value="Disable" class="smx-critical-button ml-2"
                            (click)="onSetState(false)" i18n>Disable
                    </button>
                    <button mat-button *ngIf="!datasource.isActive" type="button" value="Enable" class="smx-primary-button ml-2"
                            (click)="onSetState(true)" i18n>Enable
                    </button>
                    <h4 *ngIf="isActiveError != null" class="text-danger">{{ isActiveError }}</h4>
                </div>
            </div>
        </div>
    </div>
</div>
