import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../services/title.service';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        window.location.href = 'https://support.provisionusa.com/hc/en-us/sections/18123504404621-SecuraMax';
    }

}
