import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwtDecode, { JwtPayload } from "jwt-decode";

@Component({
  selector: 'app-member-redeem',
  templateUrl: './member-redeem.component.html',
  styleUrls: ['./member-redeem.component.css']
})
export class MemberRedeemComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id_token) {
        const decoded = jwtDecode<JwtPayload>(params.id_token);
        console.log('received token');
        console.log(decoded);
        //decoded.appTenantName
        //decoded.shareId

        //do something cool here
      }
    });
  }

}
