/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProvisioningPlanType } from './provisioningPlanType';
import { CreateDefaultAccountUserModel } from './createDefaultAccountUserModel';
import { LookupConfiguration } from './lookupConfiguration';


export interface ProvisioningOptions { 
    lookupTemplate?: LookupConfiguration;
    siteName?: string | null;
    siteDisplayName?: string | null;
    siteUrl?: string | null;
    defaultStorageLocationTypeId?: number;
    primaryStorageLocationId?: number;
    storageLocationIdentifier?: string | null;
    defaultUser?: CreateDefaultAccountUserModel;
    defaultRetentionDays?: number;
    postDeleteWaitingPeriod?: number;
    allowUsersToRegisterThemselves?: boolean;
    provisioningUserEmail?: string | null;
    planType?: ProvisioningPlanType;
    archiveDays?: number | null;
    reArchiveDays?: number | null;
    commandCenterPaddedCustomerId?: number;
}
export namespace ProvisioningOptions {
}


