import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TagViewModel } from '../../../services/api/securamaxapi.service';

@Component({
  selector: 'app-tag-type-editor',
  templateUrl: './tag-type-editor.component.html',
  styleUrls: ['./tag-type-editor.component.css']
})
export class TagTypeEditorComponent implements OnInit {

  private _tagTypeId: number;
  @Input() set tagTypeId(value: number) {
    this._tagTypeId = value;
    if (value != null) {
      this.tagTypeIdChange();
    }

  }

  get tagTypeId(): number {
    return this._tagTypeId;
  }

  private _tag: TagViewModel;

  @Input() set tag(value: TagViewModel) {

    this._tag = value;
    if (value !== null) {
      this.tagTypeIdChange();
    }
  }

  get tag(): TagViewModel {
    return this._tag;
  }

  @Output() tagTypeConfigurationEvent = new EventEmitter<string>();
  options: any;
  typeOfValue: any;
  selectedOption: any = {};

  constructor() { }

  updateConfiguration() {
    this._tag.tagTypeConfigurationData = JSON.stringify(this.options);
    this.tagTypeConfigurationEvent.emit(this._tag.tagTypeConfigurationData);
  }

  add() {
    if (!this.options) {
      this.options = this._tag.tagTypeConfigurationData ? JSON.parse(this._tag.tagTypeConfigurationData) : [];
    }
    if (this.selectedOption && this.selectedOption.value && this.selectedOption.text) {
      this.options.push(this.selectedOption);
      this.updateConfiguration();
      this.selectedOption = {};
    }

  };

  delete(item) {
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].value === item.value) {
        this.options.splice(i, 1);
        this.updateConfiguration();
        break;
      }
    }
  }

  tagTypeIdChange() {
    if (this._tag.tagTypeId === 3) { //select list
      this.options = this._tag.tagTypeConfigurationData ? JSON.parse(this._tag.tagTypeConfigurationData) : [];
      this.selectedOption = {};
    } else if (this._tag.tagTypeId === 1) { //keyvalue
      this.typeOfValue = this._tag.tagTypeConfigurationData ? JSON.parse(this._tag.tagTypeConfigurationData) : {};
    }
  }

  ngOnInit(): void {
    this.tagTypeIdChange();
  }

}





