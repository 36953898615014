import { Component, OnInit } from '@angular/core';
import { SecuraMaxApiService, IDocumentMetadataStatusViewModel } from '../../services/api/securamaxapi.service';
import { ReviewService } from '../../services/review.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.css']
})
export class ShellComponent implements OnInit {
    reviewAllCheckbox: boolean;
    reviewNowButtonDisabled: boolean = true;
    metadataDayVM: MetadataDaySelectViewModel[] = [];

    constructor(private apiService: SecuraMaxApiService, private reviewService: ReviewService, private toastr: SnackbarService) {
    }

    ngOnInit(): void {
        this.apiService.settings_Get().subscribe(g => {
        }, (err) => {
            this.toastr.error('Error occurred while getting settings');
        });

        // Save button was clicked on the metadata component. Refresh the review list.
        this.reviewService.isSaveButtonClick.subscribe(value => {
            this.populateReviewList();

            this.reviewAllCheckbox = false;
            this.reviewNowButtonDisabled = true;
        })
    }

    /**
     * Gets the pending days data to put into the list for selecting for review
     * */
    populateReviewList() {
        this.apiService.metadata_GetPendingDays().subscribe(data => {
            // Copies data into extension VM class.
            Object.assign(this.metadataDayVM, data);

            // Sets all selected properties to false.
            this.metadataDayVM.forEach(day => {
                day.selected = false;
                day.files?.forEach(file => {
                    file.selected = false;
                });
            });
        });
    }

    /**
     * Handles the 'Awaiting Review' ALL check box. 
     * Toggles nested Days and Files checkboxes.
     * */
    toggleAllReview() {
        this.reviewAllCheckbox = !this.reviewAllCheckbox;
        this.reviewNowButtonDisabled = !this.reviewAllCheckbox;
        this.metadataDayVM.forEach(day => {
            day.files.forEach(file => {
                file.selected = this.reviewAllCheckbox;
            })
            day.selected = this.reviewAllCheckbox;
        })

        this.signalDocumentListChange();
    }

    /**
     * Handles the 'Days' level check box.
     * Toggles nested Files check boxes and ALL checkbox.
     * */
    toggleReviewDay(day: MetadataDaySelectViewModel) {

        day.selected = !day.selected;
        day.files.forEach(f => f.selected = day.selected);
        this.reviewAllCheckbox = this.metadataDayVM.every(d => d.selected && d.files.every(f => f.selected));
        this.reviewNowButtonDisabled = this.metadataDayVM.every(d => !d.selected && d.files.every(f => !f.selected));

        this.signalDocumentListChange();
    }

    /**
     * Handles the 'Files' level check box.
     * Toggles nested Days check boxes and ALL checkbox.
     * */
    toggleReviewFile(file: DocumentMetadataStatusSelectedViewModel, day: MetadataDaySelectViewModel) {
        file.selected = !file.selected;
        day.selected = day.files.every(f => f.selected);
        this.reviewAllCheckbox = this.metadataDayVM.every(d => d.selected && d.files.every(f => f.selected));
        this.reviewNowButtonDisabled = this.metadataDayVM.every(d => !d.selected && d.files.every(f => !f.selected));

        this.signalDocumentListChange();
    }

    /**
     * Emits a change to the review service that the user checked/unchecked a new document
     **/
    private signalDocumentListChange() {
        let documentList: DocumentMetadataStatusSelectedViewModel[] = [];
        this.metadataDayVM.forEach(d => {
            documentList = documentList.concat(d.files.filter(f => f.selected));
        })

        this.reviewService.reviewDocumentsChanged.next(documentList);
    }
}


export class DocumentMetadataStatusSelectedViewModel implements IDocumentMetadataStatusViewModel {
    id?: string | undefined;
    name?: string | undefined;
    mimeType?: string | undefined;
    created?: Date | undefined;
    deviceName?: string | undefined;
    autoCategorized?: boolean | undefined;
    selected: boolean = false;
}

export class MetadataDaySelectViewModel {
    date?: Date | undefined;
    files?: DocumentMetadataStatusSelectedViewModel[] | undefined;
    selected: boolean = false;
}

