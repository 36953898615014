import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SecuraMaxApiService, MetadataDayViewModel, CasesListViewModel, DocumentMetadataViewModel, CategoryViewModel, DocumentTagViewModel, TagViewModel } from 'src/app/services/api/securamaxapi.service';
import { DocumentMetadataStatusViewModelExtension } from 'src/app/models/annotate-documents-viewmodel.model';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { TitleService } from '../../../services/title.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-annotate',
    templateUrl: './annotate.component.html',
    styleUrls: ['./annotate.component.css']
})
export class AnnotateComponent implements OnInit {
    pendingDaysLoaded: boolean = false;
    metadataPendingDays: MetadataDayViewModel[] = [];
    selectedDay: Date;
    documents: DocumentMetadataStatusViewModelExtension[] = [];
    metadataTitle: string;
    metadataDescription: string;
    metadata: DocumentMetadataViewModel;
    step: number = 1;
    reviewMode: boolean;
    removable = true;
    selectable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    categoryCtrl = new UntypedFormControl();
    filteredCategories: Observable<CategoryViewModel[]>;
    categories: CategoryViewModel[] = [];
    allCategories: CategoryViewModel[] = []
    casesCtrl = new UntypedFormControl();
    filteredCases: Observable<CasesListViewModel[]>;
    cases: CasesListViewModel[] = [];
    allCases: CasesListViewModel[] = []
    allTags: TagViewModel[] = [];
    selectedTag: string;
    tagsList: string[] = [];
    categoriesSelectedList: number[] = [];
    isSaving: boolean = false;
    @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
    @ViewChild('casesInput') casesInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoCases') matCasesAutocomplete: MatAutocomplete;
    @ViewChild('autoCategory') matCategoryAutocomplete: MatAutocomplete;

    metadataForm = this.fb.group({
        title: new UntypedFormControl(''),
        description: [''],
        tags: this.fb.array([]),
        categoriesForm: new UntypedFormControl(this.categoriesSelectedList),
        casesForm: new UntypedFormControl([])
    });

    constructor(private securaMaxApiService: SecuraMaxApiService, private fb: UntypedFormBuilder,private router: Router, private titleService: TitleService, private toastr: SnackbarService) {
    }

    get tags() {
        return this.metadataForm.get('tags') as UntypedFormArray;
    }

    ngOnInit(): void {
        this.titleService.setTitle('Review Uploaded Files');
        this.securaMaxApiService.metadata_GetPendingDays().subscribe(data => {
            this.metadataPendingDays = data;
            this.pendingDaysLoaded = true;
        });

        this.securaMaxApiService.categories_GetAll().subscribe(categories => {
            this.allCategories = categories;
        });

        this.securaMaxApiService.cases_Search("", 100).subscribe(cases => {
            this.allCases = cases;
        });

        this.securaMaxApiService.tags_GetAll().subscribe(tags => {
            this.allTags = tags;
        });

        this.reviewMode = false;
        this.metadata = new DocumentMetadataViewModel();

        this.metadataForm.get('title').valueChanges.subscribe(x => {
            this.metadataForm.get('title').markAsTouched();
        });
    }

    selectDay(daySelection: number) {
        this.documents = [];
        this.step = 2;
        this.selectedDay = this.metadataPendingDays[daySelection].date;
        this.documents = this.metadataPendingDays[daySelection].files;
    }

    unselectDay() {
        this.documents = [];
        this.reviewMode = false;
        this.step = 1;
        this.selectedDay = undefined;
    }

    selectAllClicked() {
        this.documents.forEach(function (doc) {
            doc.isSelected = true;
        });
    }

    deselectAllClicked() {
        this.documents.forEach(function (doc) {
            doc.isSelected = false;
        });
    }

    reviewSelectedClicked() {
        this.step = 3;
        this.reviewMode = true;
    }

    saveMetaData() {

        this.isSaving = true;
        var metadatas = new Array<DocumentMetadataViewModel>();

        this.documents.filter(x => x.isSelected == true).forEach(function (doc) {
            var model = new DocumentMetadataViewModel();
            model.documentId = doc.id;
            model.autoCategorized = doc.autoCategorized;

            var addedCases = new Array<CasesListViewModel>();
            this.metadataForm.value.casesForm.forEach(cases => {
                addedCases.push(this.allCases.filter(x => x.id == cases)[0]);
            });
            model.cases = addedCases;

            var addedCategories = new Array<CategoryViewModel>();
            this.metadataForm.value.categoriesForm.forEach(category => {
                addedCategories.push(this.allCategories.filter(x => x.id == category)[0]);
            });
            model.categories = addedCategories;

            model.description = this.metadataForm.value.description;
            model.title = this.metadataForm.value.title;

            var addedTags = new Array<DocumentTagViewModel>();
            this.metadataForm.value.tags.forEach(element => {
                var tagInfo = this.allTags.filter(x => x.id == element.id)[0];
                var tag = new DocumentTagViewModel();
                tag.tagId = tagInfo.id;
                tag.typeId = tagInfo.tagTypeId;
                tag.typeName = tagInfo.tagTypeName;
                tag.value = element.tagValue;

                addedTags.push(tag);
            });
            model.tags = addedTags;

            metadatas.push(model);
        }.bind(this));

        this.securaMaxApiService.metadata_Put(metadatas).subscribe((data) => {
            this.toastr.success("Annotation added successfully.");
            const currentUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentUrl]);
            });
            this.isSaving = false;
        }, (err) => {
            this.isSaving = false;
            this.toastr.error("An error occurred while saving the annotation.");
        });
    }

    cancelMetaDataEdit() {
        //TODO clear current metadata
        this.metadata = new DocumentMetadataViewModel();
        this.reviewMode = false;
        this.step = 2;
    }

    tagSelectionChange(tag) {
        let group = new UntypedFormGroup({
            'tagValue': new UntypedFormControl(''),
            'name': new UntypedFormControl(tag.name),
            'id': new UntypedFormControl(tag.id)
        });

        this.tags.push(group);
    }

    addTag(tag): void {
        let group = new UntypedFormGroup({
            'tagValue': new UntypedFormControl(''),
            'name': new UntypedFormControl(tag.name)
        });

        this.tags.push(group);
    }

    removeTag(tagToRemove: number): void {
        this.tags.removeAt(tagToRemove);
    }
}
