import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentDetailsViewModel, RedactionItem, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-redaction-details',
  templateUrl: './redaction-details.component.html',
  styleUrls: ['./redaction-details.component.css']
})
export class RedactionDetailsComponent implements OnInit {
    redactionId: string;
    saving: boolean = false;
    loading: boolean = true;
    redaction: RedactionItem = new RedactionItem();
    document: DocumentDetailsViewModel;
    documentId: string;
    videoSrcUrl: string = null;
    videoTrimStart: number = 0;
    videoTrimEnd: number = 10;
    audioTrimStart: number = 0;
    audioTrimEnd: number = 10;
    blurAmount: number = 0;
    currentTime: number;
    timeLeft: number;
    duration: number;

    constructor(private securamaxApiService: SecuraMaxApiService, private route: ActivatedRoute, private titleService: TitleService, private toastr: SnackbarService) {
    }

    ngOnInit(): void {
        this.titleService.setTitle('Redaction Details');
        this.documentId = this.route.snapshot.paramMap.get('id');
        this.redactionId = this.route.snapshot.paramMap.get('redactionId');
        this.securamaxApiService.documents_Get(this.documentId).subscribe(doc => {
            this.document = doc;

            if (this.document.versions) {
                this.videoSrcUrl = this.document.versions.find(x =>
                    x.videoFormat === "mp4"
                )?.url;
            }

            if (this.redactionId && this.redactionId.length > 0 && this.redactionId !== 'create') {
                this.securamaxApiService.redaction_Get(parseInt(this.redactionId)).subscribe(redac => {
                    this.redaction = redac;
                    this.videoTrimStart = this.fromTimespanString(redac.videoStart);
                    this.videoTrimEnd = this.fromTimespanString(redac.videoEnd);
                    this.audioTrimStart = this.fromTimespanString(redac.audioStart);
                    this.audioTrimEnd = this.fromTimespanString(redac.audioEnd);
                    this.blurAmount = redac.blurAmount;
                    this.loading = false;
                }, (err) => {
                    this.toastr.error('Error occurred while getting the redaction.');
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }, (err) => {
            this.toastr.error('Error occurred while getting the document.');
        });
    }
    delete() {

    }

    getDownloadLink() {

    }

    fromTimespanString(timespanString: string) {
        var date = new Date('1970-01-01T' + timespanString + 'Z');
        //convert to seconds
        return date.getMinutes() * 60 + date.getSeconds() + date.getMilliseconds() * .001;
    }

}
