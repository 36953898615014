import {Component, Input} from '@angular/core';
import {FilterOptionsForm} from "../documents-search2/documents-search2.component";
import {FormGroup} from "@angular/forms";
import {PagingApiService, PagingModel} from "../../../services/paging-api.service";
import {DocumentListViewModel} from "../../../services/api/securamaxapi.service";
import {PageEvent} from "@angular/material/paginator";
import {documentStatus} from "../../../shared/helpers/document-status-const";
import {DocumentSearchService} from "../../../services/document-search.service";
import {SearchFilter} from "../../../models/search-filter.model";


class DocumentListViewModelExtended extends DocumentListViewModel {
    children: DocumentListViewModel[];
}

@Component({
    selector: 'app-documents-search-list2',
    templateUrl: './documents-search-list2.component.html',
    styleUrls: ['./documents-search-list2.component.css']
})
export class DocumentsSearchList2Component {
    pageSizeOptions: number[] = [5, 10, 25, 100];
    isLoading: boolean = true;
    pagingModel: PagingModel = {
        from: 0,
        to: 4,
        totalRecordCount: 0
    };
    pagingModel_currentPageIndex = 0;
    pagingModel_perPage = 5;
    datasource: DocumentListViewModelExtended[];

    constructor(
        public documentSearchService: DocumentSearchService,
        private pagingApiService: PagingApiService) {
    }

    ngOnInit() {
        this.documentSearchService.currentSearchFilter.subscribe(currentSearchFilter => {
            this.isLoading = true;
            this.pagingModel.from = currentSearchFilter.from;
            this.pagingModel.to = currentSearchFilter.to;
            this.pagingApiService
                .fetchData<DocumentListViewModel>('/api/api/documents/search', currentSearchFilter, DocumentListViewModel.fromJS, this.pagingModel)
                .subscribe(x => {
                    this.pagingModel.totalRecordCount = x.totalRecords;
                    this.datasource = this.transformResponse(x.data);
                    this.isLoading = false;
                });
        })

    }


    transformResponse(docuResponse: DocumentListViewModel[]) {
        let docs: DocumentListViewModelExtended[] = [];
        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId == null) {
                var item = docuResponse[i] as DocumentListViewModelExtended;
                item.children = [];
                docs.push(item);
            }
        }

        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId != null) {
                var parentFound = false;
                for (var z = 0; z < docs.length; z++) {
                    if (docs[z].id === docuResponse[i].parentId) {
                        docs[z].children.push(docuResponse[i]);
                        parentFound = true;
                    }
                }
                //don't indent if parent not found
                if (parentFound === false) {
                    docs.push(docuResponse[i] as DocumentListViewModelExtended);
                }
            }
        }
        return docs;
    }

    onPageFired(event: PageEvent) {
        this.pagingModel_currentPageIndex = event.pageIndex;
        this.pagingModel_perPage = event.pageSize;

        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        currentSearch.from = this.pagingModel_currentPageIndex * this.pagingModel_perPage;
        currentSearch.to = currentSearch.from + this.pagingModel_perPage - 1;
        this.documentSearchService.updateSearch(currentSearch);
    }

    protected readonly documentStatus = documentStatus;

    timeline_click() {
        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        currentSearch.isListView = false;
        this.documentSearchService.updateSearch(currentSearch);

    }
}
