<h2 mat-dialog-title i18n>Additional Details</h2>

<mat-dialog-content>
    <label *ngIf="!data.document.parentId" class="details-label"> Title </label>
    <label
        *ngIf="data.document.parentId != null"
        class="details-label"
        style="font-size: 11px"
    >
        Title&nbsp;Of&nbsp;Original
    </label>
    <div>
        <span *ngIf="!data.document.title">Not Set</span>
        <span *ngIf="data.document.title">{{ data.document.title }}</span>
    </div>

    <label class="details-label">Description</label>
    <span *ngIf="!data.document.description">Not Set</span>
    <span *ngIf="data.document.description">
        {{ data.document.description }}
    </span>

    <label class="details-label">Owner</label>
    <div>
        {{ data.document.ownerName }}
    </div>

    <label class="details-label">File Name</label>
    <div>
        {{
            data.document.name
        }}
    </div>

    <label class="details-label">Created</label>

    <div>
        {{ data.document.createdDate | date : "MMM dd YYYY, h:mm:ss a z" }}
    </div>

    <label class="details-label">Uploaded</label>

    <div>
        {{ data.document.uploaded | date : "MMM dd YYYY, h:mm:ss a z" }}
    </div>

    <label class="details-label">File Size</label>

    <div>
        {{ data.document.length | bytes : 1 }}
    </div>

    <label class="details-label">Categories</label>

    <div class="d-flex align-items-center">
        <mat-chip-set>
            <mat-chip
                *ngFor="let category of data.document.categories"
                color="accent"
                selected
            >
                {{ category.name }}
            </mat-chip>
        </mat-chip-set>
        <span *ngIf="data.document.categories.length == 0">No Category Set</span>
    </div>

    <label class="details-label">Tags </label>

    <div class="d-flex align-items-center">
        <mat-chip-set>
            <mat-chip
                *ngFor="let tag of data.document.tags"
                color="accent"
                selected
            >
                {{ tag.typeName }}
            </mat-chip>
        </mat-chip-set>
        <span *ngIf="data.document.tags.length == 0">No Tags</span>
    </div>

    <label class="details-label">Cases</label>

    <div>
        <mat-chip-set>
            <mat-chip
                [routerLink]="['/cases']"
                *ngFor="let case of data.document.documentCaseItemList"
                color="accent"
                selected
                >{{ case.title }}</mat-chip
            >
        </mat-chip-set>
        <span *ngIf="data.document.documentCaseItemList && data.document.documentCaseItemList.length == 0"
            >Not in any Case</span
        >
    </div>

    <label *ngIf="data.isUnlimitedPlanUser" class="details-label"
        >Storage Status</label
    >

    <div
        *ngIf="
            data.document.statusId === data.documentStatus.active &&
            data.isUnlimitedPlanUser
        "
    >
        <span class="smx-primary-button mini"> Active </span>
    </div>

    <div
        *ngIf="
            data.document.statusId === data.documentStatus.archived &&
            data.isUnlimitedPlanUser
        "
    >
        <span class="smx-secondary-button mini"> Archived </span>
    </div>

    <div
        *ngIf="
            data.document.statusId ===
                data.documentStatus.pendingArchiveRetrieval &&
            data.isUnlimitedPlanUser
        "
    >
        <span class="smx-secondary-button mini"> Pending Retrieval </span>
    </div>

    <label class="details-label">Projected Deletion Date</label>

    <div>
        {{ data.document.deletionDate.toString() | timeAgo : true }}
    </div>

    <label
        class="details-label"
        *ngIf="data.docInOpenCaseWithRetentionList.length > 0"
    >
        Wanring</label
    >

    <div *ngIf="data.docInOpenCaseWithRetentionList.length > 0">
        data.document is part of an open case.
        <span
            class="badge badge-info"
            *ngFor="let case of data.docInOpenCaseWithRetentionList"
        >
            {{ case.title }}
        </span>
    </div>

    <label class="details-label">Shares</label>
    <div *ngIf="sharesIsLoading">
        <mat-spinner color="accent" diameter="20"></mat-spinner>
    </div>
    <div *ngIf="sharesIsLoading === false && shares && shares.length > 0">
        <div class="table-container">
            <div class="grid-header">Email</div>
            <div class="grid-header" style="margin-left: 10px">Expires</div>
            <div></div>
            <ng-container *ngFor="let share of shares">
                <div>{{ share.email }}</div>
                <div
                    style="
                        font-size: 11px;
                        text-align: center;
                        align-self: center;
                        margin-left: 10px;
                    "
                >
                    {{ share.expires | date : "short" }}
                </div>
                <div style="text-align: right">
                    <button
                        class="smx-critical-button mini"
                        (click)="openCancelShareDialog(share)"
                    >
                        Cancel
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="sharesIsLoading === false && shares && shares.length === 0">
        No Shares
    </div>

    <label class="details-label">Redactions</label>
    <div *ngIf="data.document.redactions && data.document.redactions.length > 0">
        <div
            class="table-container"
            style="grid-template-columns: auto auto auto 1fr"
        >
            <div class="grid-header">Created On</div>
            <div class="grid-header">Created By</div>
            <div class="grid-header">Status</div>
            <div class="grid-header"></div>
            <ng-container *ngFor="let redaction of data.document.redactions">
                <div
                    style="
                        font-size: 11px;
                        text-align: center;
                        align-self: center;
                        margin-left: 10px;
                    "
                >
                    {{ redaction.created | date : "short" }}
                </div>
                <div
                    style="
                        font-size: 11px;
                        text-align: center;
                        align-self: center;
                        margin-left: 10px;
                    "
                >
                    {{ redaction.createdByUsername }}
                </div>
                <div style="text-align: center; align-self: center">
                    <span
                        class="smx-secondary-button mini"
                        *ngIf="redaction.redactionStatus === 0"
                        i18n
                        >Awaiting Processing</span
                    >
                    <span
                        class="smx-primary-button mini"
                        *ngIf="redaction.redactionStatus === 1"
                        i18n
                        >Ready</span
                    >
                    <span
                        class="smx-primary-button mini"
                        *ngIf="redaction.redactionStatus === 3"
                        i18n
                        >In Progress</span
                    >
                </div>
                <div style="text-align: right">
                    <a
                        mat-button
                        *ngIf="redaction.redactiondata.documentId === null"
                        routerLink="/data.documents/{{
                            data.document.id
                        }}/redactions/{{ redaction.id }}"
                        class="smx-secondary-button mini"
                    >
                        Details
                    </a>

                    <button
                        mat-button
                        *ngIf="
                            redaction.redactiondata.documentId !== null &&
                            redaction.redactionStatus === 3
                        "
                        routerLink="/data.documents/{{
                            data.document.id
                        }}/redactions/advanced/{{ redaction.id }}"
                        [disabled]="
                            !data.document.permissions.canAdvancedRedact ||
                            data.document.statusId ===
                                data.documentStatus.archived
                        "
                        [attr.title]="
                            data.document.statusId ===
                            data.documentStatus.archived
                                ? 'You cannot edit a redaction on an archived video. You must first request retrieval.'
                                : null
                        "
                        class="smx-primary-button btn btn-link btn-xs ml-1"
                        i18n-title
                        i18n
                    >
                        Continue Editing
                    </button>

                    <a
                        mat-button
                        *ngIf="
                            redaction.redactiondata.documentId !== null &&
                            redaction.redactionStatus === 1
                        "
                        routerLink="/data.documents/{{
                            redaction.redactiondata.documentId
                        }}"
                        class="smx-secondary-button mini"
                    >
                        Details
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
    

    <div *ngIf="data.document.redactions && data.document.redactions.length === 0">
        No Redactions
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        class="smx-critical-button mr-2"
        mat-dialog-close
        cdkFocusInitial
        i18n
    >
        Close
    </button>
</mat-dialog-actions>
