import {ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormBuilder, Validators} from "@angular/forms";
import {SecuraMaxApiService} from "../../../services/api/securamaxapi.service";
import {TitleService} from "../../../services/title.service";
import {VisTimelineService} from "../../../shared/components/vis-timeline/vis-timeline.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {SnackbarService} from "../../../services/snackbar.service";
import {
    ReviewMetadataControlsComponent
} from "../../review/review-metadata-controls/review-metadata-controls.component";
import {AnnotateService} from "../../../services/annotate.service";

@Component({
  selector: 'app-annotate2',
  templateUrl: './annotate2.component.html',
  styleUrls: ['./annotate2.component.css']
})
export class Annotate2Component {


    form: FormGroup<any>;

    constructor(
        private securaMaxApiService: SecuraMaxApiService,
        private titleService: TitleService,
        private tfb: FormBuilder,
        public router: Router,
        public dialog: MatDialog,
        private toastr: SnackbarService,
        private changeDetectorRef: ChangeDetectorRef,
        public annotateService: AnnotateService
    ) {

        this.form = this.tfb.group({
            metadata: ReviewMetadataControlsComponent.buildFormPart(this.tfb),
        });

    }

    ngOnInit() {
        this.titleService.setTitle("Review");
    }

    ngDoCheck() {
        if (this.router.url === '/annotate2')
        {
            this.router.navigate(['/annotate2/step1']).then(value => {});
        }
    }

    ngOnDestroy() {
        this.annotateService.reset();
    }
}
