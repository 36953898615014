<!--<app-s-paginate (collection)="onDataSourceUpdate($event)" 
                (numItems)="numItemsUpdated($event)" 
                [url]="'api/api/documents/searchTimelineDevices'" 
                [modelFromJS]="modelFromJS" 
                (pageChange)="onPageChanged()"
                [perPage]="perPage" 
                [hidden]="!currentTimelinesDataArray"
                [urlParams]="searchFilter" 
                >
</app-s-paginate>-->
<mat-paginator #paginator [length]="pagingModel.totalRecordCount" [pageIndex]="currentPageIndex" [pageSize]="perPage" [pageSizeOptions]="pageSizeOptions" (page)="onPageFired($event)" [hidden]="pagingModel.totalRecordCount === 0">
</mat-paginator>
<ul class="list-group">
    <li class="list-group-item" *ngFor="let timeline of currentTimelinesDataArray; let i = index">
        
        <div class="d-flex justify-content-between">
            <div>
                <h2 *ngIf="timeline.device" class="title-group">
                    <img src="/assets/images/icons/{{timeline.device.iconName}}" i18n-alt alt="device image" />
                    <button class="ml-2" mat-raised-button [disabled]="!deviceViewEnabled" routerLink="/devices/{{timeline.device.id}}/details" i18n>{{timeline.device.name}}</button>
                    <!--<a class="deviceViewLink" [disabled]="!deviceViewEnabled" ui-sref="device.documents({id: timelineViewModel[i].DeviceExpandedVM.Id})">{{timelineViewModel[i].deviceExpandedVM.name}}</a>-->
                    <small class="ml-2" i18n>{{timeline.device.ownerUserName}}</small>
                </h2>
            </div>
            <div>
                <!--<a ui-sref="documents.details({id: timelineDataArray[i].selectedDocumentId})" class="btn btn-sm btn-primary pull-right" [disabled]="!timelineDataArray[i].selectedDocumentId">View Selected Document</a>-->
                <button mat-raised-button color="primary" routerLink="/documents/{{timeline.selectedDocumentId}}" [disabled]="!timeline.selectedDocumentId" i18n>View Selected Document</button>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div [visTimeline]="timeline.id" [visTimelineItems]="timeline.items" [visTimelineGroups]="timeline.groups" [visTimelineOptions]="timeline.options" (initialized)="registerTimelineEventHandlers($event)"></div>
            </div>
        </div>
    </li>
</ul>