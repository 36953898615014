import { Injectable } from '@angular/core';
import {
    EditUserViewModel,
    SecuraMaxApiService,
} from '../api/securamaxapi.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: EditUserViewModel;

    constructor(private apiService: SecuraMaxApiService) {}

    getCurrentUser() {
        return new Promise<EditUserViewModel>((resolve, reject) => {
            if (this.user != null) {
                resolve(this.user);
            } else {
                this.apiService
                    .users_GetCurrentUser()
                    .toPromise()
                    .then((u) => {
                        this.user = u;
                        resolve(u);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    }

    isUnlimitedPlan() {
      return this.user?.isUnlimitedPlan === true;
    }

    isLivePlan() {
      return this.user?.isLivePlan === true;
    }

    hasPermission(permission: string): boolean {
        if (!this.user || !this.user.rights) {
            return false;
        }

        const matching = this.user.rights.filter((obj) => {
            return obj.name === permission;
        });
        if (matching.length > 0) {
            return matching[0].value;
        } else {
            return false;
        }
    }

    hasAccessToLive() {
      const access = this.isLivePlan() && this.user.groupPermissions.filter(x => x.liveMap || x.liveView).length > 0;
      return access
    }
}
