import { Component, Inject, OnInit } from '@angular/core';
import { TitleService } from '../../services/title.service';
import { ApiService } from 'src/app/services/api.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-software',
    templateUrl: './software.component.html',
    styleUrls: ['./software.component.css']
})
export class SoftwareComponent implements OnInit {
    private readonly bc300DriverLocation : string = "https://files.provisionusa.com/software/bodycam/bc300drivers/setup.exe";
    private readonly watchFoldersLocation : string = "https://files.provisionusa.com/software/securamax/watchfolders/SecuraMax.Uploader.Setup.msi";
    private readonly PVHDSyncLocation: string = "https://files.provisionusa.com/software/pvhdsync/PVHDSync.Installer.msi";
    private readonly HDViewerLocation: string = "https://files.provisionusa.com/software/HDViewer/PV.HDViewerSetup.exe";
    private readonly LiveViewLocation: string = "https://files.provisionusa.com/software/pvliveview/PVLiveView-win-Setup.exe";
    
    constructor(private titleService: TitleService, private apiService: ApiService, @Inject(DOCUMENT) private document: Document) { }

    ngOnInit(): void {
        this.titleService.setTitle('Software Downloads');
    }

    downloadUploader(): void {
        this.apiService.uploaderUrl().subscribe((data) => {
            this.document.location.href = data;
        }, () => {
        });
    }

    downloadDrivers(): void {
        this.document.location.href = this.bc300DriverLocation;
    }
    
    downloadWatchFolders(): void {
        this.document.location.href = this.watchFoldersLocation;
    }

    downloadPVHDSync(): void {
        this.document.location.href = this.PVHDSyncLocation;
    }

    downloadHDViewer(): void {
      this.document.location.href = this.HDViewerLocation;
    }

    downloadLiveView(): void {
        this.document.location.href = this.LiveViewLocation;
    }
}
