<div class="main-container">
    <div class="d-flex">
        <app-searchbar (search)="onSearch($event)" (clear)="onSearchCleared()" placeholder="Large Document Search" isPrimary="true"></app-searchbar>
    </div>

    <div class="details-card">
        <table class="table">
            <thead>
            <tr>
                <th i18n>Document Title</th>
                <th i18n>Owner</th>
                <th i18n>Locked from Deletion</th>
                <th i18n>Usage</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let document of documentStorageUsages">
                <td>
                    <a *ngIf="document.documentTitle && document.documentTitle !== ''" routerLink="/documents/{{document.documentId}}">{{document.documentTitle}}</a>
                    <a *ngIf="!document.documentTitle || document.documentTitle === ''" routerLink="/documents/{{document.documentId}}" i18n>No Title</a>
                </td>
                <td>{{document.ownerName}}</td>
                <td>
                        <span *ngIf="document.isDeletionLocked" class="badge badge-info" i18n>
                            DELETION LOCKED
                        </span>
                </td>
                <td>{{document.bytesUsed | bytes:1}}</td>
            </tr>
            </tbody>
        </table>
    </div>


    <span *ngIf="documentStorageUsages" class="mr-5">{{totalItems}} <ng-container i18n>Results</ng-container></span>
    <span *ngIf="documentStorageUsages" i18n>{{documentStorageUsages[0].totalStoredAmount | bytes:1}} Total</span>
    <span *ngIf="!documentStorageUsages">
            <mat-spinner color="accent"></mat-spinner>
        </span>


    <app-s-paginate [modelFromJS]="modelFromJS" (collection)="onDataSourceUpdate($event)" [url]="'/api/api/Usage/DocumentStorage'" [urlParams]="filter" (numItems)="numItemsUpdated($event)"></app-s-paginate>
</div>
