import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DocumentMetadataStatusSelectedViewModel } from '../components/shell/shell.component';

@Injectable({
  providedIn: 'root'
})

/**
* Service used to communicate betwen the shell.component and metadata.component. 
*/
export class ReviewService {
    /**
    *
    * The shell.component will signal the metadata.component if the user deselects all
    * documents to be reviewed so the save button gets disabled.
    * 
    * Will pass the array of documents that have been selected.
    */
    reviewDocumentsChanged: BehaviorSubject<DocumentMetadataStatusSelectedViewModel[]> = new BehaviorSubject([]);

    /**
     * The metadata.component will signal the shell.component when the user clicks the 'save'
     * button so the review list gets refreshed.
     */
    isSaveButtonClick: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() { }
}
