import { Component, OnInit } from '@angular/core';
import { DeviceUsageViewModel } from '../../../services/api/securamaxapi.service';
import { PagingApiService } from 'src/app/services/paging-api.service';
import { TitleService } from 'src/app/services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user/user.service';
import { PlanInfoViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';

@Component({
  selector: 'app-device-usage',
  templateUrl: './device-usage.component.html',
  styleUrls: ['./device-usage.component.css']
})
export class DeviceUsageComponent implements OnInit {
  deviceUsages: DeviceUsageViewModel[];
  filter: any = { searchTerm: '' };
  totalItems: number;
  modelFromJS: any = DeviceUsageViewModel.fromJS;
  isUnlimitedPlanUser: boolean = false;
  planInformation: PlanInfoViewModel;
  hasUnlimitedPlan: boolean = false;
  totalStorageGB: number = 0;
  overageGB: number = 0;
  hasStandardPlan: boolean = false;

  constructor(private pagingAPIService: PagingApiService, private titleService: TitleService, private toastr: SnackbarService, private userService: UserService, private apiService: SecuraMaxApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Device Usage Report');

    this.userService.getCurrentUser().then((user) => {
      if (user.isUnlimitedPlan)
      {
        this.isUnlimitedPlanUser = true;
      }
    });

    this.apiService.usage_GetPlanInfo().subscribe( (data) => {
      this.planInformation = data;

      this.hasUnlimitedPlan = false;
      this.hasStandardPlan = false;
      this.planInformation.plans.forEach(plan => {
        if (plan.gbPerDevice == 0) {
          this.hasUnlimitedPlan = true;
        }
        else {
          this.hasStandardPlan = true;
        }

        this.totalStorageGB += (plan.gbPerDevice * plan.numDevices);
      });

      this.overageGB = this.planInformation.totalStorageUsedGB - this.totalStorageGB;

      if(this.overageGB < 0){
        this.overageGB = 0;
      }
    }, (err) => {
      this.toastr.error('Error occurred while getting the reports.');
    });


  }

  onDataSourceUpdate(data: DeviceUsageViewModel[]) {
    this.deviceUsages = data;
  }

  numItemsUpdated(data: number) {
    this.totalItems = data;
  }

  onSearch(searchText: string) {
    this.filter.searchTerm = searchText;
    this.filterChanged();
    //this.pagingAPIService.fetchData<DeviceUsageViewModel>('/api/api/Usage/Device', this.filter, this.modelFromJS, undefined).subscribe(function (data) {
    //  this.datasource = data.data;
    //}, (err) => {
    //  this.toastr.error('Error occurred while getting the reports.');
    //});
  }

  searchCleared(){
    this.filter.searchTerm = '';
    this.filterChanged();
  }

  filterChanged() {
    this.filter = Object.assign({}, this.filter);
  }

  getOverageColor(): string {
    return this.overageGB > 0 ? 'red' : 'black';
  }

  // only show the total if the user has either all unlimite plans or all standard plans
  getTotalDisplay(): string {
    if (this.hasUnlimitedPlan == true && this.hasStandardPlan == false)
      return 'Unlimited GB ';

    if (this.hasUnlimitedPlan == false && this.hasStandardPlan == true)
      return `${this.totalStorageGB}GB `;

    return '';
  }

  getPlanSizeDisplay(planSize: number): string {
    if (planSize == 0)
      return 'Unlimited';

      return `${planSize}GB`;
  }
}
