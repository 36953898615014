import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '../services/api/securamaxapi.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface PagingModelResponse<T>{
  totalRecords: number;
  data: Array<T>;
}

export interface PagingModel{
  from?: number;
   to?: number;
   totalRecordCount?: number; 
   sortProp?: string;
   sortDir?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PagingApiService {

  constructor(private http: HttpClient, @Inject(API_BASE_URL) private baseUrl?: string) { }

  fetchData<T>(url: string, urlParams: Object,modelFromJSFunction: any, pagingModel: PagingModel): Observable<PagingModelResponse<T>> {
    let url_ = this.baseUrl + url + "?";

    if (urlParams != null) {
      Object.keys(urlParams).forEach(key => {
        let value = urlParams[key];
        if (key === 'start' || key === 'end') {
          value = new Date(value).toISOString();
        }
        url_ += key + '=' + encodeURIComponent('' + value) + '&';
      });
    }

    if (pagingModel.from !== undefined && pagingModel.from!== null)
      url_ += "pagingModel.from=" + encodeURIComponent("" + pagingModel.from) + "&";
    if (pagingModel.to !== undefined && pagingModel.to !== null)
      url_ += "pagingModel.to=" + encodeURIComponent("" + pagingModel.to) + "&";
    if (pagingModel.totalRecordCount !== undefined && pagingModel.totalRecordCount !== null)
      url_ += "pagingModel.totalRecordCount=" + encodeURIComponent("" + pagingModel.totalRecordCount) + "&";
    if (pagingModel.sortProp !== undefined && pagingModel.sortProp !== null)
      url_ += "pagingModel.sortProp=" + encodeURIComponent("" + pagingModel.sortProp) + "&";
    if (pagingModel.sortDir !== undefined && pagingModel.sortDir !== null)
      url_ += "pagingModel.sortDir=" + encodeURIComponent("" + pagingModel.sortDir) + "&";
    url_ = url_.replace(/[?&]$/, "");

    //Range: 10-19
    //Range-Unit: items

    return this.http.get<T[]>(url_, {
      observe: "response",
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Range-Unit": "items",
        "Range": pagingModel.from + "-" + pagingModel.to
      })
    }).pipe(map((resp)=>{
      if (resp.status === 200 || resp.status === 204) {
        return {
          totalRecords: 0,
          data: new Array<T>()
        };
      }
      if (resp.status === 206) {
        //get the headers
        let acceptRangeHeader = resp.headers.get('Accept-Ranges');
        let contentRange = resp.headers.get('Content-Range');
        let rangeUnit = resp.headers.get('Range-Unit');
        if (rangeUnit == 'bytes') {
          //0 results--report anyways to tell it all to stop
          return {
            totalRecords: 0,
            data: new Array<T>()
          };
        } else {
          let firstSpace = contentRange.indexOf(' ');
          let rangeVals = contentRange.substring(firstSpace + 1);
          let fromVal = parseInt(rangeVals.substring(0, rangeVals.indexOf('-')), 10);
          let toVal = parseInt(rangeVals.substring(rangeVals.indexOf('-') + 1, rangeVals.indexOf('/')), 10);
          let totalCount = parseInt(rangeVals.substring(rangeVals.indexOf('/') + 1), 10);

          var res = new Array<T>();
          for (let item of resp.body)
            res!.push(modelFromJSFunction(item));

          return {
            totalRecords: totalCount,
            data: res
          };
        }
        //Content-Range: items 0-9/138
        //Content-Range: items 0-49/138
        //Content-Range: bytes */0

      }

      return {
        totalRecords:0,
        data: new Array<T>()
      };
    }));
  }
}
