import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    Inject,
} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { API_BASE_URL } from '../../services/api/securamaxapi.service';

@Component({
    selector: 'app-s-paginate',
    templateUrl: './s-paginate.component.html',
    styleUrls: ['./s-paginate.component.css'],
})
export class SPaginateComponent implements OnInit {
    isEmptyObject(value): boolean {
        return (
            value &&
            Object.keys(value).length === 0 &&
            value.constructor === Object
        );
    }

    private _perPage: number = 10;

    @Input() set perPage(value: number) {
        this._perPage = value;
    }

    get perPage() {
        return this._perPage;
    }

    @Input() modelFromJS: any;
    @Input() url: string;

    private _urlParams = {};
    @Input() set urlParams(value) {
        this._urlParams = value;
        if (this.init) {
            this.setPagingValues(0, this.perPage);
            this.showData();
        }
    }

    get urlParams() {
        return this._urlParams;
    }

    @Input() pageSizeOptions: number[] = [5, 10, 25, 100];

    @Output() numItems = new EventEmitter<number>();
    @Output() collection = new EventEmitter<any[]>();
    @Output() pageChange = new EventEmitter<any>();
    @Output() isLoading = new EventEmitter<boolean>();

    page_index: number = 0;
    from: number;
    to: number;
    totalRecordCount: number;
    sortProp: '';
    sortDir: '';

    init: boolean = false;
    loading: boolean = false;

    constructor(
        private http: HttpClient,
        @Inject(API_BASE_URL) private baseUrl?: string
    ) {}

    ngOnInit(): void {
        this.init = true;
        this.setPagingValues(0, this.perPage);
        this.showData();
    }

    setPagingValues(pageIndex: number, pageSize: number) {
        this.page_index = pageIndex;
        this.from = pageIndex * pageSize;
        this.to = this.from + pageSize - 1;
    }

    onPageFired(event: PageEvent) {
        this.pageChange.emit();
        this.setPagingValues(event.pageIndex, event.pageSize);
        this.showData();
    }

    showData() {
        this.isLoading.emit(true);
        this.loading = true;
        this.fetchData().subscribe((resp) => {
            this.isLoading.emit(false);
            this.loading = false;
            if (resp.status === 200 || resp.status === 204) {
                this.totalRecordCount = 0;
                this.numItems.emit(0);
                this.collection.emit([]);
            }
            if (resp.status === 206) {
                //get the headers
                const contentRange = resp.headers.get('Content-Range');
                const rangeUnit = resp.headers.get('Range-Unit');
                if (rangeUnit == 'bytes') {
                    //0 results--report anyways to tell it all to stop
                    this.totalRecordCount = 0;
                    this.numItems.emit(0);
                    this.collection.emit([]);
                } else {
                    const firstSpace = contentRange.indexOf(' ');
                    const rangeVals = contentRange.substring(firstSpace + 1);
                    const totalCount = parseInt(
                        rangeVals.substring(rangeVals.indexOf('/') + 1),
                        10
                    );
                    this.numItems.emit(totalCount);
                    this.totalRecordCount = totalCount;

                    const res = [];
                    for (const item of resp.body)
                        res!.push(this.modelFromJS(item));

                    this.collection.emit(res);
                }
                //Content-Range: items 0-9/138
                //Content-Range: items 0-49/138
                //Content-Range: bytes */0
            }
        });
    }


    
    fetchData(): Observable<HttpResponse<any[]>> {
        let url_ = this.baseUrl + this.url + '?';

        if (this.urlParams != null) {
            const ignoreProps = [
                'from',
                'to',
                'totalRecordCount',
                'sortProp',
                'sortDir',
            ];
            Object.keys(this.urlParams)
                .filter((k) => ignoreProps.indexOf(k) === -1)
                .forEach((key) => {
                    const value = this.urlParams[key];
                    url_ += key + '=' + encodeURIComponent('' + value) + '&';
                });
        }

        if (this.from !== undefined && this.from !== null)
            url_ +=
                'pagingModel.from=' + encodeURIComponent('' + this.from) + '&';
        if (this.to !== undefined && this.to !== null)
            url_ += 'pagingModel.to=' + encodeURIComponent('' + this.to) + '&';
        if (
            this.totalRecordCount !== undefined &&
            this.totalRecordCount !== null
        )
            url_ +=
                'pagingModel.totalRecordCount=' +
                encodeURIComponent('' + this.totalRecordCount) +
                '&';
        if (this.sortProp !== undefined && this.sortProp !== null)
            url_ +=
                'pagingModel.sortProp=' +
                encodeURIComponent('' + this.sortProp) +
                '&';
        if (this.sortDir !== undefined && this.sortDir !== null)
            url_ +=
                'pagingModel.sortDir=' +
                encodeURIComponent('' + this.sortDir) +
                '&';
        url_ = url_.replace(/[?&]$/, '');

        return this.http.get<any[]>(url_, {
            observe: 'response',
            headers: new HttpHeaders({
                Accept: 'application/json',
                'Range-Unit': 'items',
                Range: this.from + '-' + this.to,
            }),
        });
    }
}
