import { Component, OnInit } from '@angular/core';
import { SecuraMaxApiService, RFIDUserItem, RFIDAssignmentListItem } from 'src/app/services/api/securamaxapi.service';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
    selector: 'app-users-rfid',
    templateUrl: './users-rfid.component.html',
    styleUrls: ['./users-rfid.component.css']
})
export class UsersRfidComponent implements OnInit {
    displayUserAssign: boolean;
    rfidInput: string = "";
    rfidInputValid: any;
    numberOfRFIDTags: number;
    numberOfRFIDTagsValid: any;
    RFIDUserList: RFIDUserItem[] = []; // List used for 'Assign to Users' tab.
    CurrentRFIDAssignmentsList: RFIDAssignmentListItem[] = [];
    errorStr: string;
    rfidEditArray: boolean[] = [];
    resetDefaultUser: boolean;
    tempKey: number;
    tempName: string;
    rfidOverwrite: boolean;

    constructor(private securaMaxApiService: SecuraMaxApiService, private titleService: TitleService, private toastr: SnackbarService) {
        this.displayUserAssign = true;
        this.numberOfRFIDTags = 1;
        this.rfidOverwrite = false;
    }

    ngOnInit(): void {
        this.titleService.setTitle('RFID Assigments');
        this.GetRfidAssignments();
    }

    CheckForRFIDOverwrite(): void
    {
      var retVal = false;

      var listOfAssignments = [];

      this.CurrentRFIDAssignmentsList.forEach(x => listOfAssignments.push(parseInt(x.rFID.substring(1, x.rFID.length))));

      var startingTag = parseInt(this.rfidInput.substring(1, this.rfidInput.length));
      for (let i = 0; i < this.numberOfRFIDTags; i++)
      {
        if (listOfAssignments.includes(startingTag + i))
        {
          retVal = true;
        }
      }

      this.rfidOverwrite = retVal;

      return;
    }

    AddRFID() {
        this.rfidOverwrite = false;
        var subStr = this.rfidInput.substring(1, this.rfidInput.length);
        var rfidNumber = Number(subStr);

        if (isNaN(rfidNumber)) {
            return;
        }
        if (isNaN(this.numberOfRFIDTags)) {
            return;
        }

        for (let i = 0; i < this.numberOfRFIDTags; i++) {
            //Old code:
            //var leadingZeros = 6 - this.rfidInput.replace.toString().length;
            //var subStr = this.rfidInput.substring(0, 1) + String(0).repeat(leadingZeros) + rfidNumber;

            //Remove the 'B' from an RFID. Convert the rest to a number, incrementing it as we go through the loop and adding the leading character back to complete the RFID tag
            if (this.RFIDUserList.filter(x => x.rFID == subStr).length == 0) {
                var item = new RFIDUserItem();
                item.rFID = this.rfidInput.substring(0, 1) + rfidNumber.toString();
                this.RFIDUserList.push(item);
            }

            rfidNumber++;
        }
    }

    RemoveRFIDItem(RFIDItem) {
        var index = this.RFIDUserList.findIndex(x => x.rFID === RFIDItem.rFID);

        if (index > -1) {
            this.RFIDUserList.splice(index, 1);
        }
    }

    SaveRFIDs() {
        var index = this.RFIDUserList.findIndex(x => x.associatedUserId == null);
        if (index > -1) {
            this.errorStr = "All RFIDs must be assigned to a user.";
            return;
        }

        var result = this.RFIDUserList.reduce((acc, o) => (acc[o.associatedUserId] = (acc[o.associatedUserId] || 0) + 1, acc), {});

        for (let key of Object.keys(result)) {
            let value = result[key];

            if (value > 1) {
                this.errorStr = "A user can not be assigned to multiple RFIDs.";
                return;
            }
        }

        this.errorStr = null;

        this.securaMaxApiService.users_SetBulkRFID(this.RFIDUserList).subscribe(data => {
            this.RFIDUserList = [];
            this.rfidInput = "";
            this.GetRfidAssignments();
        }, (err) => {
            this.toastr.error('Error occurred while saving the RFIDs');
        });
    }

    SaveEditRFID(rfidItem) {
        this.securaMaxApiService.users_SetUserRFID(rfidItem.key, rfidItem.rFID).subscribe(data => {
            this.GetRfidAssignments();
        }, (err) => {
            this.toastr.error('Error occurred while saving the RFID');
        });

        return false;
    }

    DeleteAssignedRFID(rfidItem) {
        this.securaMaxApiService.users_SetUserRFID(rfidItem.key, "").subscribe(data => {
            this.GetRfidAssignments();
        }, (err) => {
            this.toastr.error('Error occurred while deleting the RFID');
        });
    }

    AddMultipleClicked() {
        this.RFIDUserList = []; //reset the list to be empty
        this.AddRFID();
    }

    GetRfidAssignments() {
        this.securaMaxApiService.users_GetRFIDAssignmnets().subscribe(data => {
            this.CurrentRFIDAssignmentsList = data;

            for (var i = 0; i < this.CurrentRFIDAssignmentsList.length; i++) {
                this.rfidEditArray.push(false);
            }
        }, (err) => {
            this.toastr.error('Error occurred while getting the RFIDs');
        });
    }

}
