<div class="outer-container">
    <form [formGroup]="createTransferForm" (ngSubmit)="onSubmit()">

        <h1 *ngIf="details" i18n>
            {{ details.unitId }}
            <small class="text-muted">{{details.serial}}</small>
        </h1>


        <h2 i18n>Create Request</h2>

        <div class="mt-3">
            <label i18n>Date/time range selection for request</label>

            <app-daterangepicker
                (change)="daterangepicker_change($event)"
                [(start)]="daterangepicker_start"
                [(end)]="daterangepicker_end"></app-daterangepicker>

            <small class="text-muted" i18n>
                Start time must be before end time.<br/>
                End time must be within 12 hours of start time.
            </small>
        </div>

        <h3 class="mt-2" i18n>Camera selection</h3>

        <div class="inner-container " formArrayName="cameras">
            <ng-container *ngFor="let item of cameras.controls; let i = index">
                <mat-slide-toggle id="cameras-{{i}}" [formControlName]="i" i18n>
                    {{details.cameras[i].cameraName}}
                </mat-slide-toggle>
            </ng-container>
        </div>


        <h2 *ngIf="details" i18n>
            Last Connected
            <small class="text-muted">{{details.lastConnected}}</small>
        </h2>

        <div class="d-flex justify-content-end" style="grid-row: -2/-1;">
            <button mat-button class="smx-primary-button mr-2" type="submit"
                    [disabled]="createTransferForm.invalid">
                <div>
                    <fa-icon [icon]="['fas','arrow-up']" class="mr-1"></fa-icon>
                    <span i18n>Send Request</span>
                </div>
            </button>

            <button mat-button class="smx-secondary-button" (click)="viewTransferDetails()">
                <div>
                    <fa-icon [icon]="['fas','list-alt']" class="mr-1"></fa-icon>
                    <span i18n>View Device Details</span>
                </div>
            </button>
        </div>
    </form>
</div>
