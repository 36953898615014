<div mat-dialog-title class="title">
    <div>Live View</div>
</div>

<mat-dialog-content class="content">

    <h2>Select cameras for live viewing</h2>

    <mat-selection-list [(ngModel)]="selectedCameras">
        <mat-list-option *ngFor="let camera of onlineCameras; let  i = $index" [value]="camera">
            <div class="document-row">
                {{ camera.cameraTitle }}
            </div>
        </mat-list-option>
    </mat-selection-list>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        color="primary"
        class="smx-primary-button"
        i18n
        (click)="completeSelection()"
    >
        OK
    </button>
</mat-dialog-actions>
