import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateUserViewModel, GroupViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.css']
})
export class UserInviteComponent implements OnInit {
  inviteForm = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', Validators.required),
      group: new UntypedFormControl('', Validators.required),
      rfid: new UntypedFormControl('', [Validators.maxLength(8), Validators.minLength(8)])
    });
  groups: GroupViewModel[];
  loaded: boolean = false;
  inviting: boolean = false;
  constructor(private api: SecuraMaxApiService, private router: Router, private toastr: SnackbarService, private titleService: TitleService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Invite User');
    this.api.group_GetAll().toPromise().then((groups) => {
      this.groups = groups;
      this.loaded = true;
    }, (err) => {
      this.toastr.error('Error occurred while getting the users');
    });
  }

  invite() {
    this.inviting = true;

    let model = new CreateUserViewModel({
      email: this.inviteForm.controls.email.value,
      group: this.inviteForm.controls.group.value,
      rFID: this.inviteForm.controls.rfid.value
    });

    this.api.users_Post(model).toPromise().then((res) => {
      this.inviting = false;
      this.toastr.success('Created Successfully!');
      this.router.navigate(['/admin/users/list']);
    }).catch((err) => {
      this.inviting = false;
      if (err && err.status === 400) {
        this.toastr.error('Error! ' + err.response);
      } else {
        this.toastr.error('Error! Please try again later.');
      }
    });
  }

  inviteAndNew() {
    this.inviting = true;

    let model = new CreateUserViewModel({
      email: this.inviteForm.controls.email.value,
      group: this.inviteForm.controls.group.value,
      rFID: this.inviteForm.controls.rfid.value
    });

    this.api.users_Post(model).toPromise().then((res) => {
      this.inviting = false;
      this.toastr.success('Created Successfully!');
      this.inviteForm.reset();
    }).catch((err) => {
      this.inviting = false;
      this.toastr.error('Error! Please try again later.');
    });
  }
}