import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
    @Input() progress = 0;

    @Input() complete = false;

    @Input() error = false;
  constructor() { }

  ngOnInit(): void {
  }

}
