/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeletionRequestViewModel } from './deletionRequestViewModel';
import { RedactionListItemModel } from './redactionListItemModel';
import { CategoryViewModel } from './categoryViewModel';
import { DocumentPermissionsViewModel } from './documentPermissionsViewModel';
import { DocumentCaseItemViewModel } from './documentCaseItemViewModel';
import { VersionViewModel } from './versionViewModel';
import { DocumentTagDisplayViewModel } from './documentTagDisplayViewModel';


export interface DocumentDetailsViewModel { 
    id?: string;
    parentId?: string | null;
    tags?: Array<DocumentTagDisplayViewModel> | null;
    categories?: Array<CategoryViewModel> | null;
    name?: string | null;
    uploaded?: string;
    ownerId?: number;
    thumbnailUrl?: string | null;
    title?: string | null;
    description?: string | null;
    versions?: Array<VersionViewModel> | null;
    permissions?: DocumentPermissionsViewModel;
    isDeletionLocked?: boolean;
    initialTagComplete?: boolean;
    deletionDate?: string | null;
    isPendingDelete?: boolean;
    isPendingHash?: boolean;
    hash?: string | null;
    deletionRequest?: DeletionRequestViewModel;
    createdDate?: string | null;
    ownerName?: string | null;
    length?: number;
    isDeleted?: boolean;
    mimeType?: string | null;
    important?: boolean;
    statusId?: number;
    redactions?: Array<RedactionListItemModel> | null;
    documentCaseItemList?: Array<DocumentCaseItemViewModel> | null;
    requireDocumentTitle?: boolean;
    documentTitleRegex?: string | null;
    titleRegexDescription?: string | null;
}

