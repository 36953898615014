import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";
import {SearchFilter} from "../../../models/search-filter.model";
import {PagingApiService, PagingModel} from "../../../services/paging-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
    CaseCreateDocumentViewModel, CaseDocumentListViewModel,
    CasesCreateViewModel, CasesDetailsViewModel,
    DocumentListViewModel,
    SecuraMaxApiService
} from "../../../services/api/securamaxapi.service";
import {TitleService} from "../../../services/title.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {AnnotateService} from "../../../services/annotate.service";
import * as moment from "moment/moment";
import {CaseDocumentListViewModelExtension} from 'src/app/models/cases-details-documents-viewmodel.model';
import {documentStatus} from 'src/app/shared/helpers/document-status-const';
import {UserSelectModel} from "../../../models/user-select.model";
import {CasesEditCloseDialogComponent} from "../cases-edit-close-dialog/cases-edit-close-dialog.component";
import {MatDialog} from "@angular/material/dialog";


interface CasesEdit2ComponentForm {
    identifier: FormControl<string>,
    description: FormControl<string>,
    retentionType: FormControl<string>,
    caseRetentionDays: FormControl<number>,
    canEditsBeSharedByUsers: FormControl<boolean>
}


@Component({
    selector: 'app-cases-edit2',
    templateUrl: './cases-edit2.component.html',
    styleUrls: ['./cases-edit2.component.css']
})
export class CasesEdit2Component {


    daterangepicker_start: string;
    daterangepicker_end: string;


    owner: UserSelectModel;


    id: number;
    form: FormGroup<CasesEdit2ComponentForm>;
    isSaving: boolean;

    pageEvent: PageEvent = new PageEvent();
    filter: SearchFilter = {};
    pagingModel: PagingModel = {
        sortDir: "",
        sortProp: ""
    };
    possibleDocuments: CaseDocumentListViewModelExtension[] = [];
    selectedDocuments: CaseDocumentListViewModelExtension[] = [];

    pageSizeOptions: number[] = [5, 10, 15, 20];
    resultsLength: number = 0;

    case: CasesDetailsViewModel;
    case_loading: boolean = true;
    isCaseClosedorDeleted: boolean;
    private caseCloseReason: string;


    constructor(
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private securaMaxApiService: SecuraMaxApiService,
        private titleService: TitleService,
        private toastr: SnackbarService,
        private dialog: MatDialog,
        private annotateService: AnnotateService,
        private pagingAPIService: PagingApiService
    ) {

        this.id = Number(this.route.snapshot.paramMap.get('id'));
        this.form = fb.group({
            identifier: ['', Validators.required],
            description: [''],
            retentionType: ['', Validators.required],
            caseRetentionDays: [0, [Validators.required, Validators.min(0), Validators.max(365)]],
            canEditsBeSharedByUsers: [false, Validators.required],
        });

        this.owner = new UserSelectModel();
    }


    ngOnInit(): void {
        this.titleService.setTitle('Edit Case');
        this.case_load();
        this.pageEvent.pageIndex = 0; //The zero-based page index of the displayed list of items. Defaulted to 0.
        this.pageEvent.pageSize = 5; // Initial number of items to show on a page.
        this.onPageFired(this.pageEvent);
    }


    checkIfDocumentSelected(doc: CaseDocumentListViewModelExtension) {
        return this.selectedDocuments.some(d => d.id === doc.id);
    }


    onPageFired(event?: PageEvent) {
        // Keep a local copy so we can search via SearchText;
        var that = this;
        this.pageEvent = event;

        this.pagingModel.from = this.pageEvent.pageIndex * this.pageEvent.pageSize;
        this.pagingModel.to = this.pagingModel.from + this.pageEvent.pageSize - 1;

        let params: any = {};

        if (this.filter.searchTerm) {
            params.searchTerm = this.filter.searchTerm;
        }

        if (this.filter.start) {
            params.start = `${this.filter.start}`;
        }

        if (this.filter.end) {
            params.end = `${this.filter.end}`;
        }

        this.pagingAPIService.fetchData<DocumentListViewModel>('/api/api/Documents/search', params, DocumentListViewModel.fromJS, this.pagingModel).subscribe(function (data) {
            that.resultsLength = data.totalRecords;
            that.possibleDocuments = that.TransformResponse(data.data);
        }, (err) => {
            this.toastr.error("An error occurred while loading devices.");
        });
    }


    private TransformResponse(documents: CaseDocumentListViewModelExtension[]) {
        let docs = [];
        let docsMap = new Map();
        //add all root docs to docs array
        for (var i = 0; i < documents.length; i++) {
            if (documents[i].parentId == null) {
                documents[i].children = [];
                docsMap.set(documents[i].id, documents[i]);
                docs.push(documents[i]);
            }
        }
        //add all child docs to the root docs
        for (var i = 0; i < documents.length; i++) {
            if (documents[i].parentId != null) {
                if (!docsMap.has(documents[i].parentId)) {
                    //sometimes the children are on a separate page
                    docs.push(documents[i]);
                } else {
                    let doc = docsMap.get(documents[i].parentId);
                    doc.children.push(documents[i]);
                }
            }
        }

        docs.sort((a, b) => {
            const aCreated = moment(a.created);
            const bCreated = moment(b.created);
            const result = aCreated.isBefore(bCreated) ? -1 : aCreated.isAfter(bCreated) ? 1 : 0;
            return result;
        });

        return docs;
    }


    OnCancel() {
        this.router.navigate(["/cases"]);
    }


    OnSave() {
        this.isSaving = true;
        var model = new CasesDetailsViewModel();
        model.id = this.case.id;
        model.ownerId = this.owner.associatedUserId;
        model.ownerName = this.owner.associatedUser;
        model.sharedUsersCanEdit = this.form.controls.canEditsBeSharedByUsers.value;
        model.startRange = this.case.startRange;
        model.endRange = this.case.endRange;
        model.status = this.case.status;
        model.title = this.form.controls.identifier.value;
        model.description = this.form.controls.description.value;


        const documentsToRemoveFromCase = [];
        for (let doc of this.case.documents) {
            let isDocumentNoLongerSelected = this.selectedDocuments.find(x => {
                return x.id === doc.id;
            }) === undefined;

            if (isDocumentNoLongerSelected) {
                documentsToRemoveFromCase.push(doc);
            }
        }

        model.documents = this.selectedDocuments;
        model.documentsToRemoveFromCase = documentsToRemoveFromCase;


        if (this.form.controls.retentionType.value === 'case') {
            model.retention = this.form.controls.caseRetentionDays.value;
        } else {
            model.retention = 0;
        }

        this.securaMaxApiService.cases_Post(model).subscribe((data) => {
            this.toastr.success("Saved!");
            this.isSaving = false;
        }, (err) => {
            this.toastr.error("An error occurred while saving the case.");
            this.isSaving = false;
        });
    }

    search(searchTerm: string) {
        this.filter.searchTerm = searchTerm;
        if (this.daterangepicker_start) {
            this.filter.start = moment(this.daterangepicker_start).toISOString();
        } else {
            this.filter.start = null;
        }
        if (this.daterangepicker_end) {
            this.filter.end = moment(this.daterangepicker_end).toISOString();
        } else {
            this.filter.end = null;
        }
        this.onPageFired(this.pageEvent);
    }

    selectDocument(document: CaseDocumentListViewModelExtension) {
        this.selectedDocuments.push(document);
    }

    deselectDocument(document: CaseDocumentListViewModelExtension) {
        this.selectedDocuments.splice(this.selectedDocuments.findIndex(d => d === document), 1);
    }

    protected readonly documentStatus = documentStatus;

    toggleDocumentLock(document: CaseDocumentListViewModelExtension) {
        document.isDeletionLocked = !document.isDeletionLocked;
    }

    protected readonly CaseDocumentListViewModelExtension = CaseDocumentListViewModelExtension;

    daterangepicker_change($event: any) {
        if (this.daterangepicker_start) {
            this.filter.start = moment(this.daterangepicker_start).toISOString();
        } else {
            this.filter.start = null;
        }
        if (this.daterangepicker_end) {
            this.filter.end = moment(this.daterangepicker_end).toISOString();
        } else {
            this.filter.end = null;
        }
        this.onPageFired(this.pageEvent);


    }

    OnReOpenCase() {
        this.securaMaxApiService.cases_ReOpenCase(this.id).subscribe(data => {
            this.case_load(() => {
                this.toastr.success("The case was re-opened successfully.");
                this.caseCloseReason = '';
            });
        }, (err) => {
            this.toastr.error("An error occurred while re-opening the case.");
        });
    }


    case_load(resolve?: () => void) {
        this.case_loading = true;
        this.securaMaxApiService.cases_Details(this.id).subscribe(data => {
            if (data.status !== 1) {
                this.isCaseClosedorDeleted = true;
            } else {
                this.isCaseClosedorDeleted = false;
            }
            this.titleService.setTitle('Edit Case ' + data.title);
            this.case = data;
            this.selectedDocuments = this.TransformResponse(this.case.documents.slice());

            this.form.controls.identifier.setValue(this.case.title);
            this.form.controls.description.setValue(this.case.description);
            const useCaseRetention = this.case.retention > 0;
            this.form.controls.retentionType.setValue(useCaseRetention ? "case" : "document");
            this.form.controls.caseRetentionDays.setValue(this.case.retention);

            this.owner = new UserSelectModel();
            this.owner.associatedUserId = this.case.ownerId;
            this.owner.associatedUser = this.case.ownerName;
            this.case_loading = false;

            if (resolve) {
                resolve();
            }
        }, (err) => {
            this.toastr.error("An error occurred while loading the case.");
            this.case_loading = false;
        });

    }


    OpenCloseCaseDialog(): void {
        const dialogRef = this.dialog.open(CasesEditCloseDialogComponent, {
            width: '600px',
            data: {caseCloseReason: this.caseCloseReason}
        });

        dialogRef.afterClosed().subscribe(result => {
            //if result is undefined, the user selected to keep the case open, set the appropriate status
            if (result === undefined) {
                this.case.status = 1;
            } else { //if result is definedd, the user selected to close the case, set the status and reason
                this.case.status = 0;
                if (result.caseCloseReason != undefined) {
                    this.caseCloseReason = result.caseCloseReason;
                }
                this.securaMaxApiService.cases_CloseCase(this.case.id, this.caseCloseReason).subscribe((data) => {
                    this.case_load(() => {

                        this.toastr.success("The case was closed successfully.");
                    });
                }, (err) => {
                    this.toastr.error("An error occurred while closing the case.");
                });
            }
        });
    }


}
