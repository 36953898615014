import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
    CaseCreateDocumentViewModel,
    CasesCreateViewModel,
    DocumentListViewModel,
    DocumentMetadataStatusViewModel,
    SecuraMaxApiService
} from "../../../services/api/securamaxapi.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {AnnotateService} from "../../../services/annotate.service";
import {
    ReviewMetadataControlsComponent
} from "../../review/review-metadata-controls/review-metadata-controls.component";
import {TitleService} from "../../../services/title.service";
import {PageEvent} from "@angular/material/paginator";
import {SearchFilter} from "../../../models/search-filter.model";
import {PagingApiService, PagingModel} from "../../../services/paging-api.service";
import {CaseDocumentListViewModelExtension} from "../../../models/cases-details-documents-viewmodel.model";
import {documentStatus} from "../../../shared/helpers/document-status-const";
import * as moment from "moment";




interface CasesCreate2ComponentForm {
    identifier: FormControl<string>,
    description: FormControl<string>,
    useCaseRetention: FormControl<boolean>,
    caseRetentionDays: FormControl<number>,
    canEditsBeSharedByUsers: FormControl<boolean>
}


@Component({
  selector: 'app-cases-create2',
  templateUrl: './cases-create2.component.html',
  styleUrls: ['./cases-create2.component.css']
})
export class CasesCreate2Component {

    daterangepicker_start: string;
    daterangepicker_end: string;

    form: FormGroup<CasesCreate2ComponentForm>;
    isSaving: boolean;

    pageEvent: PageEvent = new PageEvent();
    filter: SearchFilter = {};
    pagingModel: PagingModel = {
        sortDir: "",
        sortProp: ""
    };
    possibleDocuments: CaseDocumentListViewModelExtension[] = [];
    selectedDocuments: CaseDocumentListViewModelExtension[] = [];

    pageSizeOptions: number[] = [5, 10, 15, 20];
    resultsLength: number = 0;


    constructor(
        private router: Router,
        private fb: FormBuilder,
        private securaMaxApiService: SecuraMaxApiService,
        private titleService: TitleService,
        private toastr: SnackbarService,
        private annotateService: AnnotateService,
        private pagingAPIService: PagingApiService
    ) {
        this.form = fb.group({
            identifier: ['', Validators.required],
            description: [''],
            useCaseRetention: [false, Validators.required],
            caseRetentionDays: [0, [Validators.required, Validators.min(0), Validators.max(365)]],
            canEditsBeSharedByUsers: [false, Validators.required],
        });
    }


    ngOnInit(): void {
        this.titleService.setTitle('Create Case');

        this.pageEvent.pageIndex = 0; //The zero-based page index of the displayed list of items. Defaulted to 0.
        this.pageEvent.pageSize = 5; // Initial number of items to show on a page.
        this.onPageFired(this.pageEvent);
    }


    checkIfDocumentSelected(doc: CaseDocumentListViewModelExtension) {
        return this.selectedDocuments.some(d => d.id === doc.id);
    }



    onPageFired(event?: PageEvent) {
        // Keep a local copy so we can search via SearchText;
        var that = this;
        this.pageEvent = event;

        this.pagingModel.from = this.pageEvent.pageIndex * this.pageEvent.pageSize;
        this.pagingModel.to = this.pagingModel.from + this.pageEvent.pageSize - 1;

        let params: any = {
        };

        if (this.filter.searchTerm) {
            params.searchTerm = this.filter.searchTerm;
        }

        if (this.filter.start) {
            params.start = `${this.filter.start}`;
        }

        if (this.filter.end) {
            params.end = `${this.filter.end}`;
        }

        this.pagingAPIService.fetchData<DocumentListViewModel>('/api/api/Documents/search', params, DocumentListViewModel.fromJS, this.pagingModel).subscribe(function (data) {
            that.resultsLength = data.totalRecords;
            that.possibleDocuments = that.TransformResponse(data.data);
        }, (err) => {
            this.toastr.error("An error occurred while loading devices.");
        });
    }


    private TransformResponse(documents: CaseDocumentListViewModelExtension[]) {
        let docs = [];
        let docsMap = new Map();
        //add all root docs to docs array
        for (var i = 0; i < documents.length; i++) {
            if (documents[i].parentId == null) {
                documents[i].children = [];
                docsMap.set(documents[i].id, documents[i]);
                docs.push(documents[i]);
            }
        }
        //add all child docs to the root docs
        for (var i = 0; i < documents.length; i++) {
            if (documents[i].parentId != null) {
                if (!docsMap.has(documents[i].parentId)) {
                    //sometimes the children are on a separate page
                    docs.push(documents[i]);
                } else {
                    let doc = docsMap.get(documents[i].parentId);
                    doc.children.push(documents[i]);
                }
            }
        }

        return docs;
    }

    OnCancel() {
        this.router.navigate(["/cases"]);
    }

    OnSave() {
        this.isSaving = true;
        var newCaseVM = new CasesCreateViewModel();
        newCaseVM.title = this.form.controls.identifier.value;
        newCaseVM.description = this.form.controls.description.value;
        newCaseVM.documents = new Array<CaseCreateDocumentViewModel>();

        this.selectedDocuments.forEach(doc => {
            var createDoc = new CaseCreateDocumentViewModel();
            createDoc.id = doc.id;
            newCaseVM.documents.push(createDoc);
        });

        newCaseVM.sharedUsersCanEdit = this.form.controls.canEditsBeSharedByUsers.value;

        if (this.form.controls.useCaseRetention.value) {
            newCaseVM.retention = this.form.controls.caseRetentionDays.value;
        }
        else {
            newCaseVM.retention = 0;
        }

        this.securaMaxApiService.cases_Create(newCaseVM).subscribe((data) => {
            this.toastr.success('Saved Successfully!');
            this.router.navigate(['cases', data.id, 'details']);
        }, (err) => {
            this.isSaving = false;
            this.toastr.error("An error occurred while creating the case.");
        });


    }

    search(searchTerm: string) {
        this.filter.searchTerm = searchTerm;
        if (this.daterangepicker_start) {
            this.filter.start = moment(this.daterangepicker_start).toISOString();
        } else {
            this.filter.start = null;
        }
        if (this.daterangepicker_end) {
            this.filter.end = moment(this.daterangepicker_end).toISOString();
        } else {
            this.filter.end = null;
        }
        this.onPageFired(this.pageEvent);
    }

    selectDocument(document: CaseDocumentListViewModelExtension) {
        this.selectedDocuments.push(document);
    }

    deselectDocument(document: CaseDocumentListViewModelExtension) {
        this.selectedDocuments.splice(this.selectedDocuments.findIndex(d => d === document), 1);
    }

    protected readonly documentStatus = documentStatus;

    toggleDocumentLock(document: CaseDocumentListViewModelExtension) {
        document.isDeletionLocked = !document.isDeletionLocked;
    }

    protected readonly CaseDocumentListViewModelExtension = CaseDocumentListViewModelExtension;

    daterangepicker_change($event: any) {
        if (this.daterangepicker_start) {
            this.filter.start = moment(this.daterangepicker_start).toISOString();
        } else {
            this.filter.start = null;
        }
        if (this.daterangepicker_end) {
            this.filter.end = moment(this.daterangepicker_end).toISOString();
        } else {
            this.filter.end = null;
        }
        this.onPageFired(this.pageEvent);
    }
}
