<div class="card mt-3">
    <div class="card-body outer-container">
        <div class="d-flex justify-content-between header-container">
            <h1 i18n>Case Details</h1>
        </div>

        <form class="case-controls-container" [formGroup]="form">


            <div class="case-information-controls-container ">

                <div class="d-flex justify-content-between mb-1" style="grid-column: 1/-1;">
                    <h2>Case Information</h2>
                    <div>
                        <button mat-button class="smx-primary-button audit-case mr-1"
                                style="font-size: 0.7rem; height: 44px;"
                                (click)='AuditCase(datasource.id)' i18n>Download Case Audit Report
                        </button>
                        <button mat-button class="smx-secondary-button edit-case mr-1"
                                routerLink="/cases/{{datasource.id}}/edit" [disabled]="!datasource.canEditCase" i18n>Edit
                        </button>
                        <button mat-button class="smx-secondary-button help ml-1" i18n>
                            <i class="fas fa-question-sign"></i>
                            <fa-icon [icon]="['fas','question-circle']"></fa-icon>
                            Help
                        </button>
                    </div>
                </div>
                <div i18n>Status</div>
                <div *ngIf="datasource.status == 0" i18n>Closed</div>
                <div *ngIf="datasource.status == 1" i18n>Open</div>
                <div *ngIf="datasource.status == 2" i18n>{{datasource.status}}</div>
                <div i18n>Identifier</div>
                <div i18n>{{datasource.title}}</div>
                <div i18n>Description</div>
                <div i18n>{{datasource.description}}</div>
                <div i18n>Owner</div>
                <div i18n>{{datasource.ownerName}}</div>
                <div i18n>Retention</div>
                <div i18n>{{datasource.retention}}</div>
                <div i18n>Created By</div>
                <div i18n>{{datasource.createdByStr}}</div>
                <div i18n>Documents Time Range</div>
                <div i18n>{{datasource.startRange | date:'short'}} to {{datasource.endRange | date:'short'}}</div>
            </div>

            <div class="case-buttons-container">

            </div>


            <div class="document-selection-container">
                <div class="d-flex justify-content-between mb-2">
                    <h2>Documents in Case</h2>
                    <div class="d-flex">
                        <button mat-button class="smx-primary-button mr-1"
                                (click)='selectAll_click()' i18n>
                            Select All
                        </button>
                        <button mat-button class="smx-critical-button mr-2"
                                (click)='deselectAll_click()' i18n>
                            Deselect All
                        </button>
                        <button mat-button class="smx-secondary-button mr-1" [disabled]="form.controls.selectedDocuments.value.length <= 0"
                                (click)='shareSelected_click()' i18n>
                            Share Selected
                        </button>
                        <button mat-button class="smx-secondary-button mr-1" [disabled]="form.controls.selectedDocuments.value.length <= 0"
                                (click)='downloadSelected_click()' i18n>
                            Download Selected
                        </button>
                    </div>
                </div>
                <div class="dropscroll-area-0">
                    <div class="dropscroll-area-1">

                        <div class="document-selection-inner-container">
                            <mat-selection-list formControlName="selectedDocuments">
                                <mat-list-option [disabled]="document.isDeleted"
                                                 *ngFor="let document of datasource.documents" [value]="document">
                                    <div class="document-row">
                                        <app-doc-thumbnail id="{{document.id}}"></app-doc-thumbnail>
                                        <div class="document-row-text">
                                            <div *ngIf="document.isDeleted" style="color: darkred;"><strong
                                                i18n>Deleted</strong></div>
                                            <div><strong i18n>Title: </strong>{{document.title}}</div>
                                            <div><strong i18n>Owner: </strong>{{document.ownerName}}</div>
                                            <div><strong i18n>File: </strong>{{document.name}}</div>
                                            <div><strong i18n>Created: </strong>{{document.created}}</div>
                                            <div *ngIf="document.statusId === documentStatus.archived">
                                                <label class="badge badge-info" i18n>Archived</label>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </div>


                    </div>

                </div>
            </div>


        </form>

    </div>
</div>
