import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../../services/user/user.service';

@Injectable({
    providedIn: 'root',
})
export class SecuramaxPermissionGuard {
    constructor(private userService: UserService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        return this.userService.getCurrentUser().then((u) => {
            if (route.url.length > 0 && route.url[0].path === 'live') {
                if (this.userService.hasAccessToLive()) {
                    return true;
                }
            }

            if (this.userService.hasPermission(route.data.permission)) {
                return true;
            }

            return this.router.parseUrl('/forbidden');
        });
    }
}
