import {Component, OnInit} from '@angular/core';
import {SecuraMaxApiService, TimeBasedTagsListViewModel} from 'src/app/services/api/securamaxapi.service';
import {PagingApiService, PagingModel} from 'src/app/services/paging-api.service';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {
    HelpReviewByScheduleDialogComponent
} from '../review-by-time-help-dialog/review-by-time-help-dialog.component';
import {TitleService} from '../../../services/title.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from "moment";


export class TimeBasedTagSearchFilter {
    searchTerm: string;
    eventStart: string;
    eventEnd: string;
    active: boolean = true;
    disabled: boolean = true;
    expired: boolean = true;
};

@Component({
    selector: 'app-review-by-time-list',
    templateUrl: './review-by-time-list.component.html',
    styleUrls: ['./review-by-time-list.component.css']
})
export class ReviewByScheduleListComponent implements OnInit {
    timeBasedTagsList: TimeBasedTagsListViewModel[];
    filter: TimeBasedTagSearchFilter = new TimeBasedTagSearchFilter();
    totalItems: number;
    modelFromJS: any = TimeBasedTagsListViewModel.fromJS;
    perPage: number = 10;
    pageEvent: PageEvent = new PageEvent();
    pagingModel: PagingModel;
    daterangepicker_start: string;
    daterangepicker_end: string;

    constructor(private securaMaxApiService: SecuraMaxApiService,
                private titleService: TitleService,
                private pagingAPIService: PagingApiService,
                private router: Router,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private toastr: SnackbarService) {
    }

    ngOnInit(): void {
        this.titleService.setTitle('Review by Time - Current Associations');
        this.filter = new TimeBasedTagSearchFilter();

        this.pageEvent.pageIndex = 0; //The zero-based page index of the displayed list of items. Defaulted to 0.
        this.pageEvent.pageSize = 10; // Initial number of items to show on a page.
    }

    onDataSourceUpdate(data: TimeBasedTagsListViewModel[]) {
        this.timeBasedTagsList = data;
    }

    numItemsUpdated(data: number) {
        this.totalItems = data;
    }

    search(searchTerm: string) {
        var vm = this;
        if (!searchTerm) {
            searchTerm = '';
        }
        this.filter.searchTerm = searchTerm;
        vm.pagingModel = {
            from: 0,
            to: 10,
            totalRecordCount: 10
        };

        this.pagingAPIService.fetchData<TimeBasedTagsListViewModel>('/api/api/TimeBasedTags/GetAll', vm.filter, TimeBasedTagsListViewModel.fromJS, vm.pagingModel).subscribe(function (data) {
            vm.timeBasedTagsList = data.data;
        }, (err) => {
            this.toastr.error('Error occurred while getting the time based tags.');
        });

    }

    openHelpDialog() {
        const dialogRef = this.dialog.open(HelpReviewByScheduleDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    new_click() {
        this.router.navigate(["../new"], {relativeTo: this.route});
    }

    daterangepicker_change($event: any) {
        this.filter.eventStart = moment(this.daterangepicker_start).toISOString();
        this.filter.eventEnd = moment(this.daterangepicker_end).toISOString();
        this.search(this.filter.searchTerm);
    }
}
