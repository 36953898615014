<div class="feedback-dialog">
    <form name="feedbackForm" novalidate #feedbackForm="ngForm">
        <p mat-dialog-title *ngIf="dialogData.feedbackType===feedbackTypes.bug" i18n>Report an Issue</p>
        <p mat-dialog-title *ngIf="dialogData.feedbackType===feedbackTypes.feedback" i18n>Make a Suggestion</p>

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label *ngIf="dialogData.feedbackType===feedbackTypes.feedback" i18n>Please describe your idea or what you would like to improve</mat-label>
                <mat-label *ngIf="dialogData.feedbackType===feedbackTypes.bug" i18n>Please tell us about the bug and how you found it</mat-label>
                <textarea rows="8" matInput required [(ngModel)]="description" name="description"></textarea>
            </mat-form-field>

        <div mat-dialog-actions class="d-flex justify-content-center align-items-center">
            <button mat-stroked-button (click)="onDismiss()" mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button color="primary" [disabled]="!feedbackForm.form.valid || feedbackSending" type="submit" (click)="onSubmit()" i18n>Submit</button>
        </div>
    </form>
</div>