import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { SearchFilter } from 'src/app/models/search-filter.model';
import { DocumentListViewModel } from 'src/app/services/api/securamaxapi.service';
import { PagingApiService, PagingModel } from 'src/app/services/paging-api.service';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


class DocumentListViewModelExtended extends DocumentListViewModel {
    children: DocumentListViewModel[];
}

@Component({
    selector: 'app-documents-search-list',
    templateUrl: './documents-search-list.component.html',
    styleUrls: ['./documents-search-list.component.css']
})
export class DocumentsSearchListComponent implements OnInit {
    currentPageIndex: number;
    perPage: number;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    filter: SearchFilter = {};
    pagingModel: PagingModel = {
        from: 0,
        to: 4,
        totalRecordCount: 0
    };

    datasource: DocumentListViewModelExtended[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private pagingApiService: PagingApiService,
        private titleService: TitleService,
        private toastr: SnackbarService) { }

    ngOnInit(): void {
        this.titleService.setTitle('Search');
        this.route.queryParams.pipe(switchMap((params) => {
            //paging model use existing filter unless params override
            //filter use params filter always

            this.filter = params;

            if (this.pagingModel !== undefined) {
                if (params.totalRecordCount != undefined) {
                    this.pagingModel.totalRecordCount = parseInt(params.totalRecordCount, 10);
                }
                if (params.to != undefined) {
                    this.pagingModel.to = parseInt(params.to, 10);
                }
                if (params.from != undefined) {
                    this.pagingModel.from = parseInt(params.from, 10);
                }
            }
            
            //need defaults
            return this.pagingApiService.fetchData<DocumentListViewModel>('/api/api/documents/search', params, DocumentListViewModel.fromJS, this.pagingModel);
            })).subscribe((res) => {
                this.pagingModel.totalRecordCount = res.totalRecords,
                this.datasource = this.transformResponse(res.data);
            }, (err) => {
                this.toastr.error("An error occurred while searching for documents.");
            });
        // Subscribes to the nav-bar components search button to be pressed.
        // this.documentSearchService.isSearchButtonClick.subscribe(value => {    
        //   this.datasource=null; 
        //   this.filter = Object.assign({}, value);
        // });
    }

    transformResponse(docuResponse: DocumentListViewModel[]) {
        let docs: DocumentListViewModelExtended[] = [];
        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId == null) {
                var item = docuResponse[i] as DocumentListViewModelExtended;
                item.children = [];
                docs.push(item);
            }
        }

        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId != null) {
                var parentFound = false;
                for (var z = 0; z < docs.length; z++) {
                    if (docs[z].id === docuResponse[i].parentId) {
                        docs[z].children.push(docuResponse[i]);
                        parentFound = true;
                    }
                }
                //don't indent if parent not found
                if (parentFound === false) {
                    docs.push(docuResponse[i] as DocumentListViewModelExtended);
                }
            }
        }
        return docs;
    }

    onPageFired(event: PageEvent) {
        this.currentPageIndex = event.pageIndex;
        this.perPage = event.pageSize;

        let allParams: any = {
        };

        Object.assign(allParams, this.filter);

        allParams.from = this.currentPageIndex * this.perPage;
        allParams.to = allParams.from + this.perPage - 1;

        this.router.navigate(['/search/documents'], { queryParams: allParams })
        //todo: add page to route params
    }

}
