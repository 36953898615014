import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    SecuraMaxApiService,
    HomePageViewModel,
    EditUserViewModel,
} from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from '../../services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateRequestDialogComponent } from 'src/app/shared/components/create-request-dialog/create-request-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
    showDeviceAttentionBanner: boolean = false;
    canManageDevices = false;
    stats: HomePageViewModel;
    statsLoaded: boolean = false;
    protected readonly environment = environment;

    constructor(
        private api: SecuraMaxApiService,
        private toastr: SnackbarService,
        private userService: UserService,
        private dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.userService.getCurrentUser().then(async (u) => {
            this.canManageDevices =
                this.userService.hasPermission('manage_devices');
            if (this.canManageDevices) {
                const { doAnyNeedAttention } = await this.api
                    .devices_GetDoAnyNeedAttention()
                    .toPromise();
                this.showDeviceAttentionBanner = doAnyNeedAttention;
            }
        });
        this.api
            .metadata_GetHomePage()
            .toPromise()
            .then(
                (data) => {
                    this.stats = data;
                    this.statsLoaded = true;
                },
                (err) => {
                    this.toastr.error(
                        'Error occurred while getting the homepage'
                    );
                }
            );
    }

    handleDeviceAttentionBannerClick() {
        this.router.navigate(['admin', 'devices', 'list'], {
            queryParams: {
                shouldShowOnlyEnabledAndAssignedToClosedUsers: true,
            },
        });
    }
}
