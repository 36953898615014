<div class="card mt-3">
    <div class="card-body card-container">
        <h1 i18n>Site Settings</h1>
        <div class="outer-container">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div *ngIf="!settingsLoaded">
                            <mat-spinner color="accent"></mat-spinner>
                        </div>
                        <div *ngIf="settingsLoaded">
                            <form [formGroup]="settingsForm" (ngSubmit)="save()">
                                <div class="form-group">

                                    <label class="control-label" i18n>Minimum Retention (Days)</label>
                                    <mat-form-field class="w-50" appearance="fill" style="display:block;">
                                        <input matInput type="number" placeholder="Ex. 30" i18n-placeholder
                                               id="defaultRetentionDays" formControlName="defaultRetentionDays" required>
                                        <span class="mr-2" matSuffix i18n>Days</span>
                                        <mat-error *ngIf="f.defaultRetentionDays.errors?.required" i18n>You must have a
                                            default retention set.
                                        </mat-error>
                                        <mat-error *ngIf="f.defaultRetentionDays.errors?.min" i18n>Default retention must be
                                            at least a day.
                                        </mat-error>
                                        <mat-error *ngIf="f.defaultRetentionDays.errors?.max" i18n>
                                            <ng-container>Default retention cannot be more than</ng-container>
                                            {{settings.maxDefaultRetentionDays
                                        < 3650 ? settings.maxDefaultRetentionDays : 3650}} days
                                        </mat-error>
                                    </mat-form-field>
                                    <p class="text-muted" i18n>The minimum number of days to keep all documents. Files may
                                        be kept longer if tagged to categories or cases with longer retention times, or if
                                        Locked from Deletion is enabled.</p>
                                </div>
                                <hr/>
                                <div class="form-group">
                                    <div class="mb-2">
                                        <mat-slide-toggle formControlName="autoLockDocument" i18n>
                                            <label class="mt-2">Auto Lock Documents</label>
                                        </mat-slide-toggle>
                                    </div>
                                    <p class="text-muted" i18n>If a user adds a title, description, tag, category, or
                                        adjusts associated user on a document that still requires initial tagging, it will
                                        automatically lock that video to prevent it from being deleted. Then a user with the
                                        right permissions can unlock it later for deletion.</p>
                                </div>
                                <hr/>
                                <div>
                                    <div class="mb-2">
                                        <mat-slide-toggle id="requireDocumentTitle" formControlName="requireDocumentTitle"
                                                          i18n>
                                            <label class="mt-2">Require Document Title</label>
                                        </mat-slide-toggle>
                                    </div>
                                    <p class="text-muted" i18n>If enabled, requires every document to have a title
                                        associated when being reviewed by the user.</p>
                                </div>
                                <div *ngIf="f.requireDocumentTitle.value">
                                    <mat-form-field class="w-50" appearance="fill">
                                        <mat-label i18n>Document Title Regex</mat-label>
                                        <input matInput placeholder="optional" formControlName="documentTitleRegex"
                                               i18n-placeholder>

                                    </mat-form-field>
                                    <p class="text-muted" i18n>To force a specific title format, enter a REGEX string that
                                        will be validated when user is reviewing a document.</p>
                                </div>

                                <br/>
                                <div *ngIf="f.requireDocumentTitle.value">
                                    <mat-form-field class="w-50" appearance="fill">
                                        <mat-label i18n>Title Regex Description</mat-label>
                                        <textarea matInput formControlName="titleRegexDescription" placeholder="optional"
                                                  i18n-placeholder></textarea>

                                    </mat-form-field>
                                    <p class="text-muted" i18n>A small description that will appear on the documents page
                                        when editing metadata to help the user understand the title format.</p>
                                </div>

                                <button mat-button class="smx-primary-button" type="submit" [disabled]="!settingsForm.valid"
                                        i18n>Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
