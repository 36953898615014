<div class="outer-container">
    <div class="d-flex justify-content-end">
        <button mat-button class="smx-primary-button" (click)="openQuickDVRConfigDialog()" i18n>Generate Quick Setup File</button>
    </div>

    <div class="inner-container">

        <mat-card >
            <mat-card-header>
                <mat-card-title i18n>Step 1</mat-card-title>
            </mat-card-header>
            <mat-card-content class="p-3">
                <p i18n>
                    Verify your device is file transfer capable. Older DR-200 and PD-1800 devices may require updated firmware.
                </p>
                <ul>
                    <li i18n>
                        a. Connect to your device, open your browser, and navigate to the device's web configuration home page. (Typically 192.168.10.254)
                    </li>
                    <li i18n>
                        b. Open the "Settings" drop down menu.
                    </li>
                    <li i18n>
                        c. If your device supports file transfer, you will see a "File Transfer" menu option.
                    </li>
                </ul>
            </mat-card-content>

            <mat-card-footer>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-footer>
        </mat-card>

        <mat-card >
            <mat-card-header>
                <mat-card-title i18n>Step 2</mat-card-title>
            </mat-card-header>
            <mat-card-content class="p-3">
                <p i18n>
                    Enter the url displayed in the box below into the "Server URL" box on your devices "File
                    Transfer" settings page: <br/>
                </p>
                <mat-form-field appearance="outline" class="pr-3 w-75">
                    <input matInput type="text" value="{{pvTransferUrl}}" readonly/>
                </mat-form-field>
                <ul class="mt-0">
                    <li i18n>
                        PD-1800/DR-200: Check the "Enabled" box and then click "Register".
                    </li>
                    <li i18n>
                        PD-1900: Turn on the "File Transfer" switch.
                    </li>
                    <li i18n class="mt-2">
                        A registration code will be displayed on the device, enter it in the box on Step 3.
                    </li>
                </ul>
            </mat-card-content>

            <mat-card-footer>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-footer>
        </mat-card>

        <mat-card >
            <mat-card-header>
                <mat-card-title i18n>Step 3</mat-card-title>
            </mat-card-header>
            <mat-card-content class="pr-3 mx-3 mt-2">
                <label i18n>Enter the code displayed on your device:</label>
                <mat-form-field appearance="outline" class="ml-2">
                    <input matInput type="text" placeholder="EX: G80F51" i18n-placeholder
                           [(ngModel)]="deviceCodeModel.code"/>
                </mat-form-field>


                <div class="d-flex">
                    <div class="pl-0 pt-3 col-2">
                        <label i18n>Select Associated User:</label>
                    </div>
                    <div class="p-0 col-6">
                        <app-user-select [(userId)]="deviceCodeModel.associatedUserId"></app-user-select>
                    </div>
                </div>


                <mat-checkbox [(ngModel)]="deviceCodeModel.enableAlarmTransfers" i18n>
                    <label class="mt-2">Enable Automatic Alarm Video Transfers</label>
                </mat-checkbox>
                <button mat-button class="smx-primary-button approve-button mb-2"
                        [disabled]="deviceCodeModel.code==null || deviceCodeModel.associatedUserId==null || formSubmissionSuccess == true"
                        (click)="confirmEnterCode()" i18n>Approve Device
                </button>

                <span *ngIf="formSubmissionResponse != null" class="text-danger"
                      i18n>{{formSubmissionResponse}}</span>
            </mat-card-content>

            <mat-card-footer>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-footer>
        </mat-card>


        <ng-container *ngIf="formSubmissionSuccess" #step4>
            <mat-card >
                <mat-card-header>
                    <mat-card-title i18n>Step 4</mat-card-title>
                </mat-card-header>
                <mat-card-content class="pl-3 pt-3">
                    <h4>
                        <b i18n>The code was submitted successfully.</b><br />
                        <small i18n> Check the DVR for registration status infromation. Once accepted the device will appear
                            in the devices list.</small>
                    </h4>
                    <hr />

                    <div style="text-align:center; margin:30px"
                         *ngIf="!deviceConfirmationModel.isCompletedRegistration && !dvrConfirmationError">
                        <label i18n>
                            Waiting for Device to confirm code, this may take a few minutes. <br />
                        </label><br />
                        <mat-spinner color="accent"></mat-spinner>
                    </div>


                    <div style="text-align:center; margin:30px" *ngIf="deviceConfirmationModel.isCompletedRegistration">
                        <label i18n>
                            Device Successfully Registered.
                        </label>
                        <h5 i18n>{{deviceConfirmationModel.deviceName}} - <small>{{deviceConfirmationModel.serial}}</small>
                        </h5>
                    </div>

                    <div style="text-align:center; margin:30px"
                         *ngIf="dvrConfirmationError && !deviceConfirmationModel.isCompletedRegistration">
                        <label i18n>
                            Woops! An Error has occured.
                        </label>
                        <h5 i18n>Device did not confirm the code. Try registering the device with a new code.</h5>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <mat-card-subtitle>
                        <button mat-button class="smx-primary-button approve-button mr-3"
                                [disabled]="!deviceConfirmationModel.isCompletedRegistration"
                                (click)="approveAnotherDevice()" i18n>Approve Another Device</button>
                        <button mat-button class="smx-secondary-button approve-button mr-1" routerLink='/admin/devices/{{deviceConfirmationModel.deviceId}}/details'
                                [disabled]="!deviceConfirmationModel.isCompletedRegistration" i18n>View Device</button>
                    </mat-card-subtitle>
                </mat-card-footer>
            </mat-card>
        </ng-container>
    </div>

</div>
