<mat-dialog-content [formGroup]="form">

    <mat-form-field class="full-width">
        <input matInput
               placeholder="Title"
               formControlName="title"
               i18n-placeholder>
    </mat-form-field>
    <br />
    <mat-form-field class="full-width">
        <input matInput
               placeholder="Description (optional)"
               formControlName="description"
               i18n-placeholder>
    </mat-form-field>
    <br />
    <mat-error *ngIf="submitError" i18n>An error has occured on the server. Case could not be created.</mat-error>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()" i18n>Close</button>
    <button class="mat-raised-button mat-primary" (click)="save()" i18n>Save</button>
</mat-dialog-actions>