<div
    [id]="wide ? 'form_container_wide' : 'form_container'"
    [formGroup]="form"
    class="filter-settings-container"
>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Title (Enter a Title)</mat-label>
        <input matInput type="text" formControlName="title" />
        <mat-error *ngIf="form.controls.title.invalid">{{
            this.titleRegexDescription
        }}</mat-error>
    </mat-form-field>

    <div>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="outline" class="d-flex">
            <mat-label>Categories (Select at least one)</mat-label>
            <mat-select multiple formControlName="categoriesForm">
                <mat-option
                    *ngFor="let category of allCategories"
                    [value]="category"
                >
                    {{ category.name }}</mat-option
                >
            </mat-select>

            <mat-error *ngIf="form.controls.categoriesForm.hasError('required')"
                >You must make a selection
            </mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-label>Cases</mat-label>
        <mat-chip-set class="w-100" #chipList aria-label="case selection">
            <mat-chip
                *ngFor="let case of caseEditList"
                [removable]="true"
                (removed)="case_delete_clicked(case)"
                i18n
            >
                {{ case.title }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-set>
        <app-case-select2
            class="w-100"
            [(caseListItem)]="caseListItem"
            [duplicateFilterList]="caseEditList"
            [duplicateFilterProperty]="'title'"
            (change)="cases_add()"
        ></app-case-select2>
    </div>

    <div class="tag-stuff-container">
        <button
            mat-button
            class="smx-secondary-button m-1"
            i18n
            (click)="openTagTypeSelection()"
        >
            Add Tag
        </button>

        <div class="tags-container" formArrayName="tags">
            <div
                class="tag-item"
                *ngFor="let tag of form.controls.tags.controls; let i = index"
            >
                <mat-form-field
                    class="w-100"
                    appearance="outline"
                    style="margin-top: 22px"
                >
                    <mat-label>{{ tag.name }}</mat-label>
                    <input matInput [formControl]="tag" />
                </mat-form-field>
                <button
                    mat-mini-fab
                    color="primary"
                    class="ml-2"
                    (click)="tag_delete_clicked(i)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
