import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import {
    SecuraMaxApiService,
    UserQueryResponse,
} from '../../../services/api/securamaxapi.service';
import { TitleService } from '../../../services/title.service';
import { UsersListStateService } from './users-list-state.service';
import { SPaginate2Component } from 'src/app/shared/s-paginate2/s-paginate2.component';

export interface IUserListComponentState {
    searchTerm: string;
    showClosed: boolean;
    pageIndex: number;
    pageSize: number;
}
export interface IUserListComponentSearchFilter {
    searchTerm: string;
    showClosed: boolean;
}

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.css'],
})
export class UsersListComponent implements OnInit {

    @ViewChild("paginator") paginator: SPaginate2Component;

    datasource: UserQueryResponse[];
    state: IUserListComponentState = {
        searchTerm: '',
        showClosed: false,
        pageIndex: 0,
        pageSize: 10,
    };
    totalItems: number;
    modelFromJS: any = UserQueryResponse.fromJS;
    loading: boolean = true;
    urlParams = {};
    stateSub: any;

    constructor(
        private api: SecuraMaxApiService,
        private toastr: SnackbarService,
        private titleService: TitleService,
        private stateService: UsersListStateService
    ) {}

    ngOnInit() {
        this.titleService.setTitle('Manage Users');
    }

    ngAfterViewInit() {
        this.stateSub = this.stateService.state.subscribe((state) => {
            this.state.searchTerm = state.searchTerm;
            this.state.pageIndex = state.pageIndex;
            this.state.pageSize = state.pageSize;
            this.state.showClosed = state.showClosed;
            this.state = Object.assign({}, this.state);
            this.urlParams = {
                showClosed: this.state.showClosed,
                searchTerm: this.state.searchTerm,
            };
            setTimeout(() => this.paginator.somethingChanged());
        });
    }

    ngOnDestroy() {
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }
    }

    pageChange(event: any) {
        this.stateService.updateState({ pageIndex: event.pageIndex, pageSize: event.pageSize });
    }

    onDataSourceUpdate(data: UserQueryResponse[]) {
        this.datasource = data;
        this.loading = false;
    }

    numItemsUpdated(data: number) {
        this.totalItems = data;
    }

    showClosedChanged() {
        this.stateService.updateState(this.state);
    }

    resendVerification(user: UserQueryResponse) {
        this.api
            .users_PutResendVerification(user.iD)
            .toPromise()
            .then(() => {
                this.toastr.success('Verification resent.');
            })
            .catch(() => {
                this.toastr.error('Error resending verification!');
            });
    }

    onSearchCleared() {
        this.stateService.updateState({
            searchTerm: '',
            pageIndex: 0,
            pageSize: 10,
            showClosed: false,
        });
    }

    onSearch(searchText: string) {
        this.stateService.updateState({
            searchTerm: searchText,
            pageIndex: 0,
            showClosed: false,
        });
    }
}
