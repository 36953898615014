<div class="card mt-3">
    <div class="card-body">
        <h1 i18n>Profile Management</h1>
        <div class="outer-container">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div *ngIf="userLoaded">
                            <div class="container-fluid">
                                <div *ngIf="userService.hasAccessToLive()" class="row">
                                    <div class="col">
                                        <label id="home_page_label">Change starting home page:</label>
                                        <mat-radio-group aria-labelledby="home_page_label" [(ngModel)]="user.defaultHomepage" [disabled]="this.isSaving" (change)="save()">
                                            <mat-radio-button value="review" >Review</mat-radio-button>
                                            <mat-radio-button value="live" >Live</mat-radio-button>
                                          </mat-radio-group>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >Video Identifier</label
                                        >
                                        <div>
                                            {{
                                                user.syncUserNameAlias != null
                                                    ? user.syncUserNameAlias
                                                    : "Not Set"
                                            }}
                                        </div>
                                        <!-- <mat-form-field appearance="fill">
                                            <mat-label i18n>User Alias</mat-label>
                                            <input matInput required name="alias" type="text" #syncUserNameAlias="ngModel" [(ngModel)]="user.syncUserNameAlias" name="syncUserNameAlias" userAliasValidator="{{user.iD}}" pattern="[a-zA-Z0-9.]+"/>
                                        </mat-form-field> -->
                                    </div>
                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >Email</label
                                        >
                                        <div>
                                            {{ user.email }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >First Name</label
                                        >
                                        <div>
                                            {{ user.firstName }}
                                        </div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >Last Name</label
                                        >
                                        <div>
                                            {{ user.lastName }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >Agency/Org</label
                                        >
                                        <div>
                                            {{
                                                user.agencyOrg != null
                                                    ? user.agencyOrg
                                                    : "Not Set"
                                            }}
                                        </div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >Mobile Phone</label
                                        >
                                        <div>
                                            {{
                                                user.mobilePhone != null
                                                    ? user.mobilePhone
                                                    : "Not Set"
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >Country</label
                                        >
                                        <div>
                                            {{
                                                user.country != null
                                                    ? user.country
                                                    : "Not Set"
                                            }}
                                        </div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >State</label
                                        >
                                        <div>
                                            {{
                                                user.state != null
                                                    ? user.state
                                                    : "Not Set"
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-6">
                                        <label class="control-label" i18n
                                            >Mobile Phone</label
                                        >
                                        <div>
                                            {{
                                                user.mobilePhone != null
                                                    ? user.mobilePhone
                                                    : "Not Set"
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
