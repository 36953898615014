import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import {
    BC4ListItemViewModel,
    SecuraMaxApiService,
} from '../../../services/api/securamaxapi.service';
import { Router } from '@angular/router';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';

@Component({
    selector: 'app-bc4configs-create',
    templateUrl: './bc4configs-create.component.html',
    styleUrls: ['./bc4configs-create.component.css'],
})
export class Bc4configsCreateComponent implements OnInit {
    configs: BC4ListItemViewModel[];
    loading: boolean = true;
    configForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        basedOffId: new UntypedFormControl('', Validators.required),
    });

    constructor(
        private api: SecuraMaxApiService,
        private toastr: SnackbarService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.api
            .bC400_Get()
            .toPromise()
            .then((data) => {
                this.loading = false;
                this.configs = data;
            })
            .catch((err) => {
                this.loading = false;
                this.toastr.error('Error loading configurations');
            });
    }

    get f() {
        return this.configForm.controls;
    }

    create() {
        var that = this;
        this.loading = true;
        that.api
            .bC400_GetConfig(this.configForm.controls.basedOffId.value)
            .toPromise()
            .then(
                (data) => {
                    let config = data;
                    config.name = this.configForm.controls.name.value;
                    config.id = 0;
                    that.api
                        .bC400_PostConfig(config)
                        .toPromise()
                        .then((newId) => {
                            this.configForm.reset();
                            this.loading = false;
                            //navigate to edit
                            this.router.navigate([
                                '/admin/devices/bc4configs/',
                                newId,
                                'edit',
                            ]);
                        })
                        .catch((err) => {
                            this.loading = false;
                            this.toastr.error('Error loading configurations');
                        });
                },
                (err) => {
                    this.loading = false;
                    this.toastr.error('Error loading configurations');
                }
            );
    }
}
