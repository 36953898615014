<div class="card mt-3">
    <div class="card-body card-container">
        <div class="header-container" style="grid-area: header;">
            <h1 i18n>Reports</h1>
        </div>

        <mat-nav-list class="raised-container custom-nav-list" style="grid-area: nav;">
            <a mat-list-item routerLink="/admin/reports/pendingdeletion" *ngIf="userService.hasPermission('view_report_pending_deletion')" routerLinkActive="active" i18n>Pending Deletion</a>
            <a mat-list-item routerLink="/admin/reports/deviceusage" *ngIf="userService.hasPermission('view_report_device_usage')" routerLinkActive="active" i18n>Device Usage</a>
            <a mat-list-item routerLink="/admin/reports/usagehistory" *ngIf="userService.hasPermission('view_report_device_usage')" routerLinkActive="active" i18n>Usage History</a>
            <a mat-list-item routerLink="/admin/reports/documentStorageUsage" *ngIf="userService.hasPermission('view_report_device_usage')" routerLinkActive="active" i18n>Large Documents Report</a>
            <a mat-list-item routerLink="/admin/reports/awaitingDeleteApproval" *ngIf="userService.hasPermission('approve_deletion_requests')" routerLinkActive="active" i18n>Awaiting Delete Approval</a>
            <a mat-list-item routerLink="/admin/reports/auditUser" *ngIf="userService.hasPermission('manage_users')" routerLinkActive="active" i18n>Audit User</a>

        </mat-nav-list>

        <div style="grid-area: sub; min-height: 0;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
