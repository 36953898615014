<div class="card mt-3">
    <div class="card-body">
        <div class="d-flex justify-content-between">
            <h1 i18n>Create New Time-Based Association</h1>
            <div>
            </div>
        </div>

        <div id="card_body_container_container">
            <form [formGroup]="form" id="card_body_container">
                <div
                    [visTimeline]="timeline.id"
                    [visTimelineItems]="timeline.items"
                    [visTimelineOptions]="timeline.options"
                    (initialized)="timeline_initialized($event)"
                ></div>

                <div class="buttons-container">
                    <div>Click and drag on timeline to view active associations, use scroll wheel to zoom in and out. The red line shows the current time. <br> Information will apply to documents uploaded in the selected
                        time-range for the current user. The new association being created cannot overlap any existing active associations.</div>
                    <div style="grid-column: -1">
                        <button mat-button
                                (click)="goToNow_clicked()"
                                class="smx-secondary-button m-1"
                                i18n
                        >
                            Show Current Time/Date
                        </button>
                    </div>
                </div>

                <div i18n
                ><br/>
                </div>

                <div class="d-flex w-100 mt-4">
                    <div class="label-and-control-container filter-settings-container">
                        <label>Date and Time Range*</label>
                        <app-daterangepicker #dateRangePicker (change)="daterangepicker_change($event)"
                                             [(start)]="daterangepicker_start"
                                             [(end)]="daterangepicker_end"
                        [placeholder]="'Select a Date/Time'"></app-daterangepicker>
                    </div>
                </div>

                <app-review-metadata-controls formGroupName="metadata"
                                              wide="true"
                ></app-review-metadata-controls>


                <div class="d-flex justify-content-end">
                    <button mat-button
                            (click)="save()"
                            [disabled]="state === 'Saving'"
                            i18n
                            class="smx-primary-button mr-1"
                    >
                        Save
                    </button>
                    <button mat-button
                            (click)="saveAndCreateAnother_click()"
                            i18n
                            [disabled]="state === 'Saving'"
                            class="smx-secondary-button mr-1"
                    >
                        Save and Create Another
                    </button>
                    <button mat-button
                            class="smx-critical-button"
                            [disabled]="state === 'Saving'"
                            routerLink="/review/autoreviewrules/list"
                            i18n
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>


    </div>
</div>
