/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WifiNetwork } from './wifiNetwork';


export interface BC4ConfigViewModel { 
    id?: number;
    name: string;
    frontLed?: boolean;
    audibleTones?: boolean;
    audibleToneVolume?: number;
    vibrations?: boolean;
    lcdDisplayBrightness?: number;
    timeZone?: string | null;
    recordingResolution?: string | null;
    recordingFrameRate?: number;
    recordingBitRate?: string | null;
    backgroundRecord?: boolean;
    preEventRecord?: number;
    postEventRecord?: number;
    preEventBackgroundAudioRecord?: boolean;
    eventAudioRecord?: boolean;
    muteButton?: boolean;
    activateOtherDevices?: boolean;
    activateDevices?: string | null;
    activateByInCarSystems?: boolean;
    activateByBodycam?: boolean;
    activateByTriggerDevice?: boolean;
    autoLogoutUser?: number;
    enableEIS?: boolean;
    wifi?: boolean;
    wifiNetworks?: Array<WifiNetwork> | null;
    cellularData?: boolean;
    apn?: string | null;
}

