<form #shareDialogForm="ngForm" (ngOnSubmit)="onSubmit()">
    <h1 mat-dialog-title i18n>Share Selected Documents</h1>
    <mat-dialog-content>
        <!--Add name picker, datetime picker here-->

        <mat-radio-group [(ngModel)]="data.shareUserType" class="mr-3 mt-3" name="shareUserType">
            <mat-radio-button class="mr-3" [value]="0" i18n>
                <label class="mt-3">Existing User</label>
            </mat-radio-button>
            <mat-radio-button [value]="1" i18n>
                <label class="mt-3">New Email</label></mat-radio-button>
        </mat-radio-group>
        <app-user-select [(userId)]="data.shareSelect.associatedUserId"
            [(associatedUserName)]="data.shareSelect.associatedUser"
            [(userEmail)]="data.shareSelect.associatedUserEmail" [includeCurrentUser]="false"
            *ngIf="data.shareUserType == 0" required></app-user-select>

        <mat-form-field appearance="outline" *ngIf="data.shareUserType == 1" class="full-width">
            <input matInput class="px-2 py-2" placeholder="Email: john.doe@gmail.com"
                [(ngModel)]="data.newShareUserInput" type="email" email name="newShareUserInput" required
                i18n-placeholder>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Link Expires On</mat-label>
            <input matInput name="expireDateTime" placeholder="Choose an expiration date & time"
                [(ngModel)]="data.expireDateTime" [min]="data.shareMinDate" [min]="minShareDate"
                [matDatepicker]="picker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <h3 i18n>Permissions:</h3>
        <div class="d-flex flex-column">
            <mat-checkbox class="share-dialog-checkbox" [(ngModel)]="data.canView" name="canView" checked disabled i18n>
                <label class="mt-3">Can View</label>
            </mat-checkbox>
            <mat-checkbox class="share-dialog-checkbox" [(ngModel)]="data.canEditMetadata" name="canEditMetadata" i18n>
                <label class="mt-3">Can Edit Metadata</label>
            </mat-checkbox>
            <mat-checkbox class="share-dialog-checkbox" [(ngModel)]="data.canDownload" name="canDownload" i18n>
                <label class="mt-3">Can Download</label>
            </mat-checkbox>
            <mat-checkbox class="share-dialog-checkbox" [(ngModel)]="data.canReShare" name="canReShare" i18n>
                <label class="mt-3">Can Re-Share</label>
            </mat-checkbox>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end">
        <button mat-button class="smx-primary-button mr-1" [disabled]="!shareDialogForm.form.valid" type="submit" [mat-dialog-close]="data"
            i18n>Share</button>
        <button mat-button class="smx-critical-button" mat-dialog-close i18n>Cancel</button>
    </mat-dialog-actions>
</form>
