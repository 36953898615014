/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceCamerasViewModel } from './deviceCamerasViewModel';


export interface DeviceDetailsModel { 
    deviceId?: string;
    unitId?: string | null;
    serial?: string | null;
    deviceType?: string | null;
    lastConnected?: string | null;
    totalFilesUploaded?: number;
    totalBytesTransferred?: number;
    totalTimeUploadingSeconds?: number;
    alarmTransfer?: boolean;
    isDisabled?: boolean;
    cameras?: Array<DeviceCamerasViewModel> | null;
}

