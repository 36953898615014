import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserQueryResponse } from 'src/app/services/api/securamaxapi.service';
import { MAT_DATEPICKER_VALIDATORS } from '@angular/material/datepicker';

export interface DialogData {
  name: string,
  associatedUserId: number,
  associatedUser: string,
  deviceType: string
}

@Component({
  selector: 'app-user-audit-report-select-dialog',
  templateUrl: './user-audit-report-select-dialog.component.html',
  styleUrls: ['./user-audit-report-select-dialog.component.css']
})
export class UserAuditReportSelectDialogComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<UserAuditReportSelectDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: UserQueryResponse) { }
  maxDate: Date = new Date();
  startDate: UntypedFormControl = new UntypedFormControl(this.addMonths(new Date(), -3), [Validators.required])

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close({ iD: this.data.iD, startDate: this.formatDate(this.startDate.value) });
  }

  private formatDate(date: Date): String {
    return date.toISOString().substring(0, 10);
  }
  private addMonths(date: Date, months: number): Date {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

}
