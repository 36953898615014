import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SnackbarService } from '../../../services/snackbar.service';
import { BC4ConfigGroupAssignmentViewModel, BC4ListItemViewModel, SecuraMaxApiService } from '../../../services/api/securamaxapi.service';

@Component({
  selector: 'app-bc4configs-assign',
  templateUrl: './bc4configs-assign.component.html',
  styleUrls: ['./bc4configs-assign.component.css']
})
export class Bc4configsAssignComponent implements OnInit {
  configs: BC4ListItemViewModel[];
  groups: BC4ConfigGroupAssignmentViewModel[];
  loading: boolean = false;

  constructor(private api: SecuraMaxApiService, private toastr: SnackbarService, private location: Location) { }

  ngOnInit(): void {
    //TODO: this comes back as capitalized--find out why
    this.api.bC400_GetAssignments().toPromise().then((data) => {
      this.groups = data;
    }, function (err) {
      this.toastr.error('Error loading configurations');
    });

    this.api.bC400_Get().toPromise().then((data) => {
      this.configs = data;
    }, function (err) {
      this.toastr.error('Error loading configurations');
    });
  }

  save() {
    var that = this;
    that.loading = true;
    this.api.bC400_PutAssignments(that.groups).toPromise().then((data) => {
      this.toastr.success('Saved!');
      that.loading = false;
    }, function (err) {
      that.loading = false;
      this.toastr.error('Error saving!');
    });
  };

  cancel() {
    this.location.back();
  };
}
