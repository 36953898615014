/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceDocumentListViewModel { 
    id?: string;
    channel?: number;
    tags?: Array<string> | null;
    categories?: Array<string> | null;
    name?: string | null;
    uploaded?: string;
    ownerId?: number;
    start?: string | null;
    end?: string | null;
    important?: boolean;
    ownerName?: string | null;
    title?: string | null;
    device?: string | null;
    deviceId?: string | null;
    duration?: string | null;
    initialCreateDate?: string | null;
    parentId?: any | null;
}

