import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSelectClearService {

  constructor() { }

  private clearUserSelectAnnouncedSource = new Subject<string>();
  private clearUserSelectConfirmedSource = new Subject<string>();

  clearUserSelectAnnounced$ = this.clearUserSelectAnnouncedSource.asObservable();
  clearUserSelectConfirmed$ = this.clearUserSelectConfirmedSource.asObservable();

  announceUserClear(whichSelect: string) {
    this.clearUserSelectAnnouncedSource.next(whichSelect);
  }

  confirmUserClear(whichSelect: string) {
    this.clearUserSelectConfirmedSource.next(whichSelect);
  }
}
