import {ChangeDetectorRef, Component} from '@angular/core';
import {PagingApiService, PagingModel} from "../../../services/paging-api.service";
import {PageEvent} from "@angular/material/paginator";
import {DocumentSearchService} from "../../../services/document-search.service";
import {
    DocumentListViewModel,
    SecuraMaxApiService,
    TimelineSearchViewModel
} from "../../../services/api/securamaxapi.service";
import {
    TimelineData, TimelineDocumentViewModel,
    VisRangeChangedEventProps
} from "../documents-search-timeline/documents-search-timeline.component";
import {SnackbarService} from "../../../services/snackbar.service";
import {VisTimelineService} from "../../../shared/components/vis-timeline/vis-timeline.service";
import {DataGroup, DataItem, DateType, TimelineOptions} from "vis-timeline/peer";
import * as moment from "moment/moment";
import {DataSet} from "vis-data";
import {SearchFilter} from "../../../models/search-filter.model";

class DocumentListViewModelExtended extends DocumentListViewModel {
    children: DocumentListViewModel[];
}



@Component({
  selector: 'app-documents-search-timeline2',
  templateUrl: './documents-search-timeline2.component.html',
  styleUrls: ['./documents-search-timeline2.component.css']
})
export class DocumentsSearchTimeline2Component {

    pageSizeOptions: number[] = [5, 10, 25, 100];
    isLoading: boolean = true;
    pagingModel: PagingModel = {
        from: 0,
        to: 4,
        totalRecordCount: 0
    };
    pagingModel_currentPageIndex = 0;
    pagingModel_perPage = 5;

    currentTimelinesDataArray: TimelineData[]=[];
    loadingTracker: any = {};
    webUploadTimelineId: string="webuploads";

    datasource: DocumentListViewModelExtended[];

    constructor(
        private securaMaxAPIService: SecuraMaxApiService,
        public documentSearchService: DocumentSearchService,
        private pagingApiService: PagingApiService,
        private cdr: ChangeDetectorRef,
        private visTimelineService: VisTimelineService,
        private toastr: SnackbarService) {
    }


    ngOnInit() {
        this.documentSearchService.currentSearchFilter.subscribe(currentSearchFilter => {
            this.isLoading = true;
            this.pagingModel.from = currentSearchFilter.from;
            this.pagingModel.to = currentSearchFilter.to;
            this.pagingApiService
                .fetchData<TimelineSearchViewModel>('/api/api/documents/searchTimelineDevices', currentSearchFilter, TimelineSearchViewModel.fromJS, this.pagingModel)
                .subscribe(x => {
                    this.pagingModel.totalRecordCount = x.totalRecords;
                    this.onDataSourceUpdate(x.data, currentSearchFilter);
                    this.isLoading = false;
                });
        })

        this.setupTimelineEventHandlers();
    }

    getTimelineOptions(tdData: TimelineData, currentSearchFilter: SearchFilter){
        let opts: TimelineOptions = {
            align: 'center',
            autoResize: true,
            editable: false,
            selectable: true,
            stack: false,
            orientation: 'bottom',
            showCurrentTime: true,
            showMajorLabels: true,
            showMinorLabels: true,
            groupTemplate: function (data: any, element: any) {
                if(!data){
                    return '';
                }
                return '<div class="timelineGroupBox">' +
                    '<h5 class="timelineGroupContent">Channel: ' + data.id + ' </h5></div>';
            },
            moment: function (date) {
                return moment.utc(date).local();
            },
            min: this.makeUtcLocal(tdData.device.firstDocumentDate).subtract(6, 'hours').toDate(),
            max: this.makeUtcLocal(tdData.device.lastDocumentDate).add(6, 'hours').toDate(),
            start: this.makeUtcLocal(tdData.device.lastDocumentDate).startOf('day').toDate(),
            end: this.makeUtcLocal(tdData.device.lastDocumentDate).endOf('day').toDate()
        };

        if (currentSearchFilter.start) {
            opts.min = this.makeUtcLocal(currentSearchFilter.start).toDate();
            opts.start = this.makeUtcLocal(currentSearchFilter.start).toDate();
        }
        if (currentSearchFilter.end) {
            opts.max = this.makeUtcLocal(currentSearchFilter.end).add(1, 'day').toDate();
            opts.end = this.makeUtcLocal(currentSearchFilter.end).add(1, 'day').toDate();
        }

        return opts;
    }

    registerTimelineEventHandlers(timelineId){
        this.visTimelineService.on(timelineId, 'click');
        this.visTimelineService.on(timelineId, 'timechanged');
        this.visTimelineService.on(timelineId, 'select');
        this.visTimelineService.on(timelineId, 'doubleClick');
        this.visTimelineService.on(timelineId, 'rangechanged');
    }

    setupTimelineEventHandlers(){
        this.visTimelineService.click.subscribe((eventData: any[])=>{
            //console.log(eventData);
        });

        this.visTimelineService.timechanged.subscribe((eventData:any[])=>{
            //console.log(eventData);
        });

        this.visTimelineService.select.subscribe((eventData:any[])=>{
            if(eventData.length==2){
                let tlId = eventData[0];
                let items = eventData[1].items;

                if (items.length > 0) {
                    this.currentTimelinesDataArray.forEach((item)=>{
                        if(item.id===tlId){
                            item.selectedDocumentId=items[0];
                            this.cdr.detectChanges();
                            return;
                        }
                    });
                } else {
                    this.currentTimelinesDataArray.forEach((item)=>{
                        if(item.id===tlId){
                            item.selectedDocumentId=null;
                            this.cdr.detectChanges();
                            return;
                        }
                    });
                }
            }
        });

        this.visTimelineService.doubleClick.subscribe((eventData:any[])=>{
            let tlId=eventData[0];
            console.log(eventData);
            //this.getTimelineDoubleClick
            //   var that = this;
            // return function (evt: any) {
            //     var itemId = evt.item;
            //     if (itemId && itemId !== '') {
            //         //that.$state.go('documents.details', { id: itemId }, { reload: true });
            //     }
            // };
        });

        this.visTimelineService.rangechanged.subscribe((eventData:any[])=>{
            if(eventData.length===2){
                let tlId=eventData[0];
                let evt: VisRangeChangedEventProps = eventData[1];
                this.currentTimelinesDataArray.forEach((item)=>{
                    if(item.id===tlId){

                        const currentSearch = this.documentSearchService.currentSearchFilter.value;
                        this.loadMonth(item,evt.start, currentSearch);
                        return;
                    }
                });
            }
            //sample eventData[1]
            //byUser: true
            //end: Wed May 19 2021 08:48:04 GMT-0400 (Eastern Daylight Time) {}
            //event: {pointers: Array(1), changedPointers: Array(1), pointerType: "mouse", srcEvent: PointerEvent, isFirst: false, …}
            //start: Tue May 18 2021 08:48:04 GMT-0400 (Eastern Daylight Time) {}
        });
    }

    transformDocumentResponse(docuResponse: TimelineDocumentViewModel[]) {
        let docs = [];
        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId == null) {
                docuResponse[i].children = [];
                docs.push(docuResponse[i]);
            }
        }

        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId != null) {
                var parentFound = false;
                for (var z = 0; z < docs.length; z++) {
                    if (docs[z].id === docuResponse[i].parentId) {
                        docs[z].children.push(docuResponse[i]);
                        parentFound = true;
                    }
                }
                //don't indent if parent not found
                if (parentFound === false) {
                    docs.push(docuResponse[i]);
                }
            }
        }
        return docs;
    }

    makeUtcLocal(date) {
        return moment.utc(date).local();
    }

    setType(fileName: string, start?: any, end?: any) {
        if (fileName.endsWith('.mp4') || fileName.endsWith('.avi') || fileName.endsWith('.MOV')) {
            if (start != null && end != null) {
                return 'range';
            }
        }

        return 'point';
    }

    loadMonth(timeline: TimelineData, dateInMonthLocal: DateType, currentSearchFilter: SearchFilter) {

        var monthStart = moment(moment(dateInMonthLocal).startOf('month').format());
        var dataKey = timeline.id + '_' + monthStart.format('YYYY-MM');

        if (dataKey in this.loadingTracker) {
            return;
        }

        this.loadingTracker[dataKey] = true;

        var tlf: any = {
            deviceId: timeline.id,
            start: monthStart.utc().toDate(),
            end: monthStart.endOf('month').utc().toDate()
        };

        var docFilter = Object.assign(tlf, currentSearchFilter);
        tlf.start = moment(tlf.start);
        tlf.end = moment(tlf.end);


        if (docFilter.groups && docFilter.groups !== '') {
            tlf.groups = docFilter.groups;
        }

        if (docFilter.categories && docFilter.categories !== '') {
            tlf.categories = docFilter.categories;
        }

        if (docFilter.status && docFilter.status !== '') {
            tlf.status = docFilter.status;
        }

        if (docFilter.searchTerm && docFilter.searchTerm !== '') {
            tlf.searchTerm = docFilter.searchTerm;
        }

        if (docFilter.sharedByMeOnly) {
            tlf.sharedByMeOnly = docFilter.sharedByMeOnly;
        }

        if (docFilter.sharedWithMeOnly) {
            tlf.sharedWithMeOnly = docFilter.sharedWithMeOnly;
        }

        if (docFilter.userIds && docFilter.userIds !== '') {
            tlf.userIds = docFilter.userIds;
        }

        if (docFilter.includeDeleted) {
            tlf.includeDeleted = docFilter.includeDeleted;
        }

        if (docFilter.importantOnly) {
            tlf.importantOnly = docFilter.importantOnly;
        }

        this.securaMaxAPIService.documents_GetAllTimeline(timeline.id === this.webUploadTimelineId ? undefined : timeline.id,
            tlf.searchTerm,
            tlf.start,
            tlf.end,
            tlf.userIds,
            tlf.groups,
            tlf.categories,
            tlf.status,
            tlf.sharedWithMeOnly,
            tlf.sharedByMeOnly,
            false,
            tlf.includeDeleted,
            tlf.importantOnly).subscribe((data) => {
            //this.loadingTracker[dataKey] = true;
            var its:DataItem[] = [];

            for (var i = 0; i < data.length; i++) {
                var element: DataItem = {
                    id: data[i].id,
                    group: data[i].channel,
                    content: data[i].name,
                    start: this.makeUtcLocal(data[i].start).toDate(),
                    end: this.makeUtcLocal(data[i].end).toDate(),
                    className: '',
                    type: this.setType(data[i].name, data[i].start, data[i].end)
                };

                if (data[i].important === true) {
                    element.className = 'important';
                }
                its.push(element);
            }

            timeline.items.add(its);

            this.cdr.detectChanges();
            this.visTimelineService.setItems(timeline.id, its);
        });
    }


    refreshResults(params) {
        return this.pagingApiService.fetchData<TimelineSearchViewModel>('/api/api/documents/searchTimelineDevices', params, TimelineSearchViewModel.fromJS, this.pagingModel).subscribe((res) => {
            const currentSearch = this.documentSearchService.currentSearchFilter.value;
            this.onDataSourceUpdate(res.data, currentSearch);
            this.pagingModel.totalRecordCount = res.totalRecords;
        }, (err) => {
            this.toastr.error("An error occurred while searching for documents.");
        });
    }



    onDataSourceUpdate(newTimelines: TimelineSearchViewModel[], currentSearchFilter: SearchFilter) {
        if(this.currentTimelinesDataArray?.length > 0) {
            this.currentTimelinesDataArray.forEach((elem)=>{
                this.visTimelineService.destroy(elem.id);
            });
        }
        this.currentTimelinesDataArray=[];
        this.loadingTracker={};
        for(let deviceIndex = 0; deviceIndex < newTimelines.length; deviceIndex++) {
            var that = this;
            var groups = new DataSet<DataGroup, "id">({});

            for(let i = 0; i < newTimelines[deviceIndex].deviceExpandedVM.channels.length; i++) {
                groups.add({
                    id: newTimelines[deviceIndex].deviceExpandedVM.channels[i],
                    content: newTimelines[deviceIndex].deviceExpandedVM.channels[i] + ''
                });
            }

            var items = new DataSet<DataItem, "id">({});

            var tempTimelineData: TimelineData = {
                groups: groups,
                items: items,
                id: newTimelines[deviceIndex].deviceExpandedVM.id ?? this.webUploadTimelineId,
                device: newTimelines[deviceIndex].deviceExpandedVM,
                selectedDocumentId:null,
                options: null
            };
            tempTimelineData.options = this.getTimelineOptions(tempTimelineData, currentSearchFilter);

            that.currentTimelinesDataArray.push(tempTimelineData);
            that.loadMonth(tempTimelineData, tempTimelineData.options.start, currentSearchFilter);
        }
    }



    ngOnDestroy(): void{
        this.currentTimelinesDataArray.forEach((item)=>{
            this.visTimelineService.destroy(item.id);
        });
    }


    onPageFired(event: PageEvent) {
        this.pagingModel_currentPageIndex = event.pageIndex;
        this.pagingModel_perPage = event.pageSize;

        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        currentSearch.from = this.pagingModel_currentPageIndex * this.pagingModel_perPage;
        currentSearch.to = currentSearch.from + this.pagingModel_perPage - 1;
        this.documentSearchService.updateSearch(currentSearch);
    }

    list_click() {
        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        currentSearch.isListView = true;
        this.documentSearchService.updateSearch(currentSearch);

    }
}
