/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentPermissionsViewModel { 
    canShare?: boolean;
    canDownload?: boolean;
    canEditMetadata?: boolean;
    canPlay?: boolean;
    canDelete?: boolean;
    canDeletionLock?: boolean;
    canDownloadAuditReport?: boolean;
    canArchive?: boolean;
    canViewMetadata?: boolean;
    canView?: boolean;
    canRequestDelete?: boolean;
    canApproveDelete?: boolean;
    canShareModifyOtherShares?: boolean;
    canShareEditMetadata?: boolean;
    canShareDownload?: boolean;
    canAdvancedRedact?: boolean;
}

