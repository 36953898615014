<div mat-dialog-title class="title">
    <div>Live View</div>
</div>

<mat-dialog-content class="content">
    <p>
        The live video stream will display in the PV Live View app.
        <br>
        Not working? <a href="https://files.provisionusa.com/software/pvliveview/PVLiveView-win-Setup.exe" target="_blank">Download the PV Live View App here.</a>
    </p>
    <p>
        This dialog will close in {{ timeRemaining }} seconds.
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="primary" class="smx-primary-button" [mat-dialog-close]="true" i18n>OK</button>
</mat-dialog-actions>
