/**
 * SecuraMax API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeleteReason } from './deleteReason';


export interface PendingDeletionViewModel { 
    requestedDeleteId?: number | null;
    id?: string;
    name?: string | null;
    requestedDate?: string;
    projectedDate?: string;
    reasonCategory?: string | null;
    deleteRequestorKey?: number | null;
    deleteRequestorName?: string | null;
    readonly displayReason?: string | null;
    reason?: DeleteReason;
    shouldHiddenDelete?: boolean;
    readonly tenantName?: string | null;
    readonly deviceKey?: string | null;
}
export namespace PendingDeletionViewModel {
}


