<div class="card mt-3">
    <div class="card-body card-container">
        <div class="d-flex justify-content-between">
            <h1 i18n>Review by Time - Current Associations</h1>
            <div>
                <button mat-button class="smx-primary-button" (click)="new_click()">Create New Time Association</button>
            </div>
        </div>
        <div class="d-flex">
            Review by time allows you to associate tags and categories to specific time periods. By creating time associations, SecuraMax will automatically tag any videos created during those times from both body cameras and vehicles.
        </div>

        <div class="d-flex search-controls filter-settings-container">
            <app-searchbar (search)="search($event)"></app-searchbar>

            <div class="label-and-control-container ml-3">
                <label>Date and Time Range</label>
                <app-daterangepicker #dateRangePicker (change)="daterangepicker_change($event)" [(start)]="daterangepicker_start" [(end)]="daterangepicker_end"></app-daterangepicker>
            </div>

            <mat-slide-toggle class="ml-5" [(ngModel)]="filter.active" i18n>Active</mat-slide-toggle>

            <mat-slide-toggle class="ml-5 mb-0" [(ngModel)]="filter.disabled" i18n>Disabled</mat-slide-toggle>

            <mat-slide-toggle class="ml-5" [(ngModel)]="filter.expired" i18n>Expired</mat-slide-toggle>
        </div>

        <div class="table-container">
            <table class="table">
                <thead>
                <tr>
                    <th i18n>Title</th>
                    <th i18n>Start Time</th>
                    <th i18n>End Time</th>
                    <th i18n>Associated Videos</th>
                    <th i18n>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let timeBasedTag of timeBasedTagsList"
                    [class]="{'active': (timeBasedTag.isActive) != 'Active'}">
                    <td>{{timeBasedTag.title}}</td>
                    <td>{{timeBasedTag.startRange | date:'long' }}</td>
                    <td>{{timeBasedTag.endRange | date:'long' }}</td>
                    <td>{{timeBasedTag.autoReviewedDocumentsCount}}</td>

                    <td>
                        <span
                            [class]="(timeBasedTag.isActive? 'badge badge-success' :  'badge badge-danger')"
                            aria-hidden="true"> <span *ngIf="timeBasedTag.isActive" i18n>Active</span>
                            <span *ngIf="!timeBasedTag.isActive">Not Active</span></span>
                    </td>
                    <td>
                        <a mat-button color="primary" class="smx-secondary-button"
                           routerLink="/review/autoreviewrules/{{timeBasedTag.id}}/details" i18n>Details</a>
                    </td>
                </tr>
                <tr *ngIf="!timeBasedTagsList">
                    <td colspan="4">
                        <i class="fa fa-spin fa-spinner"></i>
                    </td>
                </tr>
                <tr *ngIf="totalItems == 0">
                    <td colspan="4">
                        <span i18n>No Tags Found</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div>
            <app-s-paginate [modelFromJS]="modelFromJS" (collection)="onDataSourceUpdate($event)"
                            (numItems)="numItemsUpdated($event)" [url]="'/api/api/TimeBasedTags/GetAll'"
                            [perPage]="perPage" [urlParams]="filter">
            </app-s-paginate>
        </div>
    </div>
</div>
