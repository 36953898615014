<div fxLayout="column" *ngIf=!datasource>
    <div class="mb-3 mt-5" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner color="accent"></mat-spinner>
    </div>
</div>
<mat-paginator #paginator [length]="pagingModel.totalRecordCount" [pageIndex]="currentPageIndex" [pageSize]="perPage" [pageSizeOptions]="pageSizeOptions" (page)="onPageFired($event)" [hidden]="!datasource">
</mat-paginator>
<!-- <app-s-paginate (collection)="onDataSourceUpdate($event)" (numItems)="numItemsUpdated($event)" [url]="'/api/api/documents/search'" [urlParams]="filter" [modelFromJS]="modelFromJS" (pageChange)="datasource = null" [hidden]=!datasource></app-s-paginate> -->
<ul class="list-group mt-3" #documents *ngIf=datasource>
    <li class="list-group-item" *ngFor="let doc of datasource">

        <div matline class="container">
            <a matLine routerLink="/documents/{{doc.id}}">
                <div class="row">
                    <div class="col-md-4">
                        <img *ngIf="doc.thumbnailUrl" src="{{doc.thumbnailUrl}}" err-src="/images/placeholder.png" class="media-object thumb" />
                        <img *ngIf="!doc.thumbnailUrl" src="/assets/images/nothumbnail.png" class="media-object placeholder" />
                        <h5 class="media-heading" i18n>[{{doc.device}}]</h5>
                        <span *ngIf="doc.important" class="badge badge-primary" i18n>Important</span>
                        <span *ngIf="doc.statusId === 3" class="badge-default" class="badge badge-primary" i18n>Archived</span>
                    </div>
                    <div class="col-md-8">
                        <h4 class="media-heading"><span *ngIf="doc.parentId!=null" i18n>Redaction of </span> <span *ngIf="doc.title">{{doc.title}}</span></h4>
                        <p class="media-paragraph"><strong i18n>Owner: </strong>{{doc.ownerName}}</p>
                        <p class="media-paragraph"><strong i18n>File Name: </strong>{{doc.name}}</p>
                        <p *ngIf="doc.device != 'Web'" class="media-paragraph"><strong i18n>Created: </strong>{{doc.created  | date: 'MMM dd YYYY, h:mm:ss a'}}</p>
                        <p *ngIf="doc.device == 'Web'" class="media-paragraph"><strong i18n>Uploaded: </strong>{{doc.uploaded  | date: 'MMM dd YYYY, h:mm:ss a'}}</p>
                        <p class="media-paragraph" *ngIf="doc.categories && doc.categories.length>0">
                            <strong i18n>Categories: </strong>
                            <span class="badge badge-default" *ngFor="let cat of doc.categories">{{cat}}</span>
                        </p>
                    </div>
                </div>
            </a>
            <ul class="list-group mt-3" *ngIf="doc.children && doc.children.length > 0">
                <li class="list-group-item" *ngFor="let child of doc.children">
                    <a matLine routerLink="/documents/{{child.id}}">
                        <div class="row ml-5" *ngIf="doc.children.length>0">
                            <div class="col-md-4">
                                <img *ngIf="child.thumbnailUrl" src="{{child.thumbnailUrl}}" err-src="/images/placeholder.png" class="media-object thumb" />
                                <img *ngIf="!child.thumbnailUrl" src="/assets/images/nothumbnail.png" class="media-object placeholder" />
                                <h5 class="media-heading" i18n>[{{child.device}}]</h5>
                                <span *ngIf="child.important" class="badge badge-primary" i18n>Important</span>
                            </div>
                            <div class="col-md-8">
                                <h4 class="media-heading"><span *ngIf="child.parentId!=null" i18n>Redaction of </span> <span *ngIf="child.title">{{child.title}}</span></h4>
                                <p class="media-paragraph"><strong i18n>Owner: </strong>{{child.ownerName}}</p>
                                <p class="media-paragraph"><strong i18n>File Name: </strong>{{child.name}}</p>
                                <p *ngIf="child.device != 'Web'" class="media-paragraph"><strong i18n>Created: </strong>{{child.created  | date: 'MMM dd YYYY, h:mm:ss a'}}</p>
                                <p *ngIf="child.device == 'Web'" class="media-paragraph"><strong i18n>Uploaded: </strong>{{child.uploaded  | date: 'MMM dd YYYY, h:mm:ss a'}}</p>
                                <p class="media-paragraph" *ngIf="child.categories && child.categories.length>0">
                                    <strong>Categories: </strong>
                                    <span class="badge badge-default" *ngFor="let cat of child.categories">{{cat}}</span>
                                </p>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </li>
</ul>

